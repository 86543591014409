/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { Moment } from 'moment';

import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';

import { useRedux } from 'util/hook/redux';

import { fetchRidingLevelsFunc, RidingLevelsApiData } from 'api/customTravelForm';
import { State as GlobalState } from './reducers';

/**
 *  從API獲取騎乘強度資料的action
 */
const getRidingLevelsData = createAction<Promise<RidingLevelsApiData[]>>(
	'GET_CUSTOM_RIDING_LEVELS',
	async () => {
		try {
			const { status, message, data } = await fetchRidingLevelsFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return data;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

// passportLastName 和 passportFirstName 兩項因應 layout 可能需求先留著
interface BasicFormData {
	lastName: string;
	firstName: string;
	passportLastName: string;
	passportFirstName: string;
	mobile: { intIdNum: string; phoneNum: string };
	email: string;
}

export type ContactFormFiled = keyof BasicFormData;

interface RequireFormData {
	preLocation: string;
	preNumberOfPeople: string;
	preDate: Moment | null;
	preNumberOfDays: string;
	remarks: string;
	isDisclaimerCheckBoxCheck: boolean;
}

export type RequireFormFiled = keyof RequireFormData;

export interface State {
	contactForm: FormDataMapping<BasicFormData>;
	requireForm: FormDataMapping<RequireFormData>;
	ridingLevels: RidingLevelsApiData[];
}

const initialState: State = {
	contactForm: {
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		passportLastName: { value: '', valid: true, error: '', required: false },
		passportFirstName: { value: '', valid: true, error: '', required: false },
		mobile: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
	},
	requireForm: {
		preLocation: { value: '', valid: true, error: '', required: true },
		preNumberOfPeople: { value: '', valid: true, error: '', required: false },
		preDate: { value: null, valid: true, error: '', required: false },
		preNumberOfDays: { value: '', valid: true, error: '', required: false },
		remarks: { value: '', valid: true, error: '', required: false },
		isDisclaimerCheckBoxCheck: { value: false, valid: true, error: '', required: true },
	},
	ridingLevels: [],
};

/**
 * 清空全部表單
 */
const resetForms = createAction('RESET_CUSTOM_TOURS_RESERVATION_FORMS');

interface ContactFormChangePayload<K extends ContactFormFiled = ContactFormFiled> {
	key: K;
	data: Partial<InputFiled<BasicFormData[K]>>;
}

const changeCustomToursReservationContactForm = createAction<
	ContactFormChangePayload,
	ContactFormChangePayload
>('CHANGE_CUSTOM_TOURS_RESERVATION_CONTACT_FORM', ({ key, data }) => ({
	key,
	data,
}));

interface RequireFormChangePayload<K extends RequireFormFiled = RequireFormFiled> {
	key: K;
	data: Partial<InputFiled<RequireFormData[K]>>;
}

const changeCustomToursReservationRequireForm = createAction<
	RequireFormChangePayload,
	RequireFormChangePayload
>('CHANGE_CUSTOM_TOURS_RESERVATION_REQUIRE_FORM', ({ key, data }) => ({
	key,
	data,
}));

export const reducer = {
	customToursReservationForm: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_CUSTOM_RIDING_LEVELS_PENDING: state => ({
				...state,
				loading: true,
				error: '',
			}),

			GET_CUSTOM_RIDING_LEVELS_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				ridingLevels: action.payload,
			}),

			GET_CUSTOM_RIDING_LEVELS_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: action.payload.message,
			}),

			RESET_CUSTOM_TOURS_RESERVATION_FORMS: state => ({
				...state,

				contactForm: initialState.contactForm,
				requireForm: initialState.requireForm,
			}),

			CHANGE_CUSTOM_TOURS_RESERVATION_CONTACT_FORM: (
				state,
				action: Action<ContactFormChangePayload>,
			) => ({
				...state,

				contactForm: {
					...state.contactForm,
					[action.payload.key]: {
						...state.contactForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CHANGE_CUSTOM_TOURS_RESERVATION_REQUIRE_FORM: (
				state,
				action: Action<RequireFormChangePayload>,
			) => ({
				...state,

				requireForm: {
					...state.requireForm,
					[action.payload.key]: {
						...state.requireForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useCustomToursReservationForm ++
++----------------------------------------------------------------------*/
const selectCustomToursReservationForm = (state: GlobalState) => ({
	contactForm: state.customToursReservationForm.contactForm,
	requireForm: state.customToursReservationForm.requireForm,
	ridingLevels: state.customToursReservationForm.ridingLevels,
});

const customToursReservationFormActionsMap = {
	resetForms,
	changeCustomToursReservationContactForm,
	changeCustomToursReservationRequireForm,
	getRidingLevelsData,
};

export const useCustomToursReservationForm = () =>
	useRedux<
		ReturnType<typeof selectCustomToursReservationForm>,
		typeof customToursReservationFormActionsMap
	>(selectCustomToursReservationForm, customToursReservationFormActionsMap);
