/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import { Dispatch } from 'redux';
import { Action, createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import {
	FAQTagCode,
	FAQTagItem,
	FAQTopicData,
	fetchFAQListFunc,
	fetchFAQSearchFunc,
	fetchFAQTagsFunc,
} from 'api/faq';

import { GetState, State as GlobalState } from './reducers';

export interface State {
	list: {
		loading: boolean;
		error: string;
		data: QuestionItemProperty[];
	};
	data: {
		loading: boolean;
		error: string;
		data: QuestionData[];
	};
	tags: {
		loading: boolean;
		error: string;
		data: {
			data: Record<FAQTagCode, (Omit<FAQTopicData, 'tags'> & { tags: number[] }) | null>;
			tagDataById: Record<number, FAQTagItem>;
		};
	};
}

const initialState: State = {
	list: {
		loading: true,
		error: '',
		data: [],
	},
	data: { loading: true, error: '', data: [] },
	tags: {
		loading: true,
		error: '',
		data: {
			data: { FAQ_COURSE: null, FAQ_RENTAL: null, FAQ_RIDER: null, FAQ_TRAVEL: null },
			tagDataById: {},
		},
	},
};

export interface QuestionItemProperty {
	id: number;
	title: string;
	content: string;
}

interface QuestionData {
	id: number;
	categoryName: string;
	list: QuestionItemProperty[];
}

const getFAQ = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<{ data: QuestionData[] }>,
	'ALL' | FAQTagCode
>('GET_FAQ', code => async (_: Dispatch, getState: GetState) => {
	try {
		const {
			routing: {
				queries: { keywords = '', categories = [] },
			},
		} = getState();

		const { status, message, data } = await fetchFAQSearchFunc({
			tag_group_codes: categories.length > 0 ? undefined : code,
			search: keywords,
			tag_ids: categories.map((id: string) => parseInt(id, 10)),
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const formattedList = data.map(d => ({
			id: d.tag_id,
			categoryName: d.tag_name,
			list: d.faqs.map(faq => ({
				id: faq.id,
				title: faq.title,
				content: faq.description,
			})),
		}));

		return {
			data: formattedList,
		};
	} catch (error) {
		return { data: [] };
	}
});

const getFAQList = createAction<Promise<{ data: QuestionItemProperty[] }>, FAQTagCode>(
	'GET_FAQ_LIST',
	async code => {
		try {
			const { status, message, data } = await fetchFAQListFunc(code);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const formattedList = data.map(d => ({
				id: d.id,
				title: d.title,
				content: d.description,
			}));

			return {
				data: formattedList,
			};
		} catch (error) {
			return { data: [] };
		}
	},
);

type GetFAQTagsPayload = Partial<State['tags']>;

const getFAQTags = createAction<Promise<GetFAQTagsPayload>>('GET_FAQ_TAGS', async () => {
	try {
		const { status, message, data } = await fetchFAQTagsFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		let newData = {} as State['tags']['data']['data'];
		let tagDataById = {} as State['tags']['data']['tagDataById'];

		Object.entries(data).forEach(([c, d]) => {
			if (d.code !== c) {
				newData = {
					...newData,
					[c]: null,
				};
				return;
			}
			const normalizedTags = d.tags.reduce<{
				ids: number[];
				byId: { [key: number]: FAQTagItem };
			}>(
				(a, v) => ({
					...a,
					ids: [...a.ids, v.id],
					byId: {
						...a.byId,
						[v.id]: v,
					},
				}),
				{ ids: [], byId: {} },
			);
			tagDataById = { ...tagDataById, ...normalizedTags.byId };

			newData = {
				...newData,
				[c]: { ...d, tags: normalizedTags.ids },
			};
		});

		return {
			data: {
				data: newData,
				tagDataById,
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				error: message,
			};
		}
		return { error: '' };
	}
});

export const reducer = {
	faq: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_FAQ_PENDING: state => ({
				...state,

				data: {
					...state.data,
					error: '',
					loading: true,
				},
			}),

			GET_FAQ_FULFILLED: (state, action: Action<{ data: QuestionData[] }>) => ({
				...state,

				data: {
					...state.data,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_FAQ_REJECTED: (state, action) => ({
				...state,

				data: {
					...state.data,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_FAQ_LIST_PENDING: state => ({
				...state,

				list: {
					...state.list,
					error: '',
					loading: true,
				},
			}),

			GET_FAQ_LIST_FULFILLED: (state, action: Action<{ data: QuestionItemProperty[] }>) => ({
				...state,

				list: {
					...state.list,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_FAQ_LIST_REJECTED: (state, action) => ({
				...state,

				list: {
					...state.list,
					loading: false,
					error: action.payload.message,
					data: [],
				},
			}),

			GET_FAQ_TAGS_PENDING: state => ({
				...state,

				tags: {
					...state.tags,
					loading: true,
					data: {
						...state.tags.data,
						data: initialState.tags.data.data,
					},
				},
			}),

			GET_FAQ_TAGS_FULFILLED: (state, action: Action<GetFAQTagsPayload>) => ({
				...state,

				tags: {
					...state.tags,
					loading: false,
					...(action.payload.data && {
						data: {
							data: action.payload.data.data,
							tagDataById: {
								...state.tags.data.tagDataById,
								...action.payload.data.tagDataById,
							},
						},
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useFAQ ++
++----------------------------------------------------------------------*/
const selectFAQ = (state: GlobalState) => state.faq;
const faqActionsMap = {
	getFAQ,
	getFAQList,
	getFAQTags,
};

export const useFAQ = () =>
	useRedux<ReturnType<typeof selectFAQ>, typeof faqActionsMap>(selectFAQ, faqActionsMap);
