import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

export type OnlyGroupType = 'STORE_TYPE' | 'STORE_SERVICE';

export interface TagData {
	id: number;
	name: string;
	code: string | null;
}

type FetchTagsFunc = (only_groups?: OnlyGroupType[]) => Promise<FetchResponse<TagData[]>>;

export const fetchTagsFunc: FetchTagsFunc = async (only_groups = []) => {
	const response = await wrapFetch(
		'tags',
		{
			method: 'GET',
		},
		{
			only_groups,
		},
	);

	return response;
};
