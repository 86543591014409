import { wrapFetch } from 'util/api';
import { FetchResponse } from 'types/FetchResponse';

type ScoreType = {
	data: {
		id: number;
		name: string;
		race_number: string;
		classification: string;
		event_name: string;
		total_time: string;
		credit_hours: null;
	}[];
};

type FetchScoreParams = {
	key_word?: string;
	page: string;
};

type FetchScore = (
	type: string,
	id: number,
	params: FetchScoreParams,
) => Promise<FetchResponse<ScoreType>>;

export const fetchScore: FetchScore = async (type, id, params) => {
	const response = await wrapFetch(
		`event/${type}/${id}/records`,
		{
			method: 'GET',
		},
		params,
	);

	return response;
};
