import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

export type NoticeType = 'rental' | 'rider' | 'travel' | 'course' | 'contact_form' | 'credit_card';

export type NoticeCode =
	| 'REGISTRATION_CONTRACT'
	| 'NATIONAL_TOURS'
	| 'INTERNATIONAL_TOURS'
	| 'PICK_UP_CONTRACT'
	| 'DISCLAIMER'
	| 'CREDIT_CARD_BINDING_DIRECTIONS';

export interface NoticeContentData {
	id: number;
	type: NoticeType;
	code: NoticeCode;
	content: string;
}

type FetchNoticeFunc = (
	type: NoticeType,
	code: NoticeCode,
) => Promise<FetchResponse<NoticeContentData>>;

/**
 * 拿其他全部的 notice content
 * @param type NoticeType
 * @param code NoticeCode
 * @returns string
 */
export const fetchNoticeFunc: FetchNoticeFunc = async (type, code) => {
	const response = await wrapFetch(`notice/${type}/${code}`, {
		method: 'GET',
	});

	return response;
};

/**
 * 專門拿定型化契約的 API
 * @param type NoticeType
 * @param code NoticeCode
 * @returns string
 */
export const fetchStandardizedContract: FetchNoticeFunc = async (type, code) => {
	const response = await wrapFetch(`notice/${type}/${code}`, {
		method: 'GET',
	});

	return response;
};

export interface NoticeCheckBoxData {
	id: number;
	type: NoticeType;
	style: string;
	is_required: boolean;
	content: string;
}

type FetchNoticeChecklistFunc = (type: NoticeType) => Promise<FetchResponse<NoticeCheckBoxData[]>>;

export const fetchNoticeChecklistFunc: FetchNoticeChecklistFunc = async type => {
	const response = await wrapFetch(`notice/checklist/${type}`, {
		method: 'GET',
	});

	return response;
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
// 操作在「客製組團需求表單」

type FetchContactFormChecklistFunc = () => Promise<FetchResponse<NoticeCheckBoxData[]>>;

export const fetchContactFormChecklistFunc: FetchContactFormChecklistFunc = async () => {
	const response = await wrapFetch(`notice/contact_form_checklist`, {
		method: 'GET',
	});

	return response;
};
