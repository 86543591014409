import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '/notice/:id',
			components: () => [import(/* webpackChunkName: 'travelClassicTourInfo' */ './component')],
			render: ([TravelClassicTourInfo]) => <TravelClassicTourInfo />,
		},
	],
};

export default routes;
