import React, { useEffect } from 'react';

import { useAuth } from 'models/auth';
import { useRouting } from 'models/routing';
import storage from 'util/storage';

const AuthGiantLayout: React.FC = ({ children }) => {
	const [{ isLogin }, { signinProcess }] = useAuth();
	const [{ pathname }] = useRouting();

	useEffect(() => {
		const refreshToken = storage.getItem('refreshToken');

		if (!isLogin && !refreshToken) {
			signinProcess(); // 如果還沒登入，也沒有 refresh token，轉導登入頁。
		}
	}, [isLogin, pathname]);

	return <>{isLogin ? children : null}</>;
};

export default AuthGiantLayout;
