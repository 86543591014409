import { FetchFunction } from 'types/FetchFunction';
import {
	GiantEventDataType,
	GiantEventModelType,
	GIANT_EVENT_DATA_TO_CODE_MAP,
} from 'types/GiantEvents';
import { EventStatus } from 'types/EventStatus';
import { PaginationData } from 'types/PaginationData';

import { wrapFetch } from 'util/api';

export type AnnouncementType = {
	id: number;
	title: string;
	description: string;
};

export interface EventLocation {
	id: number;
	name: string;
	img: string;
}

export interface CourseActivityDateData {
	date_id: number; // 識別該上課日期活動的唯一 ID
	date: string;
	active_status: EventStatus;
	is_liked: false; // 是否收藏該活動
}

interface EventData {
	model: GiantEventModelType;
	type: GiantEventDataType;
	country_id: number;
	name: string;
	activity_deadline: string;
	active_status: EventStatus;
	timezone: string;
	timezone_offset: string;
	first_banner_image_url: string;
	photo: string;
	is_liked: boolean;
	is_expired: boolean;
	locations: EventLocation[];
}

export interface EventCourseData extends EventData {
	id: number; // course ID （活動課程在後端模組的唯一 ID）
	activity_dates: CourseActivityDateData[];
	course_level_type: string;
	course_level: string;
}

export interface EventRideData extends EventData {
	id: number; // ride ID;（主題活動 和 門市約騎 在後端模組的唯一 ID）
	activity_date: string;
}

export interface EventListParams {
	type: GiantEventDataType;
	date?: string;
	search?: string;
	is_highlight?: number; // 1 = true / 0 = false
	location_ids?: number[];
	page?: number;
	per_page?: number;
}

type EventListRes = PaginationData<EventCourseData[] | EventRideData[]>;
type EventListFetch = FetchFunction<EventListRes, EventListParams>;

/**
 * 搜尋及篩選活動課程列表
 */
export const fetchEventsData: EventListFetch = async params => {
	const response = await wrapFetch(
		`events/search`,
		{
			method: 'GET',
		},
		{
			page: 1,
			...params,
			type: GIANT_EVENT_DATA_TO_CODE_MAP[(params as EventListParams).type],
		},
		{ withAuth: true },
	);

	return response;
};
