import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.adminApproveSTSTF.replace(appPath.admin, ''),
	components: () => [import(/* webpackChunkName: 'adminApproveSTSTF' */ './component')],
	render: ([AdminApproveSTSTF]) => <AdminApproveSTSTF />,
};

export default routes;
