/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createAction, handleActions } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import { fetchGuidelineDownsFunc, fetchGuidelineTopsFunc } from 'api/guideline';

import { State as GlobalState } from './reducers';

export interface GuideIntroItemProperty {
	id: number;
	title: string;
	summary: string;
	imageUrl: string;
}

interface GuideData {
	id: number;
	title: string;
	content: string;
}

interface GuidelineData {
	id: number;
	categoryName: string;
	list: GuideData[];
}

interface GuidelineTopsPayload {
	data: GuideIntroItemProperty[];
}

const getGuidelineTops = createAction<Promise<GuidelineTopsPayload>>(
	'GET_GUIDELINE_TOPS',
	async () => {
		try {
			const { status, message, data } = await fetchGuidelineTopsFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: data.map(({ id, title, description, img }) => ({
					id,
					title,
					summary: description,
					imageUrl: img,
				})),
			};
		} catch (error) {
			return { data: [] };
		}
	},
);

interface GuidelineDownsPayload {
	data: GuidelineData[];
}

const getGuidelineDowns = createAction<
	Promise<GuidelineDownsPayload>,
	{ keywords?: string; categories?: number[] }
>('GET_GUIDELINE_DOWNS', async ({ keywords = '', categories = [] }) => {
	try {
		const { status, message, data } = await fetchGuidelineDownsFunc({
			search: keywords,
			filter: categories,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: data.map(d => ({
				id: d.id,
				categoryName: d.name,
				list: d.guideline_downs.map(({ id, title, description }) => ({
					id,
					title,
					content: description,
				})),
			})),
		};
	} catch (error) {
		return { data: [] };
	}
});

const getGuidelineDownsForFilter = createAction<Promise<Partial<State['downsForFilter']>>>(
	'GET_GUIDELINE_DOWNS_FOR_FILTER',
	async () => {
		try {
			const { status, message, data } = await fetchGuidelineDownsFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: data.map(d => ({
					id: d.id,
					label: d.name,
				})),
			};
		} catch (error) {
			return { data: [] };
		}
	},
);

export interface State {
	tops: {
		loading: boolean;
		error: string;
		data: GuideIntroItemProperty[];
	};
	downs: FetchedData<GuidelineData[]>;
	downsForFilter: FetchedData<{ id: number; label: string }[]>;
}

const initialState: State = {
	tops: {
		loading: false,
		error: '',
		data: [],
	},
	downs: {
		loading: false,
		error: '',
		data: [],
	},
	downsForFilter: { loading: false, error: '', data: [] },
};

export const reducer = {
	guideline: handleActions<State, any>(
		{
			GET_GUIDELINE_TOPS_PENDING: state => ({
				...state,
				tops: {
					...initialState.tops,
					loading: true,
				},
			}),

			GET_GUIDELINE_TOPS_FULFILLED: (state, action: Action<GuidelineTopsPayload>) => ({
				...state,
				tops: {
					...state.tops,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_GUIDELINE_TOPS_REJECTED: (state, action) => ({
				...state,
				tops: { ...state.tops, loading: false, error: action.payload.message },
			}),

			GET_GUIDELINE_DOWNS_PENDING: state => ({
				...state,
				downs: { ...initialState.downs, loading: true },
			}),

			GET_GUIDELINE_DOWNS_FULFILLED: (state, action: Action<GuidelineDownsPayload>) => ({
				...state,
				downs: { ...state.downs, loading: false, data: action.payload.data },
			}),

			GET_GUIDELINE_DOWNS_REJECTED: (state, action) => ({
				...state,
				downs: { ...state.downs, loading: false, error: action.payload.message },
			}),

			GET_GUIDELINE_DOWNS_FOR_FILTER_PENDING: state => ({
				...state,
				downsForFilter: { ...initialState.downsForFilter, loading: true },
			}),

			GET_GUIDELINE_DOWNS_FOR_FILTER_FULFILLED: (
				state,
				action: Action<Partial<State['downsForFilter']>>,
			) => ({
				...state,
				downsForFilter: {
					...state.downsForFilter,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useGuidelineTops ++
++----------------------------------------------------------------------*/
const selectTops = (state: GlobalState) => state.guideline.tops;
const topsActionsMap = {
	getGuidelineTops,
};

export const useGuidelineTops = () =>
	useRedux<ReturnType<typeof selectTops>, typeof topsActionsMap>(selectTops, topsActionsMap);

/* +----------------------------------------------------------------------
	++ useGuidelineDowns ++
++----------------------------------------------------------------------*/
const selectDowns = (state: GlobalState) => ({
	downs: state.guideline.downs,
	downsForFilter: state.guideline.downsForFilter,
});
const downsActionsMap = {
	getGuidelineDowns,
	getGuidelineDownsForFilter,
};

export const useGuidelineDowns = () =>
	useRedux<ReturnType<typeof selectDowns>, typeof downsActionsMap>(selectDowns, downsActionsMap);
