/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { FetchedData } from 'types/FetchedData';
import { ChargeMethod } from 'types/ChargeMethod';

import { useRedux } from 'util/hook/redux';
import { isNumber } from 'util/helper';

import { abortController, addController } from 'models/controllers';

import {
	BikeCategoryProperty,
	BicycleRentalInfoOption,
	fetchBicycleCategoriesFunc,
	fetchBicycleSearchFunc,
	fetchBicycleStocksFunc,
	BicycleSearchParams,
	fetchBicycleSizesFunc,
	BikeSizePropery,
} from 'api/bicycle';
import { TagData } from 'api/tag';

import { State as GlobalState, GetState } from './reducers';

interface BikeModel {
	id: number;
	title: string;
	image: string;
	options: BicycleRentalInfoOption[];
	rentableSizes: string;
}

export interface BikeSearchProperty {
	model_family_id: number;
	model_family: string;
	description: string;
	tags: TagData[];
	models: BikeModel[];
}

interface ProductData {
	productId: number;
	name: string;
	categoryName: string;
	description: string;
	imgUrl: string;
	price: number;
	unit: ChargeMethod;
	maxCount: number;
	specTitle: string;
	specs: number[];
	stocks: number[];
	equipmentInfo: string;
}

interface SpecItem {
	specId: number;
	specName: string;
	specHint: string;
}

interface StockItem {
	stockId: number;
	productId: number;
	specId: number;
	stock: number;
}

export interface State {
	category: FetchedData<BikeCategoryProperty[]>;
	sizes: FetchedData<BikeSizePropery[]>;
	search: {
		loading: boolean;
		error: string;
		data: BikeSearchProperty[];
	};
	stocks: {
		loading: boolean;
		error: string;
		totalPage: number;
		currentPage: number;
		pageProductList: number[];
		normalized: {
			products: { [id: number]: ProductData };
			specs: { [id: number]: SpecItem };
			stocks: { [id: number]: StockItem };
		};
	};
}

const getBikeCategory = createAction('GET_BIKE_CATEGORY', async () => {
	try {
		const { status, message, data } = await fetchBicycleCategoriesFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		return { message: error };
	}
});

const getBikeSizes = createAction<Promise<Partial<State['sizes']>>>('GET_BIKE_SIZES', async () => {
	try {
		const { status, message, data } = await fetchBicycleSizesFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

const getBikeSearch = createAction<Promise<BikeSearchProperty[]>, BicycleSearchParams | undefined>(
	'GET_BIKE_SEARCH',
	async params => {
		try {
			const { status, message, data } = await fetchBicycleSearchFunc(params);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			/* 
				category - 車種
				model_family - 車型家族
				model - 車型
			*/
			const mapData = data.map(({ models, ...props }) => ({
				...props,
				models: models
					.filter(({ rental_info }) => rental_info) // 過濾掉沒有租賃資訊的車型，可能造成該車型（models）資料為空陣列。
					.map(({ id, title, image, rental_info, rentable_sizes }) => ({
						id,
						title,
						image,
						options: rental_info?.options || [],
						rentableSizes: rentable_sizes,
					})),
			}));

			// 如果有任何一個車型（models）資料為空陣列，就不儲存到 state 中。確保 src\layouts\RentalBikesFees\index.tsx 的 CollapseBox 自動展開正確的車型家族。
			const bikes = mapData.filter(({ models }) => models.length > 0);

			return bikes;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

type GetBicycleStocksPayload = Partial<State['stocks']>;

/**
 * 取得門市車輛商品
 */
const getBicycleStocks = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetBicycleStocksPayload>,
	{
		page: number;
	}
>('GET_BICYCLE_STOCKS', params => async (dispatch: Dispatch, getState: GetState) => {
	try {
		const {
			routing: {
				queries: { search, bikeSizes, bikeCategories },
			},
			rentalReservation: {
				storesInfo: { startStore, destinationStore, startDate, startTime, returnDate, returnTime },
			},
		} = getState();

		if (
			startStore === null ||
			destinationStore === null ||
			startDate === null ||
			startTime === null ||
			returnDate === null ||
			returnTime === null
		) {
			throw new Error('');
		}

		// cancel previous request
		await dispatch(abortController('GET_BICYCLE_STOCKS'));
		// new abort controller
		const controller = new AbortController();
		const { signal } = controller;
		await dispatch(addController('GET_BICYCLE_STOCKS', controller));

		const { status, message, data } = await fetchBicycleStocksFunc(
			{
				start_store_id: startStore?.id,
				end_store_id: destinationStore?.id,
				start_at: moment(`${startDate} ${startTime}`, 'YYYY/MM/DD HH:mm:ss').format(
					'YYYY-MM-DD HH:mm:ss',
				),
				end_at: moment(`${returnDate} ${returnTime}`, 'YYYY/MM/DD HH:mm:ss').format(
					'YYYY-MM-DD HH:mm:ss',
				),
				category_ids: bikeCategories
					? (bikeCategories as string[]).filter(d => isNumber(d)).map(d => Number(d))
					: [],
				category_with_sizes: bikeSizes ? (bikeSizes as string[]) : [],
				search,
				...params,
			},
			{
				signal,
			},
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const normalized: State['stocks']['normalized'] = {
			products: {},
			specs: {},
			stocks: {},
		};

		const list: number[] = data.data.map(d => {
			const product: ProductData = {
				productId: d.id,
				name: d.title,
				categoryName: d.bicycle_category.title,
				description: d.bicycle_category.description,
				imgUrl: d.image,
				price: d.price,
				unit: 'CHARGE_METHOD_DAY',
				maxCount: 100000, // TODO no limit
				equipmentInfo: d.standard_equipment_info,
				specTitle: '',
				specs: d.bicycle_specs.map(spec => {
					normalized.specs[spec.id] = {
						specId: spec.id,
						specName: spec.size,
						specHint: spec.height_info,
					};
					return spec.id;
				}),
				stocks: d.bicycle_specs.map(spec => {
					normalized.stocks[spec.id] = {
						stockId: spec.id,
						productId: d.id,
						specId: spec.id,
						stock: spec.stock,
					};
					return spec.id;
				}),
			};

			normalized.products[d.id] = product;
			return d.id;
		});

		return {
			loading: false,
			error: '',
			totalPage: data.total === 0 ? 0 : data.last_page,
			currentPage: data.current_page,
			pageProductList: list,
			normalized,
		};
	} catch (error) {
		if ((error as Error).name === 'AbortError') {
			// handle abort
			return {
				loading: true, // keep loading
			};
		}
		return {
			loading: false,
			error: (error as Error).message,
		};
	}
});

export const updateStocks = createAction<
	State['stocks']['normalized']['stocks'],
	State['stocks']['normalized']['stocks']
>('UPDATE_BICYCLE_STOCKS', data => data);

const clearBicycleStocks = createAction('CLEAR_BICYCLE_STOCKS');

const initialState: State = {
	category: {
		loading: false,
		error: '',
		data: [],
	},
	sizes: {
		loading: false,
		error: '',
		data: [],
	},
	search: {
		loading: false,
		error: '',
		data: [],
	},
	stocks: {
		loading: false,
		error: '',
		totalPage: 0,
		currentPage: 0,
		pageProductList: [],
		normalized: {
			products: {},
			specs: {},
			stocks: {},
		},
	},
};

export const reducer = {
	bicycle: handleActions<State, any>(
		{
			GET_BIKE_CATEGORY_PENDING: state => ({
				...state,
				category: {
					...state.category,
					loading: true,
					error: '',
				},
			}),

			GET_BIKE_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				category: {
					...state.category,
					data: action.payload.data,
					loading: false,
				},
			}),

			GET_BIKE_CATEGORY_REJECTED: (state, action) => ({
				...state,
				category: {
					...state.category,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_BIKE_SIZES_PENDING: state => ({
				...state,

				sizes: {
					...initialState.sizes,
					loading: true,
				},
			}),

			GET_BIKE_SIZES_FULFILLED: (state, action: Action<Partial<State['sizes']>>) => ({
				...state,

				sizes: {
					...state.sizes,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_BIKE_SEARCH_PENDING: state => ({
				...state,
				search: {
					...initialState.search,
					loading: true,
					error: '',
				},
			}),

			GET_BIKE_SEARCH_FULFILLED: (state, action: Action<BikeSearchProperty[]>) => ({
				...state,
				search: {
					...state.search,
					data: action.payload,
					loading: false,
				},
			}),

			GET_BIKE_SEARCH_REJECTED: (state, action) => ({
				...state,
				search: {
					...state.search,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_BICYCLE_STOCKS_PENDING: state => ({
				...state,
				stocks: {
					...state.stocks,
					loading: true,
					error: '',
				},
			}),

			GET_BICYCLE_STOCKS_FULFILLED: (state, action: Action<GetBicycleStocksPayload>) => ({
				...state,
				stocks: {
					...state.stocks,
					...action.payload,
					normalized: {
						...state.stocks.normalized,
						products: {
							...state.stocks.normalized.products,
							...action.payload.normalized?.products,
						},
						specs: {
							...state.stocks.normalized.specs,
							...action.payload.normalized?.specs,
						},
						stocks: {
							...state.stocks.normalized.stocks,
							...action.payload.normalized?.stocks,
						},
					},
				},
			}),

			UPDATE_BICYCLE_STOCKS: (state, action: Action<State['stocks']['normalized']['stocks']>) => ({
				...state,

				stocks: {
					...state.stocks,
					normalized: {
						...state.stocks.normalized,
						stocks: action.payload,
					},
				},
			}),

			CLEAR_BICYCLE_STOCKS: state => ({
				...state,
				stocks: {
					...initialState.stocks,
					loading: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
++ useBicycleCategory ++
++----------------------------------------------------------------------*/
const selectBicycleCategory = (state: GlobalState) => state.bicycle.category;
const bicycleCategoryActionMap = { getBikeCategory };

export const useBicycleCategory = () => useRedux(selectBicycleCategory, bicycleCategoryActionMap);

/* +----------------------------------------------------------------------
++ useBicycleSizes ++
++----------------------------------------------------------------------*/
const selectBicycleSizes = (state: GlobalState) => state.bicycle.sizes;
const bicycleSizesActionMap = { getBikeSizes };

export const useBicycleSizes = () =>
	useRedux<ReturnType<typeof selectBicycleSizes>, typeof bicycleSizesActionMap>(
		selectBicycleSizes,
		bicycleSizesActionMap,
	);

/* +----------------------------------------------------------------------
++ useBicycleSearch ++
++----------------------------------------------------------------------*/
const selectBicycleSearch = (state: GlobalState) => state.bicycle.search;
const bicycleSearchActionMap = { getBikeSearch };

export const useBicycleSearch = () =>
	useRedux<ReturnType<typeof selectBicycleSearch>, typeof bicycleSearchActionMap>(
		selectBicycleSearch,
		bicycleSearchActionMap,
	);

/* +----------------------------------------------------------------------
	++ useBicycleStocks ++
++----------------------------------------------------------------------*/
const selectStocks = (state: GlobalState) => state.bicycle.stocks;
const stocksActionsMap = {
	getBicycleStocks,
	clearBicycleStocks,
};

export const useBicycleStocks = () =>
	useRedux<ReturnType<typeof selectStocks>, typeof stocksActionsMap>(
		selectStocks,
		stocksActionsMap,
	);
