import { FetchResponse } from 'types/FetchResponse';
import { GiantEventCodeType } from 'types/GiantEvents';

import { wrapFetch } from 'util/api';

/**
 * 取得訂單成立說明
 */
export type CreateOrderInfoType = 'event' | 'travel';
type FetchCreateOrderInfoFunc = (params: {
	type: CreateOrderInfoType;
}) => Promise<
	FetchResponse<{
		create_order_info: string | null;
	}>
>;
export const fetchCreateOrderInfoFunc: FetchCreateOrderInfoFunc = async params => {
	const response = await wrapFetch(
		'createOrderInfo',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};

/**
 * 取得訂單取消說明
 */
export type CancelOrderInfoType = GiantEventCodeType | 'TRAVEL';
type FetchCancelOrderInfoFunc = (params: {
	type: CancelOrderInfoType;
	country_id?: number;
}) => Promise<
	FetchResponse<{
		cancel_order_info: string | null;
	}>
>;
export const fetchCancelOrderInfoFunc: FetchCancelOrderInfoFunc = async params => {
	const response = await wrapFetch(
		'cancelOrderInfo',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};

/**
 * 取得租車訂單取消說明資訊
 */
type FetchStoreRentalCancelOrderInfoFunc = (params: {
	store_id: number;
}) => Promise<
	FetchResponse<{
		rental_cancel_order_info: string | null;
	}>
>;
export const fetchStoreRentalCancelOrderInfoFunc: FetchStoreRentalCancelOrderInfoFunc = async params => {
	const response = await wrapFetch(
		'storeRentalCancelOrderInfo',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};
