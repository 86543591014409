import { createAction, handleActions } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';

export interface State {
	equipmentChecklist: FetchedData<string>;
}

const initialState: State = {
	equipmentChecklist: { loading: false, data: '', error: '' },
};

const getEquipmentChecklist = createAction('GET_EQUIPMENT_CHECKLIST', async () => {
	try {
		const { status, message, data } = await wrapFetch('equipmentChecklist');

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: data.equipment_checklist,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export const reducer = {
	rentalExamination: handleActions<State, any>(
		{
			// eslint-disable-line @typescript-eslint/no-explicit-any
			GET_EQUIPMENT_CHECKLIST_PENDING: state => ({
				...state,
				equipmentChecklist: {
					...initialState.equipmentChecklist,
					loading: true,
					error: '',
				},
			}),

			GET_EQUIPMENT_CHECKLIST_FULFILLED: (state, action) => ({
				...state,
				equipmentChecklist: {
					...state.equipmentChecklist,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_EQUIPMENT_CHECKLIST_REJECTED: (state, action) => ({
				...state,
				equipmentChecklist: {
					...state.equipmentChecklist,
					loading: false,
					error: action.payload.message,
				},
			}),
		},
		initialState,
	),
};

const selectEquipmentChecklist = (state: GlobalState) => state.rentalExamination.equipmentChecklist;
const EquipmentChecklistActionsMap = { getEquipmentChecklist };
export const useEquipmentChecklist = () =>
	useRedux<ReturnType<typeof selectEquipmentChecklist>, typeof EquipmentChecklistActionsMap>(
		selectEquipmentChecklist,
		{ getEquipmentChecklist },
	);
