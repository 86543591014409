/* eslint-disable indent */
import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { GiantEventData } from 'types/GiantEvents';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import { arrayNormalizer } from 'util/normalizer';

import {
	eventCourseFormattedFunc,
	eventRideFormattedFunc,
	EventSimpleData,
	EventSimpleTravelData,
	eventTravelFormattedFunc,
	updateGiantEventsData,
} from 'models/events';

import {
	CalenderEventType,
	FetchCalendarEventsParams,
	fetchCalendarEventsFunc,
} from 'api/calendar';

import { GetState, State as GlobalState } from './reducers';

const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const EVENT_COLOR_MAP: Record<CalenderEventType | 'disabled', string> = {
	eventsBikeClasses: '#75b3ea',
	travel: '#fbbd4a',
	eventsFeaturedRides: '#9da4d8',
	eventsStoreRides: '#d4a7dd',
	disabled: '#d8d8d8',
};

interface CalendarEvent {
	id: string;
	title: string;
	start: string;
	end: string;
	color: string;
	allDay: boolean;
	extendedProps: object; // 其他資料
}

export interface State {
	data: CalendarEvent[];
	events: FetchedData<Record<CalenderEventType, number[]>>;
}

const initialState: State = {
	events: {
		loading: false,
		error: '',
		data: { travel: [], eventsStoreRides: [], eventsFeaturedRides: [], eventsBikeClasses: [] },
	},
	data: [
		{
			id: '1',
			title: '中階室內自行車課程講座',
			start: todayStr,
			end: `${todayStr}T48:00:00`,
			color: EVENT_COLOR_MAP.eventsBikeClasses,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '1',
			title: '單車環島趣-台中出發',
			start: todayStr,
			end: `${todayStr}T48:00:00`,
			color: EVENT_COLOR_MAP.eventsFeaturedRides,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '1',
			title: '單車環島趣-高雄出發',
			start: todayStr,
			end: `${todayStr}T96:00:00`,
			color: EVENT_COLOR_MAP.eventsFeaturedRides,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '1',
			title: '單車花東縱騎-寄情山海（南下）',
			start: todayStr,
			end: `${todayStr}T72:00:00`,
			color: EVENT_COLOR_MAP.eventsBikeClasses,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '2',
			title: '自行車專業技培訓課程',
			start: `${todayStr}T144:00:00`,
			end: `${todayStr}T144:00:00`,
			color: EVENT_COLOR_MAP.eventsStoreRides,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '3',
			title: '墾丁騎車趣',
			start: '2021-04-18 07:00:00',
			end: '2021-04-25 10:30:00',
			color: moment('2021-04-18').isBefore(todayStr)
				? EVENT_COLOR_MAP.disabled
				: EVENT_COLOR_MAP.eventsFeaturedRides,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '4',
			title: 'E-bike 騎乘技訓練課程',
			start: '2021-04-12 07:00:00',
			end: '2021-04-12 10:30:00',
			color: moment('2021-04-12').isBefore(todayStr)
				? EVENT_COLOR_MAP.disabled
				: EVENT_COLOR_MAP.travel,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
		{
			id: '5',
			title: '初階室內自行體驗課程',
			start: '2021-06-01 07:00:00',
			end: '2021-06-01 10:30:00',
			color: moment('2021-06-01').isBefore(todayStr)
				? EVENT_COLOR_MAP.travel
				: EVENT_COLOR_MAP.eventsStoreRides,
			allDay: true,
			extendedProps: {
				info: '',
			},
		},
	],
};

type GetCalendarEventsPayload = Partial<FetchedData<Record<CalenderEventType, number[]>>>;

const getCalendarEvents = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetCalendarEventsPayload>,
	FetchCalendarEventsParams
>('GET_CALENDAR_EVENTS', params => async (dispatch: Dispatch) => {
	try {
		const { status, message, data } = await fetchCalendarEventsFunc(params);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const eventsBikeClasses = eventCourseFormattedFunc(data.eventsBikeClasses || []);
		const eventsFeaturedRides = eventRideFormattedFunc(data.eventsFeaturedRides || []);
		const eventsStoreRides = eventRideFormattedFunc(data.eventsStoreRides || []);
		const eventsTravel = eventTravelFormattedFunc(data.travel || []);

		const bikeClasses = arrayNormalizer<EventSimpleData>(eventsBikeClasses, 'dateId'); // 無論哪一種類別， dateId 都代表活動課程的唯一 ID。
		const featuredRidesData = arrayNormalizer<EventSimpleData>(eventsFeaturedRides, 'dateId');
		const storeRidesData = arrayNormalizer<EventSimpleData>(eventsStoreRides, 'dateId');
		const travelData = arrayNormalizer<EventSimpleTravelData>(eventsTravel, 'dateId');

		const eventData = [
			{ type: GiantEventData.eventsBikeClasses, eventData: bikeClasses.byIds },
			{ type: GiantEventData.eventsFeaturedRides, eventData: featuredRidesData.byIds },
			{ type: GiantEventData.eventsStoreRides, eventData: storeRidesData.byIds },
			{ type: GiantEventData.travel, eventData: travelData.byIds },
		];

		eventData.forEach(({ type, eventData: eventSpecificData }) => {
			dispatch(updateGiantEventsData({ type, data: eventSpecificData }));
		});

		return {
			data: {
				eventsBikeClasses: bikeClasses.ids as number[],
				eventsFeaturedRides: featuredRidesData.ids as number[],
				eventsStoreRides: storeRidesData.ids as number[],
				travel: travelData.ids as number[],
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				error: message,
			};
		}
		return {
			error: '',
		};
	}
});

export const reducer = {
	calendar: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_CALENDAR_EVENTS_PENDING: state => ({
				...state,
				events: initialState.events,
			}),

			GET_CALENDAR_EVENTS_FULFILLED: (state, action: Action<GetCalendarEventsPayload>) => ({
				...state,

				events: {
					...state.events,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
					...(action.payload.data && {
						data: action.payload.data,
					}),
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useCalendar ++
++----------------------------------------------------------------------*/
const selectCalendarEvents = (state: GlobalState) => state.calendar;

const calendarEventsActionsMap = {
	getCalendarEvents,
};

export const useCalendarEvents = () =>
	useRedux<ReturnType<typeof selectCalendarEvents>, typeof calendarEventsActionsMap>(
		selectCalendarEvents,
		calendarEventsActionsMap,
	);
