import { FetchGiantFunction } from 'types/FetchFunction';
import { GenderValues } from 'types/Gender';

import { fetchFunc, wrapRetryGiantFetch } from 'util/api';

const { GIANT_CLIENT_ID } = process.env;

export interface UserBasicInfoData {
	// Basic
	firstName: string;
	lastName: string;
	nationalityCode?: string;
	height?: string;
	weight?: string;
	birthday?: string; // YYYY-MM-DD
	gender?: GenderValues;

	email?: string;
	phoneNumber?: string;
	cityName?: string;
	countryCode?: string;
	formattedAddress?: string;
	measureUnit?: string;
}

export interface UserBasicGeneralData {
	phoneVerified?: boolean;
	phoneNumber?: number;
	email?: string;
	emailVerified?: boolean;
	isoCountryCode?: string;
}

export interface UserBasicLinkExtProvidersData {
	providerId?: number;
	providerName?: string;
	lastName?: string;
	firstName?: string;
	email?: string;
}

export interface UserBasicInfoRes {
	gid: string;
	info: UserBasicInfoData;
	general?: UserBasicGeneralData;
	registerFrom?: string;
	linkExtProviders?: UserBasicLinkExtProvidersData;
	policyVersions?: string;
	updateAt?: number;
	createdAt?: number;
}
type UserBasicInfoFetch = FetchGiantFunction<UserBasicInfoRes>;

/**
 * 因應 GID 2.0 改版，合併 1.0 的 R1-2、R1-2.1
 * https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/work_items/1282
 *
 * @returns UserBasicInfoFetch
 */
export const fetchUserProfileFunc: UserBasicInfoFetch = async () => {
	const response = await wrapRetryGiantFetch<UserBasicInfoRes>('user', {
		method: 'GET',
	});

	return response;
};

export type UpdateUserProfileParams = UserBasicInfoData;
type UpdateUserProfileRes = { message: string };
type UpdateUserProfileFetch = FetchGiantFunction<UpdateUserProfileRes, UpdateUserProfileParams>;

/**
 * Giant ID auth API R1-3
 * 使用者於該應用可修改個人資訊。
 *
 * 因應 GID 2.0 改版，修改其內容
 * https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/work_items/1282
 */
export const updateUserProfileFunc: UpdateUserProfileFetch = async data => {
	const response = await wrapRetryGiantFetch<UpdateUserProfileRes>('user', {
		method: 'PUT',
		body: JSON.stringify({ info: data }),
	});

	return response;
};

interface MemberData {
	gid: string;
	firstName: string;
	lastName: string;
}

type FamilyMemberListRes = { members: MemberData[] };
type FamilyMemberListFetch = FetchGiantFunction<FamilyMemberListRes>;

/**
 * Giant ID auth API R1-4
 * 取得使用者所隸屬的家庭之成員列表。
 */
export const fetchFamilyMemberListFunc: FamilyMemberListFetch = async () => {
	const response = await wrapRetryGiantFetch<FamilyMemberListRes>('search-member-list', {
		method: 'POST',
		body: JSON.stringify({ clientId: GIANT_CLIENT_ID }),
	});

	return response;
};

type FamilyMemberInfoParams = string;
type FamilyMemberInfoFetch = FetchGiantFunction<UserBasicInfoRes, FamilyMemberInfoParams>;

/**
 * Giant ID auth API R1-5
 * 取得使用者所隸屬的家庭之特定成員的資料，會因於家庭之權限不同，與不同應用而有所不同。
 */
export const fetchFamilyMemberInfoFunc: FamilyMemberInfoFetch = async gid => {
	const response = await wrapRetryGiantFetch<UserBasicInfoRes>('search-member-profile', {
		method: 'POST',
		body: JSON.stringify({ clientId: GIANT_CLIENT_ID, gid }),
	});

	return response;
};

export type VIDRes = { vid: string };
type VIDFetch = FetchGiantFunction<VIDRes>;

/**
 * Giant ID auth API R1-6
 * 產出 8 碼的隨機英數字組合做為匿名識別碼 VID。
 *
 * 此處因應 GID 2.0改版 新增 headers
 * ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/work_items/1282#note_1221815
 */
export const fetchVidFunc: VIDFetch = async () => {
	const response = await fetchFunc(
		'vid',
		{
			method: 'GET',
		},
		{},
		{ enpointType: 'giant', withXAPIKEY: true },
	);

	return response;
};

type RevokeTokenParams = string;
type RevokeTokenFetch = FetchGiantFunction<{}, RevokeTokenParams>;

/**
 * Giant ID auth API R1-7
 * 棄用 Cognito 所配置的重新整理字符。
 */
export const revokeTokenFunc: RevokeTokenFetch = async refreshToken => {
	const response = await wrapRetryGiantFetch<{}>('token', {
		method: 'POST',
		body: JSON.stringify({ refreshToken }),
	});

	return response;
};

export interface UserEmergencyInfoData {
	contact_name: string;
	contact_relationship: string;
	contact_phone_country_code: string;
	contact_phone: string;
}

export interface UserOtherInfoData {
	eating_habit: string;
	eating_habit_note: string;
}
