import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';

import { State as GlobalState } from './reducers';

type ModalCategory = 'toast' | 'modal';

type ToastType = 'shouldAgreeContract' | 'message' | '';

type ModalType = 'warning' | '';

type OpenModalPayload = {
	category: ModalCategory;
	type: ToastType | ModalType;
	i18n?: string;
	data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const openModal = createAction<OpenModalPayload, OpenModalPayload>(
	'OPEN_MODAL',
	({ category, type, data, i18n }) => ({
		category,
		type,
		i18n,
		data,
	}),
);

type CloseModalPayload = {
	category: ModalCategory;
};

export const closeModal = createAction<CloseModalPayload, CloseModalPayload>(
	'CLOSE_MODAL',
	({ category }) => ({ category }),
);

export type State = {
	toast: {
		type: ToastType;
		i18n: string;
		data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	};
	modal: {
		type: ModalType;
		i18n: string;
		data?: unknown;
	};
};

const initialState: State = {
	toast: { type: '', data: {}, i18n: '' },
	modal: { type: '', data: {}, i18n: '' },
};

export const reducer = {
	modal: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			OPEN_MODAL: (state, action: Action<OpenModalPayload>) => ({
				...state,
				[action.payload.category]: {
					...state[action.payload.category],
					type: action.payload.type,
					...(action.payload.i18n && { i18n: action.payload.i18n }),
					...(action.payload.data && { data: action.payload.data }),
				},
			}),

			CLOSE_MODAL: (state, action: Action<CloseModalPayload>) => ({
				...state,
				[action.payload.category]: {
					type: '',
					data: {},
					i18n: '',
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	modal: state.modal,
});

const modalActionsMap = {
	openModal,
	closeModal,
};

type ModalSelector = ReturnType<typeof mapHooksToState>;
type ModalActionsMap = typeof modalActionsMap;

export const useModal = () =>
	useRedux<ModalSelector, ModalActionsMap>(mapHooksToState, modalActionsMap);
