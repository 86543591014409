/* eslint-disable indent */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { useBoolean } from 'util/hook';
import { initGTag } from 'util/sdk';

import Button from 'components/atoms/Button';

import moment from 'moment';

import styles from './index.css';

/**
 * cookie 同意書元件
 *
 * @returns
 */
const CookieConsent: React.FC = () => {
	const translateKey = 'cookieConsent';
	const { t } = useTranslation(translateKey);
	const [cookies, setCookie] = useCookies([translateKey]);
	const [cookieHasBeenView, { setTrue }] = useBoolean({
		defaultBoolean: JSON.parse(cookies.cookieConsent || false),
	});

	/**
	 * 點擊同意/不同意按鈕時觸發
	 *
	 * @param isAgree - 是否同意
	 */
	const clickHandler = (isAgree: boolean): void => {
		setCookie(translateKey, isAgree, {
			path: '/',
			expires: moment()
				.add(1, 'month')
				.toDate(),
		});
		setTrue();

		if (isAgree && process.env.PRODUCTION) {
			initGTag();
		}
	};

	useEffect(() => {
		// 如果一開始已經同意過就直接插入 gTag。
		if (JSON.parse(cookies.cookieConsent || false) && process.env.PRODUCTION) {
			initGTag();
		}
	}, []);

	return !cookieHasBeenView ? (
		<section className={styles.cookieConsent}>
			<div>
				<h5>{t(`consentTitle`)}</h5>
				<p>{t(`consentText`)}</p>
			</div>
			<div className={styles.buttonGroup}>
				<Button className={styles.button} color="white" onClick={() => clickHandler(true)}>
					{t(`acceptBtn`)}
				</Button>
				<Button className={styles.button} color="white" outline onClick={() => clickHandler(false)}>
					{t(`doNoAcceptBtn`)}
				</Button>
			</div>
		</section>
	) : null;
};
export default CookieConsent;
