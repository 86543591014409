import React from 'react';

import { appPath } from 'util/routingConfig';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: appPath.travelTravelTours.replace(appPath.travel, ''),
	components: () => [import(/* webpackChunkName: 'travelTravelTours' */ './component')],
	render: ([TravelTravelTours]) => <TravelTravelTours />,
};

export default routes;
