// todo: 這邊的 models 待真正的旅遊 API 開發完成再調整

/* eslint-disable indent */
import { handleActions, createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { DynamicState } from 'types/DynamicState';
import { EventStatus } from 'types/EventStatus';

import { useRedux } from 'util/hook/redux';
import { getDifference } from 'util/helper';

import { eventCourseFormattedFunc, EventSimpleData } from 'models/events';
import { updateProductData } from 'models/product';
import { normalizedProduct } from 'models/product/utils';
import { TimeLeftType, AlbumDataType } from 'models/ride/type';

import { AnnouncementType } from 'api/events';

// 這邊 course 的 API 待後端完成全部功能後拔除
import { StudentFeedbackType, LecturerIntroType, CourseLeftQuotaFetchParams } from 'api/course';

import { SlideProperty } from 'components/molecules/SliderBanner';

import {
	fetchTravelBriefContent,
	fetchTravelBriefList,
	fetchTravelGuide,
	TravelBriefContent,
	TravelBriefList,
	TravelGuideInfo,
} from 'api/travel';
import {
	fetchTravelClassicToursInfoFunc,
	GetRemainingQuotaForTravelItinerary,
	getRemainingQuotaForTravelItinerary,
	TravelClassicToursInfo,
	TravelClassicToursRatingReviewer,
} from 'api/travelClassicTours';

import { GetState, State as GlobalState } from './reducers';

// type ReservationTravelQuotaData = {
// 	leftQuota: number;
// 	waitingQuota: number;
// 	isOpenWaitingList: boolean;
// };

type FormattedTravelData = Omit<TravelClassicToursInfo, 'additional_products'> & {
	additionalsByProductId: Record<
		'products' | 'services' | 'gifts' | 'applyTypes' | 'bicycle',
		number[]
	>;
};

export type ReservationTravelData = FormattedTravelData;

export type CustomerFeedbackFormFiled = keyof Partial<TravelClassicToursRatingReviewer>;

export interface State {
	briefList: DynamicState<TravelBriefList | null>;
	guide: DynamicState<TravelGuideInfo | null>;
	briefContent: DynamicState<TravelBriefContent | null>;
	reservationTravelInfo: DynamicState<ReservationTravelData | null>;
	travelItineraryRemainingQuota: DynamicState<GetRemainingQuotaForTravelItinerary | null>;
	// travelClassicToursInfo: DynamicState<TravelClassicToursInfo | null>;
}

// 這邊 course 的 State 帶後端完成全部功能後拔除
const initialState: State = {
	briefList: {
		data: null,
		loading: false,
		error: '',
	},
	guide: {
		data: null,
		loading: false,
		error: '',
	},
	briefContent: {
		data: null,
		loading: false,
		error: '',
	},
	reservationTravelInfo: {
		data: null,
		loading: false,
		error: '',
	},
	travelItineraryRemainingQuota: {
		data: null,
		loading: false,
		error: '',
	},
	// travelClassicToursInfo: {
	// 	data: null,
	// 	loading: false,
	// 	error: '',
	// },
};

/** ________________________________________________________________________________________ */

const changeAnnouncementIndex = createAction('CHANGE_ANNOUNCEMENT_INDEX');

/** ________________________________________________________________________________________ */

// type GetTravelInfoPayload = Partial<DynamicState<TravelClassicToursInfo | null>>;

// const getTravelInfo = createAction<Promise<GetTravelInfoPayload>, { id: number }>(
// 	'GET_TRAVEL_INFO',
// 	async id => {
// 		try {
// 			const { status, message, data } = await fetchTravelClassicToursInfoFunc(id);

// 			if (status !== 200 && status !== 201) {
// 				throw new Error(message);
// 			}

// 			return {
// 				data: {
// 					id: data.id,
// 					name: data.name,
// 					banner: data.banner,
// 					start_location_note: data.start_location_note,
// 					end_location_note: data.end_location_note,
// 					duration_day: data.duration_day,
// 					total_mileage: data.total_mileage,
// 					lowest_price: data.lowest_price,
// 					description: data.description,
// 					note: data.note,
// 					difficulty: data.difficulty,
// 					is_open_for_customized: data.is_open_for_customized,
// 					additional_products: data.additional_products,
// 					route: data.route,
// 					rooms: data.rooms,
// 				},
// 			};
// 		} catch (error) {
// 			if (error instanceof Error) {
// 				const { message } = error;
// 				return { error: message };
// 			}
// 			return { error: 'something went wrong' };
// 		}
// 	},
// );

/** ________________________________________________________________________________________ */

type GetTravelBriefListPayload = Partial<DynamicState<TravelBriefList | null>>;

const getTravelBriefList = createAction<Promise<GetTravelBriefListPayload>>(
	'GET_TRAVEL_BRIEF_LIST',
	async () => {
		try {
			const { status, message, data } = await fetchTravelBriefList();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: {
					data: data.data,
					first_page_url: data.first_page_url,
					from: data.from,
					last_page: data.last_page,
					last_page_url: data.last_page_url,
					next_page_url: data.next_page_url,
					path: data.path,
					per_page: data.per_page,
					prev_page_url: data.prev_page_url,
					to: data.to,
					total: data.total,
					current_page: data.current_page,
				},
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

/** ________________________________________________________________________________________ */

type GetTravelGuidePayload = Partial<DynamicState<TravelGuideInfo | null>>;

export const getTravelGuide = createAction<Promise<GetTravelGuidePayload>, number>(
	'GET_TRAVEL_GUIDE',
	async locationId => {
		try {
			const { status, message, data } = await fetchTravelGuide(locationId);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: {
					id: data.id,
					block: data.block,
				},
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

/** ________________________________________________________________________________________ */

type GetTravelBriefContentPayload = Partial<DynamicState<TravelBriefContent | null>>;

export const getTravelBriefContent = createAction<
	Promise<GetTravelBriefContentPayload>,
	TravelBriefContent['id']
>('GET_TRAVEL_BRIEF_CONTENT', async id => {
	try {
		const { status, message, data } = await fetchTravelBriefContent(id);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: {
				id: data.id,
				name: data.name,
				location: data.location,
				location_url: data.location_url,
				list_img: data.list_img,
				date: data.date,
				content: data.content,
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

/** ________________________________________________________________________________________ */

type GetTravelReservationInfoPayload = Partial<DynamicState<ReservationTravelData | null>>;

/**
 * getTravelReservationInfo 參數 country_id 是為了讓報名查詢頁面 （global route）帶入活動的 country id 設定的
 * 若是在國家底下的頁面無需設定 country_id，會自動帶入。
 */
export const getTravelReservationInfo = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetTravelReservationInfoPayload>,
	number
>('GET_TRAVEL_RESERVATION_INFO', id => async dispatch => {
	try {
		const { status: s1, message: m1, data: d1 } = await fetchTravelClassicToursInfoFunc(id);

		if (s1 !== 200 && s1 !== 201) {
			throw new Error(m1);
		}

		const { products, services, gifts, bicycle, apply_type } = d1.products;
		const formattedProducts = normalizedProduct(products);
		const formattedServices = normalizedProduct(services);
		const formattedGifts = normalizedProduct(gifts);
		const formattedBicycle = normalizedProduct(bicycle);
		const formattedApplyType = normalizedProduct(apply_type);

		dispatch(
			updateProductData({
				products: {
					...formattedProducts.products,
					...formattedServices.products,
					...formattedGifts.products,
					...formattedApplyType.products,
					...formattedBicycle.products,
				},
				stocks: {
					...formattedProducts.stocks,
					...formattedServices.stocks,
					...formattedGifts.stocks,
					...formattedApplyType.stocks,
					...formattedBicycle.stocks,
				},
				sizes: {
					...formattedProducts.sizes,
					...formattedServices.sizes,
					...formattedGifts.sizes,
					...formattedApplyType.sizes,
					...formattedBicycle.sizes,
				},
				colors: {
					...formattedProducts.colors,
					...formattedServices.colors,
					...formattedGifts.colors,
					...formattedApplyType.colors,
					...formattedBicycle.colors,
				},
			}),
		);

		return {
			data: {
				...d1,
				additionalsByProductId: {
					bicycle: formattedBicycle.ids,
					products: formattedProducts.ids,
					services: formattedServices.ids,
					gifts: formattedGifts.ids,
					applyTypes: formattedApplyType.ids,
				},
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

/** ________________________________________________________________________________________ */

type GetTravelItineraryRemainingQuotaPayload = Partial<
	DynamicState<GetRemainingQuotaForTravelItinerary | null>
>;

/**
 * 取得旅遊行程梯次剩餘名額
 *
 * 點擊新增參加者時觸發，用以即時更新
 *
 * @param {number} id 旅遊梯次行程 id
 * @returns registered(已報名人數), waiting（等待名額）
 */
export const getTravelItineraryRemainingQuota = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetTravelItineraryRemainingQuotaPayload>,
	number
>('GET_TRAVEL_ITINERARY_REMAINING_QUOTA', id => async () => {
	try {
		const { status, message, data } = await getRemainingQuotaForTravelItinerary(id);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

/** ________________________________________________________________________________________ */

type ReducerTravelPayloads =
	// GetTravelInfoPayload &
	GetTravelReservationInfoPayload &
		// GetTravelLeftQuotaPayload &
		GetTravelBriefListPayload &
		GetTravelBriefContentPayload &
		GetTravelGuidePayload &
		GetTravelItineraryRemainingQuotaPayload;

export const reducer = {
	travel: handleActions<State, ReducerTravelPayloads>(
		{
			// GET_TRAVEL_INFO_PENDING: state => ({
			// 	...state,
			// 	travelClassicToursInfo: {
			// 		...state.travelClassicToursInfo,
			// 		loading: true,
			// 		error: '',
			// 	},
			// }),

			// GET_TRAVEL_INFO_FULFILLED: (state, action) => ({
			// 	...state,
			// 	travelClassicToursInfo: {
			// 		...state.travelClassicToursInfo,
			// 		...(action.payload.data && {
			// 			data: { ...state.travelClassicToursInfo.data, ...action.payload.data },
			// 		}),
			// 		...(action.payload.error && { error: action.payload.error }),
			// 		loading: false,
			// 	},
			// }),

			CHANGE_ANNOUNCEMENT_INDEX: state => ({
				...state,
				travelClassicToursInfo: {
					...state.travelClassicToursInfo,
					...(state.travelClassicToursInfo.data && {
						data: {
							...state.travelClassicToursInfo.data,
							announcementIndex: state.travelClassicToursInfo.data?.announcementIndex || 0 + 1,
						},
					}),
				},
			}),

			GET_TRAVEL_RESERVATION_INFO_PENDING: state => ({
				...state,
				reservationTravelInfo: {
					...state.reservationTravelInfo,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_RESERVATION_INFO_FULFILLED: (state, action) => ({
				...state,
				reservationTravelInfo: {
					...state.reservationTravelInfo,
					...(action.payload?.data && { data: action.payload.data }),
					...(action.payload?.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_ITINERARY_REMAINING_QUOTA_PENDING: state => ({
				...state,
				travelItineraryRemainingQuota: {
					...state.travelItineraryRemainingQuota,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_ITINERARY_REMAINING_QUOTA_FULFILLED: (state, action) => ({
				...state,
				travelItineraryRemainingQuota: {
					...state.travelItineraryRemainingQuota,
					...(action.payload?.data && { data: action.payload.data }),
					...(action.payload?.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_BRIEF_LIST_PENDING: state => ({
				...state,
				briefList: {
					...state.briefList,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_BRIEF_LIST_FULFILLED: (state, action) => ({
				...state,
				briefList: {
					...state.briefList,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_BRIEF_CONTENT_PENDING: state => ({
				...state,
				briefContent: {
					...state.briefContent,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_BRIEF_CONTENT_FULFILLED: (state, action) => ({
				...state,
				briefContent: {
					...state.briefContent,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_GUIDE_PENDING: state => ({
				...state,
				guide: {
					...state.guide,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_GUIDE_FULFILLED: (state, action) => ({
				...state,
				guide: {
					...state.guide,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			// GET_TRAVEL_LEFT_QUOTA_PENDING: state => ({
			// 	...state,
			// 	reservationTravelInfo: {
			// 		...state.reservationTravelInfo,
			// 		loading: true,
			// 		error: '',
			// 	},
			// }),

			// GET_TRAVEL_LEFT_QUOTA_FULFILLED: (state, action) => ({
			// 	...state,
			// 	reservationTravelInfo: {
			// 		...state.reservationTravelInfo,

			// 		data: {
			// 			...(state.reservationTravelInfo.data as ReservationTravelData),
			// 			leftQuota: action.payload.leftQuota ?? 0,
			// 		},
			// 		loading: false,
			// 	},
			// }),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTravelDetail ++
++----------------------------------------------------------------------*/
const selectTravelDetail = (state: GlobalState) => state.travel.travelClassicToursInfo;

export const useTravelDetail = () =>
	useRedux(selectTravelDetail, {
		// getTravelInfo,
		changeAnnouncementIndex,
	});

/* +----------------------------------------------------------------------
	++ useTravelBriefList ++
++----------------------------------------------------------------------*/
const selectTravelBriefList = (state: GlobalState) => state.travel.briefList;

export const useTravelBriefList = () => useRedux(selectTravelBriefList, { getTravelBriefList });

/* +----------------------------------------------------------------------
	++ useTravelBriefContent ++
++----------------------------------------------------------------------*/
const selectTravelBriefContent = (state: GlobalState) => state.travel.briefContent;

export const useTravelBriefContent = () =>
	useRedux(selectTravelBriefContent, { getTravelBriefContent });

/* +----------------------------------------------------------------------
	++ useTravelGuide ++
++----------------------------------------------------------------------*/
const selectTravelGuide = (state: GlobalState) => state.travel.guide;

export const useTravelGuide = () => useRedux(selectTravelGuide, { getTravelGuide });

/* +----------------------------------------------------------------------
	++ useTravelReservationInfo(預約表單) ++
++----------------------------------------------------------------------*/

const selectTravelReservationInfo = (state: GlobalState) => ({
	reservationTravelInfo: state.travel.reservationTravelInfo,
	travelItineraryRemainingQuota: state.travel.travelItineraryRemainingQuota,
});

export const useTravelReservationInfo = () =>
	useRedux(selectTravelReservationInfo, {
		getTravelReservationInfo,
		getTravelItineraryRemainingQuota,
		// getTravelLeftQuota
	});
