import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import TravelNewsInfoRoute from './Info';

const routes: CustomRoute = {
	path: appPath.travelNews.replace(appPath.travel, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'travelNewsList' */ './component')],
			render: ([TravelNewsList]) => <TravelNewsList />,
		},
		TravelNewsInfoRoute,
	],
};

export default routes;
