import {
	GiantEventDataType,
	GIANT_EVENT_DATA_TO_CODE_MAP,
	GiantEventModelType,
} from 'types/GiantEvents';
import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

import { EventCourseData, EventRideData } from './events';

export type CalenderEventType = GiantEventDataType | 'travel';

export interface FetchCalendarEventsParams {
	start_date: string; // YYYY/MM/DD
	end_date: string; // YYYY/MM/DD
	search?: string;
	types?: CalenderEventType[];
	location_ids?: number[];
}

export interface TravelItinerariesData {
	itinerary_id: number;
	start_date: string;
	end_date: string;
}

export interface EventTravelData {
	model: GiantEventModelType;
	type: GiantEventDataType;
	country_id: number;
	id: number;
	name: string;
	itineraries: TravelItinerariesData[];
	timezone: string;
	timezone_offset: string;
}

type FetchCalendarEventsFunc = (
	params: FetchCalendarEventsParams,
) => Promise<
	FetchResponse<{
		eventsBikeClasses: EventCourseData[];
		eventsStoreRides: EventRideData[];
		eventsFeaturedRides: EventRideData[];
		travel: EventTravelData[];
	}>
>;

export const fetchCalendarEventsFunc: FetchCalendarEventsFunc = async params => {
	const response = await wrapFetch(
		'calendar/search',
		{
			method: 'GET',
		},
		{
			start_date: params.start_date,
			end_date: params.end_date,
			...(params.search && { search: params.search }),
			...((params.types || []).length >= 1 && {
				types: params.types?.map(
					type =>
						({
							...GIANT_EVENT_DATA_TO_CODE_MAP,
						}[type]),
				),
			}),
			...((params.location_ids || []).length >= 1 && { location_ids: params.location_ids }),
		},
	);

	return response;
};
