/* eslint-disable indent */
import { Dispatch } from 'redux';
import { Action, createAction, handleActions } from 'redux-actions';

import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { InvoiceTypeValues, InvoiceUnit, invoiceTypeMap } from 'types/Invoice';

import { useRedux } from 'util/hook/redux';

import { GetState, State as GlobalState } from './reducers';

interface InvoiceFieldData {
	invoiceType: SelectValue<InvoiceUnit | null>;
	companyTitle: string;
	companyId: string;
	carrierType: SelectValue<'mobileBarcode' | 'citizenDigitalCertificate' | 'thirdParty' | null>;
	carrierNo: string;
	donationType: SelectValue<'donationUnit' | 'codeOfDonatedUnit' | null>;
	donationUnit: SelectValue<string | null>;
	codeOfDonatedUnit: string;
}

export type InvoiceField = keyof InvoiceFieldData;

type InvoiceForm = FormDataMapping<InvoiceFieldData>;

export type State = {
	form: InvoiceForm;
	mappingValue: {
		invoice_type: InvoiceTypeValues | undefined;
		company_title: string;
		company_no: string;
		donate_code: string;
		carrier_no: string;
	};
};

const initialState: State = {
	form: {
		invoiceType: { value: { value: null, label: '' }, valid: true, error: '', required: true },
		companyTitle: { value: '', valid: true, error: '', required: true },
		companyId: { value: '', valid: true, error: '', required: true },
		carrierType: {
			value: { label: 'mobileBarcode', value: 'mobileBarcode' },
			valid: true,
			error: '',
			required: true,
		},
		carrierNo: { value: '', valid: true, error: '', required: true },
		donationType: {
			value: { value: 'donationUnit', label: 'donationUnit' },
			valid: true,
			error: '',
			required: true,
		},
		donationUnit: { value: { value: null, label: '' }, valid: true, error: '', required: true },
		codeOfDonatedUnit: { value: '', valid: true, error: '', required: true },
	},
	mappingValue: {
		invoice_type: undefined,
		company_title: '',
		company_no: '',
		donate_code: '',
		carrier_no: '',
	},
};

interface ChangeInvoicePayload {
	key: InvoiceField;
	data: Partial<InputFiled>;
}

const changeInvoice = createAction<ChangeInvoicePayload, ChangeInvoicePayload>(
	'CHANGE_INVOICE_FORM',
	data => data,
);

const changeInvoiceType = createAction<SelectValue<InvoiceUnit>, SelectValue<InvoiceUnit>>(
	'CHANGE_INVOICE_TYPE',
	data => data,
);

export const clearInvoice = createAction('CLEAR_INVOICE');

export const getInvoiceMappingValue = createAction<
	(_: Dispatch, getState: GetState) => State['mappingValue']
>('GET_INVOICE_MAPPING_VALUE', () => (_: Dispatch, getState: GetState) => {
	const {
		invoice: {
			form: {
				invoiceType,
				companyTitle,
				companyId,
				carrierType,
				carrierNo,
				donationType,
				donationUnit,
				codeOfDonatedUnit,
			},
		},
	} = getState();
	let invoice_type;
	let company_title = '';
	let company_no = '';
	let donate_code = '';
	let carrier_no = '';

	switch (true) {
		case invoiceType.value.value === 'companyInvoice':
			invoice_type = invoiceTypeMap.companyInvoice.value;
			company_title = companyTitle.value;
			company_no = companyId.value;
			break;
		case invoiceType.value.value === 'donationCode':
			invoice_type = invoiceTypeMap.donationCode.value;
			switch (donationType.value.value) {
				case 'codeOfDonatedUnit':
					donate_code = codeOfDonatedUnit.value;
					break;
				default:
					donate_code = donationUnit.value.value || '';
					break;
			}
			break;
		case invoiceType.value.value === 'carrier':
			switch (carrierType.value.value) {
				case 'citizenDigitalCertificate':
					invoice_type = invoiceTypeMap.citizenDigitalCertificate.value;
					carrier_no = carrierNo.value;
					break;
				case 'mobileBarcode':
					invoice_type = invoiceTypeMap.mobileBarcode.value;
					carrier_no = carrierNo.value;
					break;
				case 'thirdParty':
					invoice_type = invoiceTypeMap.thirdParty.value;
					break;
				default:
					break;
			}
			break;
		case invoiceType.value.value === 'personalInvoice':
			invoice_type = invoiceTypeMap.personalInvoice.value;
			break;
		default:
			break;
	}

	return {
		invoice_type,
		company_title,
		company_no,
		donate_code,
		carrier_no,
	};
});

export const reducer = {
	invoice: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_INVOICE: () => ({
				...initialState,
			}),

			CHANGE_INVOICE_FORM: (state, action: Action<ChangeInvoicePayload>) => ({
				...state,
				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CHANGE_INVOICE_TYPE: (state, action: Action<SelectValue<InvoiceUnit>>) => ({
				...state,

				form: {
					...initialState.form,
					invoiceType: {
						...initialState.form.invoiceType,
						value: action.payload,
					},

					...(action.payload.value === 'companyInvoice' && {
						companyTitle: {
							...initialState.form.companyTitle,
							required: true,
						},
						companyId: {
							...initialState.form.companyId,
							required: true,
						},
					}),

					...(action.payload.value === 'carrier' && {
						carrierType: {
							...initialState.form.carrierType,
							required: true,
						},
					}),

					...(action.payload.value === 'donationCode' && {
						donationType: {
							...initialState.form.donationType,
							required: true,
						},
					}),
				},
			}),

			GET_INVOICE_MAPPING_VALUE: (state, action: Action<State['mappingValue']>) => ({
				...state,

				mappingValue: {
					...state.mappingValue,
					...action.payload,
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => state.invoice.form;

const actionsMap = {
	changeInvoiceType,
	changeInvoice,
	clearInvoice,
};

export const useInvoice = () =>
	useRedux<ReturnType<typeof mapHooksToState>, typeof actionsMap>(mapHooksToState, actionsMap);
