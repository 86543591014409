import { handleActions, createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { Moment } from 'moment';

import { genderCodeToValueMap, GenderCodes } from 'types/Gender';
import { RelationshipCodes } from 'types/Relationship';
import { SelectValue } from 'types/SelectValue';

import { useRedux } from 'util/hook/redux';
import { initPhoneValue, initSelectValue } from 'util/helper';

import { updateUserProfileFunc, UpdateUserProfileParams } from 'api/auth';
import { MemberInfoUpdateParams, updateMemberInfoFunc } from 'api/member';

import { openModal } from './modal';
// import { getAuthUserGiantId, getAuthUserProfile } from './auth';
import { GetState, State as GlobalState } from './reducers';

interface AccountEditFormData {
	// Basic
	firstName: string;
	lastName: string;
	nationalityCode: SelectValue<string | null>;
	gender: SelectValue<GenderCodes | null>;
	birthday: Moment | null; // YYYY-MM-DD
	height: string;
	weight: string;
	measureUnit?: string;
	// Contact
	countryCode: SelectValue<string | null>;
	cityName: string;
	formattedAddress: string;
	// Emergency
	emergencyName: string;
	emergencyRelation: SelectValue<RelationshipCodes | null>;
	emergencyMobile: { intIdNum: string; phoneNum: string };
	// Others
	eatingHabit: SelectValue<'MEAT' | 'VEGAN' | null>;
	eatingHabitNote: string;
}

type AccountEditFormFields = keyof AccountEditFormData;
type AccountEditFormFieldValues = AccountEditFormData[AccountEditFormFields];
type AccountEditFormErrorMsg = Partial<Record<AccountEditFormFields, string>>;
export type AccountEditFormSections =
	| 'basicInfo'
	| 'contactInfo'
	| 'emergencyPersonInfo'
	| 'otherInfo';

export interface State {
	edit: {
		form: {
			fields: AccountEditFormData;
			invalidFields: AccountEditFormFields[];
			errorMsgByField: AccountEditFormErrorMsg;
			editingSections: Record<AccountEditFormSections, boolean>;
		};
	};
}

const initialState: State = {
	edit: {
		form: {
			fields: {
				// Basic
				firstName: '',
				lastName: '',
				nationalityCode: initSelectValue(),
				gender: initSelectValue(),
				birthday: null, // YYYY-MM-DD
				height: '',
				weight: '',
				measureUnit: '',
				// Contact
				countryCode: initSelectValue(),
				cityName: '',
				formattedAddress: '',
				// Emergency
				emergencyName: '',
				emergencyRelation: initSelectValue(),
				emergencyMobile: initPhoneValue(),
				// Others
				eatingHabit: initSelectValue(),
				eatingHabitNote: '',
			},
			invalidFields: [],
			errorMsgByField: {},
			editingSections: {
				basicInfo: false,
				contactInfo: false,
				emergencyPersonInfo: false,
				otherInfo: false,
			},
		},
	},
};

type ChangeAccountEditFromPayload = {
	key: AccountEditFormFields;
	value: AccountEditFormFieldValues;
};

export const changeAccountEditFrom = createAction(
	'CHANGE_ACCOUNT_EDIT_FORM',
	(key: AccountEditFormFields, value: AccountEditFormFieldValues) => ({
		key,
		value,
	}),
);

type SetAccountEditFromSectionStatusPayload = { section: AccountEditFormSections };

export const setAccountEditFromSectionStatus = createAction(
	'SET_ACCOUNT_EDIT_FORM_SECTION_STATUS',
	(section: AccountEditFormSections) => ({ section }),
);

const updateAccountUserProfile = createAction(
	'UPDATE_ACCOUNT_USER_PROFILE',
	(section: AccountEditFormSections) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			account: {
				edit: {
					form: { fields },
				},
			},
			auth: { currentUserGid, userDataByGid },
		} = getState();

		const fieldGenderValue = genderCodeToValueMap[fields.gender.value as GenderCodes];

		const requestData = {
			basicInfo: {
				...(fields.firstName && { firstName: fields.firstName }),
				...(fields.lastName && { lastName: fields.lastName }),
				...(fields.nationalityCode.value && { nationalityCode: fields.nationalityCode.value }),
				...(fieldGenderValue && { gender: fieldGenderValue }),
				...(fields.birthday && { birthday: fields.birthday }),
				// 如果修改處的身高體重沒有被修改則回傳原本的資料
				...(fields.height
					? { height: (+fields.height).toFixed(0) }
					: { height: userDataByGid[currentUserGid].height }),
				...(fields.weight
					? { weight: (+fields.weight).toFixed(0) }
					: { weight: userDataByGid[currentUserGid].weight }),
				...(fields.measureUnit
					? { measureUnit: fields.measureUnit }
					: { measureUnit: userDataByGid[currentUserGid].measureUnit }),
			},
			contactInfo: {
				...(fields.countryCode.value && { countryCode: fields.countryCode.value }),
				...(fields.cityName && { cityName: fields.cityName }),
				...(fields.formattedAddress && { formattedAddress: fields.formattedAddress }),
			},
			emergencyPersonInfo: {
				...(fields.emergencyName && { contact_name: fields.emergencyName }),
				...(fields.emergencyRelation.value && {
					contact_relationship: fields.emergencyRelation.value,
				}),
				...(fields.emergencyMobile.intIdNum && {
					contact_phone_country_code: fields.emergencyMobile.intIdNum,
				}),
				...(fields.emergencyMobile.phoneNum && {
					contact_phone: `${fields.emergencyMobile.intIdNum} ${fields.emergencyMobile.phoneNum}`,
				}),
			},
			otherInfo: {
				...(fields.eatingHabit.value && { eating_habit: fields.eatingHabit.value }),
				...(fields.eatingHabitNote && { eating_habit_note: fields.eatingHabitNote }),
			},
		}[section];

		try {
			if (section === 'basicInfo' || section === 'contactInfo') {
				const { status, message } = await updateUserProfileFunc(
					requestData as UpdateUserProfileParams,
				);

				if (status !== 200) {
					throw new Error(message);
				}
			}

			if (section === 'emergencyPersonInfo' || section === 'otherInfo') {
				const { status, message } = await updateMemberInfoFunc(
					requestData as MemberInfoUpdateParams,
				);

				if (status !== 200 && status !== 201) {
					throw new Error(message);
				}
			}

			// await dispatch(getAuthUserGiantId());
			// await dispatch(getAuthUserProfile());
			// dispatch(setAccountEditFromSectionStatus(section));

			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'toast',
					data: {
						message: 'updatedSuccessfully',
						status: 'pass',
					},
				}),
			);

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (_error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'toast',
					data: {
						message: 'systemError',
						status: 'warning',
					},
				}),
			);
		}
	},
);

type ReducerActionPayloads = ChangeAccountEditFromPayload & SetAccountEditFromSectionStatusPayload;

export const reducer = {
	account: handleActions<State, ReducerActionPayloads>(
		{
			CHANGE_ACCOUNT_EDIT_FORM: (state, action) => ({
				...state,

				edit: {
					...state.edit,

					form: {
						...state.edit.form,

						fields: {
							...state.edit.form.fields,

							[action.payload.key]: action.payload.value,
						},
					},
				},
			}),

			SET_ACCOUNT_EDIT_FORM_SECTION_STATUS: (state, action) => ({
				...state,

				edit: {
					...state.edit,

					form: {
						...state.edit.form,

						editingSections: {
							...state.edit.form.editingSections,

							[action.payload.section]: !state.edit.form.editingSections[action.payload.section],
						},
					},
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAccountEditForm ++
++----------------------------------------------------------------------*/
const selectAccountEditForm = (state: GlobalState) => state.account.edit.form;
const accountEditFormActionMap = {
	changeForm: changeAccountEditFrom,
	toggleEditSection: setAccountEditFromSectionStatus,
	updateEditSection: updateAccountUserProfile,
};

export const useAccountEditForm = () =>
	useRedux<ReturnType<typeof selectAccountEditForm>, typeof accountEditFormActionMap>(
		selectAccountEditForm,
		accountEditFormActionMap,
	);
