import { Action, createAction, handleActions } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';
import { useRedux } from 'util/hook/redux';
import { fetchCertificateFunc } from 'api/member';

import { State as GlobalState } from './reducers';

/**
 * skill 的部分 pm 暫時先讓我拔掉
 */
export interface CertificateData {
	id: number;
	event_name: string;
	date: string;
	event_type: string;
	list_img: string;
	// tag: string;
}

// tag 也先暫時不需要
// interface SkillData {
// 	id: number;
// 	label: string;
// }

export type State = FetchedData<{
	event: {
		featuredrides: CertificateData[];
		storerides: CertificateData[];
		bikeclasses: CertificateData[];
	};
	travel: [];
}>;

const initialState: State = {
	loading: false,
	error: '',
	data: {
		event: {
			featuredrides: [
				{
					id: 0,
					event_type: '',
					list_img: '',
					event_name: '',
					date: '',
				},
			],
			storerides: [
				{
					id: 0,
					event_type: '',
					list_img: '',
					event_name: '',
					date: '',
				},
			],
			bikeclasses: [
				{
					id: 0,
					event_type: '',
					list_img: '',
					event_name: '',
					date: '',
				},
			],
		},
		travel: [],
	},
};

type CertificatePayload = Partial<State>;

export const getCertificateData = createAction<Promise<CertificatePayload>>(
	'GET_CERTIFICATE_DATA',
	async () => {
		try {
			const { status, message, data } = await fetchCertificateFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const {
				event: { featuredrides, storerides, bikeclasses },
				travel,
			} = data;

			return {
				data: {
					event: {
						featuredrides,
						storerides,
						bikeclasses,
					},
					travel,
				},
			};
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

export const reducer = {
	accountCertificate: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_CERTIFICATE_DATA_PENDING: state => ({
				...state,
				...initialState,
				loading: true,
			}),

			GET_CERTIFICATE_DATA_FULFILLED: (state, action: Action<CertificatePayload>) => ({
				...state,
				...(action.payload.data && {
					data: action.payload.data,
				}),
				...(action.payload.error && {
					error: action.payload.error,
				}),
				loading: false,
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
++ useAccountCertificate ++
++----------------------------------------------------------------------*/
const accountCertificate = (state: GlobalState) => state.accountCertificate;
const accountCertificateActionMap = { getCertificateData };

export const useAccountCertificate = () =>
	useRedux<ReturnType<typeof accountCertificate>, typeof accountCertificateActionMap>(
		accountCertificate,
		accountCertificateActionMap,
	);
