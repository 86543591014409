/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSpring, useTransition, animated, config } from 'react-spring';

import { giantEventDataType } from 'types/GiantEvents';

import { useScroll, useResize, useMedia } from 'util/event';
import { useBoolean, usePrevious } from 'util/hook';
import { mapLanguageOption } from 'util/i18n';
import { appPath } from 'util/routingConfig';

import { pathSupportChecker } from 'util/helper';
import { useRouting } from 'models/routing';
import { useLanguage } from 'models/i18n';
import { useLocale } from 'models/locale';
import { useGlobal, useConfig } from 'models/global';

import Navigation from 'components/molecules/Navigation';
import ButtonLanguage from 'components/atoms/ButtonLanguage';
import ButtonLocale from 'components/atoms/ButtonLocale';
import ButtonMember from 'components/organisms/ButtonMember';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Division from 'components/atoms/Division';
import Tooltip from 'components/atoms/Tooltip';
import Link from 'components/atoms/Link';
import Hamburger from 'components/atoms/Hamburger';
import NaviLocale from 'components/atoms/NaviLocale';
import NaviLang from 'components/atoms/NaviLang';
import ModalTransMember from 'components/molecules/ModalTransMember';

// import CalendarOutlineIcon from 'images/icon/calendar-outline.inline.svg';
import CalendarFilledIcon from 'images/icon/calendar-filled.inline.svg';
import QuestionOutlineIcon from 'images/icon/question-outline.inline.svg';
import LogoIcon from 'images/icon/logo-blue.inline.svg';

import styles from './index.css';

interface CalendarIconProperty {
	className?: string;
}

const CalendarIcon: React.FC<CalendarIconProperty> = ({ className }) => {
	const { t } = useTranslation('nav');
	// const [isHovering, { setFalse, setTrue }] = useBoolean({
	// 	defaultBoolean: false,
	// });

	return (
		<Tooltip className={className} placement="bottom" trigger="hover" tooltip={t('travelCalendar')}>
			<Icon
				className={styles.calendarIcon}
				src={CalendarFilledIcon}
				// onMouseEnter={setTrue}
				// onMouseLeave={setFalse}
			/>
		</Tooltip>
	);
};

export interface DesktopComponentProperty {
	className?: string;
	// isTransparent: boolean;
	isHidden: boolean;
}

const DesktopComponent: React.FC<DesktopComponentProperty> = ({
	/* isTransparent, */ isHidden,
}) => {
	const { t } = useTranslation('nav');
	const [{ headers, allAppPaths }] = useConfig();
	const [isOpenTransMember, setIsOpenTransMember] = useState(false);
	const supportPath = pathSupportChecker(allAppPaths, {
		account: ['account'],
		calendar: ['travelClassicTours', ...giantEventDataType],
		faq: ['travelQuestions', 'rentalQuestions'],
	});

	return (
		<header
			className={classnames(styles.header, styles.desktop, {
				// [styles.transparent]: isTransparent,
				[styles.hidden]: isHidden,
			})}
		>
			<Link
				className={styles.logo}
				to={
					// 2022-03-30 首次上 prod，以 `${domain}/TW/rental` 為首頁。
					process.env.PRODUCTION ? appPath.rental : appPath.landing
				}
			>
				<Icon src={LogoIcon} className={styles.logoIcon} />
			</Link>
			<Navigation className={styles.navigation} />
			<div className={styles.group}>
				{headers.includes('travel') && (
					<Link to={appPath.travelTravelTours}>
						<Button outline color="blue">
							{t('registration')}
						</Button>
					</Link>
				)}
				{supportPath.calendar && (
					<Link to={appPath.calendar}>
						<CalendarIcon className={styles.calendar} />
					</Link>
				)}
				{/* // 2022-03-30 首次上 prod，先將 prod 的「常見問題」轉到「租車常見問題」。*/}
				{supportPath.faq && (
					<Link to={process.env.PRODUCTION ? appPath.rentalQuestions : appPath.questions}>
						<Icon className={styles.questionIcon} src={QuestionOutlineIcon} />
					</Link>
				)}
				<Division vertical size={40} color="default" />
				<ButtonLocale color="default" />
				<ButtonLanguage color="default" />
				{supportPath.account && (
					<ButtonMember
						placement="bottom"
						alignment="end"
						// triggerIconColor="black"
						onClickTransMember={() => {
							setIsOpenTransMember(true);
						}}
					/>
				)}
			</div>
			{supportPath.account && (
				<ModalTransMember
					isOpen={isOpenTransMember}
					closeModal={() => setIsOpenTransMember(false)}
				/>
			)}
		</header>
	);
};

export interface MobileComponentProperty {
	className?: string;
	// isTransparent: boolean;
	isMenuOpen: boolean;
	closeMenu: () => void;
	toggleMenu: () => void;
}

const MobileComponent: React.FC<MobileComponentProperty> = ({
	// isTransparent,
	isMenuOpen,
	closeMenu = () => {},
	toggleMenu = () => {},
}) => {
	const { t } = useTranslation(['nav', 'locales', 'common', 'auth']);
	const [
		{
			currentLocCode,
			locales: {
				data: { byCode: locByCode },
			},
			isGlobal,
		},
	] = useLocale();
	const { height: winHeight } = useResize();
	const [{ allAppPaths }] = useConfig();
	const supportPath = pathSupportChecker(allAppPaths, {
		account: ['account'],
	});
	const [menuType, setMenuType] = useState('nav');
	const [isOpenTransMember, setIsOpenTransMember] = useState(false);
	const [lan] = useLanguage();

	const headerProps = useSpring({
		from: {
			height: 64,
		},
		to: {
			height: isMenuOpen ? winHeight : 64,
		},
	});

	const navContentTransition = useTransition(isMenuOpen, {
		from: { opacity: 0, transform: 'translateY(50%)' },
		enter: { opacity: 1, transform: 'translateY(0%)' },
		leave: { opacity: 0, transform: 'translateY(50%)' },
		config: config.slow,
	});

	const menuContentTransition = useTransition(menuType, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.slow,
	});

	useEffect(() => {
		if (isMenuOpen && menuType !== 'nav') {
			setMenuType('nav');
		}
	}, [isMenuOpen]);

	return (
		<animated.header
			className={classnames(
				styles.header,
				styles.mobile,
				// isTransparent && !isMenuOpen && styles.transparent,
				isMenuOpen && styles.open,
			)}
			style={headerProps}
		>
			<div className={styles.main}>
				<Link
					className={styles.logo}
					to={
						// 2022-03-30 首次上 prod，以 `${domain}/TW/rental` 為首頁。
						process.env.PRODUCTION ? appPath.rental : appPath.landing
					}
				>
					<Icon src={LogoIcon} className={styles.logoIcon} />
				</Link>
				<Hamburger
					className={styles.hamburger}
					open={isMenuOpen}
					// color={!isTransparent || isMenuOpen ? 'default' : 'white'}
					onClick={toggleMenu}
				/>
			</div>

			{navContentTransition(
				(values, item) =>
					item && (
						<animated.div className={styles.navContent} style={values}>
							<div className={styles.container}>
								{menuContentTransition((menuValues, menuItem) => (
									<animated.div className={classnames(styles.wrapper)} style={menuValues}>
										{menuItem === 'nav' && (
											<Navigation className={styles.navigation} closeMenu={closeMenu} />
										)}
										{menuItem === 'locale' && (
											<NaviLocale
												className={styles.naviLocale}
												onChangeLocale={() => {
													setMenuType('nav');
												}}
											/>
										)}
										{menuItem === 'lang' && (
											<NaviLang
												className={styles.naviLang}
												onChangeLang={() => {
													setMenuType('nav');
												}}
											/>
										)}
									</animated.div>
								))}
							</div>
							<div className={classnames(styles.bottomGroup)}>
								{supportPath.account && (
									<ButtonMember
										placement="top"
										alignment="start"
										withLabel
										showListIcon
										triggerIconColor="black"
										trigger="click"
										onClickTransMember={() => {
											setIsOpenTransMember(true);
										}}
										onClickLinkCallback={closeMenu}
									/>
								)}
								<div className={styles.group}>
									{menuType === 'nav' ? (
										<>
											<div
												className={styles.link}
												role="button"
												tabIndex={0}
												onKeyPress={() => {}}
												onClick={() => {
													setMenuType('locale');
												}}
											>
												{isGlobal ? t(`locales:global`) : locByCode[currentLocCode].name}
											</div>
											<Division size={12} vertical />
											<div
												className={styles.link}
												role="button"
												tabIndex={0}
												onKeyPress={() => {}}
												onClick={() => {
													setMenuType('lang');
												}}
											>
												{mapLanguageOption(lan).label}
											</div>
										</>
									) : (
										<div
											className={classnames(styles.link, styles.primary)}
											role="button"
											tabIndex={0}
											onKeyPress={() => {}}
											onClick={() => {
												setMenuType('nav');
											}}
										>
											{t('common:back')}
										</div>
									)}
								</div>
							</div>
						</animated.div>
					),
			)}
			{supportPath.account && (
				<ModalTransMember
					isOpen={isOpenTransMember}
					closeModal={() => setIsOpenTransMember(false)}
				/>
			)}
		</animated.header>
	);
};

const Header: React.FC = () => {
	const media = useMedia();
	const { scrollY } = useScroll();
	const [{ pathname }] = useRouting();
	const previousScrollY = usePrevious(scrollY || 0);
	const [{ currentLocCode, isGlobal }] = useLocale();
	const [{ isBackgroundFixed }, { setBackgroundScrollY, restoreBackgroundScrollY }] = useGlobal();
	const [isTransparent, { setFalse: setHeaderFilled, setTrue: setHeaderTransparent }] = useBoolean({
		defaultBoolean: false,
	});
	const [isHidden, { setFalse: openHeader, setTrue: closeHeader }] = useBoolean({
		defaultBoolean: false,
	});
	const [isMenuOpen, { setFalse: closeMenu, toggle: toggleMenu }] = useBoolean({
		defaultBoolean: false,
		onTrue: setBackgroundScrollY,
		onFalse: restoreBackgroundScrollY,
	});

	const transparentDisabled = useMemo(() => {
		const pathnameLocale = isGlobal ? '' : currentLocCode;
		if (
			pathname === '/' ||
			pathname === `/${pathnameLocale}` ||
			pathname === `/${pathnameLocale}/`
		) {
			return false;
		}
		return true;
	}, [pathname, currentLocCode]);

	const hiddenDisabled = useMemo(() => {
		const pathnameLocale = isGlobal ? '' : currentLocCode;
		const pathRegexRuleForRentalPages = new RegExp(
			`/(${pathnameLocale}/)?(rental(/plans)?(/\\d+)?)?`,
			'g',
		);

		const isAnyRegexMatch = pathRegexRuleForRentalPages.test(pathname);
		return !isAnyRegexMatch;
	}, [pathname, currentLocCode]);

	useEffect(() => {
		if (transparentDisabled || (isBackgroundFixed && isMenuOpen)) {
			if (isTransparent) {
				setHeaderFilled();
			}
			return;
		}

		if (scrollY > 0) {
			if (isTransparent) {
				setHeaderFilled();
			}
		} else if (!isTransparent) {
			setHeaderTransparent();
		}
	}, [scrollY, isBackgroundFixed, transparentDisabled]);

	useEffect(() => {
		if (scrollY <= 0 && !isHidden) {
			openHeader();
			return;
		}

		if (hiddenDisabled || (isBackgroundFixed && isMenuOpen)) {
			if (!isHidden) {
				openHeader();
			}
			return;
		}

		const pathnameLocale = isGlobal ? '' : currentLocCode;
		const pathRegexRuleForLandingPage = new RegExp(`^/${pathnameLocale}/$`, 'g');
		const isCountryLandingPage = pathRegexRuleForLandingPage.test(pathname);
		// const pathRegexRuleForReservation = new RegExp(/^\/rental\/reservation\/\d+$/, 'g');
		// const isReservationPage = pathRegexRuleForReservation.test(pathname);

		if (pathname === '/' || isCountryLandingPage) {
			if (typeof previousScrollY !== 'undefined' && scrollY > previousScrollY) {
				closeHeader();
			} else {
				openHeader();
			}
		} else if (scrollY > 80) {
			if (!isHidden) {
				closeHeader();
			}
		} else if (isHidden) {
			openHeader();
		}
	}, [scrollY, isBackgroundFixed, hiddenDisabled]);

	useEffect(() => {
		if (isMenuOpen) {
			closeMenu();
		}
	}, [media]);

	if (media === 'desktop') {
		return <DesktopComponent isHidden={isHidden} /* isTransparent={isTransparent} */ />;
	}
	return (
		<MobileComponent
			// isTransparent={isTransparent}
			isMenuOpen={isMenuOpen}
			closeMenu={closeMenu}
			toggleMenu={toggleMenu}
		/>
	);
};

export default Header;
