import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.logisticsPackingForm.replace(appPath.logistics, ''),
	components: () => [import(/* webpackChunkName: 'deliveryPackingForm' */ './component')],
	render: ([DeliveryPackingForm]) => <DeliveryPackingForm />,
};

export default routes;
