import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.aboutContact.replace(appPath.about, ''),
	components: () => [import(/* webpackChunkName: 'contact' */ './component')],
	render: ([Contact]) => <Contact />,
};

export default routes;
