import { FetchResponse } from 'types/FetchResponse';
import { ChargeMethod } from 'types/ChargeMethod';

import { wrapFetch } from 'util/api';

export type ProductType = 'products' | 'services' | 'gifts' | 'apply_type';

export type ProductSelect = {
	id: number;
	amount: number;
};

export interface ProductData {
	id: number;
	name: string;
	description: string;
	img: string;
	size_img: string;
	charge_method: ChargeMethod;
	currency: string;
	price: number;
	max_count: number;
	size_name: string;
	colors: {
		id: number;
		color_name: string;
		hex_code: string;
	}[];
	sizes: {
		id: number;
		size_name: string;
	}[];
	stocks: {
		[key: string]: {
			id: number;
			color_id: number;
			size_id: number;
			stock: number;
			safe_stock: number;
		};
	};
}

interface ValidateProductStock {
	id: number;
	stock: number;
	safe_stock: number;
	name: string;
	img: string;
	size_img: string;
	description: string;
	price: number;
	max_count: number;
	color: {
		name: string;
		hex_code: string;
	};
	size: {
		name: string;
	};
}

type ValidateStockFunc = (params: {
	start_at: string;
	end_at: string;
	products: { id: number; amount: number }[];
}) => Promise<
	FetchResponse<{ product_stocks: ValidateProductStock[]; errors: { [id: number]: string } }>
>;

export const validateProductStockFunc: ValidateStockFunc = async params => {
	const response = await wrapFetch('product/validateStock', {
		method: 'POST',
		body: JSON.stringify(params),
	});

	return response;
};
