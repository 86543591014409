import { Difficulty } from 'types/DifficultyCode';
import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

type fetchRidingLevelsFunc = () => Promise<FetchResponse<Difficulty[]>>;

/**
 * 根據 only_groups: ['DIFFICULTY'] 參數, 從後端'tags'
 * api: /api/v1/web/tags 取得騎乘強度資料
 */
export const fetchRidingLevelsFunc: fetchRidingLevelsFunc = async () => {
	const response = await wrapFetch(
		'tags',
		{
			method: 'GET',
		},
		{
			only_groups: ['DIFFICULTY'],
		},
		{ withLocale: false, withLan: true },
	);

	return response;
};

export interface CustomTravelFormRequestBody {
	first_name: string;
	last_name: string;
	phone: string;
	email: string;
	location: string;
	guest_count: number;
	travel_date: string;
	travel_duration: number;
	riding_levels: number[];
	note: string;
	reference_travel_id: number;
}

type SendCustomTravelFormFunc = (
	data: CustomTravelFormRequestBody,
) => Promise<FetchResponse<string>>;

/**
 * 將客製化旅遊表單資料 post 送至後端 /api/v1/web/custom_travel_form
 */
export const sendCustomTravelFormFunc: SendCustomTravelFormFunc = async (
	data: CustomTravelFormRequestBody,
) => {
	const response = await wrapFetch('customTravelForm', {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};
