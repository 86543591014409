import { LeaderData } from 'types/class/Leader';
import { InstructorData } from 'types/class/Instructor';
import { FetchFunction } from 'types/FetchFunction';

import { wrapFetch } from 'util/api';

export interface AboutUsStoryData {
	id: number;
	content: string;
}

export interface AboutUsTravelAgencyData {
	id: number;
	introduction: string;
	leaders: LeaderData[];
}

export interface AboutUsSchoolData {
	id: number;
	introduction: string;
	course_introduction: string;
	instructor_introduction: string;
	become_instructor: string;
	instructors: InstructorData[];
}

type AboutUsParams = 'story' | 'school' | 'travelAgency';
type AboutUsRes = AboutUsStoryData | AboutUsTravelAgencyData | AboutUsSchoolData;
type AboutUsFetch = FetchFunction<AboutUsRes, AboutUsParams>;

export const fetchAboutUsFunc: AboutUsFetch = async (type?: AboutUsParams) => {
	const response = await wrapFetch(`aboutUs/${type}`, {
		method: 'GET',
	});

	return response;
};
