import { GenderCodes } from 'types/Gender';
import { RelationshipCodes } from 'types/Relationship';
import { FetchResponse } from 'types/FetchResponse';
import { InvoiceRequestParams } from 'types/Invoice';

import { wrapFetch } from 'util/api';
import { PaymentRequestParams } from 'util/payment';
import { OrderInfo, OrderProduct } from 'util/order';

import { SingleStore } from 'api/store';

import { ProductSelect } from './product';

type RentalOrderData = {
	start_store_id: number;
	end_store_id: number;
	start_at: string;
	end_at: string;
	total_price: number;
	first_name: string;
	last_name: string;
	gender: GenderCodes;
	nationality: string;
	identity_no: string;
	height: number;
	phone: string;
	email: string;
	contact_name: string;
	contact_relationship: RelationshipCodes;
	contact_phone: string;
	customer_note: string;
	newebpay_token_id?: number;
	bicycle_specs: ProductSelect[];
	products: ProductSelect[];
} & Partial<InvoiceRequestParams> &
	Partial<PaymentRequestParams>;

export type RentalOrderResponse = {
	selector?: string;
	view?: string;
	bicycle_spec_errors?: {
		[id: number]: string;
	};
	product_stock_errors?: {
		[id: number]: string;
	};
};

type SubmitRentalOrderFunc = (
	data: Partial<RentalOrderData>,
) => Promise<FetchResponse<RentalOrderResponse>>;

export const submitRentalOrderFunc: SubmitRentalOrderFunc = async data => {
	const response = await wrapFetch(
		'rentalOrder',
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ withAuth: true },
	);

	return response;
};

interface OrderBicycleSpec {
	id: number;
	category_name: string;
	model_family_name: string;
	model_name: string;
	size: string;
	unit_price: number;
	amount: number;
	total_price: number;
	created_at: string;
	is_reviewing: boolean;
}

export interface RentalOrder extends OrderInfo {
	start_store_id: number;
	start_store: SingleStore;
	end_store_id: number;
	end_store: SingleStore;
	start_at: string;
	end_at: string;
	original_end_at: string;
	first_name: string;
	last_name: string;
	gender: GenderCodes;
	identity_no: string;
	height: number;
	phone: string;
	email: string;
	contact_first_name: string;
	contact_last_name: string;
	contact_relationship: string;
	contact_phone: string;
	customer_note: string;
	products: OrderProduct[];
	bicycle_specs: OrderBicycleSpec[];
}

type FetchRentalOrderFunc = (selector: string) => Promise<FetchResponse<RentalOrder>>;

export const fetchRentalOrderFunc: FetchRentalOrderFunc = async selector => {
	const response = await wrapFetch(`rentalOrder/${selector}`, {
		method: 'GET',
	});

	return response;
};

type RentalOrderTransactionData = Pick<
	RentalOrderData,
	| 'payment_type'
	| 'remember_card'
	| 'card_no'
	| 'card_exp_date'
	| 'card_cvc'
	| 'card_owner_name'
	| 'newebpay_token_id'
	| 'invoice_type'
	| 'company_title'
	| 'company_no'
	| 'donate_code'
	| 'carrier_no'
>;

export type RentalOrderTransactionResponse = {
	view?: string;
};

type SubmitRentalOrderTransactionFunc = (
	selector: string,
	id: number,
	data: RentalOrderTransactionData,
) => Promise<FetchResponse<RentalOrderTransactionResponse>>;

export const submitRentalOrderTransactionFunc: SubmitRentalOrderTransactionFunc = async (
	selector,
	id,
	data,
) => {
	const response = await wrapFetch(`rentalOrder/${selector}/transaction/${id}`, {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};

type CancelRentalOrderFunc = (selector: string) => Promise<FetchResponse>;

export const cancelRentalOrderFunc: CancelRentalOrderFunc = async selector => {
	const response = await wrapFetch(`rentalOrder/${selector}/cancel`, {
		method: 'PUT',
	});

	return response;
};
