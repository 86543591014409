import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.adminBicycleMaintain.replace(appPath.admin, ''),
	components: () => [import(/* webpackChunkName: 'adminBicycleMaintain' */ './component')],
	render: ([AdminBicycleMaintain]) => <AdminBicycleMaintain />,
};

export default routes;
