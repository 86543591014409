/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import {
	GiantEventCode,
	GiantEventDataType,
	GiantEventRoute,
	GiantEventRouteType,
	GIANT_EVENT_DATA_TO_ROUTE_MAP,
} from 'types/GiantEvents';
import { GenderCodes } from 'types/Gender';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import { nullToUndefined } from 'util/helper';

import {
	EventsParticipantForms,
	EventsParticipantFormType,
	FeaturedridesFormDataType,
	StoreridesFormDataType,
} from 'models/eventsParticipant/type';
import {
	setupParticipantFormByRideOrder,
	setEventsTypeId,
	setupEventParticipantTemplateProcess,
} from 'models/eventsParticipant';
import { ProductData, StockItem, ProductItemWithAmount } from 'models/product/type';
import { openModal } from 'models/modal';
import { getRideReservationInfo } from 'models/ride';
import { getDocument } from 'models/document';
import { getCancelOrderInfo } from 'models/orderInfo';

import {
	cancelRideOrderFunc,
	fetchRideOrderBySelectorFunc,
	RideOrder,
	RideOrderPreview,
	RideParticipant,
	updateRideOrderBySelectorFunc,
} from 'api/rideOrder';
import { Product, submitAdminRideOrderAddPurchaseFunc } from 'api/eventsOrderAdmin';

import { GetState, State as GlobalState } from './reducers';

export interface RideOrderBySelector extends Omit<RideOrder, 'participants'> {
	participants: {
		ids: number[];
		byIds: { [id: number]: RideParticipant };
	};
}

export interface State {
	detail: FetchedData<string>;
	bySelector: Record<RideOrder['selector'], RideOrderBySelector>;

	// order list
	list: {
		[keys in Exclude<GiantEventDataType, 'eventsBikeClasses' | 'travel'>]: FetchedData<string[]>;
	};
	listDataBySelector: Record<RideOrder['selector'], RideOrderPreview>;
}

const initialState: State = {
	detail: {
		loading: false,
		error: '',
		data: '',
	},
	bySelector: {},
	list: {
		eventsFeaturedRides: { loading: false, error: '', data: [] },
		eventsStoreRides: { loading: false, error: '', data: [] },
	},
	listDataBySelector: {},
};

interface GetRideOrderPayload {
	error?: string;
	data?: RideOrderBySelector;
}

const getRideOrderBySelector = createAction<Promise<GetRideOrderPayload>, string>(
	'GET_RIDE_ORDER_BY_SELECTOR',
	async selector => {
		try {
			const { status, message, data } = await fetchRideOrderBySelectorFunc(selector);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { participants } = data;

			const normalizedParticipants = participants.reduce(
				(a, v) => ({ ids: [...a.ids, v.id], byIds: { ...a.byIds, [v.id]: v } }),
				{ ids: [] as number[], byIds: {} as { [id: number]: RideParticipant } },
			);

			const newData = { ...data, participants: normalizedParticipants };

			return {
				data: newData,
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return {
					error: message,
				};
			}
			return {
				error: '',
			};
		}
	},
);

const setupParticipantByRideOrderProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>(
	'SETUP_PARTICIPANT_BY_RIDE_ORDER_PROCESS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			rideOrder: {
				detail: { data: selector },
				bySelector,
			},
		} = getState();
		const data = bySelector[selector];

		// if still can edit, setup participant form
		data.participants.ids.forEach(id => {
			dispatch(
				setupParticipantFormByRideOrder(
					id,
					GIANT_EVENT_DATA_TO_ROUTE_MAP[data.ride_type] as keyof EventsParticipantForms,
				),
			);
		});
	},
);

const getRideOrderAndEventDetail = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>('GET_RIDE_ORDER_AND_EVENT_DETAIL', selector => async (dispatch: Dispatch, getState: GetState) => {
	await dispatch(getRideOrderBySelector(selector));

	const {
		rideOrder: { bySelector },
	} = getState();

	const orderData = bySelector[selector];
	const eventId = orderData.ride_id;

	await dispatch(getRideReservationInfo(eventId));
});

const initialRideOrderDetailProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string,
	Exclude<GiantEventRouteType, 'bikeclasses'>
>(
	'INITIAL_RIDE_ORDER_DETAIL_PROCESS',
	(selector, eventType) => async (dispatch: Dispatch, getState: GetState) => {
		await dispatch(getRideOrderAndEventDetail(selector));

		const {
			rideOrder: { bySelector },
			locale: {
				locales: {
					data: { byCode },
				},
			},
		} = getState();

		const orderData = bySelector[selector];
		const eventId = orderData.ride_id;

		await Promise.all([
			dispatch(
				setEventsTypeId({
					eventType,
					eventId,
				}),
			),
			dispatch(
				getDocument({
					type: 'RIDER',
					code:
						eventType === GiantEventRoute.featuredrides
							? 'CODE_THEME_ACTIVITY_PARENT'
							: 'CODE_STORE_RIDE_PARENT',
					country_id: byCode?.TW.id,
				}),
			),
			// 取得訂單取消說明文案
			dispatch(
				getCancelOrderInfo({
					type:
						eventType === GiantEventRoute.featuredrides
							? GiantEventCode.THEME_ACTIVITY
							: GiantEventCode.STORE_RIDE,
					country_id: orderData.country_id,
				}),
			),
		]);

		await dispatch(setupEventParticipantTemplateProcess(eventType));
		await dispatch(setupParticipantByRideOrderProcess());
	},
);

type UpdateRideOrderParticipantDataPayload = {
	selector: string;
	participantId: number;
	data: Partial<RideParticipant>;
};

const updateRideOrderParticipantData = createAction<
	UpdateRideOrderParticipantDataPayload,
	UpdateRideOrderParticipantDataPayload
>('UPDATE_RIDE_ORDER_PARTICIPANT_DATA', ({ selector, participantId, data }) => ({
	selector,
	participantId,
	data,
}));

const updateRideOrderBySelector = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string,
	EventsParticipantFormType
>(
	'UPDATE_RIDE_ORDER_BY_SELECTOR',
	(selector, type) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			eventsParticipant: {
				form: {
					editing: { id: participantId, data: participantForm },
				},
			},
		} = getState();

		try {
			if (participantId === null || participantForm === null) {
				throw new Error('empty participant');
			}

			let newData: Partial<RideParticipant> = { id: participantId };
			if (type === GiantEventRoute.featuredrides) {
				const form = participantForm as FormDataMapping<FeaturedridesFormDataType>;
				newData = {
					...newData,
					first_name: form.firstName.value,
					last_name: form.lastName.value,
					nationality: nullToUndefined(form.citizenship.value.value),
					identity_no: form.citizenId.value,
					gender: form.sex.value.value as GenderCodes,
					birthday: form.birthday.value ? form.birthday.value.format('YYYY-MM-DD') : '',
					height: Number(form.height.value),
					phone: `${form.phone.value.intIdNum} ${form.phone.value.phoneNum}`,
					email: form.email.value,
					contact_name: form.emergencyName.value,
					contact_relationship: form.emergencyRelation.value.value || '',
					contact_phone: `${form.emergencyPhone.value.intIdNum} ${form.emergencyPhone.value.phoneNum}`,
					extra_field_1: form.custom1.value,
					extra_field_2: form.custom2.value,
					extra_field_3: form.custom3.value,
					eating_habit: form.eatingHabit.value.value,
					eating_habit_note: form.eatingHabitNote.value,
					medical_record: form.specialDisease.value.value || false,
					medical_record_note: form.specialDiseaseNote.value,
					// parent_consent: null,
				};
			}

			if (type === GiantEventRoute.storerides) {
				const form = participantForm as FormDataMapping<StoreridesFormDataType>;
				newData = {
					...newData,
					first_name: form.firstName.value,
					last_name: form.lastName.value,
					nationality: nullToUndefined(form.citizenship.value.value),
					identity_no: form.citizenId.value,
					gender: form.sex.value.value as GenderCodes,
					phone: `${form.phone.value.intIdNum} ${form.phone.value.phoneNum}`,
					email: form.email.value,
					contact_name: form.emergencyName.value,
					contact_relationship: form.emergencyRelation.value.value || '',
					contact_phone: `${form.emergencyPhone.value.intIdNum} ${form.emergencyPhone.value.phoneNum}`,
					extra_field_1: form.custom1.value,
					extra_field_2: form.custom2.value,
					extra_field_3: form.custom3.value,
				};
			}
			const { status, message } = await updateRideOrderBySelectorFunc(selector, [newData]);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}
			dispatch(updateRideOrderParticipantData({ selector, participantId, data: newData }));
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				dispatch(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message,
							status: 'warning',
						},
					}),
				);
			}
		}
	},
);

interface SetRideOrderListPayload {
	type: Exclude<GiantEventDataType, 'eventsBikeClasses' | 'travel'>;
	data: string[];
}

export const setRideOrderList = createAction<SetRideOrderListPayload, SetRideOrderListPayload>(
	'SET_RIDE_ORDER_LIST',
	props => props,
);

type SetRideOrderListDataPayload = State['listDataBySelector'];

export const setRideOrderListData = createAction<
	SetRideOrderListDataPayload,
	SetRideOrderListDataPayload
>('SET_RIDE_ORDER_LIST_DATA', data => data);

export const participantAddPurchaseCalculator = (
	productsById: {
		[id: number]: ProductData;
	},
	stocksById: {
		[id: number]: StockItem;
	},
) => (
	participantProducts: {
		[productId: number]: ProductItemWithAmount;
	} = {},
) => {
	const a = Object.values(participantProducts).filter(({ amount }) => amount > 0);
	const r = a.map(({ productId, colorId, specId, amount }) => {
		const tC = colorId === null || typeof colorId !== 'number' ? 0 : colorId;
		const tS = specId === null || typeof specId !== 'number' ? 0 : specId;

		const { price } = productsById[productId] || { price: 0 };
		const { stockId } = Object.values(stocksById).find(
			({ productId: pId, colorId: cId, sizeId: sId }) =>
				pId === productId && cId === tC && sId === tS,
		) as StockItem;

		return {
			id: stockId,
			amount,
			price: price * amount,
		};
	});

	return r;
};

const submitAdminRideOrderAddPurchase = createAction<
	(_: Dispatch, getState: GetState) => Promise<{ success: boolean }>,
	string
>(
	'SUBMIT_ADMIN_RIDE_ORDER_ADD_PURCHASE',
	selector => async (dispatch: Dispatch, getState: GetState) => {
		const {
			eventsParticipant: {
				form: { byIds, ids },
			},
			product: {
				dataById: { products: productsByIds, stocks: stocksByIds },
			},
		} = getState();

		let totalPrice = 0;
		const participants = [];

		const calculateProduct = participantAddPurchaseCalculator(productsByIds, stocksByIds);

		for (let i = 0; i < ids.length; i += 1) {
			const pId = ids[i];
			const { applyType, giveaways, productPurchase, servicePurchase } = byIds[pId];

			const [pApplyType] = calculateProduct(applyType.value);
			const pGiveaway = calculateProduct(giveaways.value);
			const pProducts = calculateProduct(productPurchase.value);
			const pServices = calculateProduct(servicePurchase.value);

			const resGiveaways = pGiveaway.map(({ id }) => id) as number[];

			const pProductSum = [...pProducts, ...pServices];
			const resProducts = pProductSum.map(({ id, amount }) => ({
				id,
				amount,
			})) as Product[];

			totalPrice +=
				(pApplyType ? pApplyType.price : 0) + pProductSum.reduce((a, n) => a + n.price, 0);

			const res = {
				id: Number(pId),
				...(pApplyType && { apply_type_id: pApplyType.id }),
				...(resGiveaways.length > 0 && { gift_id: resGiveaways }),
				...(resProducts.length > 0 && { products: resProducts }),
			};

			participants.push(res);
		}

		try {
			const { status, message } = await submitAdminRideOrderAddPurchaseFunc(selector, {
				total_price: totalPrice,
				participants,
			});

			if (status === 200) {
				return Promise.resolve({ success: true });
			}

			if (message === 'VALIDATE_STOCK_ERROR') {
				dispatch(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message: JSON.stringify(message),
							status: 'warning',
						},
					}),
				);
			}

			throw new Error(message);
		} catch (error) {
			return Promise.reject(error);
		}
	},
);

const cancelRideOrder = createAction<(_: Dispatch) => Promise<{ success: boolean }>, string>(
	'CANCEL_RIDE_ORDER',
	selector => async dispatch => {
		try {
			const { status, message } = await cancelRideOrderFunc(selector);

			if (status === 200) {
				return Promise.resolve({ success: true });
			}
			throw new Error(message);
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: (error as Error).message,
						status: 'warning',
					},
				}),
			);
			return Promise.reject((error as Error).message);
		}
	},
);

export const reducer = {
	rideOrder: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_RIDE_ORDER_BY_SELECTOR_PENDING: state => ({
				...state,
				detail: {
					...state.detail,

					loading: true,
					error: '',
				},
			}),

			GET_RIDE_ORDER_BY_SELECTOR_FULFILLED: (state, action: Action<GetRideOrderPayload>) => ({
				...state,
				detail: {
					...state.detail,
					...(action.payload.error && {
						error: action.payload.error,
					}),
					...(action.payload.data && {
						data: action.payload.data.selector,
					}),
					loading: false,
				},

				...(action.payload.data && {
					bySelector: {
						...state.bySelector,
						[action.payload.data.selector]: action.payload.data,
					},
				}),
			}),

			UPDATE_RIDE_ORDER_PARTICIPANT_DATA: (
				state,
				action: Action<UpdateRideOrderParticipantDataPayload>,
			) => ({
				...state,

				bySelector: {
					...state.bySelector,
					[action.payload.selector]: {
						...state.bySelector[action.payload.selector],
						participants: {
							...state.bySelector[action.payload.selector].participants,
							byIds: {
								...state.bySelector[action.payload.selector].participants.byIds,
								[action.payload.participantId]: {
									...state.bySelector[action.payload.selector].participants.byIds[
										action.payload.participantId
									],
									...action.payload.data,
								},
							},
						},
					},
				},
			}),

			SET_RIDE_ORDER_LIST: (state, action: Action<SetRideOrderListPayload>) => ({
				...state,
				list: {
					...state.list,
					[action.payload.type]: {
						...state.list[action.payload.type],
						data: action.payload.data,
					},
				},
			}),

			SET_RIDE_ORDER_LIST_DATA: (state, action: Action<SetRideOrderListDataPayload>) => ({
				...state,
				listDataBySelector: {
					...state.listDataBySelector,
					...action.payload,
				},
			}),
		},
		initialState,
	),
};

const selectRideOrder = (state: GlobalState) => state.rideOrder;

const rideOrderActionsMap = {
	getRideOrderBySelector,
	setupParticipantByRideOrderProcess,
	updateRideOrderBySelector,
	setRideOrderList,
	setRideOrderListData,
	submitAdminRideOrderAddPurchase,
	initialRideOrderDetailProcess,
	getRideOrderAndEventDetail,
	cancelRideOrder,
};

export const useRideOrder = () =>
	useRedux<ReturnType<typeof selectRideOrder>, typeof rideOrderActionsMap>(
		selectRideOrder,
		rideOrderActionsMap,
	);

const selectRideOrderList = (state: GlobalState) => ({
	list: state.rideOrder.list,
	listDataBySelector: state.rideOrder.listDataBySelector,
});

const rideOrderListActionsMap = {
	setRideOrderList,
	setRideOrderListData,
};

export const useRideOrderList = () =>
	useRedux<ReturnType<typeof selectRideOrderList>, typeof rideOrderListActionsMap>(
		selectRideOrderList,
		rideOrderListActionsMap,
	);
