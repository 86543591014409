/* eslint-disable indent */
import { handleActions, createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { DynamicState } from 'types/DynamicState';
import { EventStatus } from 'types/EventStatus';

import { useRedux } from 'util/hook/redux';
import { getDifference } from 'util/helper';

import { eventCourseFormattedFunc, EventSimpleData } from 'models/events';
import { updateProductData } from 'models/product';
import { normalizedProduct } from 'models/product/utils';
import { TimeLeftType, AlbumDataType } from 'models/ride/type';

import { AnnouncementType } from 'api/events';
import {
	StudentFeedbackType,
	LecturerIntroType,
	CourseData,
	fetchCourseFunc,
	CourseLeftQuotaFetchParams,
	fetchCourseLeftQuotaFunc,
	CourseFetchParams,
} from 'api/course';

import { SlideProperty } from 'components/molecules/SliderBanner';

import { GetState, State as GlobalState } from './reducers';

type CourseInfoData = {
	mainInfo: {
		title: string;
		description: string;
		activeStatus: EventStatus;
	};
	courseInfo: {
		[key: string]: string;
	};
	applyInfo: {
		applyCondition: string;
		ability: string;
		payment: string;
		refund: string;
		quota: number;
		registeredNumber: number;
		waitingQuota: number;
		isOpenWaitingList: boolean;
	};
	courseIntro: string;
	studentFeedback: StudentFeedbackType[];
	lecturerIntro: LecturerIntroType[];
	recommendLecture: EventSimpleData[];
	albumData: AlbumDataType[];
	banners: SlideProperty[];
	announcements: AnnouncementType[];
	announcementIndex: number;
	note: string;
	showCountdownDay: boolean;
	timeLeft: TimeLeftType;
};

type ReservationCourseQuotaData = {
	leftQuota: number;
	waitingQuota: number;
	isOpenWaitingList: boolean;
};

type FormattedCourseData = Omit<CourseData, 'additional_products'> & {
	additionalsByProductId: Record<'products' | 'services' | 'gifts' | 'applyTypes', number[]>;
	dateId: number;
};

type ReservationCourseData = FormattedCourseData & ReservationCourseQuotaData;

export interface State {
	detail: DynamicState<CourseInfoData | null>;
	reservationCourseInfo: DynamicState<ReservationCourseData | null>;
}

const initialState: State = {
	detail: {
		data: null,
		loading: false,
		error: '',
	},
	reservationCourseInfo: {
		data: null,
		loading: false,
		error: '',
	},
};

const changeAnnouncementIndex = createAction('CHANGE_ANNOUNCEMENT_INDEX');

type GetCourseInfoPayload = Partial<DynamicState<CourseInfoData | null>>;

const getCourseInfo = createAction<
	Promise<GetCourseInfoPayload>,
	{ courseId: number; dateId: number }
>('GET_COURSE_INFO', async params => {
	try {
		const { status, message, data } = await fetchCourseFunc(params);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: {
				mainInfo: {
					title: data.name,
					description: data.description,
					activeStatus: data.active_status,
				},
				courseInfo: {
					// 下方的屬性排序決定前台的資訊排序 ex. 比方說 1.活動類別 2. 活動級別 3. 參加年齡 ...
					courseLevelType: data.course_level_type,
					courseLevel: data.course_level,
					studentAge: data.activity_info.age,
					studentNumber: data.activity_info.target_audience,
					courseDate: data.activity_info.date,
					courseTime: data.activity_info.time,
					requireEquip: data.activity_info.bring,
					suggestEquip: data.activity_info.recommend_bring,
					courseAddress: data.activity_info.course_place,
					bikingPlace: data.activity_info.ride_place,
					courseFee: data.activity_info.apply_fee_text,
					courseGiveaway: data.activity_info.gift,
					organizer: data.activity_info.organizer,
					remind: data.activity_info.remind,
				},
				applyInfo: {
					applyCondition: data.apply_info.apply_requirement,
					ability: data.apply_info.ability,
					payment: data.apply_info.payment,
					refund: data.apply_info.refund,
					quota: data.apply_info.quota, // 報名總名額上限
					registeredNumber: data.apply_info.registered_number, // 已經報名的人數
					waitingQuota: data.apply_info.waiting_quota, // 目前候補人數
					isOpenWaitingList: data.apply_info.is_open_waiting_list, // 是否開啟候補名額機制
				},
				courseIntro: data.introduction,
				studentFeedback: data.feedback.map(({ id: feedbackId, name, content }) => ({
					id: feedbackId,
					name,
					content,
				})),
				lecturerIntro: [],
				recommendLecture: eventCourseFormattedFunc(data.recommend_courses),
				albumData: data.photos.map(({ type, url }, index) => ({
					id: index,
					type,
					imageUrl: url,
				})),
				banners: [...data.banners],
				announcements: [...data.announcements],
				announcementIndex: data.announcements.length > 0 ? 0 : -1,
				note: data.note,
				showCountdownDay: data.show_countdown_day,
				timeLeft: getDifference(moment(), moment(data.apply_info.deadline_date)),
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

type GetCourseLeftQuotaPayload = Partial<ReservationCourseQuotaData & { error: string }>;

export const getCourseLeftQuota = createAction<
	Promise<GetCourseLeftQuotaPayload>,
	CourseLeftQuotaFetchParams['dateId']
>('GET_COURSE_LEFT_QUOTA', async dateId => {
	try {
		const { status, message, data } = await fetchCourseLeftQuotaFunc(dateId);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			leftQuota: data.left_quota,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

type GetCourseReservationInfoPayload = Partial<DynamicState<ReservationCourseData | null>>;

/**
 * getCourseReservationInfo 參數 country_id 是為了讓報名查詢頁面 （global route）帶入活動的 country id 設定的
 * 若是在國家底下的頁面無需設定 country_id，會自動帶入。
 */
export const getCourseReservationInfo = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetCourseReservationInfoPayload>,
	CourseFetchParams
>('GET_COURSE_RESERVATION_INFO', params => async dispatch => {
	try {
		const { status: s1, message: m1, data: d1 } = await fetchCourseFunc(params);
		const { status: s2, message: m2, data: d2 } = await fetchCourseLeftQuotaFunc(params.dateId);

		if (s1 !== 200 && s1 !== 201) {
			throw new Error(m1);
		}

		if (s2 !== 200 && s2 !== 201) {
			throw new Error(m2);
		}

		const { products, services, gifts, apply_type } = d1.additional_products;
		const formattedProducts = normalizedProduct(products);
		const formattedServices = normalizedProduct(services);
		const formattedGifts = normalizedProduct(gifts);
		const formattedApplyType = normalizedProduct(apply_type);

		dispatch(
			updateProductData({
				products: {
					...formattedProducts.products,
					...formattedServices.products,
					...formattedGifts.products,
					...formattedApplyType.products,
				},
				stocks: {
					...formattedProducts.stocks,
					...formattedServices.stocks,
					...formattedGifts.stocks,
					...formattedApplyType.stocks,
				},
				sizes: {
					...formattedProducts.sizes,
					...formattedServices.sizes,
					...formattedGifts.sizes,
					...formattedApplyType.sizes,
				},
				colors: {
					...formattedProducts.colors,
					...formattedServices.colors,
					...formattedGifts.colors,
					...formattedApplyType.colors,
				},
			}),
		);

		return {
			data: {
				...d1,
				dateId: params.dateId,
				leftQuota: d2.left_quota,
				waitingQuota: d2.waiting_quota,
				isOpenWaitingList: d1.apply_info.is_open_waiting_list,
				additionalsByProductId: {
					products: formattedProducts.ids,
					services: formattedServices.ids,
					gifts: formattedGifts.ids,
					applyTypes: formattedApplyType.ids,
				},
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

type ReducerCoursePayloads = GetCourseInfoPayload &
	GetCourseReservationInfoPayload &
	GetCourseLeftQuotaPayload;

export const reducer = {
	course: handleActions<State, ReducerCoursePayloads>(
		{
			GET_COURSE_INFO_PENDING: state => ({
				...state,
				detail: {
					...state.detail,
					loading: true,
					error: '',
				},
			}),

			GET_COURSE_INFO_FULFILLED: (state, action) => ({
				...state,
				detail: {
					...state.detail,
					...(action.payload.data && {
						data: { ...state.detail.data, ...action.payload.data },
					}),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			CHANGE_ANNOUNCEMENT_INDEX: state => ({
				...state,
				detail: {
					...state.detail,
					...(state.detail.data && {
						data: {
							...state.detail.data,
							announcementIndex: state.detail.data?.announcementIndex || 0 + 1,
						},
					}),
				},
			}),

			GET_COURSE_RESERVATION_INFO_PENDING: state => ({
				...state,
				reservationCourseInfo: {
					...state.reservationCourseInfo,
					loading: true,
					error: '',
				},
			}),

			GET_COURSE_RESERVATION_INFO_FULFILLED: (state, action) => ({
				...state,
				reservationCourseInfo: {
					...state.reservationCourseInfo,
					...(action.payload?.data && { data: action.payload.data }),
					...(action.payload?.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_COURSE_LEFT_QUOTA_PENDING: state => ({
				...state,
				reservationCourseInfo: {
					...state.reservationCourseInfo,
					loading: true,
					error: '',
				},
			}),

			GET_COURSE_LEFT_QUOTA_FULFILLED: (state, action) => ({
				...state,
				reservationCourseInfo: {
					...state.reservationCourseInfo,

					data: {
						...(state.reservationCourseInfo.data as ReservationCourseData),
						leftQuota: action.payload.leftQuota ?? 0,
					},
					loading: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useCourseDetail ++
++----------------------------------------------------------------------*/
const selectCourseDetail = (state: GlobalState) => state.course.detail;

export const useCourseDetail = () =>
	useRedux(selectCourseDetail, { getCourseInfo, changeAnnouncementIndex });

/* +----------------------------------------------------------------------
	++ useCourseReservationInfo(預約表單) ++
++----------------------------------------------------------------------*/

const selectCourseReservationInfo = (state: GlobalState) => state.course.reservationCourseInfo;

export const useCourseReservationInfo = () =>
	useRedux(selectCourseReservationInfo, { getCourseReservationInfo, getCourseLeftQuota });
