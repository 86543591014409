import { AboutUsTravelAgencyData } from 'api/aboutUs';

import Leader, { LeaderData } from './Leader';

/**
 * 「捷安特旅行社」
 *
 * @export
 * @class GiantAdventure
 * @implements {AboutUsTravelAgencyData}
 */
export default class GiantAdventure implements AboutUsTravelAgencyData {
	/**
	 * 唯一 ID
	 *
	 * @type {number}
	 * @memberof GiantAdventure
	 */
	id: number;

	/**
	 * 「捷安特旅行社」簡介
	 *
	 * @type {string}
	 * @memberof GiantAdventure
	 */
	introduction: string;

	/**
	 * 「捷安特旅行社」領隊資料
	 *
	 * @type {LeaderData[]}
	 * @memberof GiantAdventure
	 */
	leaders: LeaderData[];

	constructor(p?: AboutUsTravelAgencyData) {
		this.id = p?.id || 0;
		this.introduction = p?.introduction || '';
		this.leaders = p?.leaders || [];
	}

	get allLeaders(): Leader[] {
		return this.leaders.map(i => new Leader(i)) || [];
	}
}
