/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unused-vars */

const insertScript = (
	id: string,
	src: string,
	options: { async?: boolean; defer?: boolean; crossOrigin?: null | string; nonce?: null | string },
) => {
	const defaultOptions = {
		async: true,
		defer: true,
		crossOrigin: null,
		nonce: null,
		...options,
	};
	if (document.getElementById(id)) {
		return 1;
	}

	const jss: HTMLElement | null = document.getElementsByTagName('script')[0];
	const js = document.createElement('script');

	js.id = id;
	js.src = src;

	if (defaultOptions.async) {
		js.async = true;
	}

	if (defaultOptions.defer) {
		js.defer = true;
	}

	if (defaultOptions.crossOrigin) {
		js.crossOrigin = defaultOptions.crossOrigin;
	}

	if (jss.parentNode === null) {
		throw Error('jss.parentNode is null');
	}

	jss.parentNode.insertBefore(js, jss);
	return 0;
};

export const initLineSDK = (): void => {
	insertScript('line-sdk', `//www.line-website.com/social-plugins/js/thirdparty/loader.min.js`, {
		async: true,
		defer: true,
	});
};

/**
 * 動態載入 gTag 程式碼
 *
 * @param gTagId
 */
export const initGTag = (): void => {
	const gTagId = 'UA-225040408-1';
	const win = window as any;
	insertScript('g-tag', `https://www.googletagmanager.com/gtag/js?id=${gTagId}`, {
		async: true,
		defer: false,
	});
	win.dataLayer = win.dataLayer || [];
	function gtag(..._args: (string | Date)[]) {
		win.dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', gTagId);
};
