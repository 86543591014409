import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';
import storage from 'util/storage';

// Bicycle
import BicycleRedirectRoute from './Bicycle/Redirect';
import BicyclePickupRoute from './Bicycle/Pickup';
import BicycleInventoryRoute from './Bicycle/Inventory';
import BicycleReturnRoute from './Bicycle/Return';
import BicycleLostRoute from './Bicycle/Lost';
import BicycleBrokenRoute from './Bicycle/Broken';
import BicycleRepairRoute from './Bicycle/Repair';
import BicycleReceiveRoute from './Bicycle/Receive';
import BicycleMaintainRoute from './Bicycle/Maintain';
import BicycleFoundRoute from './Bicycle/Found';
import BicycleDeliverRoute from './Bicycle/Deliver';
import BicycleReserveRoute from './Bicycle/Reserve';
import BicycleToNormalRoute from './Bicycle/ToNormal';
// Approve
import ApproveSTSTFRoute from './Approve/STSTF';
import ApproveLTFRoute from './Approve/LTF';
import ApproveBTFRoute from './Approve/BTF';
// Order
import OrderTravelPurchaseRoute from './Order/TravelPurchase';
import OrderRentalPurchaseRoute from './Order/RentalPurchase';
import OrderEventsPurchaseRoute from './Order/EventsPurchase';

const { API, API_ENDPOINT } = process.env;

const routes: CustomRoute = {
	path: appPath.admin,
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, history }) => {
		const backstageToken = storage.getItem('token', false);
		if (!backstageToken) {
			const {
				location: { pathname },
			} = history;

			// 因為 backstage redirect param 只接受 pathname 所以當 local 開發時
			// backstage 會將網址導去測試機
			// 我們須手動將 backstage token 設定於 localhost:3000 底下
			window.location.replace(
				`${
					API === 'dev' ? 'https://giantadventure-dev.25demo.com' : API_ENDPOINT
				}/backstage/en/login?redirect=${pathname.substring(1)}`,
			);
		}
		const children = await next();
		return children;
	},
	children: [
		BicycleRedirectRoute,
		BicyclePickupRoute,
		BicycleInventoryRoute,
		BicycleReturnRoute,
		BicycleLostRoute,
		BicycleBrokenRoute,
		BicycleRepairRoute,
		BicycleReceiveRoute,
		BicycleMaintainRoute,
		BicycleFoundRoute,
		BicycleDeliverRoute,
		BicycleReserveRoute,
		BicycleToNormalRoute,
		ApproveSTSTFRoute,
		ApproveLTFRoute,
		ApproveBTFRoute,
		OrderTravelPurchaseRoute,
		OrderRentalPurchaseRoute,
		OrderEventsPurchaseRoute,
	],
};

export default routes;
