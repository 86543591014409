import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'LecturerInfo' */ './component')],
	render: ([LecturerInfo]) => <LecturerInfo />,
};

export default routes;
