import { Action, createAction, handleActions } from 'redux-actions';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import { RentalOrderData, EventsOrderPreview, fetchMemberJourneysFunc } from 'api/member';

import { State as GlobalState } from './reducers';

interface JourneysData<Data> {
	countries: number;
	times: number;
	days: number;
	idList: number[];
	byId: { [key: number]: Data };
}

interface RentalJourneysData<Data> extends JourneysData<Data> {
	storeData?: { [key: number]: RentalOrderData['start_store'] };
}

interface EventJourneysData<Data> extends JourneysData<Data> {
	distance: number; // 整個活動的騎乘距離與爬行高度加總
	height: number;
}

export type State = FetchedData<{
	total: {
		countries: number;
		times: number;
		days: number;
		distance: number;
		height: number;
	};
	rental: RentalJourneysData<RentalOrderData>;
	travel: JourneysData<RentalOrderData>;
	event: EventJourneysData<EventsOrderPreview>;
}>;

const initialState: State = {
	loading: false,
	error: '',
	data: {
		total: { countries: 0, times: 0, days: 0, distance: 0, height: 0 },
		rental: { countries: 0, times: 0, days: 0, idList: [], byId: {}, storeData: {} },
		travel: { countries: 0, times: 0, days: 0, idList: [], byId: {} },
		event: { countries: 0, times: 0, days: 0, idList: [], byId: {}, distance: 0, height: 0 },
	},
};

type JourneysPayload = Partial<State>;

const getAccountJourneys = createAction<Promise<JourneysPayload>>(
	'GET_ACCOUNT_JOURNEYS',
	async () => {
		try {
			const { status, message, data } = await fetchMemberJourneysFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { orders: rentalOrders, ...rentalDatas } = data.rental;
			const normalizedRentalData = (rentalOrders || []).reduce(
				(a, v) => ({
					ids: [...a.ids, v.id],
					byId: {
						...a.byId,
						[v.id]: v,
					},
					storeData: {
						...a.storeData,
						[v.start_store_id]: v.start_store,
					},
				}),
				{ ids: [], byId: {}, storeData: {} } as {
					ids: number[];
					byId: { [key: number]: RentalOrderData };
					storeData: { [key: number]: RentalOrderData['start_store'] };
				},
			);

			const { orders: eventOrders, ...eventDatas } = data.events;
			const normalizedEventData = (eventOrders || []).reduce(
				(a, v) => ({
					ids: [...a.ids, v.id],
					byId: {
						...a.byId,
						[v.id]: v,
					},
				}),
				{ ids: [], byId: {} } as {
					ids: number[];
					byId: { [key: number]: EventsOrderPreview };
				},
			);

			return {
				data: {
					total: data.total,
					rental: {
						...rentalDatas,
						idList: normalizedRentalData.ids,
						byId: normalizedRentalData.byId,
						storeData: normalizedRentalData.storeData,
					},
					travel: { countries: 0, times: 0, days: 0, idList: [], byId: {}, storeData: {} },
					event: { ...eventDatas, idList: normalizedEventData.ids, byId: normalizedEventData.byId },
				},
			};
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

export const reducer = {
	accountJourneys: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ACCOUNT_JOURNEYS_PENDING: state => ({
				...state,
				...initialState,
				loading: true,
			}),

			GET_ACCOUNT_JOURNEYS_FULFILLED: (state, action: Action<JourneysPayload>) => ({
				...state,
				...(action.payload.data && {
					data: action.payload.data,
				}),
				...(action.payload.error && {
					error: action.payload.error,
				}),
				loading: false,
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAccountJourneys ++
++----------------------------------------------------------------------*/
const selectJourneys = (state: GlobalState) => state.accountJourneys;

const actionsMap = {
	getAccountJourneys,
};

export const useAccountJourneys = () =>
	useRedux<ReturnType<typeof selectJourneys>, typeof actionsMap>(selectJourneys, actionsMap);
