/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { match, TokensToRegexpOptions } from 'path-to-regexp';

import { LocationData } from 'types/LocationData';

type routingMatcherFunc = (
	pathname: string,
	currentLoc: LocationData,
	regexp: any,
	options?: TokensToRegexpOptions,
) => boolean;

const routingMatcher: routingMatcherFunc = (pathname, currentLoc, regexp, options) => {
	return currentLoc.id === 0
		? (match(regexp, { decode: decodeURIComponent, ...options })(pathname) as boolean)
		: (match(`/${currentLoc.code}${regexp}`, { decode: decodeURIComponent, ...options })(
				pathname,
		  ) as boolean);
};

export default routingMatcher;
