import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/register-search',
	components: () => [import(/* webpackChunkName: 'eventsRegisterSearch' */ './component')],
	render: ([EventsRegisterSearch]) => <EventsRegisterSearch />,
};

export default routes;
