/* eslint-disable indent */
import { Action, createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { LocationData } from 'types/LocationData';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';

import { fetchLocationsFunc } from 'api/location';

import { GetState, State as GlobalState } from './reducers';

// For Global State usage
export interface State {
	currentLocCode: string;
	locales: FetchedData<{
		ids: number[];
		codes: string[];
		byId: Record<number, LocationData>;
		byCode: Record<string, LocationData>;
	}>;
	isGlobal: boolean;
}

export const updateCurrentLocale = createAction(
	'UPDATE_CURRENT_LOCALE',
	(value: string) => (_: Dispatch, getState: GetState) => {
		const {
			locale: {
				locales: {
					data: { byCode: locByCode },
				},
			},
		} = getState();
		storage.setItem('locale', `${locByCode[value].id}`);

		return value;
	},
);

type GetLocationListPayload = Partial<State['locales']>;

export const fetchLocaleList = createAction<Promise<GetLocationListPayload>>(
	'FETCH_LOCALE_LIST',
	async () => {
		try {
			const { status, message, data } = await fetchLocationsFunc({
				location_type: 'COUNTRY',
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const normalized = data.reduce(
				(a, v) => ({
					...a,
					ids: [...a.ids, v.id],
					byId: {
						...a.byId,
						[v.id]: v,
					},
					...(v.code && {
						codes: [...a.codes, v.code],
						byCode: {
							...a.byCode,
							[v.code]: v,
						},
					}),
				}),
				{ ids: [], codes: [], byId: {}, byCode: {} } as {
					ids: number[];
					codes: string[];
					byId: { [key: number]: LocationData };
					byCode: { [key: number]: LocationData };
				},
			);

			return {
				data: normalized,
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return {
					error: message,
				};
			}
			return { error: '' };
		}
	},
);

export const fetchLocaleListEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType('CHANGE_LANGUAGE'),
		map(() => fetchLocaleList()),
	);

export const globalLocal: LocationData = {
	id: 0,
	code: 'global',
	name: 'global',
	img: '',
};

// 2022-03-30 首次上 prod 隱藏 global locale，並以 'TW' 為預設 locale。
export const initialState: State = process.env.PRODUCTION
	? {
			currentLocCode: 'TW',
			locales: {
				loading: false,
				error: '',
				data: {
					ids: [],
					codes: [],
					byId: {},
					byCode: {},
				},
			},
			isGlobal: false,
	  }
	: {
			currentLocCode: globalLocal.code as string,
			locales: {
				loading: false,
				error: '',
				data: {
					ids: [globalLocal.id],
					codes: [globalLocal.code as string],
					byId: { [globalLocal.id]: globalLocal },
					byCode: { [globalLocal.code as string]: globalLocal },
				},
			},
			isGlobal: true,
	  };

export const reducer = {
	locale: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			UPDATE_CURRENT_LOCALE: (state, action) => ({
				...state,
				currentLocCode: action.payload,
				isGlobal: action.payload === globalLocal.code,
			}),

			FETCH_LOCALE_LIST_PENDING: state => ({
				...state,
				locales: {
					...state.locales,
					loading: true,
					error: '',
				},
			}),

			FETCH_LOCALE_LIST_FULFILLED: (state, action: Action<GetLocationListPayload>) => ({
				...state,
				locales: {
					...state.locales,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
					...(action.payload.data && {
						data: {
							...state.locales.data,

							// 2022-03-30 首次上 prod 隱藏 global locale，並以 'TW' 為預設 locale。
							...(process.env.PRODUCTION
								? {
										ids: [...action.payload.data.ids],
										codes: [...action.payload.data.codes],
										byId: { ...action.payload.data.byId },
										byCode: { ...action.payload.data.byCode },
								  }
								: {
										ids: [globalLocal.id, ...action.payload.data.ids],
										codes: [globalLocal.code as string, ...action.payload.data.codes],
										byId: { [globalLocal.id]: globalLocal, ...action.payload.data.byId },
										byCode: {
											[globalLocal.code as string]: globalLocal,
											...action.payload.data.byCode,
										},
								  }),
						},
					}),
				},
			}),
		},
		initialState,
	),
};

const selectLocale = (state: GlobalState) => ({
	currentLocCode: state.locale.currentLocCode,
	locales: state.locale.locales,
	isGlobal: state.locale.currentLocCode === globalLocal.code,
});

const localeActionsMap = { fetchLocaleList, updateCurrentLocale };

type localeSelector = ReturnType<typeof selectLocale>;
type LocaleActionsMap = typeof localeActionsMap;

export const useLocale = () =>
	useRedux<localeSelector, LocaleActionsMap>(selectLocale, localeActionsMap);
