import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'models/i18n';
import { useRouting } from 'models/routing';

import { useMedia } from 'util/event';
import { useBoolean } from 'util/hook';
import { lanList } from 'util/i18n/config';
import { mapLanguageOption, changeLng } from 'util/i18n';

import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';

import LanguageIcon from 'images/icon/language.inline.svg';

import styles from './index.css';

interface NaviLangProperty {
	className?: string;
	onChangeLang?: (lang: string) => void;
}

const NaviLang: React.FC<NaviLangProperty> = ({ className, onChangeLang = () => {} }) => {
	const { t } = useTranslation('nav');
	const [lan] = useLanguage();
	const [, { pushRoute }] = useRouting();
	const media = useMedia();

	const [isCloseTop, { setFalse: setCloseTopFalse, setTrue: setCloseTopTrue }] = useBoolean({
		defaultBoolean: true,
	});
	const [
		isCloseBottom,
		{ setFalse: setCloseBottomFalse, setTrue: setCloseBottomTrue },
	] = useBoolean({
		defaultBoolean: false,
	});

	const onClickHandler = (nextLan = '') => () => {
		changeLng(nextLan);
		onChangeLang(nextLan);
	};

	return (
		<div className={classnames(styles.naviLang, className)}>
			<div className={styles.title}>
				<Icon className={styles.icon} src={LanguageIcon} />
				<span>{t('language')}</span>
			</div>
			<div
				className={styles.wrapper}
				onScroll={e => {
					const clossBottom =
						e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 25;
					const clossTop = e.target.scrollTop <= 25;

					if (clossTop) {
						setCloseTopTrue();
					} else {
						setCloseTopFalse();
					}
					if (clossBottom) {
						setCloseBottomTrue();
					} else {
						setCloseBottomFalse();
					}
				}}
			>
				<div className={classnames(styles.gradient, styles.top, { [styles.hide]: isCloseTop })} />
				<div className={styles.list}>
					{lanList.map(l => {
						const { value, label } = mapLanguageOption(l);

						return (
							<div
								key={value}
								className={classnames(styles.item, { [styles.active]: value === lan })}
								role="button"
								tabIndex={0}
								onKeyPress={() => {}}
								onClick={onClickHandler(l)}
							>
								{label}
							</div>
						);
					})}
					{media !== 'desktop' && (
						<Button
							className={styles.itineraryRegister}
							outline
							onClick={() => {
								pushRoute({
									pathname: '/travel/travel-tours',
								});
							}}
						>
							{t('common:itineraryRegister')}
						</Button>
					)}
				</div>
				<div
					className={classnames(styles.gradient, styles.bottom, { [styles.hide]: isCloseBottom })}
				/>
			</div>
		</div>
	);
};

export default NaviLang;
