/* eslint-disable indent */
import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { GiantEventData, GiantEventDataType } from 'types/GiantEvents';
import { DynamicState } from 'types/DynamicState';

import { useRedux } from 'util/hook/redux';
import { arrayNormalizer, NormalizedData } from 'util/normalizer';
import { getGiantEventDeadline } from 'util/helper';

import {
	fetchEventsData,
	EventCourseData,
	EventRideData,
	EventListParams,
	CourseActivityDateData,
} from 'api/events';

import { EventTravelData, TravelItinerariesData } from 'api/calendar';

import { GetState, State as GlobalState } from './reducers';

export interface EventSimpleData {
	id: EventCourseData['id'];
	dateId: CourseActivityDateData['date_id'];
	countryId: EventCourseData['country_id'];
	title: EventCourseData['name'];
	type: EventCourseData['type'];
	date: string;
	deadline: EventCourseData['activity_deadline'];
	timezoneOffset: EventCourseData['timezone_offset'];
	statusType: EventCourseData['active_status'];
	imageUrl: EventCourseData['first_banner_image_url'];
	photoUrl: EventCourseData['photo'];
	venue: EventCourseData['locations'];
	isSaved: EventCourseData['is_liked'];
	overdue: EventCourseData['is_expired'];
	courseLevelType?: EventCourseData['course_level_type'];
	courseLevel?: EventCourseData['course_level'];
}

export interface EventSimpleTravelData {
	id: EventTravelData['id'];
	dateId: TravelItinerariesData['itinerary_id'];
	countryId: EventTravelData['country_id'];
	date: string;
	title: EventTravelData['name'];
	type: EventTravelData['type'];
	timezone: EventTravelData['timezone'];
	timezoneOffset: EventTravelData['timezone_offset'];
}

export type State = {
	types: Record<
		GiantEventDataType,
		DynamicState<NormalizedData<EventSimpleData>> & {
			totalPage: number;
			currentPage: number;
		}
	>;
	list: DynamicState<EventSimpleData[]> & {
		hasMore: boolean;
	};
};

const inititalState: State = {
	types: {
		eventsStoreRides: {
			loading: false,
			error: '',
			totalPage: 0,
			currentPage: 0,
			data: {
				ids: [],
				byIds: {},
			},
		},
		eventsFeaturedRides: {
			loading: false,
			error: '',
			totalPage: 0,
			currentPage: 0,
			data: {
				ids: [],
				byIds: {},
			},
		},
		eventsBikeClasses: {
			loading: false,
			error: '',
			totalPage: 0,
			currentPage: 0,
			data: {
				ids: [],
				byIds: {},
			},
		},
		travel: {
			loading: false,
			error: '',
			totalPage: 0,
			currentPage: 0,
			data: {
				ids: [],
				byIds: {},
			},
		},
	},
	list: {
		loading: false,
		error: '',
		data: [],
		hasMore: false,
	},
};

export const eventRideFormattedFunc = (data: EventRideData[]): EventSimpleData[] =>
	data.map(d => ({
		id: d.id, // ride ID
		dateId: d.id, // 主題活動 和 門市約騎 沒有區分上課日期，因此用 id 代表該活動的唯一 ID ，且讓 dateId = id 。
		countryId: d.country_id,
		title: d.name,
		type: d.type,
		date: d.activity_date,
		deadline: getGiantEventDeadline(d.activity_deadline),
		timezoneOffset: d.timezone_offset,
		statusType: d.active_status,
		imageUrl: d.first_banner_image_url,
		photoUrl: d.photo,
		venue: d.locations,
		isSaved: d.is_liked,
		overdue: d.is_expired,
	}));

export const eventCourseFormattedFunc = (data: EventCourseData[]): EventSimpleData[] =>
	data
		.map(d =>
			d.activity_dates.map(({ date_id, date, active_status, is_liked }) => ({
				id: d.id, // course ID
				dateId: date_id, // 同一種 課程講座 有區分不同上課日期，因此用 date_id 代表不同上課日期活動的唯一 ID 。
				countryId: d.country_id,
				title: d.name,
				type: d.type,
				date,
				deadline: getGiantEventDeadline(d.activity_deadline),
				timezoneOffset: d.timezone_offset,
				statusType: active_status,
				imageUrl: d.first_banner_image_url,
				photoUrl: d.photo,
				venue: d.locations,
				isSaved: is_liked,
				overdue: d.is_expired,
				courseLevelType: d.course_level_type,
				courseLevel: d.course_level,
			})),
		)
		.reduce((acc, val) => acc.concat(val), [])
		.sort((prev, next) => prev.dateId - next.dateId); // 活動日期由早至晚排列

export const eventTravelFormattedFunc = (data: EventTravelData[]): EventSimpleTravelData[] =>
	data
		.map(d =>
			d.itineraries.map(({ start_date, itinerary_id }) => ({
				id: d.id,
				countryId: d.country_id,
				dateId: itinerary_id,
				date: start_date,
				title: d.name,
				type: d.type,
				timezone: d.timezone,
				timezoneOffset: d.timezone_offset,
			})),
		)
		.reduce((acc, val) => acc.concat(val), [])
		.sort((prev, next) => prev.dateId - next.dateId); // 活動日期由早至晚排列

type ClearEventListPayload = { type: GiantEventDataType };

const clearEventList = createAction('CLEAR_EVENTS_LIST', (type: GiantEventDataType) => ({ type }));

type SetEventListPayload = { type: GiantEventDataType };

const setEventListLoading = createAction('SET_EVENTS_LIST_LOADING', (type: GiantEventDataType) => ({
	type,
}));

type GetEventListPayload = Partial<DynamicState<NormalizedData<EventSimpleData>>> & {
	type: GiantEventDataType;
	totalPage?: number;
	currentPage?: number;
};

const getEventsList = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetEventListPayload>,
	EventListParams
>('GET_EVENTS_LIST', ({ type, ...params }) => async dispatch => {
	try {
		dispatch(setEventListLoading(type));

		const {
			status,
			message,
			data: { data: eventData, current_page, last_page },
		} = await fetchEventsData({ ...params, type });

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const formattedArray =
			type === GiantEventData.eventsBikeClasses
				? eventCourseFormattedFunc(eventData as EventCourseData[])
				: eventRideFormattedFunc(eventData as EventRideData[]);

		const normalizedData = arrayNormalizer<EventSimpleData>(formattedArray, 'dateId'); // 無論哪一種類別， dateId 都代表活動課程的唯一 ID。

		return {
			type,
			totalPage: last_page,
			currentPage: current_page,
			data: normalizedData,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				type,
				error: message,
			};
		}
		return {
			type,
			error: '',
		};
	}
});

type UpdateEventDataPayload = {
	type: GiantEventDataType;
	data: Record<number, EventSimpleData | EventSimpleTravelData>;
};

export const updateGiantEventsData = createAction<UpdateEventDataPayload, UpdateEventDataPayload>(
	'UPDATE_GIANT_EVENTS_DATA',
	({ type, data }) => ({ type, data }),
);

type ToggleEventSavePayload = { type: GiantEventDataType; id: number };

const toggleEventSave = createAction<ToggleEventSavePayload, ToggleEventSavePayload>(
	'TOGGLE_EVENT_SAVE',
	({ type, id }) => ({ type, id }),
);

type InitGiantEventsListLayoutPayload = { data: State['list'] };

export const initGiantEventsListLayout = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<InitGiantEventsListLayoutPayload>,
	GiantEventDataType
>('INIT_GIANT_EVENTS_LIST_LAYOUT', eventType => async (dispatch: Dispatch, getState: GetState) => {
	const {
		routing: {
			queries: { keywords, date: qDate, location: qLocation },
		},
	} = getState();

	await dispatch(
		getEventsList({
			type: eventType,
			page: 1,
			date: qDate,
			search: keywords,
			location_ids:
				qLocation !== undefined && typeof Number(qLocation) === 'number' ? [Number(qLocation)] : [],
		}),
	);

	const { events } = getState();

	const {
		loading,
		error,
		totalPage,
		currentPage,
		data: { ids, byIds },
	} = events.types[eventType];

	return {
		data: {
			loading,
			error,
			hasMore: currentPage < totalPage,
			data: ids.map(id => byIds[id]),
		},
	};
});

type ReducerEventsPayloads = ClearEventListPayload &
	SetEventListPayload &
	GetEventListPayload &
	UpdateEventDataPayload &
	ToggleEventSavePayload &
	InitGiantEventsListLayoutPayload;

export const reducer = {
	events: handleActions<State, ReducerEventsPayloads>(
		{
			CLEAR_EVENTS_LIST: (state, action) => ({
				...state,

				types: {
					...state.types,

					[action.payload.type]: {
						...inititalState.types[action.payload.type],
					},
				},
			}),

			SET_EVENTS_LIST_LOADING: (state, action) => ({
				...state,

				types: {
					...state.types,

					[action.payload.type]: {
						...state.types[action.payload.type],
						loading: true,
						error: '',
					},
				},

				list: {
					...state.list,
					loading: true,
				},
			}),

			GET_EVENTS_LIST_FULFILLED: (state, action) => ({
				...state,

				types: {
					...state.types,

					[action.payload.type]: {
						...state.types[action.payload.type],

						loading: false,
						...(action.payload.error && {
							error: action.payload.error,
						}),
						...(action.payload.data && {
							data: {
								ids:
									action.payload.currentPage === 1
										? action.payload.data.ids
										: [...state.types[action.payload.type].data.ids, ...action.payload.data.ids],
								byIds: {
									...state.types[action.payload.type].data.byIds,
									...action.payload.data.byIds,
								},
							},
							totalPage: action.payload.totalPage,
							currentPage: action.payload.currentPage,
						}),
					},
				},

				list: {
					...state.list,

					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
					...(action.payload.data && {
						data: (action.payload.currentPage === 1
							? action.payload.data.ids
							: [...state.types[action.payload.type].data.ids, ...action.payload.data.ids]
						).map(
							id =>
								({
									...state.types[action.payload.type].data.byIds,
									...action.payload.data.byIds,
								}[id]),
						),
					}),
					...(action.payload.currentPage &&
						action.payload.totalPage && {
							hasMore: action.payload.currentPage < action.payload.totalPage,
						}),
					...(action.payload.type && {
						type: action.payload.type,
					}),
				},
			}),

			UPDATE_GIANT_EVENTS_DATA: (state, action) => ({
				...state,

				types: {
					...state.types,

					[action.payload.type]: {
						...state.types[action.payload.type],

						data: {
							...state.types[action.payload.type].data,
							byIds: {
								...state.types[action.payload.type].data.byIds,
								...action.payload.data,
							},
						},
					},
				},
			}),

			TOGGLE_EVENT_SAVE: (state, action: Action<ToggleEventSavePayload>) => ({
				...state,

				types: {
					...state.types,

					[action.payload.type]: {
						...state.types[action.payload.type],

						data: {
							...state.types[action.payload.type].data,
							byIds: {
								...state.types[action.payload.type].data.byIds,
								[action.payload.id]: {
									...state.types[action.payload.type].data.byIds[action.payload.id],
									isSaved: !state.types[action.payload.type].data.byIds[action.payload.id].isSaved,
								},
							},
						},
					},
				},

				list: {
					...state.list,

					data: state.list.data.map(({ dateId, isSaved, ...props }) => ({
						...props,
						dateId,
						isSaved: dateId === action.payload.id ? !isSaved : isSaved,
					})),
				},
			}),

			INIT_GIANT_EVENTS_LIST_LAYOUT_PENDING: state => ({
				...state,

				list: {
					...inititalState.list,
					loading: true,
				},
			}),

			INIT_GIANT_EVENTS_LIST_LAYOUT_FULFILLED: (state, action) => ({
				...state,

				list: action.payload.data,
			}),
		},
		inititalState,
	),
};

/* +----------------------------------------------------------------------
	++ useEvents ++
++----------------------------------------------------------------------*/
const selectEvents = (state: GlobalState) => state.events.types;

const actionsMap = {
	clearEventList,
	getEventsList,
	toggleEventSave,
};

export const useEvents = () =>
	useRedux<ReturnType<typeof selectEvents>, typeof actionsMap>(selectEvents, actionsMap);

/* +----------------------------------------------------------------------
	++ useEventsList ++
++----------------------------------------------------------------------*/
const selectEventsList = (state: GlobalState) => state.events.list;

const eventsListActionsMap = {
	initGiantEventsListLayout,
};

export const useEventsList = () =>
	useRedux<ReturnType<typeof selectEventsList>, typeof eventsListActionsMap>(
		selectEventsList,
		eventsListActionsMap,
	);
