import { SelectValue } from 'types/SelectValue';

export const paymentTypeMap = {
	visa1: 1, // 藍新幕後信用卡(租車訂單不適用)
	visa2: 2, // 藍新幕後授權 -> 信用卡或簽帳卡
	atm: 3, // 藍新 ATM -> ATM 匯款
	offlineCash: 4, // 線下現金 -> 線下現金
	offlineVisa: 5, // 藍新線下信用卡 -> 線下信用卡
	thirdParty: 6, // 第三方收付平台 -> KKday/台灣好行
} as const;

export const paymentTypeForCUBMap = {
	CBUCreditCard: 7,
	CBUAtm: 8,
	CBUUnionPayCard: 9,
} as const;

export type PaymentType = keyof typeof paymentTypeMap;
export type PaymentTypeValues = typeof paymentTypeMap[PaymentType];

export type PaymentTypeForCUBKey = keyof typeof paymentTypeForCUBMap;
export type PaymentTypeValuesForCUB = typeof paymentTypeForCUBMap[PaymentTypeForCUBKey];

export type OnlineVisaType = 'bindedCreditCard' | 'otherCreditCard' | 'addNewCreditCard';

export interface PaymentTypeByParticipant {
	paymentType: SelectValue<'CONSOLIDATED' | 'SEPARATE' | null>;
}

export type PaymentRequestParams = {
	payment_type?: PaymentTypeValues;
	newebpay_token_id?: number;
	remember_card: boolean;
	card_no: string;
	card_exp_date: string;
	card_cvc: string;
	card_owner_name: string;
};
