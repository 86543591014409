import { wrapFetch } from 'util/api';
import { FetchFunction } from 'types/FetchFunction';

export interface TravelNoticeData {
	[key: string]: string | null;
}

type TravelNoticeParams = string;
type TravelNoticeRes = TravelNoticeData;
type TravelNoticeFetch = FetchFunction<TravelNoticeRes, TravelNoticeParams>;

// 用來 fetch 系統通知信 html 內容 參數為 旅遊行程 id
export const fetchTravelNoticeFunc: TravelNoticeFetch = async (id?: string) => {
	const response = await wrapFetch(
		`travel/${id}/agency/mail/registration`,
		{
			method: 'GET',
		},
		{},
		{ withLan: true, withLocale: true },
	);

	return response;
};
