import { BannerItem } from 'types/BannerItem';
import { FetchFunction } from 'types/FetchFunction';
import { EventStatus } from 'types/EventStatus';

import { wrapFetch } from 'util/api';

import { RoomIconType } from 'types/RoomInfo';
import { ProductData, ProductType } from './product';
import { AnnouncementType, EventCourseData } from './events';
import { DeliveryInfoType } from './ride'; // todo: 這邊不應該從 ride 引入等待重構

export type RoomType = {
	id: number;
	name: string;
	icon: RoomIconType;
};

export type StudentFeedbackType = {
	id: number;
	name: string;
	content: string;
};

export type LecturerIntroType = {
	id: number;
	imageUrl: string;
	name: string;
	positionTitle: string;
	corp: string;
};

export interface CourseData {
	id: number;
	series: string;
	name: string;
	description: string;
	active_status: EventStatus;
	introduction: string;
	transaction_by_others: boolean;
	show_countdown_day: boolean;
	note: string;
	activity_info: {
		age: string;
		target_audience: string;
		date: string;
		time: string;
		bring: string;
		recommend_bring: string;
		course_place: string;
		ride_place: string;
		apply_fee: number;
		apply_fee_text: string;
		gift: string;
		organizer: string;
		remind: string;
		total_mileage_text: string;
	};
	apply_info: {
		apply_requirement: string;
		ability: string;
		payment: string;
		refund: string;
		deadline_date: string;
		quota: number; // 報名總名額上限
		registered_number: number; // 已經報名的人數
		waiting_quota: number; // 目前候補人數
		is_open_waiting_list: boolean; // 是否開啟候補名額機制
	};
	delivery_info: DeliveryInfoType;
	extra_field_name_1: string;
	extra_field_name_2: string;
	extra_field_name_3: string;
	additional_products: Record<ProductType, ProductData[]>;
	photos: BannerItem[];
	banners: BannerItem[];
	feedback: StudentFeedbackType[];
	recommend_courses: EventCourseData[];
	announcements: AnnouncementType[];
	rooms: RoomType[];
	course_level: string;
	course_level_type: string;
}

export type CourseFetchParams = { courseId: number; dateId: number };
type CourseFetchRes = CourseData;
type CourseFetch = FetchFunction<CourseFetchRes, CourseFetchParams>;

/**
 * 取得某堂課程資訊
 */
export const fetchCourseFunc: CourseFetch = async (
	{ courseId, dateId } = { courseId: 0, dateId: 0 },
) => {
	const response = await wrapFetch(
		`course/${courseId}`,
		{
			method: 'GET',
		},
		{
			date_id: dateId,
		},
	);

	return response;
};

export type CourseLeftQuotaFetchParams = { dateId: number };
type CourseLeftQuotaFetchRes = { left_quota: number; waiting_quota: number };

type CourseLeftQuotaFetch = FetchFunction<
	CourseLeftQuotaFetchRes,
	CourseLeftQuotaFetchParams['dateId']
>;

/**
 * 查詢上課日期剩餘可報名人數
 */
export const fetchCourseLeftQuotaFunc: CourseLeftQuotaFetch = async (dateId = 0) => {
	const response = await wrapFetch(`courseDate/${dateId}/quota`, {
		method: 'GET',
	});

	return response;
};
