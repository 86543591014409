import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import EventsBikeClassesRoutes from './Info';

const routes: CustomRoute = {
	path: appPath.eventsBikeClasses.replace(appPath.events, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'eventsBikeClasses' */ './components')],
			render: ([EventsBikeClasses]) => <EventsBikeClasses />,
		},
		EventsBikeClassesRoutes,
	],
};

export default routes;
