import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import TravelClassicToursRoute from './Info';

const routes: CustomRoute = {
	path: appPath.travelClassicTours.replace(appPath.travel, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'travelClassicTours' */ './component')],
			render: ([TravelClassicTours]) => <TravelClassicTours />,
		},
		TravelClassicToursRoute,
	],
};

export default routes;
