import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import { getLocations } from 'models/locations';

import EventsNewsRoute from './News';
import EventsFeaturedRidesRoute from './FeaturedRides';
import EventsStoreRidesRoute from './StoreRides';
import EventsBikeClassesRoute from './BikeClasses';
import EventsScoreRoute from './Score';
import EventsHighlightsRoute from './Highlights';

const routes: CustomRoute = {
	path: appPath.events,
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'Event' */ './component')],
			render: ([Event]) => <Event />,
		},
		EventsNewsRoute,
		EventsFeaturedRidesRoute,
		EventsStoreRidesRoute,
		EventsBikeClassesRoute,
		EventsScoreRoute,
		EventsHighlightsRoute,
	],
	onEnter: async ({ store }) => {
		const {
			locale: {
				isGlobal,
				currentLocCode,
				locales: {
					data: { byCode: locByCode },
				},
			},
		} = store.getState();

		await store.dispatch(
			getLocations({
				locationType: isGlobal ? 'COUNTRY' : 'CITY',
				ancestorId: isGlobal ? undefined : locByCode[currentLocCode].id,
			}),
		);
	},
};

export default routes;
