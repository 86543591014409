import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.accountJourneysDetail.replace(appPath.accountJourneys, ''),
	components: () => [import(/* webpackChunkName: 'accountJourneysDetail' */ './component')],
	render: ([AccountJourneysDetail]) => <AccountJourneysDetail />,
};

export default routes;
