/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import classnames from 'classnames';

import DropdownArrow from 'components/atoms/DropdownArrow';

import { useLanguage } from 'models/i18n';

import { lanList } from 'util/i18n/config';
import { mapLanguageOption, changeLng } from 'util/i18n';

import styles from './index.css';

interface ButtonLanguageProperty {
	className?: string;
	color?: 'default' | 'white';
}

const ButtonLanguage: React.FC<ButtonLanguageProperty> = ({ className, color = 'default' }) => {
	const [prevLan] = useLanguage();

	const onClickHandler = (nextLan = '', closePanel = () => {}) => () => {
		changeLng(nextLan);
		closePanel();
	};

	return (
		<DropdownArrow
			offset={[16, 16]}
			triggerClassName={classnames(styles.buttonLanguage, styles[color], className)}
			contentClassName={styles.buttonLanguageContent}
			contentComponent={({ close }) =>
				// 語系選項超過一個，才顯示下拉選單。
				lanList.length > 1 ? (
					<div className={styles.buttonLanguageSelection}>
						{lanList.map(lan => {
							const { value, label } = mapLanguageOption(lan);

							return (
								<div
									key={value}
									className={classnames(styles.option, { [styles.active]: value === prevLan })}
									role="button"
									tabIndex={0}
									onKeyPress={() => {}}
									onClick={onClickHandler(lan, close)}
								>
									{label}
								</div>
							);
						})}
					</div>
				) : null
			}
		>
			{mapLanguageOption(prevLan).label}
		</DropdownArrow>
	);
};

export default ButtonLanguage;
