import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

import { EventCourseData, EventRideData } from 'api/events';
import { SimplePathData } from 'api/paths';
import { TravelToursData } from 'types/travelClassicAndCustomTours';

export interface EventCollectionData extends EventCourseData {
	is_expired: boolean;
}

interface PathCollectionData extends SimplePathData {
	is_liked: boolean;
}

export interface CollectionType {
	events: {
		courses: EventCourseData[];
		eventsFeaturedRides: EventRideData[];
		eventsStoreRides: EventRideData[];
	};
	paths: PathCollectionData[];
	travel: TravelToursData[];
}

type FetchCollectionFunc = () => Promise<FetchResponse<CollectionType>>;

/**
 * 新增會員之我的收藏
 */
export const fetchCollectionFunc: FetchCollectionFunc = async () => {
	const response = await wrapFetch(
		'member/collections',
		{
			method: 'GET',
		},
		{},
		{ withAuth: true },
	);

	return response;
};

/**
 * 課程講座 區分 上課時間，因此用 DATE 來識別每一個收藏的課程講座。
 */
export type CollectionSimpleObject = {
	type: 'DATE' | 'PATH' | 'RIDE' | 'TRAVEL' /* 課程講座、租車路線、騎乘活動、旅遊行程 */;
	type_id: number;
};

type AddCollectionFunc = (params: CollectionSimpleObject) => Promise<FetchResponse<string>>;

/**
 * 刪除會員之我的收藏
 */
export const addCollectionFunc: AddCollectionFunc = async params => {
	const response = await wrapFetch(
		'member/collection',
		{
			method: 'POST',
			body: JSON.stringify(params),
		},
		{},
		{ withAuth: true },
	);

	return response;
};

type DeleteCollectionFunc = (params: CollectionSimpleObject) => Promise<FetchResponse<string>>;

export const deleteCollectionFunc: DeleteCollectionFunc = async params => {
	const response = await wrapFetch(
		'member/collection',
		{
			method: 'DELETE',
			body: JSON.stringify(params),
		},
		{},
		{ withAuth: true },
	);

	return response;
};
