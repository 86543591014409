/* eslint-disable indent */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { accountNaviLinks, AccountNavi } from 'util/routingConfig';
import routingMatcher from 'util/routingMatcher';
import { externalUrlRegex } from 'util/regex';

import { useRouting } from 'models/routing';
import { useLocale } from 'models/locale';
import { useAuth } from 'models/auth';

import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import Dropdown from 'components/atoms/Dropdown';
import DropdownArrow from 'components/atoms/DropdownArrow';

import MemberIcon from 'images/icon/member.inline.svg';
import MemberTransIcon from 'images/icon/atoms-icon-switch.inline.svg';
import MemberInfoIcon from 'images/icon/atoms-icon-user-solid.inline.svg';
import AtomsReceiptIcon from 'images/icon/atoms-icon-receipt.inline.svg';
import AtomsLikeIcon from 'images/icon/atoms-icon-like-outline.inline.svg';
import AtomsTicketIcon from 'images/icon/atoms-icon-ticket.inline.svg';
import AtomsTrophyIcon from 'images/icon/atoms-icon-trophy.inline.svg';
import AtomsHardnessIcon from 'images/icon/atoms-icon-hardness.inline.svg';
import AtomsEditIcon from 'images/icon/atoms-icon-edit.inline.svg';

import styles from './index.css';

const memberIconMap: Record<AccountNavi, JSX.Element> = {
	accountOrders: <AtomsReceiptIcon />,
	accountWishList: <AtomsLikeIcon />,
	accountCoupon: <AtomsTicketIcon />,
	accountJourneys: <AtomsTrophyIcon />,
	accountCertificate: <AtomsHardnessIcon />,
	accountEdit: <AtomsEditIcon />,
};

interface ButtonMemberProperty {
	className?: string;
	placement?: 'bottom' | 'top';
	alignment?: 'start' | 'end';
	triggerIconColor?: 'black' | 'white';
	trigger?: 'click' | 'hover';
	withLabel?: boolean;
	showListIcon?: boolean;
	onClickTransMember?: () => void;
	onClickLinkCallback?: () => void;
}

type ButtonMemberTriggerProperty = {
	onClick?: () => void;
} & Pick<ButtonMemberProperty, 'withLabel' | 'triggerIconColor'>;

const Trigger = ({
	withLabel = false,
	triggerIconColor,
	onClick = () => {},
}: ButtonMemberTriggerProperty) => {
	const { t } = useTranslation(['nav', 'auth']);
	const [{ isLogin, currentUserGid, memberList, userDataByGid }] = useAuth();

	const userName = useMemo(() => {
		if (currentUserGid === null || userDataByGid[currentUserGid] === undefined) {
			return '';
		}
		return `${userDataByGid[currentUserGid].firstName} ${userDataByGid[currentUserGid].lastName}`;
	}, [currentUserGid, memberList]);

	return (
		<div
			className={styles.buttonMemberTrigger}
			role="button"
			tabIndex={0}
			onClick={onClick}
			onKeyPress={() => {}}
		>
			<Icon
				className={classnames(
					styles.icon,
					// triggerIconColor === 'white' && styles.white,
					// triggerIconColor === 'black' && styles.black,
				)}
				src={MemberIcon}
			/>
			{withLabel && <span>{isLogin ? userName : t('auth:signin')}</span>}
		</div>
	);
};

const MenuContent = ({
	showListIcon,
	onClickTransMember,
	onClickLinkCallback = () => {},
}: Pick<
	ButtonMemberProperty,
	| 'className'
	| 'placement'
	| 'alignment'
	| 'showListIcon'
	| 'onClickTransMember'
	| 'onClickLinkCallback'
>) => {
	const { t } = useTranslation(['nav', 'auth']);
	const [{ memberList, currentUserGid, userDataByGid }, { logout }] = useAuth();
	const [{ pathname }, { pushRoute }] = useRouting();
	const [
		{
			currentLocCode,
			locales: {
				data: { byCode: locByCode },
			},
		},
	] = useLocale();

	const userName = useMemo(() => {
		if (currentUserGid === null || userDataByGid[currentUserGid] === undefined) {
			return '';
		}
		return `${userDataByGid[currentUserGid].firstName} ${userDataByGid[currentUserGid].lastName}`;
	}, [currentUserGid, memberList.data]);

	const onClickHandler = useCallback(
		(nextPath: string) => () => {
			if (externalUrlRegex.test(nextPath)) {
				window.open(nextPath);
				return;
			}
			pushRoute({ pathname: nextPath, withLoc: false });
			onClickLinkCallback();
		},
		[],
	);

	return (
		<>
			<div className={styles.memberInfo}>
				<div className={styles.memberName}>
					<MemberInfoIcon />
					<span>{userName}</span>
				</div>
				{memberList.data.length > 1 && <Icon src={MemberTransIcon} onClick={onClickTransMember} />}
			</div>
			<div className={styles.memberSelection}>
				{accountNaviLinks.map(nav => (
					<div
						key={nav.key}
						role="button"
						className={classnames(styles.option, {
							[styles.active]: routingMatcher(pathname, locByCode[currentLocCode], nav.path, {
								end: false,
							}),
							// [styles.active]:
							// 	nav.key === 'accountEdit'
							// 		? false // 「會員編輯」會直接另開新頁，不可能有 active 為 true 的時候。
							// 		: routingMatcher(pathname, locByCode[currentLocCode], nav.path, {
							// 				end: false,
							// 		  }),
						})}
						tabIndex={0}
						onKeyPress={() => {}}
						onClick={onClickHandler(nav.path)}
					>
						{showListIcon && memberIconMap[nav.key]}
						{t(`nav:${nav.key}`)}
					</div>
				))}
			</div>
			<Link onClick={() => logout(undefined)}>
				<div className={styles.logout}>{t('auth:logout')}</div>
			</Link>
		</>
	);
};

const ButtonMember: React.FC<ButtonMemberProperty> = ({
	placement = 'bottom',
	alignment = 'end',
	withLabel = false,
	showListIcon = false,
	trigger = 'hover',
	triggerIconColor = 'white',
	onClickTransMember = () => {},
	onClickLinkCallback = () => {},
}) => {
	const [{ isLogin }, { signinProcess }] = useAuth();

	if (!isLogin) {
		return (
			<Trigger
				withLabel={withLabel}
				triggerIconColor={triggerIconColor}
				onClick={() => {
					signinProcess();
					onClickLinkCallback();
				}}
			/>
		);
	}

	if (trigger === 'hover') {
		return (
			<DropdownArrow
				offset={[16, 16]}
				alignment={alignment}
				contentClassName={styles.buttonMemberMenu}
				contentComponent={({ close }) => (
					<MenuContent
						placement={placement}
						alignment={alignment}
						showListIcon={showListIcon}
						onClickTransMember={() => {
							close();
							onClickTransMember();
						}}
						onClickLinkCallback={() => {
							close();
							onClickLinkCallback();
						}}
					/>
				)}
			>
				<Trigger triggerIconColor={triggerIconColor} withLabel={withLabel} />
			</DropdownArrow>
		);
	}

	return (
		<Dropdown
			placement={placement}
			alignment={alignment}
			offset={[0, 6]}
			valueComponent={() => <Trigger triggerIconColor={triggerIconColor} withLabel={withLabel} />}
			panelClassName={classnames(
				styles.buttonMemberMenuPanel,
				alignment === 'start' && styles.alignmentStart,
				alignment === 'end' && styles.alignmentEnd,
			)}
			panelComponent={({ closePanel }) => (
				<div className={classnames(styles.buttonMemberMenu, styles.withMaxHeight)}>
					<div
						className={styles.arrow}
						{...{
							'data-placement': placement,
						}}
					/>
					<MenuContent
						placement={placement}
						alignment={alignment}
						showListIcon={showListIcon}
						onClickTransMember={() => {
							closePanel();
							onClickTransMember();
						}}
						onClickLinkCallback={() => {
							closePanel();
							onClickLinkCallback();
						}}
					/>
				</div>
			)}
		/>
	);
};

export default ButtonMember;
