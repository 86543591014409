import { Difficulty } from 'types/DifficultyCode';
import { FetchResponse } from 'types/FetchResponse';
import { BannerItem } from 'types/BannerItem';

import { wrapFetch } from 'util/api';

import { StoresSearchData } from './store';

export interface SimplePathData {
	id: number;
	first_banner_image_url: string;
	title: string;
	country_id: number;
	country_name: string;
	city_id: number;
	city_name: string;
	store_id: number;
	store_name: string;
	day: number;
	distance: number;
	is_liked: boolean;
	difficulty: Difficulty | null;
}

interface PathData {
	id: number;
	banners: BannerItem[];
	title: string;
	country_id: number;
	country_name: string;
	city_id: number;
	city_name: string;
	store_id: number;
	store_name: string;
	day: number | null;
	distance: number | null;
	difficulty: Difficulty;
	description: string;
	img_url: string;
	path_contents: [];
	recommended_stores: StoresSearchData[];
	recommended_paths: SimplePathData[];
}

type FetchPathsInfoFunc = (id: number) => Promise<FetchResponse<PathData>>;

export const fetchPathsInfoFunc: FetchPathsInfoFunc = async id => {
	const response = await wrapFetch(
		`paths/${id}`,
		{
			method: 'GET',
		},
		{},
		{ withLan: true, withLocale: false, withAuth: true },
	);

	return response;
};

interface PathsMenuData {
	cities: { id: number; name: string }[];
	difficulties: Difficulty[];
	path_types: { id: number; name: string; code: string }[];
}

type FetchPathsMenuFunc = (country_Id: number) => Promise<FetchResponse<PathsMenuData>>;

export const fetchPathsMenuFunc: FetchPathsMenuFunc = async country_Id => {
	const response = await wrapFetch(
		`paths/menu/${country_Id}`,
		{
			method: 'GET',
		},
		{},
		{ withLan: true, withLocale: false },
	);

	return response;
};

type FetchPathsSearchFunc = (params: {
	location_ids: number[];
	difficulty_ids: number[];
	path_type_ids: number[];
	search: string;
}) => Promise<FetchResponse<SimplePathData[]>>;

export const fetchPathsSearchFunc: FetchPathsSearchFunc = async params => {
	const response = await wrapFetch(
		'paths/search',
		{
			method: 'POST',
			body: JSON.stringify(params),
		},
		{},
		{ withAuth: true },
	);

	return response;
};
