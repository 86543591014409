/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { State as GlobalState, GetState } from './reducers';

type AddControllerPayload = {
	name: string;
	controller: AbortController;
};

export const addController = createAction<AddControllerPayload, string, AbortController>(
	'ADD_CONTROLLER',
	(name, controller) => ({
		name,
		controller,
	}),
);

export const abortController = createAction<
	(_: Dispatch, getState: GetState) => { name: string },
	string
>('ABORT_CONTROLLER', name => (_: Dispatch, getState: GetState) => {
	const { controllers } = getState();
	const controller = controllers[name];

	if (controller) {
		controller.abort();
	}
	return { name };
});

export interface State {
	[key: string]: AbortController | undefined;
}

const initialState: State = {};

export const reducer = {
	controllers: handleActions<State, any>(
		{
			ADD_CONTROLLER: (state, action: Action<AddControllerPayload>) => ({
				...state,

				[action.payload.name]: action.payload.controller,
			}),

			ABORT_CONTROLLER: (state, action: Action<{ name: string }>) => ({
				...state,

				[action.payload.name]: undefined,
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useControllers ++
++----------------------------------------------------------------------*/
const selectControllers = (state: GlobalState) => state.controllers;
const controllersActionsMap = {
	addController,
	abortController,
};

export const useControllers = () =>
	useRedux<ReturnType<typeof selectControllers>, typeof controllersActionsMap>(
		selectControllers,
		controllersActionsMap,
	);
