import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import styles from './index.css';

interface HamburgerProperty {
	className?: string;
	open?: boolean;
	color?: 'default' | 'white';
	onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Hamburger: React.FC<HamburgerProperty> = ({
	className,
	open,
	color = 'default',
	onClick = () => {},
}) => (
	<div
		className={classnames(styles.hamburger, className, styles[color], { [styles.open]: open })}
		role="button"
		tabIndex={0}
		onKeyPress={() => {}}
		onClick={onClick}
	>
		<span />
		<span />
		<span />
		<span />
	</div>
);

export default Hamburger;
