/* eslint-disable indent */
import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { DynamicState } from 'types/DynamicState';

import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';

import { useRedux } from 'util/hook/redux';
import { arrayNormalizer } from 'util/normalizer';
import {
	OnlineVisaType,
	PaymentType,
	PaymentTypeByParticipant,
	PaymentTypeForCUB,
	PaymentTypeForCUBKey,
	paymentTypeMap,
	PaymentTypeValues,
	PaymentTypeValuesForCUB,
} from 'util/payment';

import {
	addMemberStoredCreditCardFunc,
	deleteMemberStoredCreditCardFunc,
	fetchMemberStoredCreditCardFunc,
} from 'api/member';

import {
	TransactionTypes,
	TransactionTypesAndValue,
	TransactionTypesLabel,
	TransactionTypesValue,
} from 'types/TravelOrderParticipantPaymentDetail';
import { GetState, State as GlobalState } from './reducers';
import { openModal } from './modal';
import { TravelFormDataType } from './travelParticipant/type';

interface VisaFormFieldData {
	holder: string;
	cardId: string;
	expiredDate: string;
	cvv: string;
}

export type VisaFormField = keyof VisaFormFieldData;

export type VisaCardForm = FormDataMapping<VisaFormFieldData>;

type VisaCardInfo = {
	isRemember: boolean;
	type: string;
	cvvDigit: number;
};

export type VisaInfoField = keyof VisaCardInfo;

export interface StoredCreditCard extends Pick<VisaFormFieldData, 'cardId' | 'expiredDate'> {
	id: number;
	holder?: never;
	cvv?: never;
}

export type PaymentWayField = keyof PaymentTypeByParticipant;

export type PaymentTypeForm = FormDataMapping<PaymentTypeByParticipant>;

export interface State {
	payMethod: InputFiled<SelectValue<null | PaymentType>>;
	payMethodForCUB: InputFiled<
		SelectValue<null | PaymentTypeValuesForCUB, null | PaymentTypeForCUBKey>
	>;
	payMethodByParticipant: PaymentTypeForm; // 旅遊訂單第二階段選擇付款方式為： 一起付款、個別付款
	payMethodByTransaction: InputFiled<
		SelectValue<null | TransactionTypesValue, null | TransactionTypesLabel>
	>; // 旅遊訂單第三階段繳費款項： 付訂金、尾款、全部款項
	visa: {
		creditCardType: InputFiled<SelectValue<null | string | OnlineVisaType>>;
		form: VisaCardForm;
		info: VisaCardInfo;
		binded: {
			id: number;
			cardId: string;
			expiredDate: string;
		} | null;
	};
	storedCreditCard: DynamicState<{
		ids: string[];
		byIds: { [key: string]: StoredCreditCard };
	}>;
	mappingValue: {
		/**
		 * 付款方式
		 * 1 = 藍新幕後信用卡(租車訂單不適用)
		 * 2 = 藍新幕後授權
		 * 3 = 藍新 ATM
		 *
		 * @type {PaymentTypeValues}
		 */
		payment_type?: PaymentTypeValues;

		/**
		 * 藍新幕後授權 token ID (payment_type = 2-選取已綁定卡片 時必填)
		 *
		 * @type {number}
		 */
		newebpay_token_id?: number;

		/**
		 * 記住此張卡片 (payment_type = 2-初次授權綁卡 時必填)
		 *
		 * @type {boolean}
		 */
		remember_card: boolean;

		/**
		 * 信用卡號碼 (payment_type = 1 或 2-初次授權綁卡 時必填)
		 *
		 * @type {string}
		 */
		card_no: string;

		/**
		 * 信用卡過期日 (yymm) (payment_type = 1 或 2-初次授權綁卡 時必填)
		 *
		 * @type {string}
		 */
		card_exp_date: string;

		/**
		 * 信用卡片背面三碼 (payment_type = 1 或 2-初次授權綁卡 時必填)
		 *
		 * @type {string}
		 */
		card_cvc: string;

		/**
		 * 信用卡持卡人名 (payment_type = 1 或 2-初次授權綁卡 時必填)
		 *
		 * @type {string}
		 */
		card_owner_name: string;
	};
}

const initialState: State = {
	payMethod: { value: { label: '', value: null }, valid: true, error: '', required: true },
	payMethodForCUB: { value: { label: null, value: null }, valid: true, error: '', required: true },
	payMethodByParticipant: {
		paymentType: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
	},
	payMethodByTransaction: {
		value: { label: null, value: null },
		valid: true,
		error: '',
		required: true,
	},
	visa: {
		creditCardType: { value: { label: '', value: null }, valid: true, error: '', required: false },
		form: {
			holder: { value: '', valid: true, error: '', required: true },
			cardId: { value: '', valid: true, error: '', required: true },
			expiredDate: { value: '', valid: true, error: '', required: true },
			cvv: { value: '', valid: true, error: '', required: true },
		},
		info: {
			isRemember: false,
			type: '',
			cvvDigit: 3,
		},
		binded: null,
	},
	storedCreditCard: {
		loading: false,
		error: '',
		data: {
			ids: [],
			byIds: {},
		},
	},
	mappingValue: {
		payment_type: undefined,
		newebpay_token_id: undefined,
		remember_card: false,
		card_no: '',
		card_exp_date: '',
		card_cvc: '',
		card_owner_name: '',
	},
};

/* --------------------------------------------------------------------------------------------------------- */

type UpdatePayMethod = Partial<InputFiled<SelectValue<PaymentType>>>;

const changePayMethod = createAction<UpdatePayMethod, UpdatePayMethod>(
	'CHANGE_PAYMENT_PAYMETHOD',
	d => d,
);

/* --------------------------------------------------------------------------------------------------------- */

type UpdatePayMethodForCUB = Partial<InputFiled<SelectValue<PaymentTypeForCUBKey>>>;

const changeCUBPayMethod = createAction<UpdatePayMethodForCUB, UpdatePayMethodForCUB>(
	'CHANGE_CUB_PAYMENT_PAYMETHOD',
	d => d,
);

/* --------------------------------------------------------------------------------------------------------- */
interface UpdatePayMethodByParticipant {
	key: keyof Pick<TravelFormDataType, 'paymentType'>;
	data: Partial<InputFiled<SelectValue<PaymentTypeByParticipant>>>;
}

/**
 * 旅遊訂單 step2 處理成立訂單時選擇由誰付款：
 * 	1. CONSOLIDATED 聯絡人統一付款
 * 	2. SEPARATE 分開付款
 */
export const changePayMethodByParticipant = createAction<
	UpdatePayMethodByParticipant,
	UpdatePayMethodByParticipant
>('CHANGE_PAYMENT_PAYMETHOD_BY_PARTICIPANT', d => d);

/* --------------------------------------------------------------------------------------------------------- */
type UpdatePayMethodByTransaction = Partial<InputFiled<SelectValue<TransactionTypes>>>;

/**
 * 旅遊訂單 step3 交易明細選擇付款方式：
 * 	1. DEPOSIT 付訂金
 * 	2. BALANCE 付尾款
 * 	3. DEPOSITBALNCE 全額繳清
 */
const changePayMethodByTransaction = createAction<
	UpdatePayMethodByTransaction,
	UpdatePayMethodByTransaction
>('CHANGE_PAYMENT_PAYMETHOD_BY_TRANSACTION', d => d);

/* --------------------------------------------------------------------------------------------------------- */

type ChangeCreditCardTypePayload = Partial<InputFiled>;

export const changeCreditCardType = createAction<
	ChangeCreditCardTypePayload,
	ChangeCreditCardTypePayload
>('CHANGE_CREDIT_CARD_TYPE', d => d);
interface ChangeVisaFormPayload {
	key: VisaFormField;
	data: Partial<InputFiled>;
}

/**
 * 把對應的資料存進去 payment.visa 內
 */
export const changeVisaForm = createAction<ChangeVisaFormPayload, ChangeVisaFormPayload>(
	'CHANGE_VISA_FORM',
	d => d,
);
interface ChangeVisaInfoPayload {
	key: VisaInfoField;
	data: boolean | string | number;
}

export const changeVisaInfo = createAction<ChangeVisaInfoPayload, ChangeVisaInfoPayload>(
	'CHANGE_VISA_INFO',
	d => d,
);

type BindedVisaPayload = State['visa']['binded'];

export const setBindedVisa = createAction<BindedVisaPayload, BindedVisaPayload>(
	'SET_BINDED_VISA',
	d => d,
);

export const clearPayment = createAction('CLEAR_PAYMENT');

export const clearVisaForm = createAction('CLEAR_VISA_FORM');

export const getPaymentMappingValue = createAction<
	(_: Dispatch, getState: GetState) => State['mappingValue']
>('GET_PAYMENT_MAPPING_VALUE', () => (_: Dispatch, getState: GetState) => {
	const {
		payment: {
			payMethod,
			visa: { creditCardType, form: visaForm, info: visaInfo, binded },
			storedCreditCard: {
				data: { byIds },
			},
		},
	} = getState();
	let payment_type;
	let remember_card = false;
	let card_no = '';
	let card_exp_date = '';
	let card_cvc = '';
	let card_owner_name = '';
	let newebpay_token_id;

	switch (payMethod.value.value) {
		case null:
			break;
		case 'visa1':
			payment_type = paymentTypeMap.visa1;
			card_no = visaForm.cardId.value.replace(/ /g, '');
			card_exp_date = moment(visaForm.expiredDate.value, 'MM/YY').format('YYMM');
			card_cvc = visaForm.cvv.value;
			card_owner_name = visaForm.holder.value;
			break;
		case 'visa2':
			payment_type = paymentTypeMap.visa2;
			switch (creditCardType.value.value) {
				case 'addNewCreditCard':
					remember_card = visaInfo.isRemember;
					card_no = visaForm.cardId.value.replace(/ /g, '');
					card_exp_date = moment(visaForm.expiredDate.value, 'MM/YY').format('YYMM');
					card_cvc = visaForm.cvv.value;
					card_owner_name = visaForm.holder.value;
					break;
				case 'bindedCreditCard':
					newebpay_token_id = binded?.id;
					break;
				default:
					remember_card = visaInfo.isRemember;
					card_no = visaForm.cardId.value.replace(/ /g, '');
					card_exp_date = moment(visaForm.expiredDate.value, 'MM/YY').format('YYMM');
					card_cvc = visaForm.cvv.value;
					card_owner_name = visaForm.holder.value;
					newebpay_token_id = byIds[`${creditCardType.value.value}`].id;
					break;
			}
			break;
		default:
			payment_type = paymentTypeMap[payMethod.value.value];
			break;
	}

	return {
		payment_type,
		newebpay_token_id,
		remember_card,
		card_no,
		card_exp_date,
		card_cvc,
		card_owner_name,
	};
});

/**
 * 不會 return 值。
 * 負責使用 changeVisaForm、 changeVisaInfo 把所選取的後端傳來的綁定信用卡資訊傳進 visa.form 和 visa.info。
 */
export const pushStoredCreditCardToVisaForm = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>(
	'DISPATCH_STORED_CREDIT_CARD_TO_VISA_FORM',
	id => async (dispatch: Dispatch, getState: GetState) => {
		const {
			payment: {
				storedCreditCard: {
					data: { byIds: storedCreditCardByIds },
				},
				visa: {
					info: { isRemember },
				},
			},
		} = getState();

		if (!storedCreditCardByIds[id]) return;

		// 這邊的 id 是由 pushStoredCreditCardToVisaForm 從 paymentMethod 傳進來的，也就是選取的卡號。
		const keys = Object.keys(storedCreditCardByIds[id]) as VisaFormField[];

		// 這邊的 keys 的值是 byIds[id] 物件的 key 組成。
		keys.forEach(key => {
			if (key === 'expiredDate') {
				dispatch(
					changeVisaForm({
						key: 'expiredDate',
						data: {
							value: `${moment(storedCreditCardByIds[id].expiredDate).format('MM/DD')}`,
							required: true,
							valid: true,
							error: '',
						},
					}),
				);
			} else {
				dispatch(
					changeVisaForm({
						key,
						data: {
							value: storedCreditCardByIds[id][key],
							required: true,
							valid: true,
							error: '',
						},
					}),
				);
			}
		});

		dispatch(
			changeVisaInfo({
				key: 'isRemember',
				data: !isRemember,
			}),
		);
	},
);

/**
 * 取得會員已經綁定的信用卡列表
 */
export const fetchStoredCreditCard = createAction('FETCH_STORED_CREDIT_CARD', async () => {
	try {
		const { status, message, data } = await fetchMemberStoredCreditCardFunc();

		if ((status !== 200 && status !== 201) || data === undefined) {
			throw new Error(message);
		}

		const mapData = data.map(({ id, card_no, expire_date }) => ({
			id,
			cardId: card_no,
			expiredDate: expire_date,
		}));

		const normalizedData = arrayNormalizer(mapData, 'cardId');

		return { data: normalizedData };
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

/**
 * 會員綁定信用卡（會員編輯頁面）
 */
const addStoredCreditCard = createAction<(dispatch: Dispatch, getState: GetState) => Promise<void>>(
	'ADD_STORED_CREDIT_CARD',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			payment: {
				visa: { form: visaForm },
			},
			auth: { currentUserGid, userDataByGid },
		} = getState();

		const data = {
			card_no: visaForm.cardId.value.replace(/ /g, ''),
			card_exp_date: moment(visaForm.expiredDate.value, 'MM/YY').format('YYMM'),
			card_cvc: visaForm.cvv.value,
			email: userDataByGid[currentUserGid].email || '',
		};

		try {
			const { status, message } = await addMemberStoredCreditCardFunc(data);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'toast',
					data: {
						message: 'bindVisaSuccessfully',
						status: 'pass',
					},
				}),
			);

			await dispatch(fetchStoredCreditCard());
			dispatch(clearVisaForm());
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'toast',
					data: {
						message: 'bindVisaError',
						status: 'warning',
					},
				}),
			);
		}
	},
);

/**
 * 刪除會員已經綁定的某一張信用卡（會員編輯頁面）
 */
export const deleteStoredCreditCard = createAction<(dispatch: Dispatch) => Promise<void>, number>(
	'DELETE_STORED_CREDIT_CARD',
	(cardId: number) => async (dispatch: Dispatch) => {
		try {
			const { status, message, data } = await deleteMemberStoredCreditCardFunc(cardId);

			if ((status !== 200 && status !== 201) || data === undefined) {
				throw new Error(message);
			}
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'toast',
					data: {
						message: 'updatedSuccessfully',
						status: 'pass',
					},
				}),
			);

			await dispatch(fetchStoredCreditCard());
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'toast',
					data: {
						message: 'systemError',
						status: 'warning',
					},
				}),
			);
		}
	},
);

export const reducer = {
	payment: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_PAYMENT: () => ({
				...initialState,
			}),

			CLEAR_VISA_FORM: state => ({
				...state,

				visa: {
					...state.visa,
					form: initialState.visa.form,
					info: initialState.visa.info,
				},
			}),

			CHANGE_PAYMENT_PAYMETHOD: (state, action: Action<UpdatePayMethod>) => ({
				...state,

				payMethod: {
					...state.payMethod,
					...action.payload,
				},
			}),

			CHANGE_CUB_PAYMENT_PAYMETHOD: (state, action: Action<UpdatePayMethodForCUB>) => ({
				...state,

				payMethodForCUB: {
					...state.payMethodForCUB,
					...action.payload,
				},
			}),

			CHANGE_PAYMENT_PAYMETHOD_BY_PARTICIPANT: (
				state,
				action: Action<UpdatePayMethodByParticipant>,
			) => ({
				...state,

				payMethodByParticipant: {
					...state.payMethodByParticipant,
					[action.payload.key]: {
						...state.payMethodByParticipant,
						...action.payload.data,
					},
				},
			}),

			CHANGE_PAYMENT_PAYMETHOD_BY_TRANSACTION: (
				state,
				action: Action<UpdatePayMethodByTransaction>,
			) => ({
				...state,

				payMethodByTransaction: {
					...state.payMethodByTransaction,
					...action.payload,
				},
			}),

			CHANGE_CREDIT_CARD_TYPE: (state, action: Action<ChangeCreditCardTypePayload>) => ({
				...state,

				visa: {
					...state.visa,
					creditCardType: {
						...state.visa.creditCardType,
						...action.payload,
					},
				},
			}),

			CHANGE_VISA_FORM: (state, action: Action<ChangeVisaFormPayload>) => ({
				...state,

				visa: {
					...state.visa,

					form: {
						...state.visa.form,

						[action.payload.key]: {
							...state.visa.form[action.payload.key],
							...action.payload.data,
						},
					},
				},
			}),

			CHANGE_VISA_INFO: (state, action: Action<ChangeVisaInfoPayload>) => ({
				...state,

				visa: {
					...state.visa,

					info: {
						...state.visa.info,

						[action.payload.key]: action.payload.data,
					},
				},
			}),

			SET_BINDED_VISA: (state, action: Action<BindedVisaPayload>) => ({
				...state,

				visa: {
					...state.visa,

					binded: action.payload,
				},
			}),

			GET_PAYMENT_MAPPING_VALUE: (state, action: Action<State['mappingValue']>) => ({
				...state,

				mappingValue: {
					...state.mappingValue,
					...action.payload,
				},
			}),

			FETCH_STORED_CREDIT_CARD_PENDING: state => ({
				...state,
				storedCreditCard: {
					...state.storedCreditCard,
					loading: true,
					error: '',
				},
			}),

			FETCH_STORED_CREDIT_CARD_FULFILLED: (state, action) => ({
				...state,
				storedCreditCard: {
					...state.storedCreditCard,
					loading: false,
					...(action.payload.data && {
						data: {
							...state.storedCreditCard.data,
							...action.payload.data,
						},
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useVisa ++
++----------------------------------------------------------------------*/
const selectVisaData = (state: GlobalState) => state.payment.visa;

const VisaActionMap = { changeCreditCardType, changeVisaForm, changeVisaInfo, setBindedVisa };

export const useVisa = () =>
	useRedux<ReturnType<typeof selectVisaData>, typeof VisaActionMap>(selectVisaData, VisaActionMap);

/* +----------------------------------------------------------------------
	++ usePayment ++
++----------------------------------------------------------------------*/
const selectPaymentData = (state: GlobalState) => state.payment;

const paymentActionMap = {
	changePayMethod,
	changeCUBPayMethod,
	clearPayment,
	changePayMethodByParticipant,
	changePayMethodByTransaction,
};

export const usePayment = () =>
	useRedux<ReturnType<typeof selectPaymentData>, typeof paymentActionMap>(
		selectPaymentData,
		paymentActionMap,
	);

/* +----------------------------------------------------------------------
	++ useStoredCreditCard ++
++----------------------------------------------------------------------*/
const storedCreditCardData = (state: GlobalState) => state.payment.storedCreditCard;

const storedCreditCardActionMap = {
	fetchStoredCreditCard,
	addStoredCreditCard,
	deleteStoredCreditCard,
	pushStoredCreditCardToVisaForm,
};

export const useStoredCreditCard = () =>
	useRedux<ReturnType<typeof storedCreditCardData>, typeof storedCreditCardActionMap>(
		storedCreditCardData,
		storedCreditCardActionMap,
	);
