/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState, MouseEvent } from 'react';

import classnames from 'classnames';

import { useBoolean } from 'util/hook';
import { useScroll, useResize } from 'util/event';

import Portal from 'components/atoms/Portal';

import styles from './index.css';

interface DropdownArrowContentProperty {
	className?: string;
	triggerRect: DOMRect;
	offset: [number, number];
	alignment?: 'center' | 'start' | 'end';
	onMouseEnter?: (e: MouseEvent) => void;
	onMouseLeave?: (e: MouseEvent) => void;
}

const DropdownArrowContent: React.FC<DropdownArrowContentProperty> = ({
	className,
	triggerRect,
	offset,
	alignment = 'center',
	onMouseEnter = () => {},
	onMouseLeave = () => {},
	children,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [styleProps, setStyle] = useState({});
	const [paddingWidth, setPaddingWidth] = useState(0);
	const { scrollY } = useScroll();
	const { height: winHeight, width: winWidth } = useResize();
	const [offsetX, offsetY] = offset;

	useEffect(() => {
		if (ref.current === null) {
			return;
		}
		const { width: popoverW } = ref.current.getBoundingClientRect();
		const { width: triggerW, height: triggerH, left: triggerLeft, top: triggerTop } = triggerRect;
		let left = 0;

		if (alignment === 'start') {
			left = triggerLeft + offsetX;
		} else if (alignment === 'end') {
			left = triggerLeft + triggerW - popoverW + offsetX;
		} else {
			left = triggerLeft + triggerW / 2 - popoverW / 2;
			left = Math.max(offsetX, left);
			left = Math.min(left, document.body.clientWidth - popoverW - offsetX);
		}
		if (triggerTop < winHeight / 2) {
			setStyle({
				left,
				top: triggerTop + triggerH + offsetY,
			});
		} else {
			setStyle({
				left,
				bottom: winHeight - triggerTop + offsetY,
			});
		}
		setPaddingWidth(Math.min(popoverW, triggerW));
	}, [ref.current, winHeight, winWidth, scrollY, offset]);

	return (
		<div
			ref={ref}
			className={classnames(styles.dropdownArrowContent, className)}
			style={{ ...styleProps, opacity: ref.current ? 1 : 0 }}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{children}
			{children && (
				<div
					className={classnames(
						styles.arrow,
						alignment === 'start' && styles.start,
						alignment === 'end' && styles.end,
					)}
					style={{
						height: `calc(${offsetY}px)`,
						width: `${paddingWidth}px`,
						...(alignment === 'center' && { left: `calc(50% - ${paddingWidth / 2}px)` }),
					}}
				/>
			)}
		</div>
	);
};

interface ContentComponentProperty {
	isOpen: boolean;
	open: () => void;
	close: () => void;
}

interface DropdownArrowProperty {
	triggerClassName?: string;
	contentClassName?: string;
	keepOpen?: boolean;
	offset?: [number, number];
	alignment?: 'center' | 'start' | 'end';
	contentComponent: (props: ContentComponentProperty) => {} | null;
}

const DropdownArrow: React.FC<DropdownArrowProperty> = ({
	triggerClassName,
	contentClassName,
	contentComponent,
	keepOpen = false,
	offset = [0, 0],
	alignment = 'center',
	children,
}) => {
	const triggerRef = useRef<HTMLDivElement>(null);

	const [isOpen, { setFalse, setTrue }] = useBoolean({
		defaultBoolean: keepOpen,
	});
	const [isOverTarget, { setFalse: notOverTarget, setTrue: overTarget }] = useBoolean({
		defaultBoolean: false,
	});
	const [isOverPopover, { setFalse: notOverPopover, setTrue: overPopover }] = useBoolean({
		defaultBoolean: false,
	});

	useEffect(() => {
		if (isOverTarget || isOverPopover) {
			setTrue();
			return;
		}
		setFalse();
	}, [isOverTarget, isOverPopover]);

	return (
		<>
			<div
				ref={triggerRef}
				className={classnames(styles.dropdownArrowTrigger, triggerClassName)}
				onMouseEnter={overTarget}
				onMouseLeave={notOverTarget}
			>
				{children}
			</div>
			{(keepOpen || isOpen) && triggerRef.current && (
				<Portal>
					<DropdownArrowContent
						className={contentClassName}
						triggerRect={triggerRef.current.getBoundingClientRect()}
						onMouseEnter={overPopover}
						onMouseLeave={notOverPopover}
						offset={offset}
						alignment={alignment}
					>
						{contentComponent({
							isOpen,
							close: notOverPopover,
							open: overPopover,
						})}
					</DropdownArrowContent>
				</Portal>
			)}
		</>
	);
};

export default DropdownArrow;
