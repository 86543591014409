/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import { GiantEventDataType } from 'types/GiantEvents';
import { LocationData } from 'types/LocationData';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';
import { appPath } from 'util/routingConfig';

import { SlideProperty } from 'components/molecules/SliderBanner';

import { State as GlobalState, GetState } from './reducers';

export type Model = 'Course' | 'Ride' | 'Path' | 'Travel';

type highlightData = {
	model: Model;
	type: GiantEventDataType | '';
	id: number;
	country_id: number;
	country_name: string;
	title: string;
	description: string;
	first_banner_image_url: string;
	order: number;
	updated_at: string;
	created_at: string;
};

const createHighlightLink = (
	model: Model,
	type: GiantEventDataType | '',
	country_id: number,
	normalizeLocCode: {
		[code: string]: LocationData;
	},
) => {
	const countryCode = Object.values(normalizeLocCode).find(
		(locationData: LocationData) => locationData.id === country_id,
	)?.code;

	let path = appPath.eventsFeaturedRides;

	if (model === 'Course') path = appPath.eventsBikeClasses;
	else if (model === 'Path') path = appPath.rentalRoutes;
	else if (model === 'Travel') path = appPath.travel;
	else if (type === 'eventsStoreRides') path = appPath.eventsStoreRides;

	return `${countryCode}${path}`;
};

const getBanner = createAction('GET_BANNER', async () => {
	try {
		const { status, message, data } = await wrapFetch('home/banners', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			text: data.banner_text,
			data: {
				homeBanners: data.banners,
				rentalBanner: data.rent_banner_img,
			},
		};
	} catch (error) {
		return {
			data: {},
		};
	}
});

const getRecommendCountries = createAction('GET_RECOMMEND_COUNTRIES', async () => {
	try {
		const { status, message, data } = await wrapFetch('home/recommend/countries', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		return {
			data: {},
		};
	}
});

const getRecommendCities = createAction('GET_RECOMMEND_CITIES', async () => {
	try {
		const { status, message, data } = await wrapFetch('home/recommend/cities', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data };
	} catch (error) {
		return {
			data: {},
		};
	}
});

const getHighlight = createAction(
	'GET_HIGHLIGHT',
	() => async (_: Dispatch, getState: GetState) => {
		try {
			const { status, message, data } = await wrapFetch('home/highlights', {
				method: 'GET',
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const {
				locale: {
					locales: {
						data: { byCode: locByCode },
					},
				},
			} = getState();

			const mapData: HotpickItemProperty[] = data.map(
				({
					id,
					title,
					description,
					first_banner_image_url,
					model,
					type,
					country_id,
				}: highlightData) => ({
					id,
					title,
					summary: description,
					imageUrl: first_banner_image_url,
					link: createHighlightLink(model, type, country_id, locByCode),
				}),
			);

			return {
				data: mapData,
			};
		} catch (error) {
			return {
				data: [],
			};
		}
	},
);

export interface DestinationItemProperty {
	id: number;
	code: string;
	name: string;
	img: string;
}

export interface HotspotItemProperty {
	id: number;
	name: string;
	img: string;
	state_id: null | number;
}

export interface HotpickItemProperty {
	id: number;
	title: string;
	summary: string;
	imageUrl: string;
	link: string;
}

export interface State {
	banners: {
		text: string;
		data: {
			homeBanners: SlideProperty[];
			rentalBanner: string;
		};
		loading: boolean;
		error: string;
	};
	countries: {
		data: DestinationItemProperty[];
		loading: boolean;
		error: string;
	};
	cities: {
		data: HotspotItemProperty[];
		loading: boolean;
		error: string;
	};
	highlight: {
		data: HotpickItemProperty[];
		loading: boolean;
		error: string;
	};
}

const initialState: State = {
	banners: {
		text: '',
		data: {
			homeBanners: [],
			rentalBanner: '',
		},
		loading: false,
		error: '',
	},
	countries: {
		data: [],
		loading: false,
		error: '',
	},
	cities: {
		data: [],
		loading: false,
		error: '',
	},
	highlight: {
		data: [],
		loading: false,
		error: '',
	},
};

export const reducer = {
	home: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_BANNER_PENDING: state => ({
				...state,

				banners: {
					...state.banners,
					loading: true,
				},
			}),
			GET_BANNER_FULFILLED: (state, action) => ({
				...state,

				banners: {
					...state.banners,
					...action.payload,
					loading: false,
				},
			}),
			GET_BANNER_REJECTED: state => ({
				...state,

				banners: {
					...state.banners,
					loading: false,
					error: 'error',
				},
			}),

			GET_RECOMMEND_COUNTRIES_PENDING: state => ({
				...state,

				countries: {
					...state.countries,
					loading: true,
				},
			}),
			GET_RECOMMEND_COUNTRIES_FULFILLED: (state, action) => ({
				...state,

				countries: {
					...state.countries,
					data: action.payload.data,
					loading: false,
				},
			}),
			GET_RECOMMEND_COUNTRIES_REJECTED: state => ({
				...state,

				countries: {
					...state.countries,
					loading: false,
					error: 'error',
				},
			}),

			GET_RECOMMEND_CITIES_PENDING: state => ({
				...state,

				cities: {
					...state.cities,
					loading: true,
				},
			}),
			GET_RECOMMEND_CITIES_FULFILLED: (state, action) => ({
				...state,

				cities: {
					...state.cities,
					data: action.payload.data,
					loading: false,
				},
			}),
			GET_RECOMMEND_CITIES_REJECTED: state => ({
				...state,

				cities: {
					...state.cities,
					loading: false,
					error: 'error',
				},
			}),

			GET_HIGHLIGHT_PENDING: state => ({
				...state,

				highlight: {
					...state.highlight,
					loading: true,
				},
			}),
			GET_HIGHLIGHT_FULFILLED: (state, action: Action<{ data: HotpickItemProperty[] }>) => ({
				...state,

				highlight: {
					...state.highlight,
					data: action.payload.data,
					loading: false,
				},
			}),
			GET_HIGHLIGHT_REJECTED: state => ({
				...state,

				highlight: {
					...state.highlight,
					loading: false,
					error: 'error',
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useHome ++
++----------------------------------------------------------------------*/
const selectHome = (state: GlobalState) => state.home;
const homeActionsMap = { getBanner, getRecommendCountries, getRecommendCities, getHighlight };

export const useHome = () => useRedux(selectHome, homeActionsMap);

/* +----------------------------------------------------------------------
	++ useBanner ++
++----------------------------------------------------------------------*/
const selectBanner = (state: GlobalState) => state.home.banners;
export const useBanner = () => useRedux(selectBanner, { getBanner });
