/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { compile } from 'path-to-regexp';
import moment, { Moment } from 'moment';

import {
	GiantEventData,
	GiantEventDataType,
	GIANT_EVENT_DATA_TO_ROUTE_MAP,
} from 'types/GiantEvents';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import { appPath } from 'util/routingConfig';

import { fetchRideOrdersByIdentityFunc, RideOrderPreview } from 'api/rideOrder';

import { OrderPaymentStatus, OrderStatus } from 'util/order';
import {
	fetchTravelAuthTokenByIdentityFunc,
	fetchTravelOrderParticipantInfoFunc,
	fetchTravelOrderPaymentInfoFunc,
	fetchTravelRegisterInfoFunc,
	fetchTravelRegisterOrderInfoFunc,
	TravelRegisterInfo,
	TravelRegisterOrderInfo,
} from 'api/travelOrder';
import storage from 'util/storage';
import { GenderValuesNotInitial } from 'types/Gender';
import {
	TravelOrderPaymentInfoData,
	TravelParticipantInfoData,
} from 'types/TravelOrderParticipantPaymentDetail';
import { FetchResponse } from 'types/FetchResponse';
import { setRideOrderList, setRideOrderListData } from './rideOrder';
import { State as GlobalState, GetState } from './reducers';
import { pushRoute } from './routing';
import { TravelOrderBySelector } from './travelOrder';
import { goNextStep } from './travelReservation';

export interface TravelRegisterFormType {
	birthday: Moment | null;
	identityNo: string;
}

export interface TravelSelector {
	selector: string;
}

export type TravelRegisterFormField = keyof TravelRegisterFormType;

export type TravelRegisterSelectorField = keyof TravelSelector;

type TravelRegisterForm = FormDataMapping<TravelRegisterFormType>;

type TravelRegisterSelector = FormDataMapping<TravelSelector>;

export interface State {
	registerData: FetchedData<TravelRegisterInfo[]>;
	registerOrderInfoData: FetchedData<TravelRegisterOrderInfo>;
	loginUserRegisterData: FetchedData<TravelRegisterInfo[]>;
	form: TravelRegisterForm;
	transactionOrderInfo: FetchedData<TravelOrderBySelector[]>; // 使用在『我要繳費』第三階段下方的商品明細
	travelRegisterSelector: TravelRegisterSelector;
	travelOrderParticipantInfo: FetchedData<TravelParticipantInfoData[]>;
}

const initialState: State = {
	registerData: {
		data: [],
		loading: false,
		error: '',
	},
	registerOrderInfoData: {
		data: {} as TravelRegisterOrderInfo,
		loading: false,
		error: '',
	},
	travelOrderParticipantInfo: {
		data: [],
		loading: false,
		error: '',
	},
	loginUserRegisterData: {
		data: [],
		loading: false,
		error: '',
	},
	form: {
		birthday: { value: null, valid: true, error: '', required: true },
		identityNo: { value: '', valid: true, error: '', required: true },
	},
	transactionOrderInfo: {
		loading: false,
		error: '',
		data: [],
	},
	travelRegisterSelector: {
		selector: { value: '', valid: true, error: '', required: true },
	},
};

/* -------------------------------------------------------------------------------------------------------------------------- */

interface FormChangePayload {
	key: TravelRegisterFormField;
	data: Partial<InputFiled>;
}

const changeTravelRegisterForm = createAction<FormChangePayload, FormChangePayload>(
	'CHANGE_TRAVEL_REGISTER_FORM',
	({ key, data }) => ({ key, data }),
);

/* -------------------------------------------------------------------------------------------------------------------------- */

interface SelectorChangePayload {
	key: TravelRegisterSelectorField;
	data: Partial<InputFiled>;
}

const changeTravelRegisterSelector = createAction<SelectorChangePayload, SelectorChangePayload>(
	'CHANGE_TRAVEL_REGISTER_SELECTOR',
	({ key, data }) => ({ key, data }),
);

/* -------------------------------------------------------------------------------------------------------------------------- */

type SendTravelRegisterFormPayload = Partial<FetchedData<TravelRegisterInfo[]>>;

/**
 * 使用 currentUserGid 取得 authToken 並取得訂單查詢資料
 * 使用在 user 已登入的狀態下
 *
 * @returns {TravelRegisterInfo[]} TravelRegisterInfo[]
 */
const getTravelRegisterLoginUserData = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SendTravelRegisterFormPayload>
>('GET_TRAVEL_REGISTER_LOGIN_USER_DATA', () => async (dispatch: Dispatch, getState: GetState) => {
	try {
		const { currentUserGid } = getState().auth;

		// 拿取旅遊 authToken
		const { status: fetchStatus, message, data } = await fetchTravelAuthTokenByIdentityFunc({
			gid: currentUserGid,
		});

		// 把 travelAuthToken 打入 session 內儲存
		if (data.token) {
			storage.setItem('travelAuthToken', data.token);
		}

		// 使用 token 取得訂單查詢資料
		const { data: registerInfoData } = await fetchTravelRegisterInfoFunc();

		if (fetchStatus !== 200 && fetchStatus !== 201) {
			throw new Error(message);
		}

		return {
			registerInfoData,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

/* --------------------------------------------------------------------------------------------------------------- */

/**
 * 使用 生日、身分證、護照號碼取得 authToken 並取得訂單查詢資料
 *
 * @returns {TravelRegisterInfo[]} TravelRegisterInfo[]
 */
const sendTravelRegisterForm = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SendTravelRegisterFormPayload>
>('SEND_TRAVEL_REGISTER_FORM', () => async (dispatch: Dispatch, getState: GetState) => {
	try {
		const {
			form: { identityNo, birthday },
		} = getState().travelRegister;

		// 拿取旅遊 authToken
		const { status: fetchStatus, message, data } = await fetchTravelAuthTokenByIdentityFunc({
			birthday: birthday.value?.format('YYYY-MM-DD'),
			identityNo: identityNo.value,
		});

		// 把 travelAuthToken 打入 session 內儲存
		if (data.token) {
			storage.setItem('travelAuthToken', data.token);
		}

		// 使用 token 取得訂單查詢資料
		const { data: registerInfoData } = await fetchTravelRegisterInfoFunc();

		if (fetchStatus !== 200 && fetchStatus !== 201) {
			throw new Error(message);
		}

		if (registerInfoData.length === 0) {
			// 做無資料時的處理，例如以上面 issue 期望的結果，就是停留在輸入身分證字號及生日的頁面，報錯誤訊息
			return {
				registerInfoData: [],
			};
		}

		// 將 goNextStep() 由 layout 組件搬來 action, 否則在 layout 的onClick 中會有狀態落後的問題, 因為組件更新前沒辦法拿到最新狀態
		dispatch(goNextStep());

		return {
			registerInfoData,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

// 成立訂單、我要繳費、我的訂單 三個成功頁面失敗跟成功都統一處理：

// 成功的話，商品資訊在 TravelReservationStepOrder，商品清單難以辨識直接隱藏

// 失敗的話，全部導向 OrderDetailTravel，付款功能都正常

// 取得所有 layout 需要使用到的 資料，需建立一個 process action
// 1. 確認 localStorage 內的 auth-token 並取得
// 2. 取得 list 資料（step2）（一隻 API）
// 3. 取得 OrderDetailTravel 所有資料 (四隻 API)
// 4. 直接 dispatch setStep 強制切換頁面
// 失敗進 OrderDetailTravel(step3)
// 成功進 TravelReservationStepOrder (step6)

// const travelLayoutProcess = createAction<(dispatch: Dispatch, getState: GetState) => void>(
// 	'TRAVEL_LAYOUT_PROCESS',
// 	() => (dispatch: Dispatch, getState: GetState) => {
// 		const {
// 			form: { identityNo, birthday },
// 		} = getState().travelRegister;
// 	},
// );

// export const sendTravelRegisterFormSuccessEpic = (action$: ActionsObservable<AnyAction>) =>
// 	action$.pipe(
// 		filter(
// 			action =>
// 				action.type === 'SEND_TRAVEL_REGISTER_FORM_FULFILLED' && action.payload?.data?.eventType,
// 		),
// 		mergeMap(action => {
// 			return of(
// 				pushRoute({
// 					pathname: compile(appPath.eventsOrders, { encode: encodeURIComponent })({
// 						id: action.payload.data?.eventId,
// 						eventType:
// 							GIANT_EVENT_DATA_TO_ROUTE_MAP[action.payload.data.eventType as GiantEventDataType],
// 					}),
// 				}),
// 			);
// 		}),
// 	);

/* --------------------------------------------------------------------------------------------------------------- */

type GetTravelRegisterOrderInfoPayload = Partial<FetchedData<TravelRegisterOrderInfo>>;

/**
 * 使用 selector 取得訂單資訊 -『我要繳費』第三步驟上方訂單資訊
 *
 * @returns {TravelRegisterOrderInfo} TravelRegisterOrderInfo
 */
const getTravelRegisterOrderInfoData = createAction<
	() => Promise<GetTravelRegisterOrderInfoPayload>,
	string,
	boolean
>('GET_TRAVEL_REGISTER_ORDER_INFO_DATA', (selector, isAdmin) => async () => {
	try {
		const { status, message, data } = await fetchTravelRegisterOrderInfoFunc(selector, isAdmin);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data };
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

/* --------------------------------------------------------------------------------------------------------------- */

type GetTravelOrderParticipantInfoBySelectorPayload = TravelParticipantInfoData[];

/**
 * 取得旅遊訂單參加者資訊 - 『我要繳費』第三步驟中間的參加者資訊
 * @param {string} selector
 * @param {boolean} isAdmin
 * @return {TravelParticipantInfoData[]} 多名參加者資訊的陣列
 */
const getTravelOrderParticipantInfoBySelector = createAction<
	() => Promise<GetTravelOrderParticipantInfoBySelectorPayload | { error: string }>,
	string,
	boolean | undefined
>('GET_TRAVEL_ORDER_PARTICIPANT_INFO_BY_SELECTOR', (selector, isAdmin) => async () => {
	try {
		const { status, message, data } = await fetchTravelOrderParticipantInfoFunc(selector, isAdmin);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data;
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

/* --------------------------------------------------------------------------------------------------------------- */

interface GetTravelOrderPayload {
	error?: string;
	data?: TravelOrderPaymentInfoData[];
}

/**
 * 取得「我要繳費」下方商品資訊
 *
 * @param {string} selector
 * @return {TravelOrderPaymentInfoData[]} 付款資訊
 */
const getTravelOrderBySelector = createAction<
	Promise<GetTravelOrderPayload>,
	string,
	boolean | undefined
>('GET_TRAVEL_ORDER_BY_SELECTOR', async (selector: string, isAdmin = false) => {
	try {
		const { status, message, data } = await fetchTravelOrderPaymentInfoFunc(selector, isAdmin);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				error: message,
			};
		}
		return {
			error: '',
		};
	}
});

/* --------------------------------------------------------------------------------------------------------------- */

const resetTravelRegisterForm = createAction('RESET_TRAVEL_REGISTER_FORM');

/* --------------------------------------------------------------------------------------------------------------- */

export const reducer = {
	travelRegister: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_TRAVEL_ORDER_BY_SELECTOR_PENDING: state => ({
				...state,
				transactionOrderInfo: {
					...state.transactionOrderInfo,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_ORDER_BY_SELECTOR_FULFILLED: (state, action: Action<GetTravelOrderPayload>) => ({
				...state,
				transactionOrderInfo: {
					...state.transactionOrderInfo,

					...(action.payload.error && {
						error: action.payload.error,
					}),

					...(action.payload.data && {
						data: action.payload.data,
					}),

					loading: false,
				},
			}),

			RESET_TRAVEL_REGISTER_FORM: state => ({
				...state,
				form: {
					...initialState.form,
				},
			}),

			CHANGE_TRAVEL_REGISTER_FORM: (state, action: Action<FormChangePayload>) => ({
				...state,
				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CHANGE_TRAVEL_REGISTER_SELECTOR: (state, action: Action<SelectorChangePayload>) => ({
				...state,
				travelRegisterSelector: {
					...state.travelRegisterSelector,
					[action.payload.key]: {
						...state.travelRegisterSelector[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			SEND_TRAVEL_REGISTER_FORM_PENDING: state => ({
				...state,
				registerData: {
					...initialState.registerData,
					loading: true,
				},
			}),

			SEND_TRAVEL_REGISTER_FORM_FULFILLED: (state, action) => ({
				...state,
				registerData: {
					...state.registerData,
					data: action.payload.registerInfoData,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_TRAVEL_REGISTER_LOGIN_USER_DATA_PENDING: state => ({
				...state,
				loginUserRegisterData: {
					...initialState.loginUserRegisterData,
					loading: true,
				},
			}),

			GET_TRAVEL_REGISTER_LOGIN_USER_DATA_FULFILLED: (state, action) => ({
				...state,
				loginUserRegisterData: {
					...state.loginUserRegisterData,
					data: action.payload.registerInfoData,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_TRAVEL_REGISTER_ORDER_INFO_DATA_PENDING: state => ({
				...state,
				registerOrderInfoData: {
					...initialState.registerOrderInfoData,
					loading: true,
				},
			}),

			GET_TRAVEL_REGISTER_ORDER_INFO_DATA_FULFILLED: (state, action) => ({
				...state,
				registerOrderInfoData: {
					...state.registerOrderInfoData,
					data: action.payload.data,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_TRAVEL_ORDER_PARTICIPANT_INFO_BY_SELECTOR_PENDING: state => ({
				...state,
				travelOrderParticipantInfo: {
					...initialState.travelOrderParticipantInfo,
					loading: true,
				},
			}),

			GET_TRAVEL_ORDER_PARTICIPANT_INFO_BY_SELECTOR_FULFILLED: (state, action) => ({
				...state,
				travelOrderParticipantInfo: {
					...state.travelOrderParticipantInfo,
					data: action.payload,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		initialState,
	),
};

const selectTravelRegisterForm = (state: GlobalState) => ({
	form: state.travelRegister.form,
	transactionOrderInfo: state.travelRegister.transactionOrderInfo,
	registerData: state.travelRegister.registerData,
	travelRegisterSelector: state.travelRegister.travelRegisterSelector,
	registerOrderInfoData: state.travelRegister.registerOrderInfoData,
	loginUserRegisterData: state.travelRegister.loginUserRegisterData,
	travelOrderParticipantInfo: state.travelRegister.travelOrderParticipantInfo,
});

const travelRegisterFormActionsMap = {
	changeTravelRegisterForm,
	changeTravelRegisterSelector,
	sendTravelRegisterForm,
	getTravelRegisterLoginUserData,
	getTravelRegisterOrderInfoData,
	getTravelOrderParticipantInfoBySelector,
	resetTravelRegisterForm,
	getTravelOrderBySelector,
	// travelLayoutProcess,
};

/* +----------------------------------------------------------------------
	++ useTravelRegisterForm ++
++----------------------------------------------------------------------*/

export const useTravelRegisterForm = () =>
	useRedux<ReturnType<typeof selectTravelRegisterForm>, typeof travelRegisterFormActionsMap>(
		selectTravelRegisterForm,
		travelRegisterFormActionsMap,
	);
