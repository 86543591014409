import { FetchFunction } from 'types/FetchFunction';

import { wrapFetch } from 'util/api';
import { CertificateData } from 'models/accountCertificate';

import { RentalOrder as RentalOrderDetail } from 'api/rentalOrder';
import { RideOrderPreview } from 'api/rideOrder';
import { CourseOrderPreview } from 'api/courseOrder';

import { UserEmergencyInfoData, UserOtherInfoData } from './auth';

export type TravelOrderData = {
	selector: string;
	name: string;
	date: string;
	location: string;
	status: string;
	payment_status: string;
	travel_id: string;
	is_history: boolean;
};

export type RentalOrderData = Pick<
	RentalOrderDetail,
	| 'id'
	| 'number'
	| 'selector'
	| 'status'
	| 'payment_status'
	| 'timezone'
	| 'start_store_id'
	| 'start_store'
	| 'end_store_id'
	| 'end_store'
	| 'start_at'
	| 'end_at'
> & {
	is_history: boolean;
	type?: string;
};

export type EventsOrderPreview = (RideOrderPreview | CourseOrderPreview) & {
	coordinates: { id: number; latitude: number; longitude: number; status: string }[];
	distance: number;
	height: number;
};

interface MemberOrderList {
	travel: TravelOrderData[];
	rental: RentalOrderData[];
	event: EventsOrderPreview[];
}

type MemberOrdersFetch = FetchFunction<MemberOrderList>;

/**
 * 取得我的訂單列表
 */
export const fetchMemberOrdersFunc: MemberOrdersFetch = async () => {
	const response = await wrapFetch(
		`member/orders`,
		{
			method: 'GET',
		},
		{},
		{ withAuth: true },
	);

	return response;
};

interface MemberJourneys {
	rental: { countries: number; times: number; days: number; orders: RentalOrderData[] };
	events: {
		countries: number;
		times: number;
		days: number;
		distance: number;
		height: number;
		orders: EventsOrderPreview[];
	};
	total: {
		countries: number;
		times: number;
		days: number;
		distance: number;
		height: number;
	};
}

type MemberJourneysFetch = FetchFunction<MemberJourneys>;

/**
 * 取得我的歷程
 */
export const fetchMemberJourneysFunc: MemberJourneysFetch = async () => {
	const response = await wrapFetch(
		`member/journeys`,
		{
			method: 'GET',
		},
		{},
		{ withAuth: true },
	);

	return response;
};

export interface MemberNationality {
	name: string;
	code: string;
}

type MemberNationalitiesFetch = FetchFunction<MemberNationality[]>;

/**
 * 取得下單國籍列表
 */
export const fetchMemberNationalitiesFunc: MemberNationalitiesFetch = async () => {
	const response = await wrapFetch(`member/nationalities`, {
		method: 'GET',
	});

	return response;
};

export interface MemberCountryCodes {
	name: string;
	code: string;
}

type MemberCountryCodesFetch = FetchFunction<MemberCountryCodes[]>;

/**
 * 取得電話國碼
 */
export const fetchMemberCountryCodesFunc: MemberCountryCodesFetch = async () => {
	const response = await wrapFetch(`member/countryCodes`, {
		method: 'GET',
	});

	return response;
};

export interface MemberInfoData extends UserEmergencyInfoData, UserOtherInfoData {}

type MemberInfoFetch = FetchFunction<MemberInfoData>;

/**
 * 取得用戶資料（會員使用者資料）
 */
export const fecthMemberInfoFunc: MemberInfoFetch = async () => {
	const response = await wrapFetch(
		`member`,
		{
			method: 'GET',
		},
		{},
		{ withAuth: true },
	);

	return response;
};

export type MemberInfoUpdateParams = Partial<UserEmergencyInfoData> & Partial<UserOtherInfoData>;
type MemberInfoUpdateRes = UserEmergencyInfoData & UserOtherInfoData;
type MemberInfoUpdateFetch = FetchFunction<MemberInfoUpdateRes, MemberInfoUpdateParams>;

/**
 * 修改會員資料
 */
export const updateMemberInfoFunc: MemberInfoUpdateFetch = async data => {
	const response = await wrapFetch(
		`member`,
		{
			method: 'PUT',
			body: JSON.stringify(data),
		},
		{},
		{
			withAuth: true,
		},
	);

	return response;
};

/**
 * 後端回傳會員綁定的信用卡資料介面
 *
 * @interface StoredCreditCardData
 */
interface StoredCreditCardData {
	id: number;
	card_no: string;
	expire_date: string;
}

type MemberCreditCardsFetch = FetchFunction<StoredCreditCardData[]>;

/**
 * 取得信用卡列表
 * @returns {response} 吐出 { status, message, data } 這三個檔案解構在 payment.ts。
 */
export const fetchMemberStoredCreditCardFunc: MemberCreditCardsFetch = async () => {
	const response = await wrapFetch(
		`member/creditCards`,
		{
			method: 'GET',
		},
		{},
		{ withAuth: true },
	);

	return response;
};

export type AddMemberCreditCardParam = {
	card_no: string;
	card_exp_date: string;
	card_cvc: string;
	email: string;
};
type AddMemberCreditCardFetch = FetchFunction<string, AddMemberCreditCardParam>;

/**
 * 會員綁定信用卡
 *
 * @param {AddMemberCreditCardParam} data
 * @return {*} { status = 200, message = 'success', data = '' }
 */
export const addMemberStoredCreditCardFunc: AddMemberCreditCardFetch = async data => {
	const response = await wrapFetch(
		`member/creditCard`,
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ withAuth: true },
	);

	return response;
};

type DeleteMemberCreditCardsFetch = FetchFunction<string, number>;

/**
 * 刪除會員綁定信用卡
 *
 * @param {*} cardId 信用卡 ID （即 StoredCreditCardData 介面中的 id ，而不是卡號 card_no ）
 * @return {*} { status = 200, message = 'success', data = '' }
 */
export const deleteMemberStoredCreditCardFunc: DeleteMemberCreditCardsFetch = async cardId => {
	const response = await wrapFetch(
		`member/creditCard/${cardId}`,
		{
			method: 'DELETE',
		},
		{},
		{ withAuth: true },
	);

	return response;
};

type certificateType = {
	event: {
		featuredrides: CertificateData[];
		storerides: CertificateData[];
		bikeclasses: CertificateData[];
	};
	travel: [];
};

type FetchCertificateFunc = FetchFunction<certificateType>;

/**
 * 取得證書資訊
 */
export const fetchCertificateFunc: FetchCertificateFunc = async () => {
	const response = await wrapFetch(
		`member/certificates`,
		{
			method: 'GET',
		},
		{},
		{ withAuth: true },
	);

	return response;
};
