import { Action, createAction, handleActions } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import {
	TravelOrderData,
	RentalOrderData,
	EventsOrderPreview,
	fetchMemberOrdersFunc,
} from 'api/member';

import { State as GlobalState } from './reducers';

export interface State {
	list: FetchedData<{
		rental: {
			establishedList: number[];
			historyList: number[];
			data: { [key: number]: RentalOrderData };
		};
		travel: {
			establishedList: number[];
			historyList: number[];
			data: { [key: string]: TravelOrderData };
		};
		event: {
			establishedList: string[];
			historyList: string[];
			data: { [key: string]: EventsOrderPreview };
		};
	}>;
}

const initialState: State = {
	list: {
		loading: false,
		error: '',
		data: {
			rental: { establishedList: [], historyList: [], data: {} },
			travel: { establishedList: [], historyList: [], data: {} },
			event: { establishedList: [], historyList: [], data: {} },
		},
	},
};

type MemberOrdersPayload = Partial<State['list']>;

const getAccountOrders = createAction<Promise<MemberOrdersPayload>>(
	'GET_ACCOUNT_ORDERS',
	async () => {
		try {
			const { status, message, data } = await fetchMemberOrdersFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const normalizedTravelData = (data?.travel || []).reduce(
				(a, v) => ({
					...a,
					...(v.is_history
						? { historyIds: [...a.historyIds, v.selector] }
						: { establishedIds: [...a.establishedIds, v.selector] }),
					byId: {
						...a.byId,
						[v.selector]: v,
					},
				}),
				{ historyIds: [], establishedIds: [], byId: {} } as {
					establishedIds: string[];
					historyIds: string[];
					byId: { [key: number]: TravelOrderData };
				},
			);

			const normalizedRentalData = (data?.rental || []).reduce(
				(a, v) => ({
					...a,
					...(v.is_history
						? { historyIds: [...a.historyIds, v.id] }
						: { establishedIds: [...a.establishedIds, v.id] }),
					byId: {
						...a.byId,
						[v.id]: v,
					},
				}),
				{ historyIds: [], establishedIds: [], byId: {} } as {
					establishedIds: number[];
					historyIds: number[];
					byId: { [key: number]: RentalOrderData };
				},
			);

			/* 因為 event 的訂單在後端分為 RideOrder 和 CoourseOrder 兩個模組管理，id 可能會重複，因此這裡用 selector 作為 UUID，避免訂單複寫的情況 */
			const normalizedEventsData = (data?.event || []).reduce(
				(a, v) => ({
					...a,
					...(v.is_history
						? { historyIds: [...a.historyIds, v.selector] }
						: { establishedIds: [...a.establishedIds, v.selector] }),
					byId: {
						...a.byId,
						[v.selector]: v,
					},
				}),
				{ historyIds: [], establishedIds: [], byId: {} } as {
					establishedIds: string[];
					historyIds: string[];
					byId: { [key: string]: EventsOrderPreview };
				},
			);

			return {
				data: {
					rental: {
						establishedList: normalizedRentalData.establishedIds,
						historyList: normalizedRentalData.historyIds,
						data: normalizedRentalData.byId,
					},
					travel: {
						establishedList: normalizedTravelData.establishedIds,
						historyList: normalizedTravelData.historyIds,
						data: normalizedTravelData.byId,
					},
					event: {
						establishedList: normalizedEventsData.establishedIds,
						historyList: normalizedEventsData.historyIds,
						data: normalizedEventsData.byId,
					},
				},
			};
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

export const reducer = {
	accountOrders: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ACCOUNT_ORDERS_PENDING: state => ({
				...state,
				list: {
					...initialState.list,
					loading: true,
				},
			}),

			GET_ACCOUNT_ORDERS_FULFILLED: (state, action: Action<MemberOrdersPayload>) => ({
				...state,
				list: {
					...state.list,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
					loading: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAccountOrders ++
++----------------------------------------------------------------------*/
const selectOrders = (state: GlobalState) => state.accountOrders.list;

const ordersActionsMap = {
	getAccountOrders,
};

export const useAccountOrders = () =>
	useRedux<ReturnType<typeof selectOrders>, typeof ordersActionsMap>(
		selectOrders,
		ordersActionsMap,
	);
