import React, { useCallback, useEffect } from 'react';
import moment from 'moment';

import { useGlobal } from 'models/global';
import { useLocale } from 'models/locale';
import { useRouting } from 'models/routing';
import { useCountryCodes } from 'models/countryCodes';
import { useNationalities } from 'models/nationalities';
import { useAuth, useGiantVid } from 'models/auth';

import { localeParser } from 'util/helper';
import { initLineSDK } from 'util/sdk';
import { lanList } from 'util/i18n/config';

import styles from './index.css';

const Root: React.FC = ({ children }) => {
	const [{ pathname }] = useRouting();
	const [
		{
			currentLocCode,
			locales: {
				data: { byCode: locByCode },
			},
		},
		{ fetchLocaleList, updateCurrentLocale },
	] = useLocale();
	const [{ isInitialed }, { setInitialed, setInitial, initialSetting }] = useGlobal();
	const [, { getAuthProcess }] = useAuth();
	const [, { getCountryCodes }] = useCountryCodes();
	const [, { getNationalities }] = useNationalities();
	const [, { getVid }] = useGiantVid();

	const asyncInitialSetting = useCallback(async () => {
		console.log('Root Mounted Start -----------------------');

		setInitial();

		getVid(); // 取得 Giant 訪客 ID

		await fetchLocaleList(); // 取得 locale 列表
		await getAuthProcess(); // 會員登入流程
		await initialSetting(); // 初始化網站（判斷 locale， 拿 config）

		getCountryCodes(); // 取得手機國碼
		getNationalities(); // 取得國籍列表

		initLineSDK();

		// 2022-03-30 以 zh-TW 為預設語言，初始化 moment 的 locale 語言為 zh-TW（以後 global 變成預設首頁，語言預設是 en 後，這行應該可以移除）。
		if (process.env.PRODUCTION) {
			moment.locale(lanList[0]);
		}

		setInitialed();

		console.log('Root Mounted Ended -----------------------');
	}, []);

	useEffect(() => {
		asyncInitialSetting();
	}, [asyncInitialSetting]);

	useEffect(() => {
		if (isInitialed) {
			const { valid, locale } = localeParser(locByCode);

			/* URL 改變時，偵測 Locale 是否改變。若有，則重新 init 網站 */
			if (valid && currentLocCode !== locale.code) {
				updateCurrentLocale(locale.code as string);
				asyncInitialSetting();
			}
		}
	}, [pathname, isInitialed]);

	return <div className={styles.root}>{isInitialed ? children : null}</div>;
};

export default Root;
