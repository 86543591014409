import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import Info from './Info';

const routes: CustomRoute = {
	path: appPath.rentalRoutes.replace(appPath.rental, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'rentalRoutes' */ './component')],
			render: ([RentalRoutes]) => <RentalRoutes />,
		},
		Info,
	],
};

export default routes;
