import { FetchResponse } from 'types/FetchResponse';
import { BannerItem } from 'types/BannerItem';

import { wrapFetch } from 'util/api';

import { RecommendedBicycle } from './bicycle';
import { SimplePathData } from './paths';
import { ProductData, ProductType } from './product';

export interface StoresSearchData {
	id: number;
	country_id: number;
	city_id: number;
	location_id: number;
	first_banner_image_url: string;
	name: string;
	name_include_city: string;
	address: string;
	latitude: number;
	longitude: number;
	emails: string;
	facebook: string;
	instagram: string;
	telephone: string;
	cellphone: string;
	weekday_open_time: string;
	weekday_close_time: string;
	weekend_open_time: string;
	weekend_close_time: string;
	tour_latest_rent_time: string; // 環島租賃最晚時間
	single_latest_rent_time: string; // 單點租賃最晚時間
	regular_off_days: string;
	store_close_dates: string[];
	support_ezaio: boolean;
}

type FetchStoresSearchFunc = (params?: {
	locationIds?: (number | string)[];
	tags?: (number | string)[];
	keyword?: string;
}) => Promise<FetchResponse<StoresSearchData[]>>;

/**
 * 搜尋及篩選門市列表
 */
export const fetchStoresSearchFunc: FetchStoresSearchFunc = async params => {
	const response = await wrapFetch(
		'stores/search',
		{
			method: 'GET',
		},
		{
			location_ids: params?.locationIds || [],
			tags: params?.tags || [],
			keyword: params?.keyword || [],
		},
	);

	return response;
};

type FetchStoresDestinationsFunc = (
	startStoreId: number,
) => Promise<FetchResponse<StoresSearchData[]>>;

/**
 * 取得終點門市列表
 */
export const fetchStoresDestinationsFunc: FetchStoresDestinationsFunc = async (
	startStoreId: number,
) => {
	const response = await wrapFetch(
		'stores/destinations',
		{
			method: 'GET',
		},
		{
			store_id: startStoreId,
		},
	);

	return response;
};

export interface SingleStore {
	id: number;
	location_id: number;
	name: string;
	address: string;
	description: string;
	banners: BannerItem[];
	scenario_img: string;
	latitude: number;
	longitude: number;
	emails: string;
	facebook: string;
	instagram: string;
	telephone: string;
	cellphone: string;
	support_ezaio: boolean;
	weekday_open_time: string;
	weekday_close_time: string;
	weekend_open_time: string;
	weekend_close_time: string;
	tour_latest_rent_time: string; // 環島租賃最晚時間
	single_latest_rent_time: string; // 單點租賃最晚時間
	regular_off_days: string;
	open_time_info: string;
	transportation: string;
	additional_info: string;
	rental_order_info: string;
	store_service_info: string;
	service_tags: string[];
	store_facility: string[];
	store_close_dates: string[];
	recommended_bicycles: RecommendedBicycle[];
	recommended_paths: SimplePathData[];
}

type FetchStoresSingleFunc = (startStoreId: number) => Promise<FetchResponse<SingleStore>>;

/**
 * 取得單一門市資料
 */
export const fetchStoresSingleFunc: FetchStoresSingleFunc = async (storeId: number) => {
	const response = await wrapFetch(`stores/single/${storeId}`, {
		method: 'GET',
	});

	return response;
};

export type StoreProductType = 'rental' | 'package';

export type StoreProductsFuncParams = {
	id: number;
	start_at: string;
	end_at: string;
	type: StoreProductType;
};

type FetchStoreProductsFunc = (
	params: StoreProductsFuncParams,
) => Promise<FetchResponse<Record<ProductType | 'bicycles', ProductData[]>>>;

/**
 * 取得門市加價購商品
 */
export const fetchStoreProductsFunc: FetchStoreProductsFunc = async params => {
	const response = await wrapFetch(
		`store/${params.id}/products`,
		{
			method: 'GET',
		},
		{ start_at: params.start_at, end_at: params.end_at, type: params.type },
	);

	return response;
};
