import { createAction, handleActions } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import { fetchPrivacyTermsFunc } from 'api/privacyTerms';
import { fetchRelatedTermsFunc } from 'api/relatedTerms';

import { State as GlobalState } from './reducers';

export const getPrivacyTerms = createAction('GET_PRIVACY_TERMS', async () => {
	try {
		const { status, message, data } = await fetchPrivacyTermsFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: data.privacy_terms,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export const getRelatedTerms = createAction('GET_RELATED_TERMS', async () => {
	try {
		const { status, message, data } = await fetchRelatedTermsFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: data.related_terms,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

export interface State {
	privacyTerms: FetchedData<string>;
	relatedTerms: FetchedData<string>;
}

const initialState: State = {
	privacyTerms: { loading: false, data: '', error: '' },
	relatedTerms: { loading: false, data: '', error: '' },
};

export const reducer = {
	terms: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PRIVACY_TERMS_PENDING: state => ({
				...state,

				privacyTerms: {
					...state.privacyTerms,
					loading: true,
					error: '',
				},
			}),

			GET_PRIVACY_TERMS_FULFILLED: (state, action) => ({
				...state,
				privacyTerms: {
					...state.privacyTerms,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_PRIVACY_TERMS_REJECTED: (state, action) => ({
				...state,
				privacyTerms: {
					...state.privacyTerms,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_RELATED_TERMS_PENDING: state => ({
				...state,
				relatedTerms: {
					...state.relatedTerms,
					loading: true,
					error: '',
				},
			}),

			GET_RELATED_TERMS_FULFILLED: (state, action) => ({
				...state,
				relatedTerms: {
					...state.relatedTerms,
					loading: false,
					data: action.payload.data,
				},
			}),

			GET_RELATED_TERMS_REJECTED: (state, action) => ({
				...state,
				relatedTerms: {
					...state.relatedTerms,
					loading: false,
					error: action.payload.message,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ usePrivacyTerms ++
++----------------------------------------------------------------------*/
const selectPrivacyTerms = (state: GlobalState) => state.terms.privacyTerms;

const privacyTermsActionsMap = { getPrivacyTerms };

export const usePrivacyTerms = () =>
	useRedux<ReturnType<typeof selectPrivacyTerms>, typeof privacyTermsActionsMap>(
		selectPrivacyTerms,
		privacyTermsActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useRelatedTerms ++
++----------------------------------------------------------------------*/
const selectRelatedTerms = (state: GlobalState) => state.terms.relatedTerms;

const relatedTermsActionsMap = { getRelatedTerms };

export const useRelatedTerms = () =>
	useRedux<ReturnType<typeof selectRelatedTerms>, typeof relatedTermsActionsMap>(
		selectRelatedTerms,
		relatedTermsActionsMap,
	);
