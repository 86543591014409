import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.rentalQuestions.replace(appPath.rental, ''),
	components: () => [import(/* webpackChunkName: 'rentalQuestions' */ './component')],
	render: ([RentalQuestions]) => <RentalQuestions />,
};

export default routes;
