import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { PackagesItemProperty, fetchPackagesFunc } from 'api/package';

import { State as GlobalState } from './reducers';

export type PackageTagCode =
	| 'PACKAGE_PACK'
	| 'PACKAGE_ASSEMBLY'
	| 'PACKAGE_SHIPPING'
	| 'PACKAGE_RENT';

interface GetPackagesPayload {
	data: PackagesItemProperty[];
}

const getPackages = createAction<Promise<GetPackagesPayload>>('GET_PACKAGES', async () => {
	try {
		const { status, message, data } = await fetchPackagesFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data };
	} catch (error) {
		return { data: [] };
	}
});

export interface State {
	loading: boolean;
	error: string;
	data: PackagesItemProperty[];
}

const initialState = {
	loading: false,
	error: '',
	data: [],
};

export const reducer = {
	packages: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PACKAGES_PENDING: state => ({
				...state,
				loading: true,
				error: '',
			}),

			GET_PACKAGES_FULFILLED: (state, action: Action<GetPackagesPayload>) => ({
				...state,
				loading: false,
				data: action.payload.data,
			}),

			GET_PACKAGES_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: action.payload.message,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => state.packages;

export const usePackages = () => useRedux(mapHooksToState, { getPackages });
