import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { useRedux } from 'util/hook/redux';
import { LangCode, lanList } from 'util/i18n/config';

import { State as GlobalState } from './reducers';

export const changeLanguage = createAction('CHANGE_LANGUAGE', (lan: string) => {
	document.body.setAttribute('lan', lan);
	moment.locale(lan);
	return { lan };
});

// For Global State usage
export interface State {
	lan: LangCode;
}

export const initialState: State = {
	lan: lanList[0],
};

export const reducer = {
	i18n: handleActions(
		{
			CHANGE_LANGUAGE: (state, action) => ({
				...state,
				lan: action.payload.lan,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => state.i18n.lan;
const languageActionsMap = {};

type LanguageSelector = ReturnType<typeof mapHooksToState>;
type LanguageActionsMap = typeof languageActionsMap;

export const useLanguage = () =>
	useRedux<LanguageSelector, LanguageActionsMap>(mapHooksToState, languageActionsMap);
