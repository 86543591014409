import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'LeaderInfo' */ './component')],
	render: ([LeaderInfo]) => <LeaderInfo />,
};

export default routes;
