import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import RentalRoutesRoute from './Routes';
import RentalStoresRoute from './Stores';
import RentalNewsRoute from './News';
import RentalBikesFeesRoute from './BikesFees';
import RentalGuideRoute from './Guide';
import RentalQuestionsRoute from './Questions';
import RentalReservationRoute from './Reservation';
import RentalExaminationRoute from './Examination';

const routes: CustomRoute = {
	path: appPath.rental,
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'rental' */ './component')],
			render: ([Rental]) => <Rental />,
		},
		RentalRoutesRoute,
		RentalStoresRoute,
		RentalNewsRoute,
		RentalBikesFeesRoute,
		RentalGuideRoute,
		RentalQuestionsRoute,
		RentalReservationRoute,
		RentalExaminationRoute,
	],
};

export default routes;
