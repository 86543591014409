export enum EventStatus {
	NOT_OPEN = 'NOT_OPEN', // 未開放
	OPEN_SOON = 'OPEN_SOON', // 即將開放
	REGISTER = 'REGISTER', // 立即報名
	FULL = 'FULL', // 已額滿
	WAITING = 'WAITING', // 候補名額
	DEADLINE = 'DEADLINE', // 已截止
	LIMITED = 'LIMITED', // 倒數名額 (即將額滿)
}

export type EventStatusType = keyof typeof EventStatus;

export enum TagActiveStatus {
	ACTIVE = 'active', // 綠
	WARNING = 'warning', // 黃
	INACTIVE = 'inactive', // 灰
}

export type TagActiveStatusType = Lowercase<keyof typeof TagActiveStatus>;

export const EVENT_STATUS_TO_ACTIVE_STATUS_MAP: {
	[key in EventStatus]: TagActiveStatus;
} = {
	REGISTER: TagActiveStatus.ACTIVE,
	OPEN_SOON: TagActiveStatus.WARNING,
	FULL: TagActiveStatus.WARNING,
	WAITING: TagActiveStatus.WARNING,
	LIMITED: TagActiveStatus.WARNING,
	NOT_OPEN: TagActiveStatus.INACTIVE,
	DEADLINE: TagActiveStatus.INACTIVE,
} as const;
