import { handleActions, createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import { GiantEventData, GiantEventDataType } from 'types/GiantEvents';

import { useRedux } from 'util/hook/redux';

import {
	fetchCollectionFunc,
	addCollectionFunc,
	deleteCollectionFunc,
	CollectionSimpleObject,
} from 'api/collection';

import { TravelToursData } from 'types/travelClassicAndCustomTours';

import { eventCourseFormattedFunc, eventRideFormattedFunc, EventSimpleData } from 'models/events';

import { State as GlobalState, GetState } from './reducers';
import { RouteCardDataProperty } from './rentalRoutes';

type WishListType = GiantEventDataType | 'routes' | 'TRAVEL';

export interface State {
	routes: {
		loading: boolean;
		error: string;
		data: Omit<RouteCardDataProperty, 'isSaved'>[];
	};
	events: {
		loading: boolean;
		error: string;
		data: {
			eventsFeaturedRides: EventSimpleData[];
			eventsStoreRides: EventSimpleData[];
			eventsBikeClasses: EventSimpleData[];
		};
	};
	travel: {
		loading: boolean;
		error: string;
		data: TravelToursData[];
	};
}

const initialState: State = {
	routes: {
		loading: false,
		error: '',
		data: [],
	},
	events: {
		loading: false,
		error: '',
		data: {
			eventsFeaturedRides: [],
			eventsStoreRides: [],
			eventsBikeClasses: [],
		},
	},
	travel: {
		loading: false,
		error: '',
		data: [],
	},
};

const transformTypeFunc = (type: WishListType) => {
	switch (type) {
		case GiantEventData.eventsStoreRides:
			return 'RIDE';
		case GiantEventData.eventsBikeClasses:
			return 'DATE';
		case GiantEventData.eventsFeaturedRides:
			return 'RIDE';
		case 'TRAVEL':
			return 'TRAVEL';
		default:
			return 'PATH';
	}
};

const fetchWishlist = createAction('FETCH_WISHLIST', async () => {
	try {
		const { status, message, data } = await fetchCollectionFunc();

		if ((status !== 200 && status !== 201) || data === undefined) {
			throw new Error(message);
		}

		const transformedData = {
			routes: data.paths.map(
				({ id, title, store_name, day, difficulty, first_banner_image_url }) => ({
					id,
					title,
					store: store_name,
					days: day,
					difficulty,
					thumbnailUrl: first_banner_image_url,
				}),
			),
			events: {
				eventsFeaturedRides: eventRideFormattedFunc(data.events.eventsFeaturedRides),
				eventsStoreRides: eventRideFormattedFunc(data.events.eventsStoreRides),
				eventsBikeClasses: eventCourseFormattedFunc(data.events.courses),
			},
			travel: data.travel,
		};

		return { data: transformedData };
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

const removeFromWishlist = createAction(
	'REMOVE_FROM_WISHLIST',
	(wishListData: { type: WishListType; id: number }, index: number) => async (
		_: Dispatch,
		getState: GetState,
	) => {
		try {
			const collectionData: CollectionSimpleObject = {
				type: transformTypeFunc(wishListData.type),
				type_id: wishListData.id,
			};

			const { status, message } = await deleteCollectionFunc(collectionData);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			let wishListAfterRemoved = [];

			if (wishListData.type === 'routes') {
				wishListAfterRemoved = [...getState().accountWishList.routes.data];
			} else {
				wishListAfterRemoved = [...getState().accountWishList.events.data[wishListData.type]];
			}

			wishListAfterRemoved.splice(index, 1);

			return { wishListType: wishListData.type, wishListData: wishListAfterRemoved };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { wishListType: wishListData.type, error: message };
			}
			return { wishListType: wishListData.type, error: '' };
		}
	},
);

const toggleCollect = createAction(
	'TOGGLE_COLLECT',
	async (type: WishListType, id: number, isSaved: boolean, needReload?: boolean) => {
		const collectionData: CollectionSimpleObject = {
			type: transformTypeFunc(type),
			type_id: id,
		};
		if (isSaved) {
			await deleteCollectionFunc(collectionData);
		} else {
			await addCollectionFunc(collectionData);
		}

		if (needReload) window.location.reload();
	},
);

export const reducer = {
	accountWishList: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			FETCH_WISHLIST_PENDING: state => ({
				...state,
				routes: {
					...state.routes,
					loading: true,
					error: '',
				},
				events: {
					...state.events,
					loading: true,
					error: '',
				},
				travel: {
					...state.travel,
					loading: true,
					error: '',
				},
			}),

			FETCH_WISHLIST_FULFILLED: (state, action) => ({
				...state,
				routes: {
					...state.routes,
					loading: false,
					...(action.payload.data && {
						data: [...action.payload.data.routes],
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
				events: {
					...state.events,
					loading: false,
					...(action.payload.data && {
						data: { ...action.payload.data.events },
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
				travel: {
					...state.travel,
					loading: false,
					...(action.payload.data && {
						data: [...action.payload.data.travel],
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			REMOVE_FROM_WISHLIST_FULFILLED: (state, action) => ({
				...state,
				routes: {
					...state.routes,
					...(action.payload.wishListType === 'routes' && {
						data: [...action.payload.wishListData],
					}),
				},
				events: {
					...state.events,
					...(action.payload.wishListType.includes('events') && {
						data: {
							...state.events.data,
							[action.payload.wishListType]: [...action.payload.wishListData],
						},
					}),
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => state.accountWishList;

export const useAccountWishList = () =>
	useRedux(mapHooksToState, {
		fetchWishlist,
		removeFromWishlist,
		toggleCollect,
	});
