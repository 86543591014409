import { FetchResponse } from 'types/FetchResponse';
import { FetchTravelToursList } from 'types/travelClassicAndCustomTours';
import { wrapFetch } from 'util/api';

/**
 * 取得旅遊客製包團行程列表
 *
 * API: /api/v1/web/travel
 */
export const fetchTravelCustomToursList: FetchTravelToursList = async params => {
	const response = await wrapFetch(
		`travel`,
		{
			method: 'GET',
		},
		params,
		{ withAuth: true },
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TypeIdsApiData {
	id: number;
	name: string;
	code?: string;
}

type fetchTypeIdsFunc = () => Promise<FetchResponse<TypeIdsApiData[]>>;

/**
 * 取得篩選器 - 旅遊行程
 *
 * only_groups: ['TRAVEL_TYPE']
 * api: /api/v1/web/tags 取得篩選器 - 旅遊行程
 */
export const fetchTypeIdsFunc: fetchTypeIdsFunc = async () => {
	const response = await wrapFetch(
		'tags',
		{
			method: 'GET',
		},
		{
			only_groups: ['TRAVEL_TYPE'],
		},
		{ withLocale: false, withLan: true },
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TypeIdsMultiApiData {
	id: number;
	name: string;
	code?: string;
	subTags?: TypeIdsMultiApiData[];
}

type fetchTypeIdsMultiFunc = () => Promise<FetchResponse<TypeIdsMultiApiData[]>>;

/**
 * 取得篩選器 - 旅遊行程
 * 注意這邊取得的是兩階層 check box 旅遊行程的選項 (multi)
 * only_groups: ['TRAVEL_TYPE']
 * api: /api/v1/web/tags/multi 取得篩選器 - 旅遊行程
 */
export const fetchTypeIdsMultiFunc: fetchTypeIdsMultiFunc = async () => {
	const response = await wrapFetch(
		'tags/multi',
		{
			method: 'GET',
		},
		{
			only_groups: ['TRAVEL_TYPE'],
		},
		{ withLocale: false, withLan: true },
	);

	return response;
};
