interface Data {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}
const data: Data = {};
let isSessionStorageSupport = true;

const prefix = (key: string) => `giantAdventure-${key}`;

export const detectSessionStorageSupport = () => {
	try {
		window.sessionStorage.setItem('test', '1');
		window.sessionStorage.removeItem('test');
	} catch (e) {
		console.warn('Does not support sessionStorage', e);
		isSessionStorageSupport = false;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setItem: (key: string, value: string, withPrefix?: boolean) => void = (
	key,
	value,
	withPrefix = true,
) => {
	const prefixKey = withPrefix ? prefix(key) : key;

	if (isSessionStorageSupport) {
		window.sessionStorage.setItem(prefixKey, value);
		return;
	}

	data[prefixKey] = value;
};

const getItem: (key: string, withPrefix?: boolean) => string | null = (key, withPrefix = true) => {
	const prefixKey = withPrefix ? prefix(key) : key;

	if (isSessionStorageSupport) {
		return window.sessionStorage.getItem(prefixKey);
	}

	if (Object.hasOwnProperty.call(data, prefixKey)) {
		return data[prefixKey];
	}

	return null;
};

const removeItem: (key: string, withPrefix?: boolean) => void = (
	key: string,
	withPrefix = true,
) => {
	const prefixKey = withPrefix ? prefix(key) : key;

	if (isSessionStorageSupport) {
		window.sessionStorage.removeItem(prefixKey);
		return;
	}

	if (Object.hasOwnProperty.call(data, prefixKey)) {
		delete data[prefixKey];
	}
};

export default {
	setItem,
	getItem,
	removeItem,
};
