import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'eventsNewsInfo' */ './component')],
	render: ([EventsNewsInfo]) => <EventsNewsInfo />,
};

export default routes;
