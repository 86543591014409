/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';

import { useRedux } from 'util/hook/redux';
import { wrapRetryAdminFetch } from 'util/api';

import { openModal } from 'models/modal';

import { fetchApproveSTSTFFunc, fetchApproveLTFFunc, fetchApproveBTFFunc } from 'api/approve';

import { State as GlobalState, GetState } from './reducers';

type AdminApproveField = {
	id: number;
	fromPosition: string; // 起點
	toPosition: string; // 終點
	spec: string; // item
	note: string; // 備註
	amount: number; // 請求數量
	reason: string; // 原因
	bicycleNumber: string; // 車架號碼
	brokenTime: string; // 異常時間
	approve: boolean; // 是否核准調車
	approveAmount: number; // Approve 數量
};

export type AdminApproveForm = 'STSTF' | 'LTF' | 'BTF';

type FromStatus = 'fetching' | 'editing' | 'validating' | 'submitting' | 'success' | 'failed';

interface FormState<Data, FormFields> {
	status: FromStatus;
	message: string;
	data: Data;
	form: FormFields;
}

const defaultSTSTF: FormState<
	Pick<AdminApproveField, 'id' | 'fromPosition' | 'toPosition' | 'spec' | 'note' | 'amount'>,
	{ approve: InputFiled<SelectValue<boolean | null>> }
> = {
	status: 'editing',
	message: '',
	data: {
		id: 0,
		fromPosition: '',
		toPosition: '',
		spec: '',
		note: '',
		amount: 0,
	},
	form: {
		approve: { value: { value: null, label: '' }, valid: true, error: '', required: true },
	},
};

const defaultLTF: FormState<
	Pick<
		AdminApproveField,
		'id' | 'reason' | 'fromPosition' | 'toPosition' | 'spec' | 'note' | 'amount'
	>,
	{ approveAmount: InputFiled<number | null> }
> = {
	status: 'editing',
	message: '',
	data: {
		id: 0,
		reason: '',
		fromPosition: '',
		toPosition: '',
		spec: '',
		note: '',
		amount: 0,
	},
	form: {
		approveAmount: { value: null, valid: true, error: '', required: true },
	},
};

const defaultBTF: FormState<
	Pick<
		AdminApproveField,
		'id' | 'fromPosition' | 'toPosition' | 'spec' | 'bicycleNumber' | 'brokenTime'
	>,
	{ approve: InputFiled<SelectValue<boolean | null>> }
> = {
	status: 'editing',
	message: '',
	data: {
		id: 0,
		fromPosition: '',
		toPosition: '',
		spec: '',
		bicycleNumber: '',
		brokenTime: '',
	},
	form: {
		approve: { value: { value: null, label: '' }, valid: true, error: '', required: true },
	},
};

export type State = {
	status: FromStatus;
	message: string;
	STSTF: Record<number | string, typeof defaultSTSTF>;
	LTF: Record<number | string, typeof defaultLTF>;
	BTF: Record<number | string, typeof defaultBTF>;
};

const initialState: State = {
	status: 'fetching',
	message: '',
	STSTF: {},
	LTF: {},
	BTF: {},
};

type GetAdminApproveFormPayload =
	| {
			key: 'STSTF';
			data: Record<number, typeof defaultSTSTF>;
	  }
	| {
			key: 'LTF';
			data: Record<number, typeof defaultLTF>;
	  }
	| {
			key: 'BTF';
			data: Record<number, typeof defaultBTF>;
	  };

const getAdminApproveSTSTF = createAction<Promise<GetAdminApproveFormPayload>, number>(
	'GET_ADMIN_APPROVE_FORM',
	async (formId: number) => {
		const { status, message, data } = await fetchApproveSTSTFFunc(formId);

		if ((status !== 200 && status !== 201) || !data) {
			throw new Error(message);
		}

		return {
			key: 'STSTF',
			data: {
				[data.id]: {
					...defaultSTSTF,
					data: {
						id: data.id,
						fromPosition: data.from_position_name,
						toPosition: data.to_position_name,
						spec: data.bicycle_spec_name,
						note: data.note,
						amount: data.amount,
					},
				},
			},
		};
	},
);

const getAdminApproveLTF = createAction<Promise<GetAdminApproveFormPayload>, number, string>(
	'GET_ADMIN_APPROVE_FORM',
	async (storeId: number, logisticsDate: string) => {
		const { status, message, data } = await fetchApproveLTFFunc(storeId, logisticsDate);

		if ((status !== 200 && status !== 201) || !data) {
			throw new Error(message);
		}

		const returnObj: Record<number, typeof defaultLTF> = {};

		// const data = [
		// 	{
		// 		id: 0,
		// 		reason: 'reason',
		// 		from_position_name: 'from_position_name',
		// 		to_position_name: 'to_position_name',
		// 		bicycle_spec_name: 'bicycle_spec_name',
		// 		note: 'note',
		// 		amount: 12,
		// 	},
		// 	{
		// 		id: 1,
		// 		reason: 'reason',
		// 		from_position_name: 'from_position_name',
		// 		to_position_name: 'to_position_name',
		// 		bicycle_spec_name: 'bicycle_spec_name',
		// 		note: 'note',
		// 		amount: 2,
		// 	},
		// 	{
		// 		id: 2,
		// 		reason: 'reason',
		// 		from_position_name: 'from_position_name',
		// 		to_position_name: 'to_position_name',
		// 		bicycle_spec_name: 'bicycle_spec_name',
		// 		note: 'note',
		// 		amount: 3,
		// 	},
		// 	{
		// 		id: 3,
		// 		reason: 'reason',
		// 		from_position_name: 'from_position_name',
		// 		to_position_name: 'to_position_name',
		// 		bicycle_spec_name: 'bicycle_spec_name',
		// 		note: 'note 5',
		// 		amount: 5,
		// 	},
		// ];

		data.forEach(form => {
			returnObj[form.id] = {
				...defaultLTF,
				data: {
					id: form.id,
					reason: form.reason,
					fromPosition: form.from_position_name,
					toPosition: form.to_position_name,
					spec: form.bicycle_spec_name,
					note: form.note,
					amount: form.amount,
				},
			};
		});

		return {
			key: 'LTF',
			data: returnObj,
		};
	},
);

const getAdminApproveBTF = createAction<Promise<GetAdminApproveFormPayload>, number, string>(
	'GET_ADMIN_APPROVE_FORM',
	async (formId: number, logisticsDate: string) => {
		const { status, message, data } = await fetchApproveBTFFunc(formId, logisticsDate);

		if ((status !== 200 && status !== 201) || !data) {
			throw new Error(message);
		}

		const returnObj: Record<number, typeof defaultBTF> = {};

		data.forEach(form => {
			returnObj[form.id] = {
				...defaultBTF,
				data: {
					id: form.id,
					fromPosition: form.from_position_name,
					toPosition: form.to_position_name,
					spec: form.bicycle_spec_name,
					bicycleNumber: form.bicycle_number,
					brokenTime: form.broken_time,
				},
			};
		});

		return {
			key: 'BTF',
			data: returnObj,
		};
	},
);

type ChangeFormPayload =
	| {
			formType: 'STSTF';
			formId: number | string;
			key: 'approve';
			data: Partial<InputFiled>;
	  }
	| {
			formType: 'LTF';
			formId: number | string;
			key: 'approveAmount';
			data: Partial<InputFiled>;
	  }
	| {
			formType: 'BTF';
			formId: number | string;
			key: 'approve';
			data: Partial<InputFiled>;
	  };

const changeForm = createAction(
	'CHANGE_ADMIN_APPROVE_SINGLE_FORM',
	({ formType, formId, key, data }: ChangeFormPayload) => ({
		formType,
		formId,
		key,
		data,
	}),
);

type ChangeSingleFormStatusPayload = {
	formType: AdminApproveForm;
	formId: number | string;
	status: FromStatus;
};

const changeSingleFormStatus = createAction<
	ChangeSingleFormStatusPayload,
	ChangeSingleFormStatusPayload
>('CHANGE_ADMIN_APPROVE_SINGLE_FORM_STATUS', ({ formType, formId, status }) => ({
	formType,
	formId,
	status,
}));

type SubmitFormFPayload = {
	formType: AdminApproveForm;
	formId: number | string;
	status: FromStatus;
	message: string;
};

const submitAdminApproveSTSTF = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SubmitFormFPayload>,
	number | string
>('SUBMIT_ADMIN_APPROVE_FORM', formId => async (dispatch: Dispatch, getState: GetState) => {
	dispatch(changeSingleFormStatus({ formType: 'STSTF', formId, status: 'submitting' }));

	const {
		adminApproveForms: { STSTF: formObj },
	} = getState();

	const { status, message = '' } = await wrapRetryAdminFetch(`approve/STSTF/${formId}`, {
		method: 'POST',
		body: JSON.stringify({
			approve: formObj[formId].form.approve.value.value,
		}),
	});

	if (status !== 200 && status !== 201) {
		dispatch(
			openModal({
				category: 'toast',
				type: 'message',
				data: {
					message,
					status: 'warning',
				},
			}),
		);

		return { formType: 'STSTF', formId, message, status: 'failed' };
	}

	return { formType: 'STSTF', formId, message, status: 'success' };
});

const submitAdminApproveLTF = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SubmitFormFPayload>,
	{ storeId: number; formId: number | string; logisticsDate: string }
>(
	'SUBMIT_ADMIN_APPROVE_FORM',
	({ storeId, formId, logisticsDate }) => async (dispatch: Dispatch, getState: GetState) => {
		dispatch(changeSingleFormStatus({ formType: 'LTF', formId, status: 'submitting' }));

		const {
			adminApproveForms: { LTF: formObj },
		} = getState();

		const { status, message = '' } = await wrapRetryAdminFetch(
			`approve/LTF/${storeId}/${logisticsDate}`,
			{
				method: 'POST',
				body: JSON.stringify({
					forms: {
						[formId]: formObj[formId].form.approveAmount.value,
					},
				}),
			},
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message,
						status: 'warning',
					},
				}),
			);

			return { formType: 'LTF', formId, message, status: 'failed' };
		}

		return { formType: 'LTF', formId, message, status: 'success' };
	},
);

const submitAdminApproveBTF = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SubmitFormFPayload>,
	{ storeId: number; formId: number | string; logisticsDate: string }
>(
	'SUBMIT_ADMIN_APPROVE_FORM',
	({ storeId, formId, logisticsDate }) => async (dispatch: Dispatch, getState: GetState) => {
		dispatch(changeSingleFormStatus({ formType: 'BTF', formId, status: 'submitting' }));

		const {
			adminApproveForms: { BTF: formObj },
		} = getState();

		const { status, message = '' } = await wrapRetryAdminFetch(
			`approve/BTF/${storeId}/${logisticsDate}`,
			{
				method: 'POST',
				body: JSON.stringify({
					forms: {
						[formId]: formObj[formId].form.approve.value.value,
					},
				}),
			},
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message,
						status: 'warning',
					},
				}),
			);

			return { formType: 'BTF', formId, message, status: 'failed' };
		}

		return { formType: 'BTF', formId, message, status: 'success' };
	},
);

export const reducer = {
	adminApproveForms: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ADMIN_APPROVE_FORM_PENDING: state => ({
				...state,
				status: 'fetching',
				message: '',
			}),

			GET_ADMIN_APPROVE_FORM_FULFILLED: (state, action: Action<GetAdminApproveFormPayload>) => ({
				...state,
				status: 'success',
				[action.payload.key]: action.payload.data,
			}),

			GET_ADMIN_APPROVE_FORM_REJECTED: (state, action) => ({
				...state,
				status: 'failed',
				message: action.payload.message,
			}),

			CHANGE_ADMIN_APPROVE_SINGLE_FORM_STATUS: (
				state,
				action: Action<ChangeSingleFormStatusPayload>,
			) => ({
				...state,

				[action.payload.formType]: {
					...state[action.payload.formType],
					[action.payload.formId]: {
						...state[action.payload.formType][action.payload.formId],
						status: action.payload.status,
					},
				},
			}),

			CHANGE_ADMIN_APPROVE_SINGLE_FORM: (state, action: Action<ChangeFormPayload>) => ({
				...state,

				[action.payload.formType]: {
					...state[action.payload.formType],
					[action.payload.formId]: {
						...state[action.payload.formType][action.payload.formId],
						form: {
							...state[action.payload.formType][action.payload.formId].form,
							...(action.payload.formType === 'STSTF' && {
								approve: {
									...state[action.payload.formType][action.payload.formId].form[action.payload.key],
									...action.payload.data,
								},
							}),
							...(action.payload.formType === 'LTF' && {
								approveAmount: {
									...state[action.payload.formType][action.payload.formId].form[action.payload.key],
									...action.payload.data,
								},
							}),
							...(action.payload.formType === 'BTF' && {
								approve: {
									...state[action.payload.formType][action.payload.formId].form[action.payload.key],
									...action.payload.data,
								},
							}),
						},
					},
				},
			}),

			SUBMIT_ADMIN_APPROVE_FORM_FULFILLED: (state, action: Action<SubmitFormFPayload>) => ({
				...state,
				[action.payload.formType]: {
					...state[action.payload.formType],
					[action.payload.formId]: {
						...state[action.payload.formType][action.payload.formId],
						status: action.payload.status,
						message: action.payload.message,
					},
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAdminApproveSTSTF ++
++----------------------------------------------------------------------*/
const selectSTSTF = (state: GlobalState) => ({
	status: state.adminApproveForms.status,
	message: state.adminApproveForms.message,
	data: state.adminApproveForms.STSTF,
});

const ststfActionsMap = {
	getForm: getAdminApproveSTSTF,
	changeForm,
	changeStatus: changeSingleFormStatus,
	submitForm: submitAdminApproveSTSTF,
};

export const useAdminApproveSTSTF = () =>
	useRedux<ReturnType<typeof selectSTSTF>, typeof ststfActionsMap>(selectSTSTF, ststfActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminApproveLTF ++
++----------------------------------------------------------------------*/
const selectLTF = (state: GlobalState) => ({
	status: state.adminApproveForms.status,
	message: state.adminApproveForms.message,
	data: state.adminApproveForms.LTF,
});

const ltfActionsMap = {
	getForm: getAdminApproveLTF,
	changeForm,
	changeStatus: changeSingleFormStatus,
	submitForm: submitAdminApproveLTF,
};

export const useAdminApproveLTF = () =>
	useRedux<ReturnType<typeof selectLTF>, typeof ltfActionsMap>(selectLTF, ltfActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminApproveBTF ++
++----------------------------------------------------------------------*/
const selectBTF = (state: GlobalState) => ({
	status: state.adminApproveForms.status,
	message: state.adminApproveForms.message,
	data: state.adminApproveForms.BTF,
});

const btfActionsMap = {
	getForm: getAdminApproveBTF,
	changeForm,
	changeStatus: changeSingleFormStatus,
	submitForm: submitAdminApproveBTF,
};

export const useAdminApproveBTF = () =>
	useRedux<ReturnType<typeof selectBTF>, typeof btfActionsMap>(selectBTF, btfActionsMap);
