import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

type FetchRelatedTermsFunc = () => Promise<FetchResponse<{ related_terms: string }>>;

export const fetchRelatedTermsFunc: FetchRelatedTermsFunc = async () => {
	const response = await wrapFetch('relatedTerms', {
		method: 'GET',
	});

	return response;
};
