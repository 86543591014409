import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useTransition, animated, config } from 'react-spring';
import { useTranslation } from 'react-i18next';

import { useModal } from 'models/modal';

import Portal from 'components/atoms/Portal';
import Icon from 'components/atoms/Icon';

import WarningIcon from 'images/icon/warning.inline.svg';
import CheckIcon from 'images/icon/check-circle.inline.svg';

import styles from './index.css';

type Status = 'warning' | 'pass';

const StatusIconMap = {
	warning: WarningIcon,
	pass: CheckIcon,
};

type ToastProperty = {
	className?: string;
};

const Toast = ({ className }: ToastProperty) => {
	const [
		{
			modal: {
				toast: { type, data, i18n },
			},
		},
		{ closeModal },
	] = useModal();

	const { t } = useTranslation(['toast', i18n]);

	const onRestHandler = useCallback(() => {
		if (type !== '') {
			setTimeout(() => {
				closeModal({ category: 'toast' });
			}, 3000);
		}
	}, [type]);

	const modalTransition = useTransition(type, {
		from: { opacity: 0, transform: 'translateY(100%) translateX(-50%)' },
		enter: { opacity: 1, transform: 'translateY(0%) translateX(-50%)' },
		leave: { opacity: 0, transform: 'translateY(100%) translateX(-50%)' },
		config: config.stiff,
		onRest: onRestHandler,
	});

	return (
		<>
			{modalTransition(
				(values, item) =>
					item && (
						<Portal>
							<animated.div className={classnames(styles.toast, className)} style={values}>
								{item === 'shouldAgreeContract' && (
									<>
										<Icon className={classnames(styles.icon, styles.warning)} src={WarningIcon} />
										<div className={styles.text}>{t('toast:shouldAgreeContract')}</div>
									</>
								)}
								{item === 'message' && (data.message || data.status) && (
									<>
										{data.status && (
											<Icon
												className={classnames(styles.icon, styles[data.status])}
												src={StatusIconMap[data.status as Status]}
											/>
										)}
										<div className={styles.text}>
											{i18n ? t(`${i18n}:${data.message}`) : data.message}
										</div>
									</>
								)}
							</animated.div>
						</Portal>
					),
			)}
		</>
	);
};

export default Toast;
