import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.storiesAboutUs.replace(appPath.stories, ''),
	components: () => [import(/* webpackChunkName: 'eventsBikeClasses' */ './component')],
	render: ([StoriesAboutUs]) => <StoriesAboutUs />,
};

export default routes;
