/**
 * 判斷是否為外部 URL
 */
export const externalUrlRegex = /^https?:\/\//;

/**
 * 判斷電話號碼開頭是否為台灣 0 或 886, 並判斷整個字串是否出現 +886
 */
export const twPhoneNumberRegex = /^(0|886)|(\+886)/g;

/**
 * 匹配 URL 中目前地區（locale）的 route 字串
 *
 * 例如： /TW/ 或 /JP/
 */
export const localeUrlRegex = /^\/([A-Za-z]{2})\//;

/**
 * 刪除所有空白字元
 */
export const stringWithoutSpaces = /\s/g;
