/* eslint-disable react/jsx-curly-newline */
/* eslint-disable indent */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { accountNaviLinks, appPath } from 'util/routingConfig';
import { useMedia } from 'util/event';
import { pathSupportChecker } from 'util/helper';

import { globalLocal, useLocale } from 'models/locale';
import { useConfig } from 'models/global';
import { useAuth } from 'models/auth';

import Link from 'components/atoms/Link';
import Division from 'components/atoms/Division';
import Icon from 'components/atoms/Icon';
import NaviCollapse, { NavItem } from 'components/molecules/NaviCollapse';

import FacebookIcon from 'images/icon/facebook.inline.svg';
import InstagramIcon from 'images/icon/instagram.inline.svg';
import TwitterIcon from 'images/icon/twitter.inline.svg';
import YoutubeIcon from 'images/icon/youtube.inline.svg';
import LogoIcon from 'images/icon/logo-blue.inline.svg';

import styles from './index.css';

export interface FooterNaviProperty {
	title: {
		name: string;
		url: string;
	};
	links: {
		name: string;
		url: string;
	}[];
}

interface NaviatorsProperty {
	className?: string;
	navis: FooterNaviProperty[];
}

const DesktopNaviators = ({ className, navis }: NaviatorsProperty) => {
	return (
		<div className={classnames(styles.footerNavigators, styles.desktop, className)}>
			{navis.map(({ title, links }) => (
				<div key={title.name} className={styles.item}>
					{title.url ? (
						<Link className={styles.title} to={title.url} type="external">
							{title.name}
						</Link>
					) : (
						<div className={styles.title}>{title.name}</div>
					)}
					<ul>
						{links.map(link => (
							<li key={link.name}>
								<Link to={link.url} type="external">
									{link.name}
								</Link>
							</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
};

const MobileNaviators = ({ className, navis }: NaviatorsProperty) => {
	return (
		<div className={classnames(styles.footerNavigators, styles.mobile, className)}>
			{navis.map(({ title, links }) => (
				<NaviCollapse key={title.name} title={title.name} titleClassName={styles.title}>
					{links.map(link => (
						<NavItem key={link.name} to={link.url} actived={false} onClick={() => {}}>
							{link.name}
						</NavItem>
					))}
				</NaviCollapse>
			))}
		</div>
	);
};

/**
 * 判斷當前 url 是否包含 travel
 *
 * @returns {boolean}
 * @memberof Footer
 */
const isTravelInURL: () => boolean = () => {
	return window.location.pathname.includes('travel');
};

const SocialMediaLinks = () => {
	// const media = useMedia();
	// if (media !== 'desktop') {
	// 	return null;
	// }

	return (
		<div className={styles.socialMedia}>
			<Link type="external" to="https://www.facebook.com/bicyclecycling/">
				<Icon src={FacebookIcon} />
			</Link>
			{// 2022-03-30 首次上 prod 只有 facebook icon。
			process.env.DEV ||
				process.env.DEMO ||
				(process.env.STAGE && (
					<>
						<Link type="external" to="https://www.instagram.com/giantadventure/?hl=zh-tw">
							<Icon src={InstagramIcon} />
						</Link>
						{/* <Link type="external" to="https://www.google.com.tw/">
							<Icon src={TwitterIcon} />
						</Link> */}
						<Link type="external" to="https://www.youtube.com/@GIANTADVENTURE/videos">
							<Icon src={YoutubeIcon} />
						</Link>
					</>
				))}
		</div>
	);
};

const AgencyDetail = (translationProps: {
	title: string;
	address: string;
	number: string;
	serviceHour: string;
}) => {
	const { title, address, number, serviceHour } = translationProps;
	return (
		<div
			className={classnames(styles.agencyDetail, {
				[styles.hideAgencyDetail]: !isTravelInURL(),
			})}
		>
			<p>{title}</p>
			交觀甲 7120 - 品保中 0307｜代表人：鄭秋菊
			<p>{address}</p>
			<p>{number}</p>
			<p>{serviceHour}</p>
		</div>
	);
};

const PrivacyRelateTerms = () => {
	// const media = useMedia();
	const { t } = useTranslation(['common']);

	// if (media !== 'desktop') {
	// 	return null;
	// }

	return (
		<div
			className={classnames(styles.linksContainer, {
				[styles.hideAgencyDetail]: isTravelInURL(),
			})}
		>
			<div className={styles.links}>
				<Link type="external" to={appPath.privacyTerms}>
					{t('nav:privacyTerms')}
				</Link>
				<Division vertical className={styles.division} />
				<Link type="external" to={appPath.relatedTerms}>
					{t('nav:relatedTerms')}
				</Link>
			</div>
		</div>
	);
};

const Footer: React.FC = () => {
	const { t } = useTranslation(['nav', 'contactService', 'common']);
	const media = useMedia();
	const [
		{
			locales: { data: locales },
			isGlobal,
		},
	] = useLocale();
	const [{ isLogin }] = useAuth();
	const [{ headers, subnavis, allAppPaths }] = useConfig();
	const supportPath = pathSupportChecker(allAppPaths, {
		account: ['account'],
	});

	const renderNavigators: FooterNaviProperty[] = !isGlobal
		? [
				...headers
					.filter(d => d !== 'stories')
					.map(navi => ({
						title: { name: t(`nav:${navi}`), url: appPath[navi] },
						links: (subnavis[navi] || []).map(d => ({
							name: t(`nav:${d}`),
							url: appPath[d],
						})),
					})),
				...(supportPath.account && isLogin
					? [
							{
								title: { name: t('nav:account'), url: appPath.account },
								links: accountNaviLinks.map(d => ({
									name: t(`nav:${d.key}`),
									url: d.path,
								})),
							},
					  ]
					: []),
				{
					title: { name: t('nav:contact'), url: appPath.aboutContact },
					links: headers
						.filter(d => d !== 'stories')
						.map(d => (d === 'logistics' ? 'package' : d))
						.map(service => ({
							name: t(`contactService:${service}`),
							url: `${appPath.aboutContact}?service=${service}`,
						})),
				},
				{
					title: { name: t('nav:download'), url: appPath.download },
					links: [
						{
							name: t('nav:download'),
							url: appPath.download,
						},
					],
				},
		  ]
		: [
				{
					title: { name: t('nav:locale'), url: '' },
					links: locales.codes
						.filter(code => code !== globalLocal.code)
						.map(code => ({ name: locales.byCode[code].name, url: `/${code}/` })),
				},
				{
					title: { name: t('nav:contact'), url: '' }, // 待定
					links: [
						{
							name: t('nav:customService'),
							url: '',
						},
						{
							name: t('nav:cooperation'),
							url: '',
						},
					],
				},
				{
					title: { name: t('nav:relatedLinks'), url: '' }, // 待定
					links: [
						{
							name: t('nav:shoppingCentre'),
							url: '',
						},
						{
							name: t('nav:officialWeb'),
							url: '',
						},
					],
				},
		  ];

	return (
		<footer className={styles.footer}>
			<div className={styles.body}>
				<div className={styles.iconAndPrivacy}>
					<Icon src={LogoIcon} className={styles.logo} />
					{media === 'desktop' && SocialMediaLinks()}
				</div>

				<div className={styles.contentContainer}>
					<div className={styles.content}>
						{media === 'desktop' ? (
							<DesktopNaviators navis={renderNavigators} />
						) : (
							<MobileNaviators navis={renderNavigators} />
						)}
					</div>

					<div className={styles.termsContent}>
						{media === 'desktop' && (
							<AgencyDetail
								title={t('agencyTitle')}
								address={t('agencyAddress')}
								number={t('agencyNumber')}
								serviceHour={t('agencyServiceHour')}
							/>
						)}
						{media === 'desktop' && PrivacyRelateTerms()}
					</div>
				</div>
			</div>

			<div className={styles.contentContainer}>
				{media !== 'desktop' && SocialMediaLinks()}
				{media !== 'desktop' && PrivacyRelateTerms()}
			</div>

			<div className={styles.declaration}>
				<div className={styles.declarationContent}>
					{/* © 2022 Giant Adventure All Rights Reserved 本站圖文係 Giant Group 版權所有，盜用必究。 */}
					<p>{t('common:declaration')}</p>
				</div>
				{media !== 'desktop' && (
					<AgencyDetail
						title={t('agencyTitle')}
						address={t('agencyAddress')}
						number={t('agencyNumber')}
						serviceHour={t('agencyServiceHour')}
					/>
				)}
			</div>
		</footer>
	);
};

export default Footer;
