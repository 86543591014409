import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/orders',
	components: () => [import(/* webpackChunkName: 'eventsOrders' */ './component')],
	render: ([EventsOrders]) => <EventsOrders />,
};

export default routes;
