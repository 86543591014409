import { ProductItemWithAmount } from 'models/product/type';

type ObjectType<
	K extends keyof ProductItemWithAmount = keyof ProductItemWithAmount
> = K extends 'stockId' ? { [key: number]: ProductItemWithAmount } : { [key: number]: number };

const calcParticipantProductAmount = <K extends keyof ProductItemWithAmount>({
	data,
	key,
}: {
	data: { [productId: number]: ProductItemWithAmount }[];
	key: K;
}): ObjectType<K> => {
	const list = data
		.map(d =>
			Object.entries(d)
				.filter(([, productSpec]) => productSpec && productSpec.amount > 0)
				.map(([, productSpec]) => productSpec),
		)
		.reduce((acc, val) => acc.concat(val), []);
	if (key === 'stockId') {
		return list.reduce<ObjectType<K>>((acc, val) => {
			const { amount } = val;
			const original = acc[val[key]] as ProductItemWithAmount;
			if (original) {
				return {
					...acc,
					[val[key]]: {
						...original,
						amount: original.amount + amount,
					},
				};
			}

			return {
				...acc,
				[val[key]]: val,
			};
		}, {});
	}

	return list.reduce<ObjectType<K>>((acc, val) => {
		const { amount } = val;
		if (acc[val[key]] !== undefined) {
			return {
				...acc,
				[val[key]]: (acc[val[key]] as number) + amount,
			};
		}

		return {
			...acc,
			[val[key]]: val.amount,
		};
	}, {});
};

export default calcParticipantProductAmount;
