import { combineReducers } from 'redux';

import * as routing from './routing';
import * as global from './global';
import * as locale from './locale';
import * as members from './member';
import * as blogs from './blog';
import * as i18n from './i18n';
import * as modal from './modal';
import * as locations from './locations';
import * as news from './news';
import * as rentalRoutes from './rentalRoutes';
import * as rentalReservation from './rentalReservation';
import * as rentalExamination from './rentalExamination';
import * as product from './product';
import * as tours from './tours';
import * as stores from './stores';
import * as tag from './tag';
import * as payment from './payment';
import * as invoice from './invoice';
import * as guideline from './guideline';
import * as faq from './faq';
import * as course from './course';
import * as courseOrder from './courseOrder';
import * as calendar from './calendar';
import * as accountOrders from './accountOrders';
import * as accountJourneys from './accountJourneys';
import * as accountWishList from './accountWishList';
import * as accountCertificate from './accountCertificate';
import * as deliveryPackingForm from './deliveryPackingForm';
import * as deliveryWay from './deliveryWay';
import * as stories from './stories';
import * as auth from './auth';
import * as account from './account';
import * as events from './events';
import * as packages from './packages';
import * as packagesPrice from './packagesPrice';
import * as rentalOrder from './rentalOrder';
import * as home from './home';
import * as ride from './ride';
import * as rideOrder from './rideOrder';
import * as customToursReservationForm from './customToursReservationForm';
import * as eventsReservation from './eventsReservation';
import * as travelReservation from './travelReservation';
import * as eventsParticipant from './eventsParticipant';
import * as travelParticipant from './travelParticipant';
import * as bicycle from './bicycle';
import * as adminBicycleForms from './adminBicycleForms';
import * as adminStockBicycle from './adminStockBicycle';
import * as adminUser from './adminUser';
import * as adminApproveForms from './adminApproveForms';
import * as notice from './notice';
import * as controllers from './controllers';
import * as contact from './contact';
import * as terms from './terms';
import * as document from './document';
import * as eventsRegister from './eventsRegisterSearch';
import * as orderInfo from './orderInfo';
import * as nationalities from './nationalities';
import * as countryCodes from './countryCodes';
import * as travel from './travel';
import * as travelOrder from './travelOrder';
import * as travelCustomTours from './travelCustomTours';
import * as travelTour from './travelTour';
import * as travelClassicTours from './travelClassicTours';
import * as travelRegister from './travelRegisterSearch';

// For Global State interface
export interface State {
	account: account.State;
	accountCertificate: accountCertificate.State;
	accountJourneys: accountJourneys.State;
	accountOrders: accountOrders.State;
	accountWishList: accountWishList.State;
	adminApproveForms: adminApproveForms.State;
	adminBicycleForms: adminBicycleForms.State;
	adminStockBicycle: adminStockBicycle.State;
	adminUser: adminUser.State;
	auth: auth.State;
	bicycle: bicycle.State;
	blogs: blogs.State;
	calendar: calendar.State;
	countryCodes: countryCodes.State;
	contact: contact.State;
	controllers: controllers.State;
	course: course.State;
	courseOrder: courseOrder.State;
	customToursReservationForm: customToursReservationForm.State;
	deliveryPackingForm: deliveryPackingForm.State;
	deliveryWay: deliveryWay.State;
	document: document.State;
	events: events.State;
	eventsParticipant: eventsParticipant.State;
	eventsRegister: eventsRegister.State;
	eventsReservation: eventsReservation.State;
	faq: faq.State;
	global: global.State;
	guideline: guideline.State;
	home: home.State;
	i18n: i18n.State;
	invoice: invoice.State;
	locale: locale.State;
	locations: locations.State;
	members: members.State;
	modal: modal.State;
	news: news.State;
	notice: notice.State;
	nationalities: nationalities.State;
	orderInfo: orderInfo.State;
	packages: packages.State;
	packagesPrice: packagesPrice.State;
	payment: payment.State;
	product: product.State;
	rentalExamination: rentalExamination.State;
	rentalOrder: rentalOrder.State;
	rentalReservation: rentalReservation.State;
	rentalRoutes: rentalRoutes.State;
	ride: ride.State;
	rideOrder: rideOrder.State;
	routing: routing.State;
	stores: stores.State;
	stories: stories.State;
	tag: tag.State;
	terms: terms.State;
	tours: tours.State;
	travel: travel.State;
	travelTour: travelTour.State;
	travelOrder: travelOrder.State;
	travelCustomTours: travelCustomTours.State;
	travelClassicTours: travelClassicTours.State;
	travelReservation: travelReservation.State;
	travelParticipant: travelParticipant.State;
	travelRegister: travelRegister.State;
}

export type GetState = () => State;

const reducers = combineReducers<State>({
	...account.reducer,
	...accountCertificate.reducer,
	...accountJourneys.reducer,
	...accountOrders.reducer,
	...accountWishList.reducer,
	...adminApproveForms.reducer,
	...adminBicycleForms.reducer,
	...adminStockBicycle.reducer,
	...adminUser.reducer,
	...auth.reducer,
	...bicycle.reducer,
	...blogs.reducer,
	...calendar.reducer,
	...contact.reducer,
	...controllers.reducer,
	...countryCodes.reducer,
	...course.reducer,
	...courseOrder.reducer,
	...customToursReservationForm.reducer,
	...deliveryPackingForm.reducer,
	...deliveryWay.reducer,
	...document.reducer,
	...events.reducer,
	...eventsParticipant.reducer,
	...eventsRegister.reducer,
	...eventsReservation.reducer,
	...faq.reducer,
	...global.reducer,
	...guideline.reducer,
	...home.reducer,
	...i18n.reducer,
	...invoice.reducer,
	...locale.reducer,
	...locations.reducer,
	...members.reducer,
	...modal.reducer,
	...news.reducer,
	...notice.reducer,
	...nationalities.reducer,
	...orderInfo.reducer,
	...packages.reducer,
	...packagesPrice.reducer,
	...payment.reducer,
	...product.reducer,
	...rentalExamination.reducer,
	...rentalOrder.reducer,
	...rentalReservation.reducer,
	...rentalRoutes.reducer,
	...ride.reducer,
	...rideOrder.reducer,
	...routing.reducer,
	...stores.reducer,
	...stories.reducer,
	...tag.reducer,
	...terms.reducer,
	...tours.reducer,
	...travel.reducer,
	...travelTour.reducer,
	...travelReservation.reducer,
	...travelParticipant.reducer,
	...travelOrder.reducer,
	...travelCustomTours.reducer,
	...travelClassicTours.reducer,
	...travelRegister.reducer,
});

export default reducers;
