import React from 'react';

import Loading from 'components/atoms/Loading';

import styles from './index.css';

const LoadingLayout: React.FC = ({ children }) => (
	<div className={styles.loadingLayout}>{children || <Loading fixed />}</div>
);

export default LoadingLayout;
