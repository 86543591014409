/* eslint-disable indent */
import React, { useRef, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { appPath, appPathKey, ConfigHeaders } from 'util/routingConfig';
import routingMatcher from 'util/routingMatcher';

import { useRouting } from 'models/routing';
import { useLocale } from 'models/locale';
import { useConfig } from 'models/global';
import { useTravelBriefList } from 'models/travel';

import Link from 'components/atoms/Link';

import styles from './index.css';

interface NavItemProperty {
	className?: string;
	actived?: boolean;
	to: string;
	onClick?: () => void;
	hasData?: boolean;
}

const NavItem: React.FC<NavItemProperty> = ({
	className,
	to = '',
	actived = false,
	onClick = () => {},
	children,
	hasData,
}) => (
	!hasData ? 
	<Link
		to={to}
		className={classnames(styles.link, className, {
			[styles.actived]: actived,
		})}
		onClick={onClick}
	>
		{children}
	</Link>
	: null
);

interface SubNavigationProperty {
	className?: string;
}

const SubNavigation: React.FC<SubNavigationProperty> = ({ className }) => {
	const { t } = useTranslation('nav');

	const [{ pathname }] = useRouting();
	const [
		{
			currentLocCode,
			locales: {
				data: { byCode: locByCode },
			},
			isGlobal,
		},
	] = useLocale();
	const [{ subnavis: subnavisData }] = useConfig();
	const subNavigationRef = useRef<HTMLDivElement | null>(null);

	const navName = useMemo(
		() => (isGlobal || !currentLocCode ? pathname.split('/')[1] : pathname.split('/')[2]),
		[pathname, currentLocCode],
	) as ConfigHeaders;

	const subnavis =
		subnavisData[navName] && !(isGlobal && navName === 'rental')
			? (subnavisData[navName] as appPathKey[])
			: [];

	const [{ data: brief }, { getTravelBriefList }] = useTravelBriefList();
	useEffect(() => {
		getTravelBriefList();
	}, []);

	return subnavis.length >= 1 ? (
		<div ref={subNavigationRef} className={classnames(styles.subNavigation, className)}>
			<div className={classnames(styles.container)}>
				<div className={styles.wrapper}>
					{subnavis.map(nav => (
						<NavItem
							key={nav}
							to={appPath[nav]}
							actived={routingMatcher(pathname, locByCode[currentLocCode], appPath[nav], {
								end: false,
							})}
							hasData = {nav === 'travelBrief' && brief?.data.length === 0}
						>
							{t(nav)}
						</NavItem>
					))}
				</div>
			</div>
		</div>
	) : null;
};

export default SubNavigation;
