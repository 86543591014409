/* eslint-disable no-nested-ternary */
import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

export type FAQTagCode = 'FAQ_COURSE' | 'FAQ_RENTAL' | 'FAQ_RIDER' | 'FAQ_TRAVEL';

export interface FAQTagItem {
	id: number;
	name: string;
}

export interface FAQTopicData {
	id: number;
	name: string;
	code: FAQTagCode;
	tags: FAQTagItem[];
}

type FAQTag = Record<FAQTagCode, FAQTopicData>;

interface FAQItem {
	id: number;
	title: string;
	description: string;
	status: string; // ONLINE, OFFLINE?
	order: number;
}

interface FAQSearchData {
	tag_id: number;
	tag_name: string;
	tag_group: Pick<FAQTopicData, 'id' | 'name' | 'code'>;
	faqs: FAQItem[];
}

type FetchFAQTagsFunc = () => Promise<FetchResponse<FAQTag>>;

export const fetchFAQTagsFunc: FetchFAQTagsFunc = async () => {
	const response = await wrapFetch('faq/tags', {
		method: 'GET',
	});

	return response;
};

type FetchFAQListFunc = (code: FAQTagCode) => Promise<FetchResponse<FAQItem[]>>;

export const fetchFAQListFunc: FetchFAQListFunc = async code => {
	const response = await wrapFetch(`faq/list/${code}`, {
		method: 'GET',
	});

	return response;
};

type FetchFAQSearchFunc = (params?: {
	tag_group_codes?: FAQTagCode | 'ALL';
	search?: string;
	tag_ids?: number[];
}) => Promise<FetchResponse<FAQSearchData[]>>;

export const fetchFAQSearchFunc: FetchFAQSearchFunc = async params => {
	const response = await wrapFetch('faq/search', {
		method: 'POST',
		body: JSON.stringify({
			tag_group_codes: params?.tag_group_codes
				? params?.tag_group_codes === 'ALL'
					? ['FAQ_COURSE', 'FAQ_RENTAL', 'FAQ_RIDER', 'FAQ_TRAVEL']
					: [params.tag_group_codes]
				: undefined,
			search: params?.search || '',
			tag_ids: params?.tag_ids || [],
		}),
	});

	return response;
};
