import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { initDynamicState } from 'util/helper';

import { DynamicState } from 'types/DynamicState';
import GiantBikeSchool from 'types/class/GiantBikeSchool';
import GiantAdventure from 'types/class/GiantAdventure';
import Leader from 'types/class/Leader';
import Instructor from 'types/class/Instructor';

import {
	fetchAboutUsFunc,
	AboutUsStoryData,
	AboutUsSchoolData,
	AboutUsTravelAgencyData,
} from 'api/aboutUs';
import { fetchTravelNoticeFunc } from 'api/travelNotice';

import { State as GlobalState } from './reducers';

export type StaffDataType = {
	id: number;
	name: string;
	position: string;
	imageUrl: string;
	linkTo: string;
};

export interface State {
	storyAboutUs: DynamicState<string>;
	storyGiantBikeSchool: DynamicState<GiantBikeSchool>;
	giantBikeSchoolLecturerInfo: DynamicState<Instructor>;
	storyGiantAdventure: DynamicState<GiantAdventure>;
	giantAdventureLeaderInfo: DynamicState<Leader>;
	travelNotice: DynamicState<string[]>;
}

const inititalState: State = {
	storyAboutUs: initDynamicState(''),
	storyGiantBikeSchool: initDynamicState(new GiantBikeSchool()),
	giantBikeSchoolLecturerInfo: initDynamicState(new Instructor()),
	storyGiantAdventure: initDynamicState(new GiantAdventure()),
	giantAdventureLeaderInfo: initDynamicState(new Leader()),
	travelNotice: initDynamicState([]),
};

const getStoryAboutUs = createAction('GET_STORY_ABOUT_US', async () => {
	try {
		const { status, message, data } = await fetchAboutUsFunc('story');

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const { content } = data as AboutUsStoryData;

		return { data: content };
	} catch (_error) {
		if (_error instanceof Error) {
			const { message } = _error;
			return { error: message };
		}
		return { error: '' };
	}
});

const getStoryGiantBikeSchool = createAction('GET_STORY_GIANT_BIKE_SCHOOL', async () => {
	try {
		const { status, message, data } = await fetchAboutUsFunc('school');
		const giantBikeSchool = new GiantBikeSchool(data as AboutUsSchoolData);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data: giantBikeSchool };
	} catch (_error) {
		if (_error instanceof Error) {
			const { message } = _error;
			return { error: message };
		}
		return { error: '' };
	}
});

const getGiantBikeSchoolLecturerInfo = createAction(
	'GET_GIANT_BIKE_SCHOOL_LECTURER_INFO',
	async (id: number) => {
		try {
			const { status, message, data } = await fetchAboutUsFunc('school');
			const { instructors } = data as AboutUsSchoolData;

			const targetInstructor = instructors.find(instructor => instructor.id === id);
			const instructor = new Instructor(targetInstructor);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data: instructor };
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

const getStoryGiantAdventure = createAction('GET_STORY_GIANT_ADVENTURE', async () => {
	try {
		const { status, message, data } = await fetchAboutUsFunc('travelAgency');
		const travelAgency = new GiantAdventure(data as AboutUsTravelAgencyData);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data: travelAgency };
	} catch (_error) {
		if (_error instanceof Error) {
			const { message } = _error;
			return { error: message };
		}
		return { error: '' };
	}
});

const getGiantAdventureLeaderInfo = createAction(
	'GET_GIANT_ADVENTURE_LEADER_INFO',
	async (id: number) => {
		try {
			const { status, message, data } = await fetchAboutUsFunc('travelAgency');
			const { leaders } = data as AboutUsTravelAgencyData;

			const targetLeader = leaders.find(leader => leader.id === id);
			const leader = new Leader(targetLeader);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data: leader };
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

export const getTravelNotice = createAction(
	'GET_TRAVEL_NOTICE',
	async (travelId: string | number) => {
		try {
			const { status, message, data } = await fetchTravelNoticeFunc(travelId.toString());

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const result: string[] = Object.keys(data)
				.filter(key => data[key] !== null)
				.map(key => data[key] as string)
				.reverse();

			return { data: result };
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

export const reducer = {
	stories: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_STORY_ABOUT_US_PENDING: state => ({
				...state,
				storyAboutUs: {
					...state.storyAboutUs,
					loading: true,
					error: '',
				},
			}),

			GET_STORY_ABOUT_US_FULFILLED: (state, action) => ({
				...state,
				storyAboutUs: {
					...state.storyAboutUs,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_STORY_GIANT_BIKE_SCHOOL_PENDING: state => ({
				...state,
				storyGiantBikeSchool: {
					...state.storyGiantBikeSchool,
					loading: true,
					error: '',
				},
			}),

			GET_STORY_GIANT_BIKE_SCHOOL_FULFILLED: (state, action) => ({
				...state,
				storyGiantBikeSchool: {
					...state.storyGiantBikeSchool,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_GIANT_BIKE_SCHOOL_LECTURER_INFO_PENDING: state => ({
				...state,
				giantBikeSchoolLecturerInfo: {
					...state.giantBikeSchoolLecturerInfo,
					loading: true,
					error: '',
				},
			}),

			GET_GIANT_BIKE_SCHOOL_LECTURER_INFO_FULFILLED: (state, action) => ({
				...state,
				giantBikeSchoolLecturerInfo: {
					...state.giantBikeSchoolLecturerInfo,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_STORY_GIANT_ADVENTURE_PENDING: state => ({
				...state,
				storyGiantAdventure: {
					...state.storyGiantAdventure,
					loading: true,
					error: '',
				},
			}),

			GET_STORY_GIANT_ADVENTURE_FULFILLED: (state, action) => ({
				...state,
				storyGiantAdventure: {
					...state.storyGiantAdventure,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_GIANT_ADVENTURE_LEADER_INFO_PENDING: state => ({
				...state,
				giantBikeSchoolLecturerInfo: {
					...state.giantBikeSchoolLecturerInfo,
					loading: true,
					error: '',
				},
			}),

			GET_GIANT_ADVENTURE_LEADER_INFO_FULFILLED: (state, action) => ({
				...state,
				giantAdventureLeaderInfo: {
					...state.giantAdventureLeaderInfo,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),

			GET_TRAVEL_NOTICE_PENDING: state => ({
				...state,
				travelNotice: {
					...state.travelNotice,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_NOTICE_FULFILLED: (state, action) => ({
				...state,
				travelNotice: {
					...state.travelNotice,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		inititalState,
	),
};

/* +----------------------------------------------------------------------
	++ useStoryAboutUs ++
++----------------------------------------------------------------------*/
const selectStoryAboutUs = (state: GlobalState) => ({
	storyAboutUs: state.stories.storyAboutUs,
});

export const useStoryAboutUs = () => useRedux(selectStoryAboutUs, { getStoryAboutUs });

/* +----------------------------------------------------------------------
	++ useStoryGiantBikeSchool ++
++----------------------------------------------------------------------*/
const selectStoryGiantBikeSchool = (state: GlobalState) => ({
	storyGiantBikeSchool: state.stories.storyGiantBikeSchool,
});

export const useStoryGiantBikeSchool = () =>
	useRedux(selectStoryGiantBikeSchool, { getStoryGiantBikeSchool });

/* +----------------------------------------------------------------------
++ useGiantBikeSchoolLecturer ++
++----------------------------------------------------------------------*/
const selectGiantBikeSchoolLecturer = (state: GlobalState) =>
	state.stories.giantBikeSchoolLecturerInfo;

export const useGiantBikeSchoolLecturer = () =>
	useRedux(selectGiantBikeSchoolLecturer, { getGiantBikeSchoolLecturerInfo });

/* +----------------------------------------------------------------------
	++ useStoryGiantAdventure ++
++----------------------------------------------------------------------*/
const selectStoryGiantAdventure = (state: GlobalState) => ({
	storyGiantAdventure: state.stories.storyGiantAdventure,
});

export const useStoryGiantAdventure = () =>
	useRedux(selectStoryGiantAdventure, { getStoryGiantAdventure });

/* +----------------------------------------------------------------------
	++ useGiantAdventureLeader ++
++----------------------------------------------------------------------*/
const selectGiantAdventureLeader = (state: GlobalState) => state.stories.giantAdventureLeaderInfo;

export const useGiantAdventureLeader = () =>
	useRedux(selectGiantAdventureLeader, { getGiantAdventureLeaderInfo });

/* +----------------------------------------------------------------------
	++ useTravelNotice ++
++----------------------------------------------------------------------*/
const selectTravelNotice = (state: GlobalState) => ({
	travelNotice: state.stories.travelNotice,
});

export const useTravelNotice = () => useRedux(selectTravelNotice, { getTravelNotice });
