/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, handleActions, Action } from 'redux-actions';

import { DataNormalized, FetchedDataNormalized } from 'types/DataNormalized';

import { useRedux } from 'util/hook/redux';

import { fetchTagsFunc, OnlyGroupType, TagData } from 'api/tag';

import { State as GlobalState } from './reducers';

export interface State {
	tags: FetchedDataNormalized<TagData, number>;
}

const initialState: State = {
	tags: {
		loading: false,
		error: '',
		data: {},
		list: [],
	},
};

const getTags = createAction<Promise<DataNormalized<TagData>>, OnlyGroupType[]>(
	'GET_TAGS',
	async only_groups => {
		try {
			const { status, message, data } = await fetchTagsFunc(only_groups);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const normalizedData = data.reduce(
				(a, v) => ({
					...a,
					[v.id]: v,
				}),
				{},
			);

			return { data: normalizedData, list: data.map(d => d.id) };
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const reducer = {
	tag: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_TAGS_PENDING: state => ({
				...state,
				tags: {
					...initialState.tags,

					loading: true,
				},
			}),

			GET_TAGS_FULFILLED: (state, action: Action<DataNormalized<TagData>>) => ({
				...state,
				tags: {
					...state.tags,
					loading: false,
					data: action.payload.data,
					list: action.payload.list,
				},
			}),

			GET_TAGS_REJECTED: (state, action) => ({
				...state,
				tags: {
					...state.tags,
					loading: false,
					error: action.payload.error.message,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTags ++
++----------------------------------------------------------------------*/
const selectTags = (state: GlobalState) => state.tag.tags;
const tagsMap = { getTags };

export const useTags = () =>
	useRedux<ReturnType<typeof selectTags>, typeof tagsMap>(selectTags, tagsMap);
