import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { LocationData } from 'types/LocationData';

import { useBoolean } from 'util/hook';
import { useMedia } from 'util/event';

import { useRouting } from 'models/routing';
import { useLocale, globalLocal } from 'models/locale';

import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import GlobeIcon from 'images/icon/globe.inline.svg';

import styles from './index.css';

interface NaviLocaleProperty {
	className?: string;
	onChangeLocale?: (loc: LocationData) => void;
}

const NaviLocale: React.FC<NaviLocaleProperty> = ({ className, onChangeLocale = () => {} }) => {
	const { t } = useTranslation(['locales', 'nav']);
	const media = useMedia();
	const [
		{
			currentLocCode,
			locales: { data: locales },
			isGlobal,
		},
	] = useLocale();

	const [, { pushRoute }] = useRouting();

	const [isCloseTop, { setFalse: setCloseTopFalse, setTrue: setCloseTopTrue }] = useBoolean({
		defaultBoolean: true,
	});
	const [
		isCloseBottom,
		{ setFalse: setCloseBottomFalse, setTrue: setCloseBottomTrue },
	] = useBoolean({
		defaultBoolean: false,
	});

	const onClickHandler = (nextLoc: LocationData) => () => {
		pushRoute({ loc: nextLoc.code });
		onChangeLocale(nextLoc);
	};

	return (
		<div className={classnames(styles.naviLocale, className)}>
			<div className={styles.title}>
				<Icon className={styles.icon} src={GlobeIcon} />
				<span>{t('nav:locale')}</span>
			</div>
			<div
				className={styles.wrapper}
				onScroll={e => {
					const clossBottom =
						e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 25;
					const clossTop = e.target.scrollTop <= 25;

					if (clossTop) {
						setCloseTopTrue();
					} else {
						setCloseTopFalse();
					}
					if (clossBottom) {
						setCloseBottomTrue();
					} else {
						setCloseBottomFalse();
					}
				}}
			>
				<div className={classnames(styles.gradient, styles.top, { [styles.hide]: isCloseTop })} />
				<div className={styles.list}>
					{/* // 2022-03-30 首次上 prod，隱藏 Global 全球按鈕。	 */}
					{!process.env.PRODUCTION && (
						<div
							className={classnames(styles.item, { [styles.active]: isGlobal })}
							role="button"
							tabIndex={0}
							onKeyPress={() => {}}
							onClick={onClickHandler(globalLocal)}
						>
							{t('global')}
						</div>
					)}
					{locales.codes
						.filter(code => code !== globalLocal.code)
						.map(code => {
							return (
								<div
									key={code}
									className={classnames(styles.item, {
										[styles.active]: code === currentLocCode,
									})}
									role="button"
									tabIndex={0}
									onKeyPress={() => {}}
									onClick={onClickHandler(locales.byCode[code])}
								>
									{locales.byCode[code].name}
								</div>
							);
						})}
					{media !== 'desktop' && (
						<Button
							className={styles.itineraryRegister}
							outline
							onClick={() => {
								pushRoute({
									pathname: '/travel/travel-tours',
								});
							}}
						>
							{t('common:itineraryRegister')}
						</Button>
					)}
				</div>
				<div
					className={classnames(styles.gradient, styles.bottom, { [styles.hide]: isCloseBottom })}
				/>
			</div>
		</div>
	);
};

export default NaviLocale;
