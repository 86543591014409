import React, { useRef, useCallback } from 'react';
import classnames from 'classnames';
import { animated } from 'react-spring';

import useOnClickOutside from 'util/hook/useOnClickOutside';
import useEventListener from 'util/hook/useEventListener';
import useModalSpring from 'util/hook/useModalSpring';

import Portal from 'components/atoms/Portal';
import ScrollLocker from 'components/atoms/ScrollLocker';
import Icon from 'components/atoms/Icon';

import CloseIcon from 'images/icon/close.inline.svg';

import styles from './index.css';

export type ModalProps = {
	className?: string;
	childrenWrapperClassName?: string;
	coverClassName?: string;
	withCloseButton?: boolean;
	canClickOutsideClose?: boolean;
	isOpen: boolean;
	closeModal: () => void;
	type?: string;
	title?: string;
	footerComponent?: () => JSX.Element;
	footerClass?: string;
};

const Modal: React.FC<ModalProps> = ({
	className,
	coverClassName,
	childrenWrapperClassName,
	withCloseButton = true,
	canClickOutsideClose = true,
	isOpen = false,
	closeModal = () => {},
	type = '',
	title = '',
	footerComponent,
	footerClass = '',
	children,
}) => {
	const refModal = useRef<HTMLDivElement>(null);

	const { coverTransition, mainTransition } = useModalSpring({ isOpen });

	const handleEscape = useCallback(
		(event: Event) => {
			if (event.defaultPrevented) {
				return;
			}

			const key = (event as KeyboardEvent).key || (event as KeyboardEvent).code;
			if (key === 'Escape' || key === 'Esc') {
				closeModal();
			}
		},
		[closeModal],
	);

	useEventListener('keydown', withCloseButton ? handleEscape : () => {});
	useOnClickOutside(refModal, canClickOutsideClose ? closeModal : () => {});

	return (
		<>
			{coverTransition(
				(values, item) =>
					item && (
						<Portal>
							<ScrollLocker />
							<animated.div
								className={classnames(styles.modalCover, coverClassName, {
									[styles.modalWhiteCover]: type === 'white',
								})}
								style={values}
								ref={refModal}
							>
								{mainTransition(
									(innerValues, innerItem) =>
										innerItem && (
											<animated.div
												className={classnames(styles.modal, className)}
												style={innerValues}
											>
												<div className={classnames(styles.header, !children && styles.noBottom)}>
													{title && <h5>{title}</h5>}
													{withCloseButton && (
														<Icon className={styles.close} src={CloseIcon} onClick={closeModal} />
													)}
												</div>
												<div className={classnames(styles.body, childrenWrapperClassName)}>
													{children}
												</div>
												{footerComponent !== undefined && (
													<div className={classnames(styles.footer, footerClass)}>
														{footerComponent()}
													</div>
												)}
											</animated.div>
										),
								)}
							</animated.div>
						</Portal>
					),
			)}
		</>
	);
};

export default Modal;
