/* eslint-disable indent */
import React, { UIEvent, forwardRef, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { useBoolean } from 'util/hook';

import styles from './index.css';

interface ScrollingContainerProperty extends React.ComponentPropsWithoutRef<'div'> {
	className?: string;
	gradientClassName?: string;
	bodyClassName?: string;
	offset?: number;
	onScroll?: (e: UIEvent<HTMLElement>) => void;
}

const ScrollingContainer = forwardRef<HTMLDivElement, ScrollingContainerProperty>(
	(
		{ className, gradientClassName, bodyClassName, offset = 25, onScroll = () => {}, children },
		containerRef,
	) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const [isCloseTop, { setFalse: setCloseTopFalse, setTrue: setCloseTopTrue }] = useBoolean({
			defaultBoolean: true,
		});
		const [
			isCloseBottom,
			{ setFalse: setCloseBottomFalse, setTrue: setCloseBottomTrue },
		] = useBoolean({
			defaultBoolean: false,
		});

		const detectPosition = (ele: HTMLElement) => {
			const clossBottom = ele.scrollHeight - ele.scrollTop <= ele.clientHeight + offset;
			const clossTop = ele.scrollTop <= offset;

			if (clossTop) {
				setCloseTopTrue();
			} else {
				setCloseTopFalse();
			}

			if (clossBottom) {
				setCloseBottomTrue();
			} else {
				setCloseBottomFalse();
			}
		};

		const handleOnScroll = (e: UIEvent<HTMLElement>): void => {
			const ele = e.target as HTMLElement;
			detectPosition(ele);

			onScroll(e);
		};

		useEffect(() => {
			if (ref.current) {
				const target = ref.current.childNodes[1] as HTMLElement;
				detectPosition(target);
			}
		}, [ref.current]);

		return (
			<div
				ref={ref}
				className={classnames(styles.scrollingContainer, className)}
				onScroll={handleOnScroll}
			>
				<div
					className={classnames(styles.scrollingContainerGradient, gradientClassName, styles.top, {
						[styles.hide]: isCloseTop,
					})}
				/>
				<div
					ref={containerRef}
					className={classnames(styles.scrollingContainerBody, bodyClassName)}
				>
					{children}
				</div>
				<div
					className={classnames(
						styles.scrollingContainerGradient,
						gradientClassName,
						styles.bottom,
						{
							[styles.hide]: isCloseBottom,
						},
					)}
				/>
			</div>
		);
	},
);

export default ScrollingContainer;

// const ScrollingContainer: React.FC<ScrollingContainerProperty> = ({
// 	className,
// 	gradientClassName,
// 	bodyClassName,
// 	offset = 25,
// 	children,
// }) => {
// 	const [isCloseTop, { setFalse: setCloseTopFalse, setTrue: setCloseTopTrue }] = useBoolean({
// 		defaultBoolean: true,
// 	});
// 	const [
// 		isCloseBottom,
// 		{ setFalse: setCloseBottomFalse, setTrue: setCloseBottomTrue },
// 	] = useBoolean({
// 		defaultBoolean: false,
// 	});

// 	const handleOnScroll = (e: UIEvent<HTMLElement>): void => {
// 		const ele = e.target as HTMLElement;
// 		const clossBottom = ele.scrollHeight - ele.scrollTop <= ele.clientHeight + offset;
// 		const clossTop = ele.scrollTop <= offset;

// 		if (clossTop) {
// 			setCloseTopTrue();
// 		} else {
// 			setCloseTopFalse();
// 		}

// 		if (clossBottom) {
// 			setCloseBottomTrue();
// 		} else {
// 			setCloseBottomFalse();
// 		}
// 	};

// 	return (
// 		<div className={classnames(styles.scrollingContainer, className)} onScroll={handleOnScroll}>
// 			<div
// 				className={classnames(styles.scrollingContainerGradient, gradientClassName, styles.top, {
// 					[styles.hide]: isCloseTop,
// 				})}
// 			/>
// 			<div className={classnames(styles.scrollingContainerBody, bodyClassName)}>{children}</div>
// 			<div
// 				className={classnames(styles.scrollingContainerGradient, gradientClassName, styles.bottom, {
// 					[styles.hide]: isCloseBottom,
// 				})}
// 			/>
// 		</div>
// 	);
// };

// export const withGradientMask = <P extends ScrollingContainerProperty>(
// 	WrappedComponent: React.ComponentType<P>,
// ): React.ComponentType<P> => props => {
// 	const { className, gradientClassName, bodyClassName, offset = 25, children } = props;

// 	const [isCloseTop, { setFalse: setCloseTopFalse, setTrue: setCloseTopTrue }] = useBoolean({
// 		defaultBoolean: true,
// 	});
// 	const [
// 		isCloseBottom,
// 		{ setFalse: setCloseBottomFalse, setTrue: setCloseBottomTrue },
// 	] = useBoolean({
// 		defaultBoolean: false,
// 	});

// 	const handleOnScroll = (e: UIEvent<HTMLElement>): void => {
// 		const ele = e.target as HTMLElement;
// 		const clossBottom = ele.scrollHeight - ele.scrollTop <= ele.clientHeight + offset;
// 		const clossTop = ele.scrollTop <= offset;

// 		if (clossTop) {
// 			setCloseTopTrue();
// 		} else {
// 			setCloseTopFalse();
// 		}

// 		if (clossBottom) {
// 			setCloseBottomTrue();
// 		} else {
// 			setCloseBottomFalse();
// 		}
// 	};

// 	return (
// 		<WrappedComponent
// 			className={classnames(styles.scrollingContainer, className)}
// 			onScroll={handleOnScroll}
// 			// eslint-disable-next-line react/jsx-props-no-spreading
// 			{...props}
// 		>
// 			<div
// 				className={classnames(styles.scrollingContainerGradient, gradientClassName, styles.top, {
// 					[styles.hide]: isCloseTop,
// 				})}
// 			/>
// 			<div className={classnames(styles.scrollingContainerBody, bodyClassName)}>{children}</div>
// 			<div
// 				className={classnames(styles.scrollingContainerGradient, gradientClassName, styles.bottom, {
// 					[styles.hide]: isCloseBottom,
// 				})}
// 			/>
// 		</WrappedComponent>
// 	);
// };
