export const invoiceTypeMap = {
	companyInvoice: { unit: 'companyInvoice', value: 1 }, // 公司行號
	mobileBarcode: { unit: 'carrier', value: 2 }, // 手機載具 (電子載具)
	citizenDigitalCertificate: { unit: 'carrier', value: 3 }, // 自然人憑證 (電子載具)
	donationCode: { unit: 'donationCode', value: 4 }, // 捐贈碼
	personalInvoice: { unit: 'personalInvoice', value: 5 }, // 個人紙本發票
	thirdParty: { unit: 'carrier', value: 6 }, // ezPay 會員
} as const;

export type InvoiceType = keyof typeof invoiceTypeMap;
export type InvoiceTypeValues = typeof invoiceTypeMap[InvoiceType]['value'];
export type InvoiceUnit = typeof invoiceTypeMap[InvoiceType]['unit'];

export type InvoiceRequestParams = {
	invoice_type: InvoiceTypeValues | undefined;
	company_title: string;
	company_no: string;
	donate_code: string;
	carrier_no: string;
};
