/* eslint-disable indent */
import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';

import { useRedux } from 'util/hook/redux';

import { DeliveryType } from 'api/ride';

import { GetState, State as GlobalState } from './reducers';

interface DeliveryWayFormData {
	deliveryType: SelectValue<DeliveryType | null>;
	deliveryStore: SelectValue<number | null>;
	country: SelectValue<string | null>;
	county: string;
	district: string;
	zipCode: string;
	address: string;
}

export type DeliveryWayField = keyof DeliveryWayFormData;

type DeliveryWayForm = FormDataMapping<DeliveryWayFormData>;

export type State = {
	form: DeliveryWayForm;
	mappingValue: {
		delivery_type: DeliveryType | null;
		delivery_address: string;
		delivery_store_id: number | null;
	};
};

const initialState: State = {
	form: {
		deliveryType: { value: { label: '', value: null }, valid: true, error: '', required: true },
		deliveryStore: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		country: { value: { label: '', value: null }, valid: true, error: '', required: true },
		county: { value: '', valid: true, error: '', required: true },
		district: { value: '', valid: true, error: '', required: true },
		zipCode: { value: '', valid: true, error: '', required: true },
		address: { value: '', valid: true, error: '', required: true },
	},
	mappingValue: {
		delivery_type: null,
		delivery_address: '',
		delivery_store_id: null,
	},
};

interface ChangeFormPayload {
	key: DeliveryWayField;
	data: Partial<InputFiled>;
}

const changeForm = createAction<ChangeFormPayload, ChangeFormPayload>(
	'CHANGE_DELIVERY_WAY_FORM',
	data => data,
);

const clearForm = createAction('CLEAR_DELIVERY_WAY_FORM');

export const getDeliveryWayMappingValue = createAction<
	(_: Dispatch, getState: GetState) => State['mappingValue']
>('GET_DELIVERY_WAY_MAPPING_VALUE', () => (_: Dispatch, getState: GetState) => {
	const {
		deliveryWay: {
			form: { deliveryType, deliveryStore, country, county, address, district, zipCode },
		},
	} = getState();
	let type = null;
	let addr = '';
	let id = null;

	switch (true) {
		case deliveryType.value.value === 'HOME_DELIVERY':
			switch (country.value.value) {
				case 'TW':
					type = deliveryType.value.value;
					addr = `${country.value.label} ${county.value} ${district.value} ${address.value}`;
					break;

				default:
					type = deliveryType.value.value;
					addr = `${country.value.label} ${zipCode.value} ${address.value}`;
					break;
			}
			break;
		case deliveryType.value.value === 'STORE':
			type = deliveryType.value.value;
			id = typeof deliveryStore.value.value === 'number' ? deliveryStore.value.value : null;
			break;
		case deliveryType.value.value === 'ON_SITE':
			type = deliveryType.value.value;
			break;
		default:
			break;
	}

	return {
		delivery_type: type,
		delivery_address: addr,
		delivery_store_id: id,
	};
});

export const reducer = {
	deliveryWay: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_DELIVERY_WAY_FORM: state => ({
				...state,
				form: initialState.form,
			}),

			CHANGE_DELIVERY_WAY_FORM: (state, action: Action<ChangeFormPayload>) => ({
				...state,

				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			GET_DELIVERY_WAY_MAPPING_VALUE: (state, action: Action<State['mappingValue']>) => ({
				...state,

				mappingValue: {
					...state.mappingValue,
					...action.payload,
				},
			}),
		},
		initialState,
	),
};

const selectForm = (state: GlobalState) => state.deliveryWay.form;

const formActionsMap = {
	changeForm,
	clearForm,
	getDeliveryWayMappingValue,
};

export const useDeliveryWay = () =>
	useRedux<ReturnType<typeof selectForm>, typeof formActionsMap>(selectForm, formActionsMap);
