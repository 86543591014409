import { ProductData as RawProductData } from 'api/product';

import { State as ProductState } from './index';

/* ***** Product Aditional Format Rules ****
	需用 colors 跟 sizes mapping 出 id 後再去 stocks 找出那個 id 的 stock
	* Rules
	colors 是空的 -> color 帶 0
	sizes 是空的 -> size 帶 0
	--------------------------------------------
	| 情況ㄧ
	| colors:[],sizes:[]
	| => color-0_size-0
	--------------------------------------------
	| 情況二
	| colors:[],sizes:[{id: 1}, {id: 2}]
	| => color-0_size-1, color-0_size-2
	--------------------------------------------
	| 情況三
	| colors:[{id: 1}],sizes:[{id: 1}, {id: 2}]
	| => color-1_size-1, color-1_size-2
	--------------------------------------------
***** */
export const normalizedProduct = (arr: RawProductData[]) =>
	arr.reduce(
		(acc, val) => {
			const color = val.colors.reduce(
				(a, v) => ({
					ids: [...a.ids, v.id],
					dataById: {
						...a.dataById,
						[v.id]: { colorId: v.id, colorName: v.color_name, hexCode: v.hex_code },
					},
				}),
				{ ids: [] as number[], dataById: {} as ProductState['dataById']['colors'] },
			);
			const size = val.sizes.reduce(
				(a, v) => ({
					ids: [...a.ids, v.id],
					dataById: {
						...a.dataById,
						[v.id]: { sizeId: v.id, sizeName: v.size_name },
					},
				}),
				{ ids: [] as number[], dataById: {} as ProductState['dataById']['sizes'] },
			);
			const stock = Object.values(val.stocks).reduce(
				(a, v) => ({
					ids: [...a.ids, v.id],
					dataById: {
						...a.dataById,
						[v.id]: {
							stockId: v.id,
							productId: val.id,
							colorId: v.color_id,
							sizeId: v.size_id,
							stock: v.stock,
						},
					},
				}),
				{ ids: [] as number[], dataById: {} as ProductState['dataById']['stocks'] },
			);
			return {
				ids: [...acc.ids, val.id],
				products: {
					...acc.products,
					[val.id]: {
						productId: val.id,
						name: val.name,
						description: val.description,
						imgUrl: val.img,
						sizeImgUrl: val.size_img,
						price: val.price,
						currency: val.currency,
						unit: val.charge_method,
						maxCount: val.max_count,
						colorTitle: '',
						colors: color.ids,
						sizeTitle: val.size_name,
						sizes: size.ids,
						stocks: stock.ids,
					},
				},
				stocks: {
					...acc.stocks,
					...stock.dataById,
				},
				colors: {
					...acc.colors,
					...color.dataById,
				},
				sizes: {
					...acc.sizes,
					...size.dataById,
				},
			};
		},
		{
			ids: [] as number[],
			products: {} as ProductState['dataById']['products'],
			stocks: {} as ProductState['dataById']['stocks'],
			colors: {} as ProductState['dataById']['colors'],
			sizes: {} as ProductState['dataById']['sizes'],
		},
	);
