export enum GiantTravelData {
	travel = 'travel', // 旅遊
}

export type GiantTravelDataType = keyof typeof GiantTravelData;

/**
 * 「旅遊」的前端 Route
 */
export enum GiantTravelRoute {
	travel = 'travel',
}

export type GiantTravelRouteType = keyof typeof GiantTravelRoute;
