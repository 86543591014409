import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';
import RentalNewsInfoRoute from './Info';

const routes: CustomRoute = {
	path: appPath.rentalNews.replace(appPath.rental, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'rentalNewsList' */ './component')],
			render: ([RentalNewsList]) => <RentalNewsList />,
		},
		RentalNewsInfoRoute,
	],
};

export default routes;
