/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { compile } from 'path-to-regexp';

import { adminPathMap, appPath } from 'util/routingConfig';
import { usePathMatch } from 'util/hook/usePathMatch';
import { useRouting } from 'models/routing';
import { useAdminStockBicycleInfo } from 'models/adminStockBicycle';
import { useAdminUserInfo } from 'models/adminUser';
import { AdminBicycleForm } from 'models/adminBicycleForms';

import Icon from 'components/atoms/Icon';
import FetchingContentWrapper from 'components/atoms/FetchingContentWrapper';
import PageWrapper, { SectionWrapper } from 'components/atoms/PageWrapper';
import Toast from 'components/molecules/Toast';

import LogoIcon from 'images/icon/logo-blue.inline.svg';

import styles from './index.css';

const AdminBicycleLayout: React.FC = ({ children }) => {
	const [, { replaceRoute }] = useRouting();
	const [info, { getStockBicycleInfo }] = useAdminStockBicycleInfo();
	const [userInfo, { fetchAdminUserInfo }] = useAdminUserInfo();
	const { id, page } = usePathMatch<{ id: string; page: string }>(
		`${appPath.admin}/bicycle/:id/:page`,
	);

	useEffect(() => {
		fetchAdminUserInfo();
	}, []);

	useEffect(() => {
		if (userInfo.data && id) {
			getStockBicycleInfo(id);
		}
	}, [userInfo.data, id, page]);

	// 如果頁面不在 redirect 表單列表內（後端傳來的 info.data.forms），則導回 redirect 頁面
	useEffect(() => {
		if (page === 'redirect' || !info.data) {
			return;
		}
	
		const isPageInRedirectForms = info.data.forms.some(
			d => adminPathMap[d as AdminBicycleForm].path.replace('/admin/bicycle/:id/', '') === page
		);
	
		if (!isPageInRedirectForms) {
			replaceRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id,
				}),
				withLoc: false,
			});
		}
	}, [info.data, page, replaceRoute]);

	return (
		<div className={styles.adminLayout}>
			<header data-html2canvas-ignore>
				<Icon src={LogoIcon} className={styles.logo} />
			</header>
			<PageWrapper withSubnav={false}>
				<FetchingContentWrapper
					loading={(info.loading || userInfo.loading) && !userInfo.error}
					isEmpty={!!info.data && info.data.forms.length <= 0}
				>
					{userInfo.error ? (
						<SectionWrapper>{userInfo.error}</SectionWrapper>
					) : !info.error ? (
						children
					) : (
						<SectionWrapper>{info.error}</SectionWrapper>
					)}
				</FetchingContentWrapper>
			</PageWrapper>
			<Toast />
		</div>
	);
};

const AdminDefaultLayout: React.FC = ({ children }) => {
	return (
		<div className={styles.adminLayout}>
			<header data-html2canvas-ignore>
				<Icon src={LogoIcon} className={styles.logo} />
			</header>
			<PageWrapper withSubnav={false}>{children}</PageWrapper>
			<Toast />
		</div>
	);
};

const AdminLayout: React.FC = ({ children }) => {
	const [{ pathname }] = useRouting();

	if (pathname.includes(appPath.adminBicycle)) {
		return <AdminBicycleLayout>{children}</AdminBicycleLayout>;
	}

	if (pathname.includes(appPath.adminApprove) || pathname.includes(appPath.adminOrder)) {
		return <AdminDefaultLayout>{children}</AdminDefaultLayout>;
	}

	return null;
};

export default AdminLayout;
