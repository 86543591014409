/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import formatToRentalStore from 'util/functions/formatToRentalStore';

import { RentalReservationStore } from 'models/stores';
import { openModal } from 'models/modal';

import { cancelRentalOrderFunc, fetchRentalOrderFunc, RentalOrder } from 'api/rentalOrder';

import { ItemProperties } from 'components/atoms/ShoppingList';

import { State as GlobalState } from './reducers';

export interface RentalOrderDetail {
	id: RentalOrder['id'];
	selector: RentalOrder['selector'];
	orderNo: RentalOrder['number'];
	createdAt: RentalOrder['created_at'];
	status: RentalOrder['status'];
	paymentStatus: RentalOrder['payment_status'];
	newebpay_token: RentalOrder['newebpay_token'];
	currency: RentalOrder['currency'];
	totalPrice: RentalOrder['total_price'];
	originalPrice: RentalOrder['original_price'];
	notes: RentalOrder['customer_note'];
	rentalOrderInfo: RentalOrder['start_store']['rental_order_info'];
	startStore: RentalReservationStore;
	startAt: RentalOrder['start_at'];
	endStore: RentalReservationStore;
	endAt: RentalOrder['end_at'];
	lastName: RentalOrder['last_name'];
	firstName: RentalOrder['first_name'];
	phone: RentalOrder['phone'];
	email: RentalOrder['email'];
	gender: RentalOrder['gender'];
	orderList: ItemProperties[];
	transactions: RentalOrder['transactions'];
}

export interface State {
	rental: {
		detail: FetchedData<RentalOrderDetail | null>;
	};
}

const initialState: State = {
	rental: {
		detail: { loading: false, error: '', data: null },
	},
};

const getRentalOrder = createAction<Promise<RentalOrderDetail>, string>(
	'GET_RENTAL_ORDER',
	async selector => {
		try {
			const { status, message, data } = await fetchRentalOrderFunc(selector);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const returnObj: RentalOrderDetail = {
				id: data.id,
				selector: data.selector,
				orderNo: data.number,
				createdAt: data.created_at,
				status: data.status,
				paymentStatus: data.payment_status,
				newebpay_token: data.newebpay_token,
				currency: data.currency,
				totalPrice: data.total_price,
				originalPrice: data.original_price,
				notes: data.customer_note,
				rentalOrderInfo: data.start_store.rental_order_info,
				startStore: formatToRentalStore(data.start_store),
				startAt: data.start_at,
				endStore: formatToRentalStore(data.end_store),
				endAt: data.end_at,
				lastName: data.last_name,
				firstName: data.first_name,
				phone: data.phone,
				email: data.email,
				gender: data.gender,
				orderList: [
					...data.bicycle_specs.map(d => ({
						id: d.id,
						name: d.model_name,
						categoryName: d.category_name,
						colorName: '',
						specName: d.size,
						amount: d.amount,
						stock: d.amount,
						price: d.total_price,
						createdAt: d.created_at,
						isReviewing: d.is_reviewing,
					})),
					...data.products.map(d => ({
						id: d.id,
						name: d.name,
						categoryName: '',
						colorName: d.color_name,
						specName: d.size_name,
						amount: d.amount,
						stock: d.amount,
						price: d.total_price,
						createdAt: d.created_at,
						isReviewing: d.is_reviewing,
					})),
				]
					.sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
					.map(d => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { createdAt, ...a } = d;
						return a;
					}),
				transactions: data.transactions
					.filter(
						d =>
							d.status !== 'MISTAKE' &&
							d.status !== 'REVIEWING' &&
							d.status !== 'OFFLINE_PROCESSING',
					)
					.map(d => ({
						...d,
						status: d.status === 'COMPLETED' && d.amount < 0 ? 'REFUND_COMPLETED' : d.status,
					})),
			};

			return returnObj;
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

const cancelRentalOrder = createAction<(_: Dispatch) => Promise<{ success: boolean }>, string>(
	'CANCEL_RENTAL_ORDER',
	selector => async dispatch => {
		try {
			const { status, message } = await cancelRentalOrderFunc(selector);

			if (status === 200) {
				return Promise.resolve({ success: true });
			}
			throw new Error(message);
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: (error as Error).message,
						status: 'warning',
					},
				}),
			);
			return Promise.reject((error as Error).message);
		}
	},
);

export const reducer = {
	rentalOrder: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_RENTAL_ORDER_PENDING: state => ({
				...state,
				rental: {
					...state.rental,
					detail: { ...initialState.rental.detail, loading: true },
				},
			}),

			GET_RENTAL_ORDER_FULFILLED: (state, action: Action<RentalOrderDetail>) => ({
				...state,
				rental: {
					...state.rental,
					detail: { ...state.rental.detail, loading: false, data: action.payload },
				},
			}),

			GET_RENTAL_ORDER_REJECTED: (state, action) => ({
				...state,
				rental: {
					...state.rental,
					detail: { ...state.rental.detail, loading: false, error: action.payload.message },
				},
			}),
		},
		initialState,
	),
};

const selectRental = (state: GlobalState) => state.rentalOrder.rental;

const rentalActionsMap = { getRentalOrder, cancelRentalOrder };

export const useRentalOrder = () =>
	useRedux<ReturnType<typeof selectRental>, typeof rentalActionsMap>(
		selectRental,
		rentalActionsMap,
	);
