import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBoolean } from 'util/hook';

import { useModal } from 'models/modal';

import Modal from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

import WarningIcon from 'images/icon/warning-triangle.inline.svg';

import styles from './index.css';

interface ModalWarningProperty {
	className?: string;
}

const ModalWarning: React.FC<ModalWarningProperty> = ({ className = '' }) => {
	const [
		{
			modal: {
				modal: { type, i18n, data },
			},
		},
		{ closeModal },
	] = useModal();

	const { t } = useTranslation(['common', i18n]);

	const [isModalWarningOpen, { setFalse: closeModalWarning }] = useBoolean({
		defaultBoolean: type === 'warning',
		onFalse: () => {
			closeModal({ category: 'modal' });
		},
	});

	const { title = '', message = '' } = (data || {}) as {
		[key: string]: string;
	};

	return (
		<>
			{isModalWarningOpen && (
				<Modal
					className={classnames(styles.modal, className)}
					isOpen={isModalWarningOpen}
					closeModal={closeModalWarning}
					footerClass={styles.footer}
					footerComponent={() => (
						<>
							<Button className={styles.modalButton} onClick={closeModalWarning}>
								{t('common:confirm')}
							</Button>
						</>
					)}
				>
					<div className={styles.modalInfo}>
						<Icon className={styles.modalInfoImg} src={WarningIcon} />
						<div className={styles.modalInfoTitle}>
							{(title && t(`${i18n}:${title}`)) || t('common:modalwarningTitle')}
						</div>
						<div className={styles.modalInfoContent}>{t(`${i18n}:${message}`)}</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default ModalWarning;
