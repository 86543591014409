import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.calendar,
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'calendarPage' */ './component')],
			render: ([CalendarPage]) => <CalendarPage />,
		},
	],
};

export default routes;
