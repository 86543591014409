export const pathInTwWhiteList = [
	'rental',
	'events',
	'logistics',
	'about',
	'admin',
	'calendar',
	'questions',
	'download',
	'privacy-terms',
	'related-terms',
	'stories',
];

export const pathInTwGlobalWhiteList = ['account', 'order', 'admin', 'auth-callback', 'signin'];

/**
 * 預約租車、活動報名訂單金額上限
 */
export const MAX_SHOP_PRICE_LIMIT = 199999;

/**
 * 付款方式使用「匯款」的訂單總金額上限
 */
export const MAX_ORDER_PRICE_FOR_TRANSFER_PAYMENT = 50000;

/**
 * 後台車輛管理表單錯誤訊息的 i18n 翻譯檔 namespace
 */
export const ADMIN_BICYCLE_ERROR_I18N = 'adminBicycleError';

/**
 * 寄發家長同意書年齡
 */
export const PARENT_CONSENT_AGE = 18;

/**
 * 寄發高齡同意書年齡
 */
export const ELDERLIES_CONSENT_AGE = 70;

/**
 * 驗證護照的國家清單
 *
 * ref: https://www.npmjs.com/package/validator -> isPassportNumber(str, countryCode)
 */
export const PASSPORT_VALIDATOR_COUNTRY_LIST = [
	'AM',
	'AR',
	'AT',
	'AU',
	'BE',
	'BG',
	'BY',
	'BR',
	'CA',
	'CH',
	'CN',
	'CY',
	'CZ',
	'DE',
	'DK',
	'DZ',
	'EE',
	'ES',
	'FI',
	'FR',
	'GB',
	'GR',
	'HR',
	'HU',
	'IE',
	'IN',
	'IR',
	'ID',
	'IS',
	'IT',
	'JP',
	'KR',
	'LT',
	'LU',
	'LV',
	'LY',
	'MT',
	'MY',
	'MZ',
	'NL',
	'PL',
	'PT',
	'RO',
	'RU',
	'SE',
	'SL',
	'SK',
	'TR',
	'UA',
	'US',
];
