/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, handleActions, Action } from 'redux-actions';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { FormDataMapping } from 'types/FormDataMapping';
import { GiantTravelRoute, GiantTravelRouteType } from 'types/GiantTravel';
import { InputFiled } from 'types/InputFiled';
// import { relationshipI18nNs } from 'types/Relationship';
import {
	GenderCodes,
	GenderValues,
	GenderValuesNotInitial,
	genderValueToNoInitialCodesMap,
} from 'types/Gender';

import { useRedux } from 'util/hook/redux';
import { t } from 'util/i18n';
import { objectDataKeyMapper } from 'util/functions/objectKeyMapper';
import { findIntNumByCountry, modifyPhoneByIntId, isExist } from 'util/helper';

import { calcTravelReservationShoppingDetail } from 'models/travelReservation';
import { UserInfoData } from 'models/auth';
import { ProductData, ProductItemWithAmount } from 'models/product/type';
import { TravelFormFieldData } from 'models/travelReservation/type';
import {
	TravelFormDataType,
	TravelFormField,
	TravelParticipantForms,
	TravelParticipantFormType,
	PurchaseField,
} from './type';

import { formTemplates } from './utils';
import { GetState, State as GlobalState } from '../reducers';
import { ItemInfo, TravelParticipantInfoData } from 'types/TravelOrderParticipantPaymentDetail';
import { SelectValue } from 'types/SelectValue';
// import { ProductType } from 'api/product';
// import { travelParticipantTravelMockByIds, travelParticipantTravelMockIds } from './__mocks__/data';

// 測試表單用假資料
/* import {
	travelParticipantTravelMockByIds,
	travelParticipantTravelMockIds,
} from './mockData'; */

export interface FormData<T extends TravelParticipantFormType = TravelParticipantFormType> {
	id?: number; // 報名時是用 timestamp， 報名完後端回傳是用 id
	form: TravelParticipantForms[T];
}

export interface State<T extends TravelParticipantFormType = TravelParticipantFormType> {
	travel: {
		type: T;
		id: number | null;
	};
	form: {
		ids: number[];
		byIds: { [key: Required<FormData<T>>['id']]: FormData<T>['form'] };
		editing: {
			id: null | number;
			data: null | FormData<T>['form'];
		};
		template: null | FormData<T>['form'];
	};
}

const initialState: State = {
	travel: {
		type: 'travel',
		id: null,
	},
	form: {
		ids: [],
		byIds: {},
		// ids: [...travelParticipantTravelMockIds],
		// byIds: {
		// 	...travelParticipantTravelMockByIds,
		// },
		editing: {
			id: null,
			data: null,
		},
		template: null,
	},
};

/* -------------------------------------------------------------------------------------------------------------------- */

const clearTravelParticipant = createAction('CLEAR_TRAVEL_PARTICIPANT');

/* -------------------------------------------------------------------------------------------------------------------- */

export interface FormChangePayload<T extends TravelParticipantFormType> {
	key: {
		travel: TravelFormField;
	}[T];
	data: Partial<InputFiled>;
}

const changeParticipantForm = createAction(
	'CHANGE_TRAVEL_PARTICIPANT_FORM',
	<T extends TravelParticipantFormType>({ key, data }: FormChangePayload<T>) => ({
		key,
		data,
	}),
);

export const changeParticipantFormTemplate = createAction(
	'CHANGE_TRAVEL_PARTICIPANT_FORM_TEMPLATE',
	<T extends TravelParticipantFormType>({ key, data }: FormChangePayload<T>) => ({
		key,
		data,
	}),
);

/* -------------------------------------------------------------------------------------------------------------------- */

interface FormTypeIdPayload {
	eventType: TravelParticipantFormType;
	travelId?: State['travel']['id'];
}

/**
 * setTravelTypeId
 *
 * @param {string}eventType 擷取自頁面的 url
 * @param {string}travelId 擷取自頁面的 url
 *
 * 設置 eventType, travelId 進去 state ，讓 form 內的屬性（ex. editing, template）得以成立
 */
export const setTravelTypeId = createAction<FormTypeIdPayload, FormTypeIdPayload>(
	'SET_TRAVEL_PARTICIPANT_TYPE_ID',
	({ eventType, travelId }) => ({ eventType, travelId }),
);

/* -------------------------------------------------------------------------------------------------------------------- */

const initTravelParticipantForm = createAction('INIT_TRAVEL_PARTICIPANT_FORM');

const initTravelEditingParticipantForm = createAction('INIT_TRAVEL_EDITING_PARTICIPANT_FORM');

const removeParticipantForm = createAction<FormData['id'] | undefined, FormData['id'] | undefined>(
	'REMOVE_PARTICIPANT_FORM',
	(id = undefined) => id,
);

const setParticipantFormEditable = createAction<number | undefined, number | undefined>(
	'SET_TRAVEL_PARTICIPANT_FORM_EDITABLE',
	id => id,
);

const completeParticipantFormEdit = createAction<number>(
	'COMPLETE_TRAVEL_PARTICIPANT_FORM_EDIT',
	() => new Date().getTime(),
);

/* -------------------------------------------------------------------------------------------------------------------- */

// type UpdateParticipantPrimaryStatusPayload = {
// 	data: {
// 		[key: Required<FormData<TravelParticipantFormType>>['id']]: FormData<
// 			TravelParticipantFormType
// 		>['form'];
// 	};
// };

// export const updateParticipantPrimaryStatus = createAction(
// 	'UPDATE_PARTICIPANT_PRIMARY_STATUS',
// 	() => (_: Dispatch, getState: GetState) => {
// 		const {
// 			travelParticipant: {
// 				form: { ids, byIds },
// 			},
// 			travel: {
// 				reservationTravelInfo: { data: travelData },
// 			},
// 		} = getState();

// 		const {leftQuota, isOpenWaitingList = false } = travelData || {};

// 		const data = { ...byIds };
// 		const isSecondaryIds = ids.slice(leftQuota);

// 		// 如果候補機制沒有開啟，則不會出現備取的狀況
// 		if (!isOpenWaitingList) {
// 			return { data };
// 		}

// 		isSecondaryIds.forEach(id => {
// 			data[id] = {
// 				...byIds[id],
// 				isPrimary: { value: false, valid: true, error: '', required: false },
// 			} as FormData<'travel'>['form'];
// 		});

// 		return { data };
// 	},
// );

/* -------------------------------------------------------------------------------------------------------------------- */

/**
 * 每當「參加者表單初始化完成」、「編輯完一個參加者表單」或「刪除一個參加者表單」，重新計算旅遊的訂單明細和總金額。
 */
export const calcTravelRegisterDetailEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType(
			'INIT_TRAVEL_PARTICIPANT_FORM',
			'COMPLETE_TRAVEL_PARTICIPANT_FORM_EDIT',
			'REMOVE_PARTICIPANT_FORM',
		),
		map(() => calcTravelReservationShoppingDetail()),
	);

/* -------------------------------------------------------------------------------------------------------------------- */
const syncTravelParticipantFormByContactInfo = createAction<
	(dispatch: Dispatch, getState: GetState) => void
>('SYNC_TRAVEL_PARTICIPANT_FORM_BY_CONTACT_INFO', () => (dispatch, getState) => {
	const {
		travelReservation: { form },
	} = getState();

	const fieldsToSync: (keyof FormDataMapping<TravelFormFieldData>)[] = [
		'phone',
		'email',
		'citizenId',
		'lastName',
		'firstName',
		'gender',
		'birthday',
	];

	fieldsToSync.forEach(field => {
		const fieldValue = form[field].value;

		if (fieldValue) {
			dispatch(
				changeParticipantForm({
					key: field as keyof TravelFormDataType,
					data: {
						value: fieldValue,
						error: '',
						valid: true,
					},
				}),
			);
		}

		// Define a type for your fieldValue when it's an object
		interface FieldValueObject {
			label: string;
			value: string;
		}

		// Create a type guard function
		function isFieldValueObject(obj: any): obj is FieldValueObject {
			return obj && typeof obj === 'object' && 'label' in obj && 'value' in obj;
		}

		// Use the type guard in your code
		if (field === 'gender' && isFieldValueObject(fieldValue)) {
			dispatch(
				changeParticipantForm({
					key: 'sex',
					data: {
						value: {
							label: fieldValue.label === 'Male' ? 'M' : 'F',
							value: fieldValue.value,
						},
						error: '',
						valid: true,
					},
				}),
			);
		}
	});
});

/* -------------------------------------------------------------------------------------------------------------------- */
const KeyMapperFromUserInfoToParticipantForm = {
	nationalityCode: 'citizenship',
	phoneNumber: 'phone',
	gender: 'sex',
	// contact_name: 'emergencyName',
	// contact_relationship: 'emergencyRelation',
	// contact_phone: 'emergencyPhone',
} as const;

type KeyMapperFromUserInfoToParticipantForm = typeof KeyMapperFromUserInfoToParticipantForm;

/**
 * 同步會員資料到參加者資訊欄位
 */
const syncTravelParticipantFormByUserProfile = createAction<
	(_: Dispatch, getState: GetState) => void,
	boolean
>(
	'SYNC_TRAVEL_PARTICIPANT_FORM_BY_USER_PROFILE',
	(check = true) => (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: { currentUserGid, userDataByGid },
			nationalities: {
				data: { byCode: nationalitiesByCodes },
			},
			countryCodes: {
				data: { byCodes: countryCodesByCodes },
			},
		} = getState();

		const currentUserData = userDataByGid[currentUserGid];

		// 轉換後端格式
		const data = objectDataKeyMapper<UserInfoData, KeyMapperFromUserInfoToParticipantForm>(
			currentUserData,
			KeyMapperFromUserInfoToParticipantForm,
		);

		(Object.keys(data) as (keyof typeof data)[]).forEach(key => {
			const DataValue = check ? data[key] : '';
			let formDataValue: unknown = DataValue;

			if (key === 'sex') {
				formDataValue = {
					label: genderValueToNoInitialCodesMap[DataValue as GenderValues],
					value: genderValueToNoInitialCodesMap[DataValue as GenderValues],
				};
			}

			// 這邊因應 GID 2.0 改版，打進來的電話需經過處理才能帶入
			if (key === 'phone') {
				formDataValue = currentUserData.general?.isoCountryCode
					? {
							intIdNum:
								findIntNumByCountry(countryCodesByCodes, currentUserData.general.isoCountryCode) ||
								'',
							phoneNum:
								modifyPhoneByIntId(
									DataValue as string,
									findIntNumByCountry(countryCodesByCodes, currentUserData.general.isoCountryCode),
								) || '',
					  }
					: {
							intIdNum: '',
							phoneNum: '',
					  };
			}

			if (key === 'citizenship') {
				if (!isExist(DataValue)) {
					// 如果沒有國籍資料，就不要回填表單，避免把使用者自己填寫的內容蓋掉
					return;
				}
				formDataValue = {
					label: nationalitiesByCodes[DataValue as string]?.name || '',
					value: nationalitiesByCodes[DataValue as string]?.code || '',
				};
			}
			if (key === 'birthday' && check) {
				formDataValue = formDataValue !== '' ? moment(formDataValue as string) : null; // 後端回傳的空值為空字串
			}

			// 因應 GID 2.0 改版，緊急聯絡人的部分沒有從捷安特被帶入
			// if (key === 'emergencyRelation')
			// 	formDataValue = { label: t(`${relationshipI18nNs}:${DataValue}`), value: DataValue };
			// if (key === 'emergencyPhone' && typeof DataValue === 'string') {
			// 	formDataValue = {
			// 		intIdNum: currentUserData.countryCode || '',
			// 		phoneNum: DataValue?.split(' ')[1] || '',
			// 	};
			// }

			if (key !== undefined) {
				dispatch(
					changeParticipantForm({
						key: key as keyof TravelFormDataType,
						data: { value: formDataValue, valid: true, error: '' },
					}),
				);
			}
		});
	},
);

/* -------------------------------------------------------------------------------------------------------------------- */
interface ChangeParticipantAdditionalPayload {
	key: PurchaseField;
	data: ProductItemWithAmount;
}

const changeParticipantAdditional = createAction<
	ChangeParticipantAdditionalPayload,
	ChangeParticipantAdditionalPayload
>('CHANGE_PARTICIPANT_ADDITIONAL', ({ key, data }) => ({ key, data }));

/* -------------------------------------------------------------------------------------------------------------------- */

export type SetupParticipantFormByTravelOrderPayload = {
	id: number;
	type: GiantTravelRoute.travel;
	form: Partial<TravelParticipantForms['travel']>;
};

interface ProductSelectedPayload {
	[key: number]: {
		productId: number;
		stockId: number;
		amount: number;
		colorId: number;
		specId: number;
	};
}

/**
 * 將 旅遊 訂單資訊 map 到參加者表單欄位 -『我要繳費』第三步驟中間的參加者資訊
 *
 * @param {string} id 參加者 id
 * @param {string} type 'travel'
 * */
export const setupParticipantFormByTravelOrder = createAction<
	(dispatch: Dispatch, getState: GetState) => SetupParticipantFormByTravelOrderPayload,
	number,
	Extract<TravelParticipantFormType, 'travel'>
>('SETUP_PARTICIPANT_FORM_BY_TRAVEL_ORDER', (id, type) => (_: Dispatch, getState: GetState) => {
	const {
		nationalities: {
			data: { byCode: nationalitiesByCode },
		},
		travelRegister: {
			travelOrderParticipantInfo: { data: bySelector },
		},
		travelParticipant: {
			form: { template },
		},
		travel: {
			reservationTravelInfo: { data },
		},
	} = getState();

	const participantFilterData = bySelector.filter(pId => pId.id === id);
	const participantData = participantFilterData[0];
	let templateData = template;

	// console.log('🚀 ~ participantFilterData:', participantFilterData)
	// console.log('🚀 ~ participantData:', participantData)
	// console.log('🚀 ~ templateData:', templateData);

	if (templateData === null) {
		templateData = formTemplates.travel;
	}

	const createProductData = (products: ProductData[], participantProducts: ItemInfo[]) => {
		const productData = {} as ProductSelectedPayload;
		products.forEach((product: { stocks: { [x: string]: any }; id?: number }) => {
			const stockList = Object.keys(product.stocks).map(key => product.stocks[key]);
			participantProducts.forEach(partProd => {
				if (product.id === partProd.product_id) {
					productData[product.id] = {
						productId: partProd.product_id,
						stockId: partProd.product_stock_id,
						amount: partProd.amount,
						colorId: stockList.find(stock => stock.id === partProd.product_stock_id)?.color_id,
						specId: stockList.find(stock => stock.id === partProd.product_stock_id)?.size_id,
					};
				}
			});
		});
		return productData;
	};

	// const productDataHasValue = (data?.products && Object.keys(data.products).some(key => (data.products[key as ProductType | "bicycle"]).length > 0));

	const giveawaysData: ProductSelectedPayload = createProductData(
		((data?.products.gifts as unknown) as ProductData[]) || [],
		participantData.products.gift,
	);
	const productPurchaseData: ProductSelectedPayload = createProductData(
		((data?.products.products as unknown) as ProductData[]) || [],
		participantData.products.product,
	);
	const rentalBikeData: ProductSelectedPayload = createProductData(
		(data?.products.bicycle as unknown) as ProductData[],
		participantData.products.product,
	);
	const servicePurchaseData: ProductSelectedPayload = createProductData(
		((data?.products.services as unknown) as ProductData[]) || [],
		participantData.products.service,
	);

	console.log('rentalBikeData', rentalBikeData);
	console.log('productPurchaseData', productPurchaseData);

	return ({
		id,
		type,
		form: {
			...templateData,
			lastName: {
				...templateData.lastName,
				value: participantData.last_name,
			},
			firstName: {
				...templateData.firstName,
				value: participantData.first_name,
			},
			lastNameEn: {
				...templateData.lastNameEn,
				value: participantData.en_last_name,
			},
			firstNameEn: {
				...templateData.firstNameEn,
				value: participantData.en_first_name,
			},
			citizenship: {
				...templateData.citizenship,
				value: {
					label: nationalitiesByCode[participantData.nationality]?.name || '',
					value: participantData.nationality || null,
				},
			},
			citizenId: {
				...templateData.citizenId,
				value: participantData.identity_no,
			},
			sex: {
				...templateData.sex,
				value:
					participantData.gender === 'Female'
						? { label: t('gender:F'), value: 'Female' }
						: { label: t('gender:M'), value: 'Male' },
			},
			height: {
				...templateData.height,
				value: `${participantData.height}`,
			},
			weight: {
				...templateData.weight,
				value: `${participantData.weight}`,
			},
			phone: {
				...templateData.phone,
				value: {
					intIdNum: participantData.phone_country_code.name,
					phoneNum: participantData.phone.split(' ')[1],
				},
			},
			homePhone: {
				...templateData.homePhone,
				value: {
					intIdNum: participantData.home_phone_country_code.name,
					phoneNum: participantData.home_phone.split(' ')[1],
				},
			},
			email: {
				...templateData.email,
				value: participantData.email,
			},
			birthday: {
				...templateData.birthday,
				value: moment(participantData.birthday),
			},
			eatingHabit: {
				...templateData.eatingHabit,
				value:
					participantData.eating_habit === 'MEAT'
						? {
								value: 'MEAT',
								label: t('travelReservationForm:meat'),
						  }
						: participantData.eating_habit === 'VEGAN'
						? {
								value: 'VEGAN',
								label: t('travelReservationForm:vegan'),
						  }
						: {
								value: null,
								label: '',
						  },
			},
			eatingHabitNote: {
				...templateData.eatingHabitNote,
				value: participantData.eating_habit_note || '',
			},
			isMedicalRecord: {
				...templateData.isMedicalRecord,
				value:
					participantData.is_medical_record === true
						? {
								value: true,
								label: t('travelReservationForm:yes'),
						  }
						: participantData.is_medical_record === false
						? {
								value: false,
								label: t('travelReservationForm:no'),
						  }
						: {
								value: null,
								label: '',
						  },
			},
			specialDiseaseNote: {
				...templateData.isMedicalRecord,
				value: participantData.is_medical_record_note || '',
			},
			receiptType: {
				...templateData.receiptType,
				value:
					participantData.receiptType === 'B2C'
						? { label: t('B2C'), value: 'B2C' }
						: { label: t('B2B'), value: 'B2B' },
			},
			companyNo: {
				...templateData.companyNo,
				value: participantData.company_no || '',
			},
			companyTitle: {
				...templateData.companyTitle,
				value: participantData.company_title || '',
			},
			country: {
				...templateData.country,
				value: participantData.country,
			},
			city: {
				...templateData.city,
				value: participantData.city,
			},
			address: {
				...templateData.address,
				value: participantData.address,
			},
			emergencyName: {
				...templateData.emergencyName,
				value: participantData.contact_name,
			},
			emergencyRelation: {
				...templateData.emergencyRelation,
				value: {
					label: t(`contactRelationship:${participantData.contact_relationship}`) || '',
					value: participantData.contact_relationship || '',
				},
			},
			emergencyPhone: {
				...templateData.emergencyPhone,
				value: {
					intIdNum: participantData.contact_phone_country_code.name,
					phoneNum: participantData.contact_phone.split(' ')[1],
				},
			},
			isOwnBicycle: {
				...templateData.isOwnBicycle,
				value: {
					label: participantData.is_own_bicycle,
					value: participantData.is_own_bicycle,
				},
			},
			roommate: {
				...templateData.roommate,
				value: participantData.roommate_name,
			},
			roomPrice: {
				...templateData.roomPrice,
				value: participantData.room.price,
			},
			custom1: {
				...templateData.custom1,
				value: participantData.extra_field_1,
			},
			custom2: {
				...templateData.custom2,
				value: participantData.extra_field_2,
			},
			custom3: {
				...templateData.custom3,
				value: participantData.extra_field_3,
			},
			parentConsent: {
				...templateData.parentConsent,
				value: {
					url: participantData.parent_consent,
					filename: participantData.parent_consent?.split('/').pop() || '',
				},
			},
			elderliesConsent: {
				...templateData.elderliesConsent,
				value: {
					url: participantData.elder_consent,
					filename:
						participantData.elder_consent && (participantData.elder_consent.split('/').pop() || ''),
				},
			},
			// isPrimary: {
			// 	...templateData.isPrimary,
			// 	value: participantData.is_primary,
			// },
			rentalBicycle: {
				...templateData.giveaways,
				value: rentalBikeData,
			},
			servicePurchase: {
				...templateData.giveaways,
				value: servicePurchaseData,
			},

			giveaways: {
				...templateData.giveaways,
				value: giveawaysData,
			},
			productPurchase: {
				...templateData.productPurchase,
				value: productPurchaseData,
			},
		},
	} as unknown) as SetupParticipantFormByTravelOrderPayload;
});

/* -------------------------------------------------------------------------------------------------------------------- */

/**
 * 轉團的 action ， map 其資料到參加者表單欄位
 */
export const setupParticipantFormByChangeItinerary = createAction<
	(dispatch: Dispatch, getState: GetState) => SetupParticipantFormByTravelOrderPayload,
	Partial<TravelParticipantInfoData>,
	Extract<TravelParticipantFormType, 'travel'>
>(
	'SETUP_PARTICIPANT_FORM_BY_CHANGE_ITINERARY',
	(pData, type) => (_: Dispatch, getState: GetState) => {
		const {
			nationalities: {
				data: { byCode: nationalitiesByCode },
			},
			travelParticipant: {
				form: { template },
			},
		} = getState();

		const participantData = pData;
		let templateData = template;

		if (templateData === null) {
			templateData = formTemplates.travel;
		}
		// 	const productData = {} as ProductSelectedPayload;
		// 	products.forEach((product: { stocks: { [x: string]: any }; id?: number }) => {
		// 		const stockList = Object.keys(product.stocks).map(key => product.stocks[key]);
		// 		participantProducts.forEach(partProd => {
		// 			if (product.id === partProd.product_id) {
		// 				productData[product.id] = {
		// 					productId: partProd.product_id,
		// 					stockId: partProd.product_stock_id,
		// 					amount: partProd.amount,
		// 					colorId: stockList.find(stock => stock.id === partProd.product_stock_id)?.color_id,
		// 					specId: stockList.find(stock => stock.id === partProd.product_stock_id)?.size_id,
		// 				};
		// 			}
		// 		});
		// 	});
		// 	return productData;
		// };

		// const productDataHasValue = (data?.products && Object.keys(data.products).some(key => (data.products[key as ProductType | "bicycle"]).length > 0));

		// const giveawaysData: ProductSelectedPayload = createProductData(
		// 	((data?.products.gifts as unknown) as ProductData[]) || [],
		// 	participantData.products.gift,
		// );
		// const productPurchaseData: ProductSelectedPayload = createProductData(
		// 	((data?.products.products as unknown) as ProductData[]) || [],
		// 	participantData.products.product,
		// );
		// const rentalBikeData: ProductSelectedPayload = createProductData(
		// 	(data?.products.bicycle as unknown) as ProductData[],
		// 	participantData.products.product,
		// );
		// const servicePurchaseData: ProductSelectedPayload = createProductData(
		// 	((data?.products.services as unknown) as ProductData[]) || [],
		// 	participantData.products.service,
		// );

		// console.log('rentalBikeData', rentalBikeData);
		// console.log('productPurchaseData', productPurchaseData);

		return ({
			id: new Date().getTime(),
			type,
			form: {
				...templateData,
				lastName: {
					...templateData.lastName,
					value: participantData.last_name,
				},
				firstName: {
					...templateData.firstName,
					value: participantData.first_name,
				},
				lastNameEn: {
					...templateData.lastNameEn,
					value: participantData.en_last_name,
				},
				firstNameEn: {
					...templateData.firstNameEn,
					value: participantData.en_first_name,
				},
				citizenship: {
					...templateData.citizenship,
					value: {
						label:
							participantData?.nationality &&
							(nationalitiesByCode[participantData?.nationality]?.name === 'Taiwan'
								? `${nationalitiesByCode[participantData?.nationality]?.name} 臺灣`
								: nationalitiesByCode[participantData?.nationality]?.name || ''),
						value: participantData.nationality || null,
					},
				},
				citizenId: {
					...templateData.citizenId,
					value: participantData.identity_no,
				},
				sex: {
					...templateData.sex,
					value:
						participantData.gender === 'Female'
							? { label: t('gender:F'), value: 'Female' }
							: { label: t('gender:M'), value: 'Male' },
				},
				height: {
					...templateData.height,
					value: `${participantData.height}`,
				},
				weight: {
					...templateData.weight,
					value: `${participantData.weight}`,
				},
				phone: {
					...templateData.phone,
					value: {
						intIdNum: participantData.phone_country_code,
						phoneNum: participantData?.phone,
					},
				},
				email: {
					...templateData.email,
					value: participantData.email,
				},
				birthday: {
					...templateData.birthday,
					value: moment(participantData.birthday),
				},
				// 	...templateData.eatingHabit,
				// 	value:
				// 		participantData.eating_habit === 'MEAT'
				// 			? {
				// 					value: 'MEAT',
				// 					label: t('travelReservationForm:meat'),
				// 			  }
				// 			: participantData.eating_habit === 'VEGAN'
				// 			? {
				// 					value: 'VEGAN',
				// 					label: t('travelReservationForm:vegan'),
				// 			  }
				// 			: {
				// 					value: null,
				// 					label: '',
				// 			  },
				// },
				// eatingHabitNote: {
				// 	...templateData.eatingHabitNote,
				// 	value: participantData.eating_habit_note || '',
				// },
				// isMedicalRecord: {
				// 	...templateData.isMedicalRecord,
				// 	value:
				// 		participantData.is_medical_record === true
				// 			? {
				// 					value: true,
				// 					label: t('travelReservationForm:yes'),
				// 			  }
				// 			: participantData.is_medical_record === false
				// 			? {
				// 					value: false,
				// 					label: t('travelReservationForm:no'),
				// 			  }
				// 			: {
				// 					value: null,
				// 					label: '',
				// 			  },
				// },
				// specialDiseaseNote: {
				// 	...templateData.isMedicalRecord,
				// 	value: participantData.is_medical_record_note || '',
				// },
				// receiptType: {
				// 	...templateData.receiptType,
				// 	value:
				// 		participantData.receiptType === 'B2C'
				// 			? { label: t('B2C'), value: 'B2C' }
				// 			: { label: t('B2B'), value: 'B2B' },
				// },
				// companyNo: {
				// 	...templateData.companyNo,
				// 	value: participantData.company_no || '',
				// },
				// companyTitle: {
				// 	...templateData.companyTitle,
				// 	value: participantData.company_title || '',
				// },
				country: {
					...templateData.country,
					value: participantData.country,
				},
				city: {
					...templateData.city,
					value: participantData.city,
				},
				address: {
					...templateData.address,
					value: participantData.address,
				},
				emergencyName: {
					...templateData.emergencyName,
					value: participantData.contact_name,
				},
				emergencyRelation: {
					...templateData.emergencyRelation,
					value: {
						label: t(`contactRelationship:${participantData.contact_relationship}`) || '',
						value: participantData.contact_relationship || '',
					},
				},
				emergencyPhone: {
					...templateData.emergencyPhone,
					value: {
						intIdNum: participantData.contact_phone_country_code,
						phoneNum: participantData?.contact_phone,
					},
				},
				// 	...templateData.isOwnBicycle,
				// 	value: {
				// 		label: participantData.is_own_bicycle,
				// 		value: participantData.is_own_bicycle,
				// 	},
				// },
				// roommate: {
				// 	...templateData.roommate,
				// 	value: participantData.roommate_name,
				// },
				// roomPrice: {
				// 	...templateData.roomPrice,
				// 	value: participantData.room.price,
				// },
				// custom1: {
				// 	...templateData.custom1,
				// 	value: participantData.extra_field_1,
				// },
				// custom2: {
				// 	...templateData.custom2,
				// 	value: participantData.extra_field_2,
				// },
				// custom3: {
				// 	...templateData.custom3,
				// 	value: participantData.extra_field_3,
				// },
				parentConsent: {
					...templateData.parentConsent,
					value: {
						url: participantData.parent_consent,
						filename: participantData.parent_consent?.split('/').pop() || '',
					},
				},
				elderliesConsent: {
					...templateData.elderliesConsent,
					value: {
						url: participantData.elder_consent,
						filename:
							participantData.elder_consent &&
							(participantData.elder_consent.split('/').pop() || ''),
					},
				},
			},
		} as unknown) as SetupParticipantFormByTravelOrderPayload;
	},
);

/* -------------------------------------------------------------------------------------------------------------------- */
/**
 * 拿回 travel data 後依資料更改必填需求，以及初始化參加者欄位。
 *
 * ex. 讓欄位的需求改為必填
 */
export const setupTravelParticipantTemplateProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	GiantTravelRouteType
>('SETUP_TRAVEL_PARTICIPANT_TEMPLATE_PROCESS', () => async (dispatch: Dispatch) => {
	// 範例填寫如下，因應未來需求加入
	// apply_type 報名項目有回傳資料時，設定為必填。
	// dispatch(
	// 	changeParticipantFormTemplate({
	// 		key: 'applyType',
	// 		data: {
	// 			required: (data?.additionalsByProductId.applyTypes || []).length >= 1,
	// 		},
	// 	}),
	// );

	// 初始化參加者表單
	dispatch(initTravelParticipantForm());
});

/* -------------------------------------------------------------------------------------------------------------------- */

/**
 * 使用情境：後台開啟管理員加價購頁面，把後台資料打回表單時使用
 * 將已經切分完的參加者(participant)的加價購項目，重新組合成加價購表單編輯所需的格式
 */
export const setupParticipantFormByTravelOrderAddPurchase = createAction(
	'SETUP_PARTICIPANT_FORM_BY_TRAVEL_ORDER_ADD_PURCHASE',
	(id: number) => (_: Dispatch, getState: GetState) => {
		const {
			travelOrder: {
				adminTravelOrderAddPurchase: { data },
			},
		} = getState();

		const addPurchaseData = data.find(item => item.id === id);

		return {
			id,
			type: GiantTravelRoute.travel,
			form: {
				name: addPurchaseData?.name,
				products: addPurchaseData?.products.map(item => ({
					id: item.id,
					type: item.type,
					stockId: item.stock_id,
					colorId: item.color_id,
					specId: item.size_id,
					productId: item.product_id,
					name: item.name,
					color: item.color,
					size: item.size,
					chargeMethod: item.charge_method,
					amount: item.amount,
					totalPrice: item.totalPrice,
				})),
				services: addPurchaseData?.services.map(item => ({
					id: item.id,
					type: item.type,
					stockId: item.stock_id,
					colorId: item.color_id,
					specId: item.size_id,
					productId: item.product_id,
					name: item.name,
					color: item.color,
					size: item.size,
					chargeMethod: item.charge_method,
					amount: item.amount,
					totalPrice: item.totalPrice,
				})),
				gifts: addPurchaseData?.gifts.map(item => ({
					id: item.id,
					type: item.type,
					stockId: item.stock_id,
					colorId: item.color_id,
					specId: item.size_id,
					productId: item.product_id,
					name: item.name,
					color: item.color,
					size: item.size,
					chargeMethod: item.charge_method,
					amount: item.amount,
					totalPrice: item.totalPrice,
				})),
				bicycle: addPurchaseData?.bicycles?.map(item => ({
					id: item.id,
					type: item.type,
					stockId: item.stock_id,
					colorId: item.color_id,
					specId: item.size_id,
					productId: item.product_id,
					name: item.name,
					color: item.color,
					size: item.size,
					chargeMethod: item.charge_method,
					amount: item.amount,
					totalPrice: item.totalPrice,
				})),
			},
		};
	},
);

/* -------------------------------------------------------------------------------------------------------------------- */

/**
 * 使用情境：後台開啟管理員加價購頁面，編輯加價購表單時使用
 */
export const changeParticipantFormByAdminTravelOrderAddPurchase = createAction<
	{ key: string; data: FormDataMapping<TravelFormDataType>[] },
	{ key: string; data: FormDataMapping<TravelFormDataType>[] }
>('CHANGE_PARTICIPANT_FORM_BY_ADMIN_TRAVEL_ORDER_ADD_PURCHASE', ({ key, data }) => ({ key, data }));

/* -------------------------------------------------------------------------------------------------------------------- */

export const reducer = {
	travelParticipant: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_TRAVEL_PARTICIPANT: () => ({
				...initialState,
			}),

			SET_TRAVEL_PARTICIPANT_TYPE_ID: (state, action: Action<FormTypeIdPayload>) => ({
				...state,
				travel: {
					...state.travel,
					type: action.payload.eventType,
					...(action.payload.travelId !== undefined && {
						id: action.payload.travelId,
					}),
				},
				form: {
					...state.form,
					template: formTemplates[action.payload.eventType],
				},
			}),

			CHANGE_TRAVEL_PARTICIPANT_FORM_TEMPLATE: <T extends TravelParticipantFormType>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
				action: Action<FormChangePayload<T>>,
			) => ({
				...state,
				form: {
					...state.form,
					template: {
						...state.form.template,
						[action.payload.key]: {
							...state.form.template[action.payload.key],
							...action.payload.data,
						},
					},
				},
			}),

			INIT_TRAVEL_PARTICIPANT_FORM: state => ({
				...state,
				form: {
					...state.form,
					ids: [],
					byIds: {},
					editing: {
						...state.form.editing,
						id: null,
						data: state.form.template,
					},
				},
			}),

			INIT_TRAVEL_EDITING_PARTICIPANT_FORM: state => ({
				...state,
				form: {
					...state.form,
					editing: {
						...state.form.editing,
						data: state.form.template,
					},
				},
			}),

			CHANGE_TRAVEL_PARTICIPANT_FORM: <T extends TravelParticipantFormType>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
				action: Action<FormChangePayload<T>>,
			) => ({
				...state,
				form: {
					...state.form,
					editing: {
						...state.form.editing,
						data: {
							...state.form.editing.data,
							[action.payload.key]: {
								...state.form.editing.data[action.payload.key],
								...action.payload.data,
							},
						},
					},
				},
			}),

			CHANGE_PARTICIPANT_ADDITIONAL: (
				state,
				action: Action<ChangeParticipantAdditionalPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					...(state.form.editing.data !== null && {
						editing: {
							...state.form.editing,

							data: {
								...state.form.editing.data,
								[action.payload.key]: {
									...state.form.editing.data[action.payload.key],

									value: {
										...(action.payload.key === 'applyType'
											? { [action.payload.data.productId]: action.payload.data }
											: {
													...state.form.editing.data[action.payload.key]?.value,
													[action.payload.data.productId]: action.payload.data,
											  }),
									},
								},
							},
						},
					}),
				},
			}),

			REMOVE_PARTICIPANT_FORM: (state, action: Action<FormData['id']>) => ({
				...state,

				form: {
					...state.form,
					ids: state.form.ids.filter(id => id !== action.payload),

					editing: {
						id: null,
						data: null,
					},
				},
			}),

			SET_TRAVEL_PARTICIPANT_FORM_EDITABLE: (state, action: Action<number | undefined>) => ({
				...state,
				form: {
					...state.form,
					editing: {
						...state.form.editing,
						id: action.payload === undefined ? null : action.payload,
						data:
							action.payload === undefined ? state.form.template : state.form.byIds[action.payload],
					},
				},
			}),

			COMPLETE_TRAVEL_PARTICIPANT_FORM_EDIT: (state, action: Action<number>) => ({
				...state,
				form: {
					...state.form,
					editing: {
						id: null,
						data: null,
					},
					ids:
						state.form.editing.id === null ? [...state.form.ids, action.payload] : state.form.ids,
					byIds: {
						...state.form.byIds,
						[state.form.editing.id === null ? action.payload : state.form.editing.id]: {
							...(state.form.editing.data as FormData<TravelParticipantFormType>['form']),
						},
					},
				},
			}),

			SETUP_PARTICIPANT_FORM_BY_TRAVEL_ORDER: (
				state,
				action: Action<SetupParticipantFormByTravelOrderPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					ids: [...state.form.ids, action.payload.id],
					byIds: { ...state.form.byIds, [action.payload.id]: action.payload.form },
				},
			}),
			SETUP_PARTICIPANT_FORM_BY_CHANGE_ITINERARY: (
				state,
				action: Action<SetupParticipantFormByTravelOrderPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					ids: [...state.form.ids, action.payload.id],
					byIds: { ...state.form.byIds, [action.payload.id]: action.payload.form },
				},
			}),

			SETUP_PARTICIPANT_FORM_BY_TRAVEL_ORDER_ADD_PURCHASE: (
				state,
				action: Action<SetupParticipantFormByTravelOrderPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					ids: [...state.form.ids, action.payload.id],
					byIds: {
						...state.form.byIds,
						[action.payload.id]: action.payload.form,
					},
				},
			}),

			CHANGE_PARTICIPANT_FORM_BY_ADMIN_TRAVEL_ORDER_ADD_PURCHASE: (
				state,
				action: Action<{ key: string; data: FormDataMapping<TravelFormDataType>[] }>,
			) => ({
				...state,
				form: {
					...state?.form,
					editing: {
						...state?.form?.editing,
						data: {
							...state?.form?.editing?.data,
							[action.payload.key]: action.payload.data,
						},
					},
				},
			}),

			// UPDATE_PARTICIPANT_PRIMARY_STATUS: (
			// 	state,
			// 	action: Action<UpdateParticipantPrimaryStatusPayload>,
			// ) => ({
			// 	...state,
			// 	form: {
			// 		...state.form,
			// 		byIds: action.payload.data,
			// 	},
			// }),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTravelParticipantTypeId ++
++----------------------------------------------------------------------*/
const selectTravel = (state: GlobalState) => state.travelParticipant.travel;
const travelActionsMap = {
	clearTravelParticipant,
	setTravelTypeId,
	// initForm: initTravelParticipantForm,
};

export const useTravelParticipantTypeId = () =>
	useRedux<ReturnType<typeof selectTravel>, typeof travelActionsMap>(
		selectTravel,
		travelActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useTravelParticipantForm ++
++----------------------------------------------------------------------*/
const selectForm = (state: GlobalState) => state.travelParticipant.form;
const formActionsMap = {
	clearTravelParticipant,
	changeFormTemplate: changeParticipantFormTemplate,
	removeForm: removeParticipantForm,
	changeForm: changeParticipantForm,
	changeParticipantAdditional,
	syncTravelParticipantFormByContactInfo,
	setupParticipantFormByTravelOrder,
	setupParticipantFormByChangeItinerary,
	setParticipantFormEditable,
	completeParticipantFormEdit,
	setupTravelParticipantTemplateProcess,
	syncTravelParticipantFormByUserProfile,
	initTravelParticipantForm,
	initTravelEditingParticipantForm,
	setupParticipantFormByTravelOrderAddPurchase,
	changeParticipantFormByAdminTravelOrderAddPurchase,
};

export const useTravelParticipantForm = () =>
	useRedux<ReturnType<typeof selectForm>, typeof formActionsMap>(selectForm, formActionsMap);
