export type NormalizedData<D, K extends PropertyKey = PropertyKey> = {
	ids: K[];
	byIds: Record<K, D>;
};

/**
 * normalize 由物件組成的陣列資料
 *
 * @template T 參數 data 陣列中單一物件的型別（介面）。
 * @param {T[]} data data 所欲 normalize 的陣列資料。
 * @param {keyof T} uIdKey uIdKey data 陣列中單一物件的某個 key name，作為提值的 ID。
 * @return {*} 一個物件，包括 ids 和 byIds。 ids 為從 uIdKey 中提值的陣列； byIds 是以 uIdKey 為 key name 所組成的物件。
 */
export const arrayNormalizer = <T>(data: T[], uIdKey: keyof T): NormalizedData<T> => {
	return data.reduce(
		(a, v) => ({
			ids: [...a.ids, (v[uIdKey] as unknown) as PropertyKey],
			byIds: { ...a.byIds, [(v[uIdKey] as unknown) as PropertyKey]: v },
		}),
		({ ids: [], byIds: {} } as unknown) as {
			ids: PropertyKey[];
			byIds: Record<T[keyof T] extends PropertyKey ? T[keyof T] : PropertyKey, T>;
		},
	);
};
