import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import DetailRoute from './Detail';

const routes: CustomRoute = {
	path: appPath.accountJourneys.replace(appPath.account, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'accountJourneys' */ './component')],
			render: ([AccountJourneys]) => <AccountJourneys />,
		},
		DetailRoute,
	],
};

export default routes;
