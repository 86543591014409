import { FormDataMapping } from 'types/FormDataMapping';
// import { RelationshipCodes } from 'types/Relationship';

import { State as ParticipantState } from 'models/travelParticipant';
import { State as ProductState } from 'models/product';
import { ProductItemWithAmount } from 'models/product/type';
import { TravelFormDataType } from 'models/travelParticipant/type';
import { PaymentTypeForm } from 'models/payment';

import { nullToUndefined, removeCommas } from 'util/helper';
import calcParticipantProductAmount from 'util/functions/calcParticipantProductAmount';

import { TravelOrderRequestBody } from 'api/travelOrder';

import { DynamicState } from 'types/DynamicState';
import { ReservationTravelData } from 'models/travel';
import { TravelFormFieldData, ShoppingItemData } from './type';

export const travelReservationFormDataMapping = (
	formData: FormDataMapping<TravelFormFieldData>,
	payMethodByParticipant: PaymentTypeForm,
) => ({
	first_name: formData.firstName.value,
	last_name: formData.lastName.value,
	identity_no: formData.citizenId.value,
	gender: nullToUndefined(formData.gender.value.value),
	birthday: formData.birthday.value?.format('YYYY-MM-DD'),
	cellphone: formData.phone.value
		? `${formData.phone.value.intIdNum} ${formData.phone.value.phoneNum}`
		: '',
	email: formData.email.value,
	payment_type: payMethodByParticipant.paymentType.value.value,
});

export const participantTravelDataMapping = (
	pIds: ParticipantState['form']['ids'],
	pByIds: { [id: number]: FormDataMapping<TravelFormDataType> },
): Partial<TravelOrderRequestBody> => ({
	participants: pIds.map(pId => ({
		first_name: pByIds[pId].firstName.value,
		last_name: pByIds[pId].lastName.value,
		en_first_name: pByIds[pId].firstNameEn.value,
		en_last_name: pByIds[pId].lastNameEn.value,
		nationality: nullToUndefined(pByIds[pId].citizenship.value.value),
		identity_no: pByIds[pId].citizenId.value,
		passport: pByIds[pId].passport.value,
		passport_expiration_date: pByIds[pId].passportExpirationDate?.value?.format('YYYY-MM-DD'),
		gender: nullToUndefined(pByIds[pId].sex.value.value),
		birthday: nullToUndefined(pByIds[pId].birthday.value.format('YYYY-MM-DD')),
		height:
			typeof Number(pByIds[pId].height?.value) === 'number' ? Number(pByIds[pId].height.value) : 0,
		weight:
			typeof Number(pByIds[pId].weight?.value) === 'number' ? Number(pByIds[pId].weight?.value) : 0,
		phone: `${pByIds[pId].phone.value.intIdNum} ${pByIds[pId].phone.value.phoneNum}`, // 待改
		home_phone: `${pByIds[pId].phone.value.intIdNum} ${pByIds[pId].phone?.value.phoneNum}`, // 待改 nullable
		email: pByIds[pId].email.value,
		country: pByIds[pId].country?.value,
		zip: pByIds[pId].zip?.value,
		state: pByIds[pId].state?.value,
		city: pByIds[pId].city?.value,
		address: pByIds[pId].address?.value,
		elderlies_consent: pByIds[pId].elderliesConsent?.value?.filename,
		parent_consent: pByIds[pId].parentConsent?.value?.filename,
		contact_name: pByIds[pId].emergencyName.value,
		contact_relationship: nullToUndefined(pByIds[pId].emergencyRelation.value.value),
		contact_phone: `${pByIds[pId].emergencyPhone.value.intIdNum} ${pByIds[pId].emergencyPhone.value.phoneNum}`,
		room_id: nullToUndefined(pByIds[pId].stayRoom.value),
		roommate_name: pByIds[pId].roommate?.value,
		is_own_bicycle: nullToUndefined(pByIds[pId].isOwnBicycle?.value.value),
		eating_habit: nullToUndefined(pByIds[pId].eatingHabit.value.value),
		eating_habit_note: pByIds[pId].eatingHabitNote?.value,
		is_medical_record: nullToUndefined(pByIds[pId].isMedicalRecord.value.value),
		medical_record_note: pByIds[pId].medicalRecordNote?.value
			? pByIds[pId].medicalRecordNote?.value
			: '',
		demand: pByIds[pId].demand.value,
		extra_field_1: pByIds[pId].custom1?.value,
		extra_field_2: pByIds[pId].custom2?.value,
		extra_field_3: pByIds[pId].custom3?.value,
		receiptType: nullToUndefined(pByIds[pId].receiptType.value.value),
		company_title: pByIds[pId].companyTitle?.value,
		company_no: pByIds[pId].companyNo?.value,
		isPrimary: pByIds[pId].isPrimary.value,
		// apply_type_id:
		// 	Object.values(pByIds[pId].applyType.value).length > 0
		// 		? Object.values(pByIds[pId].applyType.value)[0].stockId
		// 		: undefined,
		gifts: [
			...Object.values(pByIds[pId].giveaways?.value)
				.filter(d => d.amount > 0)
				.map(d => d.stockId),
		],
		products: [
			...Object.values(pByIds[pId].productPurchase?.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
			...Object.values(pByIds[pId].servicePurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
			...Object.values(pByIds[pId].rentalBicycle.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
		].filter(product => product.amount !== 0),
	})),
});

/**
 * 計算參加者的加價購明細 - 因應 UI 需求而棄用，但扣的部分暫時保留
 *
 * @param participantIds
 * @param participantById
 * @param productDataById
 * @returns
 */
// export const calcParticipantAdditionalItems = (
// 	participantIds: ParticipantState['form']['ids'],
// 	participantById: ParticipantState['form']['byIds'],
// 	productDataById: ProductState['dataById'],
// 	reservationTravelInfo?: DynamicState<ReservationTravelData | null>['data'],
// ) => {
// 	const { products: productByPid, colors: colorByCid, stocks: stockBySid } = productDataById;

// 	/** 計算參加者的加價購資料，回傳以 stock ID 為 key 的 Hash Table。 */
// 	const participantAdditionalData = {
// 		...calcParticipantProductAmount({
// 			data: participantIds.reduce(
// 				(a, pId) => [
// 					...a,
// 					participantById[pId].applyType?.value || {}, // 每一個參加者的報名項目
// 					participantById[pId].giveaways?.value || {}, // 每一個參加者的贈品
// 					participantById[pId].productPurchase?.value || {}, // 每一個參加者的商品加價購
// 					participantById[pId].servicePurchase?.value || {}, // 每一個參加者的服務加價購
// 					participantById[pId].rentalBicycle?.value || {}, // 每一個參加者的租賃腳踏車
// 				],
// 				[] as { [productId: number]: ProductItemWithAmount }[],
// 			),
// 			key: 'stockId',
// 		}),
// 	};

// 	const participantAdditionalPurchaseData = participantIds.map(pId => ({
// 		...participantById[pId].productPurchase?.value,
// 		...participantById[pId].servicePurchase?.value,
// 		...participantById[pId].rentalBicycle?.value,
// 		...participantById[pId].giveaways?.value,
// 		participantName: `${participantById[pId].firstName.value}${participantById[pId].lastName.value}`,
// 		roomName:
// 			reservationTravelInfo?.rooms.find(room => room.id === participantById[pId].stayRoom.value)
// 				?.name || '',
// 	})) as Array<ProductItemWithAmount & { participantName: string; roomName: string }>;

// 	console.log(
// 		'🚀 ~ participantAdditionalPurchaseData ~ participantAdditionalPurchaseData:',
// 		participantAdditionalPurchaseData,
// 	);

// 	// const participantAdditionalPurchaseTransformData = participantAdditionalPurchaseData.map(d => ({
// 	// 	id: d.stockId,
// 	// 	name: productByPid[d.productId].name,
// 	// 	categoryName: productByPid[d.productId].description || '',
// 	// 	colorName: colorByCid[d.colorId]?.colorName || '',
// 	// 	specName: productByPid[d.productId].sizeTitle || '',
// 	// 	participantName: d.participantName,
// 	// 	roomName: d.roomName,
// 	// }));
// 	// console.log('🚀 ~ finally~ :', participantAdditionalPurchaseTransformData);

// 	/** 計算參加者的加價購明細 */
// 	const participantAdditionalItems: ShoppingItemData[] = Object.values(
// 		participantAdditionalData,
// 	).map(data => ({
// 		id: data.stockId,
// 		name: productByPid[data.productId].name,
// 		categoryName: productByPid[data.productId].description || '',
// 		colorName: colorByCid[data.colorId]?.colorName || '',
// 		specName: productByPid[data.productId].sizeTitle || '',
// 		stock: stockBySid[data.stockId].stock,
// 		amount: data.amount,
// 		price: productByPid[data.productId].price * data.amount, // 參加者的加價購計入總金額
// 	}));

// 	return participantAdditionalItems;
// };

export interface TravelStepOrderPurchaseItem {
	id: string;
	productName: string;
	participantName: string;
	amount: number;
	price: number;
	size?: string;
	color?: string;
	description?: string;
}

/**
 * 計算參加者的加價購明細
 *
 * @param participantIds
 * @param participantById
 * @param productDataById
}
 */
export const calcParticipantAdditionalPurchaseItems = (
	participantIds: ParticipantState['form']['ids'],
	participantById: ParticipantState['form']['byIds'],
	productDataById: ProductState['dataById'],
	reservationTravelInfo?: DynamicState<ReservationTravelData | null>['data'],
) => {
	const result = {
		roomInfo: [] as TravelStepOrderPurchaseItem[],
		additionalPurchaseBike: [] as TravelStepOrderPurchaseItem[],
		giveaway: [] as TravelStepOrderPurchaseItem[],
		additionalPurchaseProducts: [] as TravelStepOrderPurchaseItem[],
	};

	const createMappingItem = (
		pId: string,
		participant: FormDataMapping<TravelFormDataType>,
		value: {
			[productId: number]: ProductItemWithAmount;
		},
	) => {
		const { amount = 0 } = value[+pId];

		return {
			id: pId,
			productName: productDataById.products[+pId].name,
			participantName: participant.firstName.value + participant.lastName.value,
			amount: amount || 0,
			price: productDataById.products[+pId]?.price || 0,
			description: productDataById.products[+pId]?.description || '',
			size: productDataById.products[+pId]?.sizeTitle || '',
			color: productDataById.colors[+pId]?.colorName || '',
		};
	};

	participantIds
		.map(pId => participantById[pId])
		.forEach(participant => {
			const {
				giveaways: { value: giveawayValue },
				productPurchase: { value: productValue },
				rentalBicycle: { value: rentalBicycleValue },
				roomPrice: { value: roomPrice },
				stayRoom: { value: stayRoomId },
				servicePurchase: { value: servicePurchaseValue },
			} = participant;

			Object.keys(giveawayValue).forEach(pId => {
				result.giveaway.push(createMappingItem(pId, participant, giveawayValue));
			});

			Object.keys(productValue).forEach(pId => {
				result.additionalPurchaseProducts.push(createMappingItem(pId, participant, productValue));
			});

			Object.keys(rentalBicycleValue).forEach(pId => {
				result.additionalPurchaseBike.push(createMappingItem(pId, participant, rentalBicycleValue));
			});

			Object.keys(servicePurchaseValue).forEach(pId => {
				result.additionalPurchaseBike.push(
					createMappingItem(pId, participant, servicePurchaseValue),
				);
			});

			const roomData = {
				id: participant.firstName.value + participant.lastName.value,
				productName: reservationTravelInfo?.rooms.find(room => room.id === stayRoomId)?.name || '',
				participantName: participant.firstName.value + participant.lastName.value,
				amount: 1,
				price: +removeCommas(roomPrice),
				size: '',
				description: '',
				color: '',
			};

			result.roomInfo.push(roomData);
		});

	const totalPrice = Object.values(result).reduce((total, category) => {
		return (
			// eslint-disable-next-line prettier/prettier
			total + category.reduce((initialPrice, item) => initialPrice + (item.price * item.amount), 0)
		);
	}, 0);

	return { result, totalPrice };
};

/**
 * 加總所有參加者的房費 - 因應 UI 需求而棄用，但扣的部分暫時保留
 *
 * @param participantIds
 * @param participantById
 * @returns totalRoomPrice
 */
// export const calcParticipantRoomPrice = (
// 	participantIds: ParticipantState['form']['ids'],
// 	participantById: ParticipantState['form']['byIds'],
// ): number => {
// 	const totalRoomPrice = participantIds.reduce((totalPrice, pId) => {
// 		const { roomPrice } = participantById[pId];

// 		// 後端給的 price 會有 number\string 故作此判斷
// 		if (roomPrice.value) {
// 			const price =
// 				typeof roomPrice.value === 'string'
// 					? parseInt(removeCommas(roomPrice.value), 10)
// 					: roomPrice.value;
// 			return totalPrice + price;
// 		}

// 		return totalPrice;
// 	}, 0);

// 	return totalRoomPrice;
// };
