/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { LocationData } from 'types/LocationData';

import { useRouting } from 'models/routing';
import { useLocale, globalLocal } from 'models/locale';

import DropdownArrow from 'components/atoms/DropdownArrow';

import styles from './index.css';

interface ButtonLocaleProperty {
	className?: string;
	color?: 'default' | 'white';
}

const ButtonLocale: React.FC<ButtonLocaleProperty> = ({ className, color = 'default' }) => {
	const { t } = useTranslation('locales');
	const [
		{
			currentLocCode,
			locales: { data: locales },
			isGlobal,
		},
	] = useLocale();

	const [, { pushRoute }] = useRouting();

	const onClickHandler = (nextLoc: LocationData, closePanel = () => {}) => () => {
		pushRoute({ loc: nextLoc.code });

		closePanel();
	};

	return (
		<DropdownArrow
			offset={[16, 16]}
			triggerClassName={classnames(styles.buttonLocale, styles[color], className)}
			contentClassName={styles.buttonLocaleContent}
			contentComponent={({ close }) =>
				// 地區選項超過一個，才顯示下拉選單。
				locales.codes.length > 1 ? (
					<div className={styles.buttonLocaleSelection}>
						<div
							className={classnames(styles.option, { [styles.active]: isGlobal })}
							role="button"
							tabIndex={0}
							onKeyPress={() => {}}
							onClick={onClickHandler(globalLocal, close)}
						>
							{t('global')}
						</div>
						{locales.codes
							.filter(code => code !== globalLocal.code)
							.map(code => {
								return (
									<div
										key={code}
										className={classnames(styles.option, {
											[styles.active]: code === currentLocCode,
										})}
										role="button"
										tabIndex={0}
										onKeyPress={() => {}}
										onClick={onClickHandler(locales.byCode[code], close)}
									>
										{locales.byCode[code].name}
									</div>
								);
							})}
					</div>
				) : null
			}
		>
			{isGlobal ? t('global') : locales.byCode[currentLocCode].name}
		</DropdownArrow>
	);
};

export default ButtonLocale;
