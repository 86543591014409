import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { DynamicState } from 'types/DynamicState';

import { useRedux } from 'util/hook/redux';
import { initDynamicState } from 'util/helper';

import {
	NoticeType,
	NoticeCode,
	NoticeCheckBoxData,
	NoticeContentData,
	fetchNoticeFunc,
	fetchNoticeChecklistFunc,
	fetchContactFormChecklistFunc,
} from 'api/notice';

import { GetState, State as GlobalState } from './reducers';

interface CheckBox extends NoticeCheckBoxData {
	is_checked: boolean;
}

interface NoticeContent {
	loading: boolean;
	error: string;
	data: NoticeContentData;
}

export const getStandardizedContractContent = createAction(
	'GET_STANDARDIZED_CONTRACT_CONTENT',
	async (type: NoticeType, code: NoticeCode) => {
		try {
			const { status, message, data } = await fetchNoticeFunc(type, code);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data };
		} catch (error) {
			return {
				data: {},
			};
		}
	},
);

export const getNoticeContent = createAction(
	'GET_NOTICE_CONTENT',
	async (type: NoticeType, code: NoticeCode) => {
		try {
			const { status, message, data } = await fetchNoticeFunc(type, code);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data };
		} catch (error) {
			return {
				data: {},
			};
		}
	},
);

export const getDisclaimer = createAction('GET_DISCLAIMER', async (type: NoticeType) => {
	try {
		const { status, message, data } = await fetchNoticeFunc(type, 'DISCLAIMER');

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data };
	} catch (error) {
		return {
			data: {},
		};
	}
});

export const getCheckList = createAction('GET_NOTICE_CHECKLIST', async (type: NoticeType) => {
	try {
		const { status, message, data: checkBoxes } = await fetchNoticeChecklistFunc(type);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data: checkBoxes.map(d => ({ ...d, is_checked: false })) };
	} catch (error) {
		return {
			data: {},
		};
	}
});

export const getContactFormCheckList = createAction('GET_CONTACT_FORM_CHECKLIST', async () => {
	try {
		const { status, message, data: checkBoxes } = await fetchContactFormChecklistFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data: checkBoxes.map(d => ({ ...d, is_checked: false })) };
	} catch (error) {
		return {
			data: {},
		};
	}
});

const setCheckListChecked = createAction('SET_NOTICE_IS_CHECKLIST_CHECKED');

export const setCheckboxChecked = createAction(
	'SET_NOTICE_IS_CHECKBOX_CHECKED',
	(index: number) => (dispatch: Dispatch, getState: GetState) => {
		const {
			notice: { checkList, contactFormCheckList },
		} = getState();
		const checkBoxes =
			checkList.data.length > 0 ? [...checkList.data] : [...contactFormCheckList.data];

		checkBoxes[index].is_checked = !checkBoxes[index].is_checked;

		for (let i = 0; i < checkBoxes.length; i += 1) {
			if (!checkBoxes[i].is_checked) {
				if (checkList.isChecked) {
					dispatch(setCheckListChecked());
				}
				break;
			}
			if (i + 1 === checkBoxes.length) {
				dispatch(setCheckListChecked());
			}
		}

		return { data: checkBoxes };
	},
);

export interface State {
	disclaimer: NoticeContent;
	content: DynamicState<NoticeContentData>;
	standardizedContract: DynamicState<NoticeContentData>;
	checkList: {
		loading: boolean;
		error: string;
		data: CheckBox[];
		isChecked: boolean;
	};
	contactFormCheckList: {
		loading: boolean;
		error: string;
		data: CheckBox[];
		isChecked: boolean;
	},
}

const initialState: State = {
	disclaimer: {
		loading: false,
		error: '',
		data: { id: 0, type: 'contact_form', code: 'DISCLAIMER', content: '' },
	},
	content: initDynamicState({ id: 0, type: 'rental', code: 'REGISTRATION_CONTRACT', content: '' }),
	standardizedContract: initDynamicState({
		id: 0,
		type: 'travel',
		code: 'NATIONAL_TOURS',
		content: '',
	}),
	checkList: {
		loading: false,
		error: '',
		data: [],
		isChecked: false,
	},
	contactFormCheckList: {
		loading: false,
		error: '',
		data: [],
		isChecked: false,
	},
};

export const reducer = {
	notice: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_NOTICE_CONTENT_PENDING: state => ({
				...state,
				content: {
					...state.content,
					loading: true,
				},
			}),
			GET_NOTICE_CONTENT_FULFILLED: (state, action) => ({
				...state,
				content: {
					data: action.payload.data,
					error: '',
					loading: false,
				},
			}),
			GET_STANDARDIZED_CONTRACT_CONTENT_PENDING: state => ({
				...state,
				standardizedContract: {
					...state.standardizedContract,
					loading: true,
				},
			}),
			GET_STANDARDIZED_CONTRACT_CONTENT_FULFILLED: (state, action) => ({
				...state,
				standardizedContract: {
					data: action.payload.data,
					error: '',
					loading: false,
				},
			}),

			GET_DISCLAIMER_PENDING: state => ({
				...state,
				disclaimer: {
					...state.disclaimer,
					loading: true,
				},
			}),
			GET_DISCLAIMER_FULFILLED: (state, action) => ({
				...state,
				disclaimer: {
					data: action.payload.data,
					error: '',
					loading: false,
				},
			}),
			GET_DISCLAIMER_REJECTED: (state, action) => ({
				...state,
				disclaimer: {
					...state.disclaimer,
					error: action.payload.message,
					loading: false,
				},
			}),
			GET_NOTICE_CHECKLIST_PENDING: state => ({
				...state,
				checkList: {
					...state.checkList,
					error: '',
					loading: true,
				},
			}),
			GET_NOTICE_CHECKLIST_FULFILLED: (state, action) => ({
				...state,
				checkList: {
					data: action.payload.data,
					error: '',
					loading: false,
					isChecked: false,
				},
			}),
			GET_NOTICE_CHECKLIST_REJECTED: (state, action) => ({
				...state,
				checkList: {
					...state.checkList,
					error: action.payload.message,
					loading: false,
				},
			}),
			GET_CONTACT_FORM_CHECKLIST_FULFILLED: (state, action) => ({
				...state,
				contactFormCheckList: {
					data: action.payload.data,
					error: '',
					loading: false,
					isChecked: false,
				},
			}),
			SET_NOTICE_IS_CHECKBOX_CHECKED: (state, action) => ({
				...state,
				checkList: {
					...state.checkList,
					data: action.payload.data,
					error: '',
					loading: false,
				},
			}),
			SET_NOTICE_IS_CHECKLIST_CHECKED: state => ({
				...state,
				checkList: {
					...state.checkList,
					isChecked: !state.checkList.isChecked,
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	content: state.notice.content,
	standardizedContent: state.notice.standardizedContract,
	disclaimer: state.notice.disclaimer,
	checkList: state.notice.checkList,
	contactFormCheckList: state.notice.contactFormCheckList,
});

export const useNotice = () =>
	useRedux(mapHooksToState, {
		getNoticeContent,
		getCheckList,
		getDisclaimer,
		setCheckboxChecked,
		getStandardizedContractContent,
		getContactFormCheckList,
	});
