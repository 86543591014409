import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import NoResultIcon from 'images/icon/noresult.inline.svg';

import styles from './index.css';
import Loading from '../Loading';

interface FetchingContentWrapperProperty {
	loading?: boolean;
	isEmpty?: boolean;
	message?: string;
	withBackground?: boolean;
	hideIfEmpty?: boolean;
}

const FetchingContentWrapper: React.FC<FetchingContentWrapperProperty> = ({
	loading = false,
	isEmpty = true,
	message = '',
	withBackground = false,
	hideIfEmpty = false,
	children,
}) => {
	const { t } = useTranslation('common');

	if (loading) {
		console.log('loading');
		return (
			<div
				className={classnames(
					styles.fetchingContentWrapper,
					withBackground && styles.withBackground,
				)}
			>
				<Loading />
			</div>
		);
	}

	if (message || isEmpty) {
		if (message === '' && hideIfEmpty) {
			return null;
		}

		return (
			<div
				className={classnames(
					styles.fetchingContentWrapper,
					withBackground && styles.withBackground,
				)}
			>
				<NoResultIcon />
				<p>{message || t('isEmpty')}</p>
			</div>
		);
	}

	return <>{children}</>;
};

export default FetchingContentWrapper;
