import React from 'react';
import classnames from 'classnames';
import { useSpring, animated } from 'react-spring';

import { useBoolean, useMeasure, usePrevious } from 'util/hook';

import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';

import ArrowIcon from 'images/icon/arrow.inline.svg';

import styles from './index.css';

interface NavItemProperty {
	className?: string;
	actived?: boolean;
	to?: string;
	onClick?: () => void;
	hasData?: boolean;
}

export const NavItem: React.FC<NavItemProperty> = ({
	className,
	to = '',
	actived = false,
	onClick = () => {},
	children,
	hasData,
}) => (
	!hasData ? 
	<Link
		to={to}
		className={classnames(styles.navItem, className, {
			[styles.actived]: actived,
		})}
		onClick={onClick}
	>
		{children}
	</Link>
	: null
);

export interface NaviCollapseProperty {
	className?: string;
	titleClassName?: string;
	title: string;
}

const NaviCollapse: React.FC<NaviCollapseProperty> = ({
	className,
	titleClassName,
	title,
	children,
}) => {
	const [isOpen, { toggle }] = useBoolean({
		defaultBoolean: false,
	});

	const previous = usePrevious<boolean>(isOpen);
	const [{ ref: contentRef }, { height: contentHeight }] = useMeasure<HTMLDivElement>();
	const { opacity, height, transform } = useSpring({
		from: { height: 0, opacity: 0, transform: 'translate3d(10px,0,0)' },
		to: {
			height: isOpen ? contentHeight : 0,
			opacity: isOpen ? 1 : 0,
			transform: `translate3d(${isOpen ? 0 : 10}px,0,0)`,
		},
	});

	return (
		<div className={classnames(styles.naviCollapse, className)}>
			{title && (
				<div
					className={styles.header}
					role="button"
					tabIndex={0}
					onClick={toggle}
					onKeyPress={() => {}}
				>
					<div className={classnames(styles.title, titleClassName)}>{title}</div>
					<Icon src={ArrowIcon} className={classnames(styles.arrow, isOpen && styles.open)} />
				</div>
			)}

			<animated.div
				className={styles.list}
				style={{
					opacity,
					height: isOpen && previous === isOpen ? 'auto' : height,
				}}
			>
				<animated.div style={{ transform }} ref={contentRef}>
					{children}
				</animated.div>
			</animated.div>
		</div>
	);
};

export default NaviCollapse;
