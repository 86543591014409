import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { arrayNormalizer } from 'util/normalizer';
import { MemberCountryCodes, fetchMemberCountryCodesFunc } from 'api/member';

import { State as GlobalState } from './reducers';

export interface State {
	data: {
		codes: string[];
		byCodes: Record<string, MemberCountryCodes>;
	};
	loading: boolean;
	error: string;
}

const initialState: State = {
	data: { codes: [], byCodes: {} },
	loading: false,
	error: '',
};

export const getCountryCodes = createAction<Promise<Partial<State>>>(
	'FETCH_COUNTRY_CODES',
	async () => {
		try {
			const { status, message, data } = await fetchMemberCountryCodesFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			// 選項第一筆需要是臺灣
			const targetIndex = data.findIndex(country => country.code === '+886');
			const [TWCode] = data.splice(targetIndex, 1);
			data.unshift(TWCode);

			const { ids: codes, byIds: byCodes } = arrayNormalizer<MemberCountryCodes>(data, 'name');

			return {
				data: {
					codes: codes as string[],
					byCodes: byCodes as Record<string, MemberCountryCodes>,
				},
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

export const reducer = {
	countryCodes: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			FETCH_COUNTRY_CODES_PENDING: () => ({
				...initialState,
				loading: true,
			}),

			FETCH_COUNTRY_CODES_FULFILLED: (state, action: Action<Partial<State>>) => ({
				...state,
				loading: false,
				...(action.payload.data && {
					data: action.payload.data,
				}),
				...(action.payload.error && {
					error: action.payload.error,
				}),
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useCountryCodes ++
++----------------------------------------------------------------------*/
const selectCountryCodes = (state: GlobalState) => state.countryCodes;

const CountryCodesActionsMap = {
	getCountryCodes,
};

export const useCountryCodes = () =>
	useRedux<ReturnType<typeof selectCountryCodes>, typeof CountryCodesActionsMap>(
		selectCountryCodes,
		CountryCodesActionsMap,
	);
