import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.accountWishList.replace(appPath.account, ''),
	components: () => [import(/* webpackChunkName: 'accountWishList' */ './component')],
	render: ([AccountWishList]) => <AccountWishList />,
};

export default routes;
