import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import ContactRoute from './Contact';

const routes: CustomRoute = {
	path: appPath.about,
	components: () => [],
	render: (_, children) => children,
	children: [ContactRoute],
};

export default routes;
