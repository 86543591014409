import React, { forwardRef, HTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './index.css';

interface HtmlContentProperty extends HTMLAttributes<HTMLDivElement> {
	className?: string;
	withStyle?: boolean;
	content: string;
	preline?: boolean;
}

const HtmlContent = forwardRef<HTMLDivElement, HtmlContentProperty>(
	({ className, preline = true, withStyle = false, content, ...props }, ref) => (
		<div
			ref={ref}
			className={classnames(
				styles.htmlContent,
				preline && styles.preline,
				withStyle && styles.withStyle,
				className,
			)}
			dangerouslySetInnerHTML={{ __html: content }}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	),
);

export default HtmlContent;
