import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

export type ServiceType = 'travel' | 'rental' | 'event' | 'package';

export interface ContactFormData {
	type: ServiceType;
	name: string;
	phone: string;
	email: string;
	title: string;
	content: string;
}

type SendContactFormFunc = (data: ContactFormData) => Promise<FetchResponse<string>>;

export const sendContactFormFunc: SendContactFormFunc = async (data: ContactFormData) => {
	const response = await wrapFetch('contactForm', {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};

type FetchContactInfoFunc = (type: ServiceType) => Promise<FetchResponse<{ contact_info: string }>>;

export const fetchContactInfoFunc: FetchContactInfoFunc = async (type: ServiceType) => {
	const response = await wrapFetch(
		'contactInfo',
		{
			method: 'GET',
		},
		{ type },
	);

	return response;
};
