/* eslint-disable no-param-reassign */
const createPaymentWindow = (): Window | null => {
	// Check if the user is on a mobile device
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	if (isMobile) {
		// For mobile devices, open a new tab instead of a new window
		const safariWindow = window.open('', '_blank');
		return safariWindow;
	}
	// For desktop devices, open a new window with specified dimensions
	const safariWindow = window.open('', 'win', `width=800,height=400,screenX=200,screenY=200`);
	return safariWindow;
};

const openPaymentView = (windowRef: Window | null, htmlString: string) => {
	const winUrl = URL.createObjectURL(new Blob([htmlString], { type: 'text/html' }));
	if (windowRef) {
		windowRef.location.href = winUrl;
	}
};

export { openPaymentView, createPaymentWindow };
