import { combineEpics } from 'redux-observable';

import { fetchLocaleListEpic } from './locale';
import { calcCartAmountByProductEpic, clearRentalReservationEpic } from './rentalReservation';
import { sendEventsRegisterFormSuccessEpic } from './eventsRegisterSearch';
import { giantAuthErrorEpic } from './auth';
import { catchEventOrderErrorEpic } from './eventsReservation';
import { calcEventsRegiterDetailEpic } from './eventsParticipant';
import { catchTravelOrderErrorEpic } from './travelReservation';

export default combineEpics(
	fetchLocaleListEpic,
	calcCartAmountByProductEpic,
	clearRentalReservationEpic,
	calcEventsRegiterDetailEpic,
	sendEventsRegisterFormSuccessEpic,
	giantAuthErrorEpic,
	giantAuthErrorEpic,
	catchEventOrderErrorEpic,
	catchTravelOrderErrorEpic,
);
