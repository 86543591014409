import { FetchResponse } from 'types/FetchResponse';

import { PackageContentData } from 'types/class/PackageContentData';

import { wrapFetch } from 'util/api';

import { PackageFormType } from 'models/deliveryPackingForm';
import { PackageTagCode } from 'models/packages';

export interface PackagesItemProperty {
	id: number;
	title: string;
	description: string;
	tag: string;
	tag_code: string;
	created_at: string;
	image: string;
	content: string;
	package_contents: PackageContentData[];
}

type FetchPackagesFunc = () => Promise<FetchResponse<PackagesItemProperty[]>>;

export const fetchPackagesFunc: FetchPackagesFunc = async () => {
	const response = await wrapFetch('packages', {
		method: 'GET',
	});

	return response;
};

interface PackageStoreRawData {
	id: number;
	country_id: number;
	city_id: number;
	location_id: number;
	name: string;
	address: string;
	latitude: number;
	longitude: number;
	service_tag_codes: PackageTagCode[];
}

type FetchPackageStoresFunc = (
	type: PackageFormType,
	locationId: number,
) => Promise<FetchResponse<{ stores: PackageStoreRawData[] }>>;

export const fetchPackageStoresFunc: FetchPackageStoresFunc = async (type, locationId) => {
	const response = await wrapFetch(
		`packages/${
			{
				packing: 'PACKAGE_PACK',
				assembly: 'PACKAGE_ASSEMBLY',
				depart: 'PACKAGE_SHIPPING',
				arrival: 'PACKAGE_SHIPPING',
			}[type]
		}/${locationId}`,
		{
			method: 'GET',
		},
	);

	return response;
};

export interface PackageImageUploadResponseData {
	url: string;
	filename: string;
	original_filename: string;
}

type SendPackageImageFunc = (
	data: FormData,
) => Promise<FetchResponse<PackageImageUploadResponseData>>;

export const sendPackageImageFunc: SendPackageImageFunc = async (data: FormData) => {
	const response = await wrapFetch(
		'imageUpload',
		{
			method: 'POST',
			body: data,
		},
		{},
		{ isFormData: true },
	);

	return response;
};

export interface PackageOrderRequestBody {
	first_name: string;
	last_name: string;
	en_first_name: string;
	en_last_name: string;
	phone: string;
	email: string;
	pack_store_id: number;
	pack_date: string | null;
	assemble_store_id: number;
	assemble_date: string | null;
	depart_store_id: number;
	depart_date: string | null;
	arrive_store_id: number;
	arrive_date: string | null;
	customer_note: string;
	bicycles: {
		bicycle_category_id: number | null;
		image_paths: (string | null)[];
	}[];
	product_stock_ids: number[];
}

type SendPackageFormFunc = (data: PackageOrderRequestBody) => Promise<FetchResponse<string>>;

export const sendPackageFormFunc: SendPackageFormFunc = async (data: PackageOrderRequestBody) => {
	const response = await wrapFetch('packageOrder', {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};
