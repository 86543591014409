/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, handleActions, Action } from 'redux-actions';

import { SlideProperty } from 'components/molecules/SliderBanner';

import { Coordinate } from 'types/Coordinate';

import { useRedux } from 'util/hook/redux';
import formatToRentalStore from 'util/functions/formatToRentalStore';

import { RouteCardDataProperty } from 'models/rentalRoutes';

import { TagData } from 'api/tag';
import {
	StoresSearchData,
	fetchStoresSearchFunc,
	fetchStoresDestinationsFunc,
	fetchStoresSingleFunc,
} from 'api/store';
import { RecommendedBicycle } from 'api/bicycle';

import { State as GlobalState } from './reducers';

export interface MainMapProperty {
	defaultZoom: number;
	location: Coordinate;
}

export type StoreItemProperty = Pick<StoresSearchData, 'id' | 'name' | 'address'> & {
	time: string;
	email: StoresSearchData['emails'];
	phone: StoresSearchData['telephone'];
	imageUrl: StoresSearchData['first_banner_image_url'];
	links: {
		name: string;
		href: StoresSearchData['facebook'] | StoresSearchData['instagram'];
	}[];
};

export interface StoreListProperty {
	[key: string]: StoreItemProperty[];
}

export type StoreCardDataProperty = Pick<
	StoreItemProperty,
	'name' | 'time' | 'address' | 'imageUrl' | 'links'
>;

export type StoreMapCardDataProperty = Pick<
	StoreDetailProperty,
	'name' | 'address' | 'phone' | 'email' | 'imageUrl'
> & { time: string };

type SearchStoreProperty = {
	time: string;
	cityId: StoresSearchData['city_id'];
	imageUrl: StoresSearchData['first_banner_image_url'];
	location: Coordinate;
	phone: StoresSearchData['telephone'];
	email: StoresSearchData['emails'];
	disabledDays: string[];
} & Pick<StoresSearchData, 'id' | 'name' | 'name_include_city' | 'address'>;

export type RentalReservationStore = Pick<
	StoresSearchData,
	| 'id'
	| 'name'
	| 'telephone'
	| 'emails'
	| 'weekday_open_time'
	| 'weekday_close_time'
	| 'weekend_open_time'
	| 'weekend_close_time'
	| 'store_close_dates'
	| 'single_latest_rent_time'
	| 'tour_latest_rent_time'
	| 'support_ezaio'
> & {
	regular_off_days: number[];
};

export interface StoreDetailProperty {
	id: number;
	name: string;
	weekdayOpenTime?: string;
	weekdayCloseTime?: string;
	weekendOpenTime?: string;
	weekendCloseTime?: string;
	openTimeInfo?: string;
	address: string;
	location: Coordinate;
	intro: {
		content: string;
		photo: string;
	};
	imageUrl?: string;
	banners: SlideProperty[];
	phone: string;
	closeTime: string;
	email: string;
	service: string;
	facility: string;
	transportation: string;
	greeting: string;
	facebook: string;
	instagram: string;
	recommendedBikes: { data: { [key: number]: RecommendedBicycle }; list: number[] };
	recommendedPaths: RouteCardDataProperty[];
}

const getStoreListForHome = createAction<
	Promise<{
		storeLists: {
			[tag: string]: StoreItemProperty[];
		}[];
	}>,
	TagData[]
>('GET_STORE_LIST_HOME', async tags => {
	try {
		const storeLists = await Promise.all(
			tags.map(tag => fetchStoresSearchFunc({ tags: [tag.id] })),
		);
		return {
			storeLists: storeLists.map(({ data: list }, i) => ({
				[tags[i].name]: list.map(d => ({
					id: d.id,
					name: d.name,
					time: `${d.weekday_open_time.slice(0, 5)} - ${d.weekday_close_time.slice(0, 5)}`,
					address: d.address,
					phone: d.telephone,
					email: d.emails,
					imageUrl: d.first_banner_image_url,
					links: [
						{
							name: 'facebook',
							href: d.facebook,
						},
						{
							name: 'instagram',
							href: d.instagram,
						},
					].filter(l => l.href),
				})),
			})),
		};
	} catch (error) {
		return { storeLists: [], error };
	}
});

interface GetStoreListPayload {
	error?: string;
	data?: State['searchList']['data'];
}

type GetStoreList = { keyword?: string; locationIds?: number[]; tags?: number[] };

const getStoreList = createAction<Promise<GetStoreListPayload>, GetStoreList>(
	'GET_STORE_LIST',
	async params => {
		try {
			const { status, message, data } = await fetchStoresSearchFunc({
				locationIds: params?.locationIds,
				tags: params?.tags,
				keyword: params?.keyword,
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: data.map(d => ({
					id: d.id,
					name: d.name,
					name_include_city: d.name_include_city,
					time: `${d.weekday_open_time.slice(0, 5)} - ${d.weekday_close_time.slice(0, 5)}`,
					address: d.address,
					phone: d.telephone,
					email: d.emails,
					imageUrl: d.first_banner_image_url,
					cityId: d.city_id,
					location: {
						lat: d.latitude,
						lng: d.longitude,
					},
					disabledDays: [
						...d.store_close_dates,
						...d.regular_off_days.split(',').map(day => (day === '7' ? '0' : day)),
					],
				})),
			};
		} catch (error) {
			return { error: (error as Error).message };
		}
	},
);

const getStoreInfo = createAction<Promise<{ detail: StoreDetailProperty }>, number>(
	'GET_STORE_INFO',
	async storeId => {
		try {
			const { status, message, data } = await fetchStoresSingleFunc(storeId);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const {
				id,
				name,
				address,
				description,
				banners,
				telephone,
				emails,
				weekday_open_time,
				weekday_close_time,
				weekend_open_time,
				weekend_close_time,
				open_time_info,
				latitude,
				longitude,
				scenario_img,
				regular_off_days,
				store_facility,
				store_service_info,
				recommended_bicycles,
				recommended_paths,
				additional_info,
				transportation,
				facebook,
				instagram,
			} = data;

			const mapStoreInfo = {
				id,
				name,
				weekdayOpenTime: weekday_open_time.slice(0, 5),
				weekdayCloseTime: weekday_close_time.slice(0, 5),
				weekendOpenTime: weekend_open_time.slice(0, 5),
				weekendCloseTime: weekend_close_time.slice(0, 5),
				openTimeInfo: open_time_info,
				address,
				location: {
					lat: latitude,
					lng: longitude,
				},
				intro: {
					content: description,
					photo: scenario_img,
				},
				imageUrl: scenario_img,
				banners,
				phone: telephone,
				closeTime: regular_off_days,
				email: emails,
				transportation,
				facility: store_facility.join(' / '),
				service: store_service_info,
				greeting: additional_info,
				facebook,
				instagram,
				recommendedBikes: {
					data: recommended_bicycles.reduce(
						(a, v) => ({
							...a,
							[v.id]: v,
						}),
						{},
					),
					list: recommended_bicycles.map(d => d.id),
				},
				recommendedPaths: recommended_paths.map(
					({
						id: pId,
						difficulty,
						title,
						day,
						store_name,
						first_banner_image_url,
						liked,
					}: any) => ({
						id: pId,
						title,
						difficulty,
						days: day,
						store: store_name,
						thumbnailUrl: first_banner_image_url,
						isSaved: liked,
					}),
				),
			};

			return {
				detail: mapStoreInfo,
			};
		} catch (error) {
			return Promise.reject(error);
		}
	},
);

type RentalReservationStoresPayload = {
	list: RentalReservationStore[];
	data: {
		byName: {
			[key: number]: RentalReservationStore;
		};
		byId: {
			[key: string]: RentalReservationStore;
		};
	};
};

const getStoresRentalStartStores = createAction<Promise<RentalReservationStoresPayload>>(
	'GET_STORES_RENTAL_START_STORES',
	async () => {
		try {
			const { status, message, data } = await fetchStoresSearchFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const list = data.map(d => formatToRentalStore(d));
			const normalizeData = list.reduce(
				(a, v) => ({
					byId: {
						...a.byId,
						[v.id]: v,
					},
					byName: {
						...a.byName,
						[v.name]: v,
					},
				}),
				{ byId: {}, byName: {} } as {
					byId: { [key: number]: RentalReservationStore };
					byName: { [key: number]: RentalReservationStore };
				},
			);

			return {
				list,
				data: normalizeData,
			};
		} catch (error) {
			return {
				list: [],
				data: {
					byName: {},
					byId: {},
				},
			};
		}
	},
);

const getStoresRentalDestinationStores = createAction<
	Promise<RentalReservationStoresPayload>,
	number
>('GET_STORES_RENTAL_DESTINATION_STORES', async (startStoreId: number) => {
	try {
		const { status, message, data } = await fetchStoresDestinationsFunc(startStoreId);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const list = data.map(d => formatToRentalStore(d));
		const normalizeData = list.reduce(
			(a, v) => ({
				byId: {
					...a.byId,
					[v.id]: v,
				},
				byName: {
					...a.byName,
					[v.name]: v,
				},
			}),
			{ byId: {}, byName: {} } as {
				byId: { [key: number]: RentalReservationStore };
				byName: { [key: number]: RentalReservationStore };
			},
		);

		return {
			list,
			data: normalizeData,
		};
	} catch (error) {
		return {
			list: [],
			data: {
				byName: {},
				byId: {},
			},
		};
	}
});

const clearStoresRentalDestinationStores = createAction('CLEAR_STORES_RENTAL_DESTINATION_STORES');

export interface State {
	storeLists: {
		loading: boolean;
		error: string;
		data: {
			[tag: string]: StoreItemProperty[];
		}[];
	};
	searchList: {
		loading: boolean;
		error: string;
		data: SearchStoreProperty[];
	};
	detail: StoreDetailProperty | null;
	rentalReservation: {
		startStores: {
			loading: boolean;
			list: RentalReservationStore[];
			data: {
				byName: {
					[key: string]: RentalReservationStore;
				};
				byId: {
					[key: number]: RentalReservationStore;
				};
			};
		};
		destinationStores: {
			loading: boolean;
			list: RentalReservationStore[];
			data: {
				byName: {
					[key: string]: RentalReservationStore;
				};
				byId: {
					[key: number]: RentalReservationStore;
				};
			};
		};
	};
}

const initialState: State = {
	storeLists: {
		loading: false,
		error: '',
		data: [],
	},
	searchList: {
		loading: false,
		error: '',
		data: [],
	},
	detail: null,
	rentalReservation: {
		startStores: {
			loading: false,
			list: [],
			data: {
				byName: {},
				byId: {},
			},
		},
		destinationStores: {
			loading: false,
			list: [],
			data: {
				byName: {},
				byId: {},
			},
		},
	},
};

export const reducer = {
	stores: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_STORE_LIST_HOME_PENDING: state => ({
				...state,
				storeLists: {
					...state.storeLists,

					loading: true,
					error: '',
					data: initialState.storeLists.data,
				},
			}),

			GET_STORE_LIST_HOME_FULFILLED: (
				state,
				action: Action<{
					storeLists: {
						[tag: string]: StoreItemProperty[];
					}[];
				}>,
			) => ({
				...state,
				storeLists: {
					...state.storeLists,

					loading: false,
					data: [...action.payload.storeLists],
				},
			}),

			GET_STORE_LIST_HOME_REJECTED: (state, action) => ({
				...state,
				storeLists: {
					...state.storeLists,

					loading: false,
					data: [...action.payload.storeLists],
					error: action.payload.error.message,
				},
			}),

			GET_STORE_LIST_PENDING: state => ({
				...state,
				searchList: {
					...state.searchList,

					loading: true,
					error: '',
					data: initialState.searchList.data,
				},
			}),

			GET_STORE_LIST_FULFILLED: (state, action: Action<GetStoreListPayload>) => ({
				...state,
				searchList: {
					...state.searchList,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
					loading: false,
				},
			}),

			GET_STORE_INFO_PENDING: state => ({
				...state,
				detail: null,
			}),

			GET_STORE_INFO_FULFILLED: (state, action: Action<{ detail: StoreDetailProperty }>) => ({
				...state,
				detail: {
					...state.detail,
					...action.payload.detail,
				},
			}),

			GET_STORES_RENTAL_START_STORES_PENDING: state => ({
				...state,
				rentalReservation: {
					...state.rentalReservation,
					rentalStartStores: {
						...state.rentalReservation.startStores,
						loading: true,
						list: [],
						data: {
							byName: {},
							byId: {},
						},
					},
					rentalDestinationStores: {
						...state.rentalReservation.destinationStores,
						loading: false,
						list: [],
						data: {
							byName: {},
							byId: {},
						},
					},
				},
			}),

			GET_STORES_RENTAL_START_STORES_FULFILLED: (
				state,
				action: Action<RentalReservationStoresPayload>,
			) => ({
				...state,
				rentalReservation: {
					...state.rentalReservation,
					startStores: {
						...state.rentalReservation.startStores,
						loading: false,
						list: action.payload.list,
						data: action.payload.data,
					},
				},
			}),

			GET_STORES_RENTAL_DESTINATION_STORES_PENDING: state => ({
				...state,
				rentalReservation: {
					...state.rentalReservation,
					destinationStores: {
						...state.rentalReservation.destinationStores,
						loading: true,
						list: [],
						data: {
							byName: {},
							byId: {},
						},
					},
				},
			}),

			GET_STORES_RENTAL_DESTINATION_STORES_FULFILLED: (
				state,
				action: Action<RentalReservationStoresPayload>,
			) => ({
				...state,
				rentalReservation: {
					...state.rentalReservation,
					destinationStores: {
						...state.rentalReservation.destinationStores,
						loading: false,
						list: action.payload.list,
						data: action.payload.data,
					},
				},
			}),

			CLEAR_STORES_RENTAL_DESTINATION_STORES: state => ({
				...state,
				rentalReservation: {
					...state.rentalReservation,
					destinationStores: {
						...initialState.rentalReservation.destinationStores,
					},
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	storeLists: state.stores.storeLists,
	searchList: state.stores.searchList,
});

export const useStores = () => useRedux(mapHooksToState, { getStoreListForHome, getStoreList });

/* +----------------------------------------------------------------------
	++ useRentalStoreDetail ++
++----------------------------------------------------------------------*/
const selectStoreDetail = (state: GlobalState) => state.stores.detail;
const storeDetailMap = { getStoreInfo };

export const useStoreDetail = () =>
	useRedux<ReturnType<typeof selectStoreDetail>, typeof storeDetailMap>(
		selectStoreDetail,
		storeDetailMap,
	);

/* +----------------------------------------------------------------------
	++ useStoresRentalReservation ++
++----------------------------------------------------------------------*/
const selectRentalReservation = (state: GlobalState) => state.stores.rentalReservation;
const rentalReservationActionsMap = {
	getStoresRentalStartStores,
	getStoresRentalDestinationStores,
	clearStoresRentalDestinationStores,
};

export const useStoresRentalReservation = () =>
	useRedux<ReturnType<typeof selectRentalReservation>, typeof rentalReservationActionsMap>(
		selectRentalReservation,
		rentalReservationActionsMap,
	);
