import { StoresSearchData, SingleStore } from 'api/store';
import { RentalReservationStore } from 'models/stores';

type FuncType = (d: StoresSearchData | SingleStore) => RentalReservationStore;

const formatToRentalStore: FuncType = d => ({
	id: d.id,
	name: d.name,
	telephone: d.telephone,
	emails: d.emails,
	support_ezaio: d.support_ezaio,
	weekday_open_time: d.weekday_open_time,
	weekday_close_time: d.weekday_close_time,
	weekend_open_time: d.weekend_open_time,
	weekend_close_time: d.weekend_close_time,
	tour_latest_rent_time: d.tour_latest_rent_time,
	single_latest_rent_time: d.single_latest_rent_time,
	regular_off_days: d.regular_off_days.split(',').map(s => parseInt(s, 10)),
	/* 訂單查詢頁面 API：fetchRentalOrderFunc 不會回傳 store_close_dates */
	store_close_dates: d.store_close_dates?.map(s => s.replace(/-/g, '/')) || [],
});

export default formatToRentalStore;
