import { FetchFunction } from 'types/FetchFunction';

import { wrapFetch } from 'util/api';

import { Difficulty } from 'types/DifficultyCode';
import { RoomInfo } from 'types/RoomInfo';
import { FetchTravelToursList } from 'types/travelClassicAndCustomTours';

import { SlideProperty } from 'components/molecules/SliderBanner';

import { RouteInfoTransportationProperty } from 'models/rentalRoutes';
import { FetchResponse } from 'types/FetchResponse';
import { PackageImageUploadResponseData } from './package';
import { ProductData, ProductType } from './product';

/**
 * 取得旅遊經典行程列表
 *
 * API: /api/v1/web/travel
 */
export const fetchTravelClassicToursList: FetchTravelToursList = async params => {
	const response = await wrapFetch(
		`travel`,
		{
			method: 'GET',
		},
		params,
		{ withAuth: true },
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TravelClassicToursRidingLevelsData {
	id: number;
	name: string;
	code: string;
}

type TravelClassicToursRidingLevelsFetch = FetchFunction<TravelClassicToursRidingLevelsData[]>;

/**
 * 取得旅遊經典行程列表頁篩選條件 - 騎乘強度
 *
 * 根據 only_groups: ['DIFFICULTY'] 參數, 從後端'tags'
 * api: /api/v1/web/tags 取得騎乘強度資料
 */
export const fetchTravelClassicToursRidingLevelsFunc: TravelClassicToursRidingLevelsFetch = async () => {
	const response = await wrapFetch(
		'tags',
		{
			method: 'GET',
		},
		{
			only_groups: ['DIFFICULTY'],
		},
		{ withLocale: false, withLan: true },
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TravelClassicToursRouteData {
	id: number;
	date?: string;
	time: string;
	title: string;
	distance: string;
	overview: string;
	meal: string;
	transportation_type: RouteInfoTransportationProperty[];
	hotel_name: string;
	hotel_url?: string;
	tour_description: string;
}

export interface TravelClassicToursInfo {
	id: number;
	name: string;
	trip_type: 'DOMESTIC' | 'ABROAD';
	banner: SlideProperty[];
	start_location_note: string;
	end_location_note: string;
	duration_day: string;
	total_mileage: number;
	lowest_price: string;
	description: string;
	note: string;
	difficulty: Difficulty;
	is_open_for_customized: boolean;
	route: TravelClassicToursRouteData[];
	products: Record<ProductType | 'bicycle', ProductData[]>;
	rooms: RoomInfo[];
	youtube_url: string;
	extra_field_name_1?: string;
	extra_field_name_2?: string;
	extra_field_name_3?: string;
}

type TravelClassicToursInfoFetch = FetchFunction<TravelClassicToursInfo>;

/**
 * 取得旅遊經典行程內頁資料
 * @param id 旅遊經典行程 id
 *
 * api: /api/v1/web/travel/{id}
 */
export const fetchTravelClassicToursInfoFunc: TravelClassicToursInfoFetch = async id => {
	const response = await wrapFetch(`travel/${id}`, {
		method: 'GET',
	});

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TravelClassicToursRatingReviewer {
	name: string;
	date: string;
	image: Partial<SlideProperty>[];
	rating: number;
	comment: string;
}

export interface TravelClassicToursInfoRating {
	total_rating: number;
	reviewer: TravelClassicToursRatingReviewer[];
}

type TravelClassicToursInfoRatingFetch = FetchFunction<TravelClassicToursInfoRating>;

/**
 * 取得旅遊經典行程內頁 - 行程評價
 * @param id 旅遊經典行程 id
 */
export const fetchTravelClassicToursRatingFunc: TravelClassicToursInfoRatingFetch = async id => {
	const response = await wrapFetch(
		`travel/${id}/rating`,
		{
			method: 'GET',
		},
		{
			withLocale: false,
		},
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

type CustomerFeedbackImageUploadResponseData = Partial<PackageImageUploadResponseData>;

type SendCustomerFeedbackImageFunc = (
	data: FormData,
) => Promise<FetchResponse<CustomerFeedbackImageUploadResponseData>>;

/**
 * 經典旅遊行程內頁 - 上傳旅客評價照片
 * @param data
 * @returns 上傳照片後的 response
 */
export const sendCustomerFeedbackImageFunc: SendCustomerFeedbackImageFunc = async (
	data: FormData,
) => {
	const response = await wrapFetch(
		'imageUpload',
		{
			method: 'POST',
			body: data,
		},
		{},
		{ isFormData: true },
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

type SendTravelClassicToursInfoRating = (
	id: number,
	data: Partial<TravelClassicToursRatingReviewer>,
) => Promise<FetchResponse<string>>;

/**
 * 送出經典旅遊行程內頁 - 行程評價
 * @param id 旅遊經典行程 id
 */
export const sendTravelClassicToursRatingFunc: SendTravelClassicToursInfoRating = async (
	id,
	data,
) => {
	const response = await wrapFetch(`travel/${id}/rating`, {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface GetRemainingQuotaForTravelItinerary {
	registered: number;
	waiting: number;
}

export type RemainingQuotaForTravelItinerary = FetchFunction<GetRemainingQuotaForTravelItinerary>;

/**
 * 取得旅遊行程 報名餘額、備取人數
 * @param id
 * @returns registered（剩餘報名名額）, waiting（備取人數）
 */
export const getRemainingQuotaForTravelItinerary: RemainingQuotaForTravelItinerary = async id => {
	const response = await wrapFetch(`travel/itinerary/${id}/balance`, {
		method: 'GET',
	});

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export type GetTravelClassicTourPDF = FetchFunction<Blob, number>;

/**
 * 取得旅遊行程 PDF
 * @param id 旅遊經典行程 id
 * @returns PDF url
 */
export const getTravelClassicTourPDFFunc: GetTravelClassicTourPDF = async id => {
	const response = await wrapFetch(
		`travel/${id}/pdf`,
		{
			method: 'GET',
		},
		{},
		{ isPDF: true },
	);

	return response;
};
