import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import EventsNewsInfoRoute from './Info';

const routes: CustomRoute = {
	path: appPath.eventsNews.replace(appPath.events, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'eventsNewsList' */ './component')],
			render: ([EventsNewsList]) => <EventsNewsList />,
		},
		EventsNewsInfoRoute,
	],
};

export default routes;
