import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { PackageTagCode } from 'models/packages';

import { State as GlobalState } from './reducers';

export type PriceOverviewContent = {
	id: number;
	title: string;
	content: string;
};

type PackagesPricePayload = {
	data: PriceOverviewContent[];
};

const getPackagesPrice = createAction<Promise<PackagesPricePayload>, PackageTagCode>(
	'GET_PACKAGES_PRICE',
	async tag => {
		try {
			const data: PriceOverviewContent[] = [
				{
					id: 1,
					title: '台灣',
					content: `<ul>
					<li>腳踏車打包裝箱服務</li><li>一台$500</li>
					<li>價格包含紙箱費用不含運費</li>
					<li>運費依照 #大榮貨運 發票實報實銷</li>
					<li>可支援到府收件，單趟300元（來回600）</li>
				</ul>`,
				},
				{
					id: 2,
					title: '日本',
					content: `<ul>
					<li>腳踏車打包裝箱服務</li><li>一台$500</li>
					<li>價格包含紙箱費用不含運費</li>
					<li>運費依照 #大榮貨運 發票實報實銷</li>
					<li>可支援到府收件，單趟300元（來回600）</li>
				</ul>`,
				},
			];

			return { data };
		} catch (error) {
			return { data: [] };
		}
	},
);

export interface State {
	loading: boolean;
	error: string;
	data: PriceOverviewContent[];
}

const initialState = {
	loading: false,
	error: '',
	data: [],
};

export const reducer = {
	packagesPrice: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PACKAGES_PRICE_PENDING: state => ({
				...state,
				loading: true,
				error: '',
				data: [],
			}),

			GET_PACKAGES_PRICE_FULFILLED: (state, action: Action<PackagesPricePayload>) => ({
				...state,
				loading: false,
				data: action.payload.data,
			}),

			GET_PACKAGES_PRICE_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: action.payload.message,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => state.packagesPrice;

export const usePackagesPrice = () => useRedux(mapHooksToState, { getPackagesPrice });
