/* eslint-disable indent */
import { Action, createAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { useRedux } from 'util/hook/redux';

import { fetchStoreProductsFunc, StoreProductType } from 'api/store';

import { normalizedProduct } from './utils';
import { ColorItem, ProductData, SizeItem, StockItem } from './type';
import { State as GlobalState } from '../reducers';

export interface State {
	additional: {
		loading: boolean;
		error: string;
	};
	mainProductIds: {
		productIds: number[];
		serviceIds: number[];
		giftIds: number[];
		bicycleIds: number[];
		applyItemIds: number[];
	};
	dataById: {
		products: { [id: number]: ProductData }; // mainProduct
		stocks: { [id: number]: StockItem };
		colors: { [id: number]: ColorItem };
		sizes: { [id: number]: SizeItem };
	};
}

interface GetProductAdditionalsPayload {
	error?: string;
	data?: {
		mainProductIds: Partial<State['mainProductIds']>;
		dataById: Partial<State['dataById']>;
	};
}

/**
 * 取得門市加價購商品
 */
const getProductAdditionals = createAction<
	Promise<GetProductAdditionalsPayload>,
	{
		storeId: number;
		startDate?: string;
		startTime?: string;
		returnDate?: string;
		returnTime?: string;
		type: StoreProductType;
	}
>(
	'GET_PRODUCT_ADDITIONALS',
	async ({ storeId, startDate, startTime, returnDate, returnTime, type }) => {
		try {
			const { status, message, data } = await fetchStoreProductsFunc({
				id: storeId,
				start_at:
					startDate && startTime
						? moment(`${startDate} ${startTime}`, 'YYYY/MM/DD HH:mm:ss').format(
								'YYYY-MM-DD HH:mm:ss',
						  )
						: '',
				end_at:
					returnDate && returnTime
						? moment(`${returnDate} ${returnTime}`, 'YYYY/MM/DD HH:mm:ss').format(
								'YYYY-MM-DD HH:mm:ss',
						  )
						: '',
				type,
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const products = normalizedProduct(data.products);
			const services = normalizedProduct(data.services);
			const gifts = normalizedProduct(data.gifts);
			const bicycles = normalizedProduct(data.bicycles);

			console.log('bicycles in getProductAdditionals', bicycles);

			return {
				data: {
					mainProductIds: {
						productIds: products.ids,
						serviceIds: services.ids,
						giftIds: gifts.ids,
						bicyclesIds: bicycles.ids,
					},
					dataById: {
						products: {
							...products.products,
							...services.products,
							...gifts.products,
							...bicycles.products,
						},
						stocks: { ...products.stocks, ...services.stocks, ...gifts.stocks, ...bicycles.stocks },
						colors: { ...products.colors, ...services.colors, ...gifts.colors, ...bicycles.colors },
						sizes: { ...products.sizes, ...services.sizes, ...gifts.sizes, ...bicycles.sizes },
					},
				},
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

const clearProductAdditional = createAction('CLEAR_PRODUCT_ADDITIONAL');

export const updateStocks = createAction<State['dataById']['stocks'], State['dataById']['stocks']>(
	'UPDATE_PRODUCT_ADDITIONALS_STOCKS',
	data => data,
);

export const updateProductData = createAction<
	Partial<State['dataById']>,
	Partial<State['dataById']>
>('UPDATE_PRODUCT_ADDITIONALS_DATA', data => data);

const initialState: State = {
	additional: {
		loading: false,
		error: '',
	},
	mainProductIds: {
		productIds: [],
		serviceIds: [],
		giftIds: [],
		bicycleIds: [],
		applyItemIds: [],
	},
	dataById: {
		products: {},
		stocks: {},
		colors: {},
		sizes: {},
	},
};

export const reducer = {
	product: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_PRODUCT_ADDITIONAL: state => ({
				...state,
				...initialState,
			}),

			GET_PRODUCT_ADDITIONALS_PENDING: state => ({
				...state,
				additional: {
					...state.additional,
					loading: true,
					error: '',
				},
				mainProductIds: initialState.mainProductIds,
				dataById: initialState.dataById,
			}),

			GET_PRODUCT_ADDITIONALS_FULFILLED: (state, action: Action<GetProductAdditionalsPayload>) => ({
				...state,
				additional: {
					...state.additional,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
				...(action.payload.data && {
					mainProductIds: {
						...state.mainProductIds,
						...action.payload.data.mainProductIds,
					},
					dataById: {
						...state.dataById,
						...action.payload.data.dataById,
					},
				}),
			}),

			UPDATE_PRODUCT_ADDITIONALS_STOCKS: (state, action: Action<State['dataById']['stocks']>) => ({
				...state,

				dataById: {
					...state.dataById,
					stocks: {
						...state.dataById.stocks,
						...action.payload,
					},
				},
			}),

			UPDATE_PRODUCT_ADDITIONALS_DATA: (state, action: Action<Partial<State['dataById']>>) => ({
				...state,

				dataById: {
					...state.dataById,
					products: { ...state.dataById.products, ...action.payload.products },
					stocks: { ...state.dataById.stocks, ...action.payload.stocks },
					colors: { ...state.dataById.colors, ...action.payload.colors },
					sizes: { ...state.dataById.sizes, ...action.payload.sizes },
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useProductAdditionals ++
++----------------------------------------------------------------------*/
const selectAdditionals = (state: GlobalState) => ({
	additional: state.product.additional,
	mainProductIds: state.product.mainProductIds,
	dataById: state.product.dataById,
});

const additionalsActionsMap = {
	getProductAdditionals,
	clearProductAdditional,
	updateStocks,
};

export const useProductAdditionals = () =>
	useRedux<ReturnType<typeof selectAdditionals>, typeof additionalsActionsMap>(
		selectAdditionals,
		additionalsActionsMap,
	);
