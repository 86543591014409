import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

export interface DocumentData {
	id: number;
	type: string;
	code: string;
	name: string;
	note: string;
	langs: string;
	file_url: string;
}

type DocumentCode =
	| 'CODE_COURSE_PARENT'
	| 'CODE_THEME_ACTIVITY_PARENT'
	| 'CODE_STORE_RIDE_PARENT'
	| 'CODE_TRAVEL_PARENT'
	| 'CODE_TRAVEL_ELDERLIES'
	| 'CODE_THEME_ACTIVITY_ELDERLIES'
	| 'CODE_COURSE_ELDERLIES';

type DocumentType = 'TRAVEL' | 'RENTAL' | 'RIDER' | 'COURSE' | 'PACKAGE';

export type FetchDocumentDataFuncParams = {
	search?: string;
	code?: DocumentCode;
	type?: DocumentType;
	country_id?: number;
};

type FetchDocumentDataFunc = (
	params: FetchDocumentDataFuncParams,
	options?: { signal: AbortSignal },
) => Promise<FetchResponse<DocumentData[]>>;

export const fetchDocumentData: FetchDocumentDataFunc = async (params, options) => {
	const response = await wrapFetch(
		`documents/search`,
		{
			method: 'GET',
			...(options?.signal && { signal: options.signal }),
		},
		params,
	);

	return response;
};
