import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

type FetchPrivacyTermsFunc = () => Promise<FetchResponse<{ privacy_terms: string }>>;

export const fetchPrivacyTermsFunc: FetchPrivacyTermsFunc = async () => {
	const response = await wrapFetch('privacyTerms', {
		method: 'GET',
	});

	return response;
};
