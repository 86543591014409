import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';

interface GuidelineRawData {
	id: number;
	type: number;
	title: string;
	description: string;
	img: string;
	status: 'ONLINE' | 'OFFLINE';
	order: number;
}

interface GuidelineDownData {
	id: number;
	name: string;
	status: number;
	guideline_downs: GuidelineRawData[];
}

type FetchGuidelineTopsFunc = () => Promise<FetchResponse<GuidelineRawData[]>>;

export const fetchGuidelineTopsFunc: FetchGuidelineTopsFunc = async () => {
	const response = await wrapFetch('guideline_tops', {
		method: 'GET',
	});

	return response;
};

type FetchGuidelineDownsFunc = (params?: {
	search?: string;
	filter?: number[];
}) => Promise<FetchResponse<GuidelineDownData[]>>;

export const fetchGuidelineDownsFunc: FetchGuidelineDownsFunc = async params => {
	const response = await wrapFetch('guideline_downs', {
		method: 'POST',
		body: JSON.stringify({
			search: params?.search || '',
			filter: params?.filter || [],
		}),
	});

	return response;
};
