/* eslint-disable indent */
import { AdminBicycleForm } from 'models/adminBicycleForms';
import { CustomRoute } from './hook/useRouter';

export enum appPath {
	landing = '/',

	// 我要旅遊
	travel = '/travel',
	travelNews = '/travel/news', // 最新消息
	travelNewsInfo = '/travel/news/:id', // 最新消息內頁
	travelClassicTours = '/travel/classictours', // 經典行程
	travelClassicTourInfo = '/travel/classictours/:id', // 經典行程內頁
	travelClassicReservation = '/:eventType/classictours/:id/reservation', // 我要報名
	travelCustomTours = '/travel/customtours', // 客製包團
	travelCustomReservation = '/travel/customtours/reservation', // 客製包團洽詢表單
	travelGuide = '/travel/guide', // 服務內容
	travelQuestions = '/travel/questions', // 常見問題
	travelBrief = '/travel/brief', // 說明會分享
	travelBriefInfo = '/travel/brief/:id', // 說明會分享內頁
	travelPayment = '/travel/payment', // 我要繳費
	travelTravelTours = '/travel/travel-tours', // 行程總覽
	travelNotice = '/travel/notice/:id',

	// 我要租車
	rental = '/rental',
	rentalNews = '/rental/news', // 方案資訊
	rentalNewInfo = '/rental/news/:id', // 方案資訊內頁
	rentalRoutes = '/rental/routes', // 推薦路線
	rentalRouteInfo = '/rental/routes/:id', // 推薦路線內頁
	rentalStores = '/rental/stores', // 租賃門市
	rentalStoreInfo = '/rental/stores/:id', // 租賃門市內頁
	rentalBikesFees = '/rental/bikefees', // 車種與費用
	rentalGuide = '/rental/guide', // 使用指引
	rentalQuestions = '/rental/questions', // 常見問題
	rentalReservation = '/rental/reservation', // 預約租車
	rentalExamination = '/rental/examination', // 租車配備檢點表

	// 活動課程
	events = '/events',
	eventsNews = '/events/news', // 最新消息
	eventsFeaturedRides = '/events/featuredrides', // 主題活動
	eventsStoreRides = '/events/storerides', // 門市約騎
	eventsBikeClasses = '/events/bikeclasses', // 課程講座
	eventsHighlights = '/events/highlights', // 活動花絮
	eventsHighlightsList = '/events/highlights/:eventType', // 活動花絮列表
	eventsReservation = '/events/:eventType/:id/reservation', // 報名流程
	eventsRegisterSearch = '/events/:eventType/:id/register-search', // 報名查詢
	eventsOrders = '/events/:eventType/:id/orders', // 訂單列表頁
	eventsScore = '/events/score/:id', // 成績查詢

	// 打包運送
	logistics = '/logistics',
	logisticsPackingForm = '/logistics/packing-form', // 打包組裝洽詢表單

	// 關於我們
	stories = '/stories',
	storiesAboutUs = '/stories/aboutus', // 我們的故事
	// storiesRideWithUs = '/stories/ridewithus', // 旅遊日誌
	storiesGiantAdventure = '/stories/giantadventure', // 捷安特旅行社
	storiesGiantAdventureLeader = '/stories/giantadventure/:id', // 捷安特旅行社領隊
	storiesGiantBikeSchool = '/stories/giantbikeschool', // 捷安特單車學校
	storiesGiantBikeSchoolLecturer = '/stories/giantbikeschool/:id', // 捷安特單車學校講師

	// 會員中心
	account = '/account',
	accountOrders = '/account/order', // 我的訂單
	accountWishList = '/account/wishlist', // 我的收藏
	accountCoupon = '/account/coupon', // 我的優惠券
	accountJourneys = '/account/journeys', // 我的紀錄
	accountJourneysDetail = '/account/journeys/:category', // 我的紀錄內頁
	accountCertificate = '/account/certificate', // 活動履歷
	accountEdit = '/account/edit', // 會員編輯

	// 訂單內頁
	orderDetail = '/order/:orderType/:selector', // 訂單內頁
	orderTransaction = '/order/:orderType/:selector/transaction/:id', // 訂單金流交易付款頁

	// admin
	admin = '/admin', // 表單 base url
	// 庫存表單
	adminBicycle = '/admin/bicycle',
	adminBicycleRedirect = '/admin/bicycle/:id/redirect', // 表單轉導
	adminBicyclePickup = '/admin/bicycle/:id/pick-up', // 取車檢查表
	adminBicycleInventory = '/admin/bicycle/:id/inventory', // 車輛盤點
	adminBicycleReturn = '/admin/bicycle/:id/return', // 還車檢查表
	adminBicycleLost = '/admin/bicycle/:id/lost', // 遺失表
	adminBicycleBroken = '/admin/bicycle/:id/broken', // 異動單
	adminBicycleRepair = '/admin/bicycle/:id/repair', // 門市/物流修復單
	adminBicycleReceive = '/admin/bicycle/:id/receive', // 門市/物流收車單
	adminBicycleMaintain = '/admin/bicycle/:id/maintain', // 門市/物流保養單
	adminBicycleFound = '/admin/bicycle/:id/found', // 遺失回復表
	adminBicycleDeliver = '/admin/bicycle/:id/deliver', // 門市/物流配送單
	adminBicycleReserve = '/admin/bicycle/:id/reserve', // 車輛保留單
	adminBicycleToNormal = '/admin/bicycle/:id/to-normal', //	保留回復單
	// 審核用表單
	adminApprove = '/admin/approve',
	adminApproveSTSTF = '/admin/approve/STSTF/:formId', // 門市調車核准單
	adminApproveLTF = '/admin/approve/LTF/:storeId/:logisticsDate', // 物流調車核准單
	adminApproveBTF = '/admin/approve/BTF/:storeId/:logisticsDate', // 異常車調車核准單
	// 訂單
	adminOrder = '/admin/order',
	adminOrderTravelPurchase = '/admin/order/travel/:selector/add-purchase', // 旅遊加購
	adminOrderRentalPurchase = '/admin/order/rental/:selector/add-purchase', // 租車加購
	adminOrderEventsPurchase = '/admin/order/:eventType/:selector/add-purchase', // 活動加購

	// auth
	authCallback = '/auth-callback', // giant login callback
	signin = '/signin',

	// about
	about = '/about', // 關於我們
	aboutContact = '/about/contact', // 聯絡我們

	// 其他
	certificate = '/certificate', // 證書
	calendar = '/calendar', // 行事曆
	questions = '/questions', // 常見問題
	privacyTerms = '/privacy-terms', // 隱私權
	relatedTerms = '/related-terms', // 相關條款
	download = '/download', // 下載專區
}

export type appPathKey = keyof typeof appPath;

export type ConfigHeaders = Extract<
	appPathKey,
	'travel' | 'rental' | 'events' | 'logistics' | 'stories'
>;

export type AccountNavi = Extract<
	appPathKey,
	| 'accountOrders'
	| 'accountWishList'
	| 'accountCoupon'
	| 'accountJourneys'
	| 'accountCertificate'
	| 'accountEdit'
>;

export const accountNavis: AccountNavi[] = process.env.PRODUCTION
	? ['accountOrders', 'accountWishList', 'accountJourneys', 'accountEdit', 'accountCertificate']
	: [
			'accountOrders',
			'accountWishList',
			'accountCoupon',
			'accountJourneys',
			'accountCertificate',
			'accountEdit',
	  ];

export const accountNaviLinks = accountNavis.map(key => ({
	key,
	path: appPath[key],
}));

// 下面的 key 同 i18n 的 key name
export const adminPathMap: {
	[key in AdminBicycleForm]: {
		path: appPath;
		key: string;
	};
} = {
	pickUp: {
		path: appPath.adminBicyclePickup,
		key: 'pickupForm',
	},
	inventory: {
		path: appPath.adminBicycleInventory,
		key: 'inventoryForm',
	},
	return: {
		path: appPath.adminBicycleReturn,
		key: 'returnForm',
	},
	lost: { path: appPath.adminBicycleLost, key: 'lostForm' },
	broken: { path: appPath.adminBicycleBroken, key: 'brokenForm' },
	repair: { path: appPath.adminBicycleRepair, key: 'repairForm' },
	receive: { path: appPath.adminBicycleReceive, key: 'receiveForm' },
	maintain: { path: appPath.adminBicycleMaintain, key: 'maintainForm' },
	found: { path: appPath.adminBicycleFound, key: 'foundForm' },
	deliver: { path: appPath.adminBicycleDeliver, key: 'deliverForm' },
	reserve: { path: appPath.adminBicycleReserve, key: 'reserveForm' },
	toNormal: { path: appPath.adminBicycleToNormal, key: 'toNormalForm' },
};
