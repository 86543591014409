/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import moment from 'moment';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import { abortController, addController } from 'models/controllers';

import { DocumentData, FetchDocumentDataFuncParams, fetchDocumentData } from 'api/document';

import { State as GlobalState } from './reducers';

const doGetDocument = async (
	actionName: string,
	params: FetchDocumentDataFuncParams,
	dispatch: Dispatch,
) => {
	try {
		const { search = '', code, type, country_id } = params;
		await dispatch(abortController(actionName));
		const controller = new AbortController();
		const { signal } = controller;
		await dispatch(addController(actionName, controller));

		const { status, message, data } = await fetchDocumentData(
			{
				...(search && { search }),
				...(code && { code }),
				...(type && { type }),
				...(country_id !== undefined && {
					country_id,
				}),
			},
			{
				signal,
			},
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data;
	} catch (error) {
		return [];
	}
};

export const isConsentShow = (
	formValue: moment.Moment | null,
	documentList: DocumentData[],
	upperLimit: number,
	lowerLimit: number,
): boolean => {
	let condition = false;
	if (upperLimit) {
		condition = moment().diff(formValue, 'years') > upperLimit;
	} else if (lowerLimit) {
		condition = moment().diff(formValue, 'years') < lowerLimit;
	}
	return !!(condition && documentList.length);
};

export const getDocument = createAction<
	(dispatch: Dispatch) => Promise<DocumentData[]>,
	FetchDocumentDataFuncParams
>('GET_DOCUMENT', params => async (dispatch: Dispatch) =>
	doGetDocument('GET_DOCUMENT', params, dispatch),
);

export const getElderDocument = createAction<
	(dispatch: Dispatch) => Promise<DocumentData[]>,
	FetchDocumentDataFuncParams
>('GET_ELDER_DOCUMENT', params => async (dispatch: Dispatch) =>
	doGetDocument('GET_ELDER_DOCUMENT', params, dispatch),
);

export interface State {
	/**
	 * 家長同意書
	 *
	 * @type {DocumentData[]}
	 * @memberof State
	 */
	data: DocumentData[];

	/**
	 * 高齡同意書
	 *
	 * @type {DocumentData[]}
	 * @memberof State
	 */
	elderDoucment: FetchedData<DocumentData[]>;
	loading: boolean;
	error: string;
}

const inititialState: State = {
	data: [],
	elderDoucment: {
		loading: false,
		error: '',
		data: [],
	},
	loading: false,
	error: '',
};

export const reducer = {
	document: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_DOCUMENT_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_DOCUMENT_FULFILLED: (state, action) => ({
				...state,
				data: action.payload,
				loading: false,
			}),
			GET_DOCUMENT_REJECTED: state => ({
				...state,
				loading: false,
				error: '',
			}),
			GET_ELDER_DOCUMENT_PENDING: state => ({
				...state,
				elderDoucment: {
					...state.elderDoucment,
					loading: true,
				},
			}),
			GET_ELDER_DOCUMENT_FULFILLED: (state, action: Action<DocumentData[]>) => ({
				...state,
				elderDoucment: {
					...state.elderDoucment,
					loading: false,
					data: action.payload,
				},
			}),
			GET_ELDER_DOCUMENT_REJECTED: state => ({
				...state,
				elderDoucment: {
					...state.elderDoucment,
					loading: false,
					error: '',
				},
			}),
		},
		inititialState,
	),
};

/* +----------------------------------------------------------------------
	++ useDocument ++
++----------------------------------------------------------------------*/
const selectDocument = (state: GlobalState) => ({
	data: state.document.data,
	loading: state.document.loading,
	error: state.document.error,
	elderDocument: state.document.elderDoucment,
});
const documentActionsMap = {
	getDocument,
	getElderDocument,
};

export const useDocument = () =>
	useRedux<ReturnType<typeof selectDocument>, typeof documentActionsMap>(
		selectDocument,
		documentActionsMap,
	);
