import { FormDataMapping } from 'types/FormDataMapping';
import { RelationshipCodes } from 'types/Relationship';

import { State as ParticipantState } from 'models/eventsParticipant';
import {
	BikeclassesFormDataType,
	FeaturedridesFormDataType,
	StoreridesFormDataType,
} from 'models/eventsParticipant/type';
import { State as ProductState } from 'models/product';
import { ProductItemWithAmount } from 'models/product/type';

import { CourseOrderRequestBody } from 'api/courseOrder';

import { nullToUndefined } from 'util/helper';
import calcParticipantProductAmount from 'util/functions/calcParticipantProductAmount';

import { EventsFormFieldData, ShoppingItemData } from './type';

export const eventReservationFormDataMapping = (
	formData: FormDataMapping<EventsFormFieldData>,
) => ({
	team_name: formData.teamName.value, // 課程講座會是空值
	name: formData.name.value,
	cellphone: `${formData.cellphone.value.intIdNum} ${formData.cellphone.value.phoneNum}`,
	phone:
		formData.phone.value.intIdNum && formData.phone.value.phoneNum
			? `${formData.phone.value.intIdNum} ${formData.phone.value.phoneNum}`
			: '',
	email: formData.email.value,
	identity_no: formData.id.value,
	code: formData.gradeSkipCode.value,
});

export const participantFeaturedRideDataMapping = (
	pIds: ParticipantState['form']['ids'],
	pByIds: { [id: number]: FormDataMapping<FeaturedridesFormDataType> },
) => ({
	participants: pIds.map(pId => ({
		first_name: pByIds[pId].firstName.value,
		last_name: pByIds[pId].lastName.value,
		nationality: pByIds[pId].citizenship.value.value,
		identity_no: pByIds[pId].citizenId.value,
		gender: pByIds[pId].sex.value.value,
		birthday: pByIds[pId].birthday?.value?.format('YYYY-MM-DD'),
		height: pByIds[pId].height?.value,
		phone: `${pByIds[pId].phone.value.intIdNum} ${pByIds[pId].phone.value.phoneNum}`,
		email: pByIds[pId].email.value,
		contact_name: pByIds[pId].emergencyName.value,
		contact_relationship: pByIds[pId].emergencyRelation.value.value as RelationshipCodes,
		contact_phone: `${pByIds[pId].emergencyPhone.value.intIdNum} ${pByIds[pId].emergencyPhone.value.phoneNum}`,
		extra_field_1: pByIds[pId].custom1?.value,
		extra_field_2: pByIds[pId].custom2?.value,
		extra_field_3: pByIds[pId].custom3?.value,
		room_id: pByIds[pId].stayRoom?.value,
		roommate_name: pByIds[pId].roommate?.value,
		eating_habit: pByIds[pId].eatingHabit?.value.value,
		eating_habit_note: pByIds[pId].eatingHabitNote?.value,
		medical_record: pByIds[pId].specialDisease?.value.value,
		medical_record_note: pByIds[pId].specialDiseaseNote?.value,
		parent_consent: pByIds[pId].parentConsent?.value?.filename,
		elderlies_consent: pByIds[pId].elderliesConsent?.value?.filename,
		apply_type_id:
			Object.values(pByIds[pId].applyType.value).length > 0
				? Object.values(pByIds[pId].applyType.value)[0].stockId
				: undefined,
		gift_id: [
			...Object.values(pByIds[pId].giveaways.value)
				.filter(d => d.amount > 0)
				.map(d => d.stockId),
		],
		products: [
			...Object.values(pByIds[pId].productPurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
			...Object.values(pByIds[pId].servicePurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
		].filter(product => product.amount !== 0),
	})),
});

export const participantStoreridesDataMapping = (
	pIds: ParticipantState['form']['ids'],
	pByIds: { [id: number]: FormDataMapping<StoreridesFormDataType> },
) => ({
	participants: pIds.map(pId => ({
		first_name: pByIds[pId].firstName.value,
		last_name: pByIds[pId].lastName.value,
		nationality: pByIds[pId].citizenship.value.value,
		identity_no: pByIds[pId].citizenId.value,
		gender: pByIds[pId].sex.value.value,
		phone: `${pByIds[pId].phone.value.intIdNum} ${pByIds[pId].phone.value.phoneNum}`,
		email: pByIds[pId].email.value,
		contact_name: pByIds[pId].emergencyName.value,
		contact_relationship: pByIds[pId].emergencyRelation.value.value as RelationshipCodes,
		contact_phone: `${pByIds[pId].emergencyPhone.value.intIdNum} ${pByIds[pId].emergencyPhone.value.phoneNum}`,
		extra_field_1: pByIds[pId].custom1?.value,
		extra_field_2: pByIds[pId].custom2?.value,
		extra_field_3: pByIds[pId].custom3?.value,
		room_id: pByIds[pId].stayRoom?.value,
		roommate_name: pByIds[pId].roommate?.value,
		apply_type_id:
			Object.values(pByIds[pId].applyType.value).length > 0
				? Object.values(pByIds[pId].applyType.value)[0].stockId
				: undefined,
		gift_id: [
			...Object.values(pByIds[pId].giveaways.value)
				.filter(d => d.amount > 0)
				.map(d => d.stockId),
		],
		products: [
			...Object.values(pByIds[pId].productPurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
			...Object.values(pByIds[pId].servicePurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
		].filter(product => product.amount !== 0),
	})),
});

export const participantBikeClassesDataMapping = (
	pIds: ParticipantState['form']['ids'],
	pByIds: { [id: number]: FormDataMapping<BikeclassesFormDataType> },
): Partial<CourseOrderRequestBody> => ({
	participants: pIds.map(pId => ({
		first_name: pByIds[pId].firstName.value,
		last_name: pByIds[pId].lastName.value,
		en_first_name: pByIds[pId].firstNameEn.value,
		en_last_name: pByIds[pId].lastNameEn.value,
		nationality: nullToUndefined(pByIds[pId].citizenship.value.value),
		identity_no: pByIds[pId].citizenId.value,
		gender: nullToUndefined(pByIds[pId].sex.value.value),
		birthday: nullToUndefined(pByIds[pId].birthday?.value?.format('YYYY-MM-DD')),
		height:
			typeof Number(pByIds[pId].height?.value) === 'number' ? Number(pByIds[pId].height?.value) : 0,
		weight:
			typeof Number(pByIds[pId].weight?.value) === 'number' ? Number(pByIds[pId].weight?.value) : 0,
		phone: `${pByIds[pId].phone.value.intIdNum} ${pByIds[pId].phone.value.phoneNum}`,
		email: pByIds[pId].email.value,
		country: pByIds[pId].country.value,
		city: pByIds[pId].city.value,
		address: pByIds[pId].address.value,
		note: pByIds[pId].otherNote.value,
		contact_name: pByIds[pId].emergencyName.value,
		contact_relationship: nullToUndefined(pByIds[pId].emergencyRelation?.value.value),
		contact_phone: `${pByIds[pId].emergencyPhone.value.intIdNum} ${pByIds[pId].emergencyPhone.value.phoneNum}`,
		extra_field_1: pByIds[pId].custom1?.value,
		extra_field_2: pByIds[pId].custom2?.value,
		extra_field_3: pByIds[pId].custom3?.value,
		room_id: nullToUndefined(pByIds[pId].stayRoom?.value),
		roommate_name: pByIds[pId].roommate?.value,
		eating_habit: nullToUndefined(pByIds[pId].eatingHabit?.value.value),
		eating_habit_note: pByIds[pId].eatingHabitNote?.value,
		medical_record: nullToUndefined(pByIds[pId].specialDisease.value.value),
		medical_record_note: pByIds[pId].specialDiseaseNote?.value,
		has_participated: nullToUndefined(pByIds[pId].hasParticipated.value.value),
		parent_consent: pByIds[pId].parentConsent?.value?.filename,
		elderlies_consent: pByIds[pId].elderliesConsent?.value?.filename,
		is_primary: pByIds[pId].isPrimary.value,
		apply_type_id:
			Object.values(pByIds[pId].applyType.value).length > 0
				? Object.values(pByIds[pId].applyType.value)[0].stockId
				: undefined,
		gift_id: [
			...Object.values(pByIds[pId].giveaways.value)
				.filter(d => d.amount > 0)
				.map(d => d.stockId),
		],
		products: [
			...Object.values(pByIds[pId].productPurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
			...Object.values(pByIds[pId].servicePurchase.value).map(d => ({
				id: d.stockId,
				amount: d.amount,
			})),
		].filter(product => product.amount !== 0),
	})),
});

/**
 * 計算參加者的加價購明細
 *
 * @param participantIds
 * @param participantById
 * @param productDataById
 * @returns
 */
export const calcParticipantAdditionalItems = (
	participantIds: ParticipantState['form']['ids'],
	participantById: ParticipantState['form']['byIds'],
	productDataById: ProductState['dataById'],
) => {
	const { products: productByPid, colors: colorByCid, stocks: stockBySid } = productDataById;

	/** 計算參加者的加價購資料，回傳以 stock ID 為 key 的 Hash Table。 */
	const participantAdditionalData = {
		...calcParticipantProductAmount({
			data: participantIds.reduce(
				(a, pId) => [
					...a,
					participantById[pId].applyType?.value || {}, // 每一個參加者的報名項目
					participantById[pId].giveaways?.value || {}, // 每一個參加者的贈品
					participantById[pId].productPurchase?.value || {}, // 每一個參加者的商品加價購
					participantById[pId].servicePurchase?.value || {}, // 每一個參加者的服務加價購
				],
				[] as { [productId: number]: ProductItemWithAmount }[],
			),
			key: 'stockId',
		}),
	};

	/** 計算參參加者的加價購明細 */
	const participantAdditionalItems: ShoppingItemData[] = Object.values(
		participantAdditionalData,
	).map(data => ({
		id: data.stockId,
		name: productByPid[data.productId].name,
		categoryName: productByPid[data.productId].description || '',
		colorName: colorByCid[data.colorId]?.colorName || '',
		specName: productByPid[data.productId].sizeTitle || '',
		stock: stockBySid[data.stockId].stock,
		amount: data.amount,
		price: productByPid[data.productId].price * data.amount, // 正取參加者的加價購計入總金額
	}));

	return participantAdditionalItems;
};
