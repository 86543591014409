/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { NewsPageType, OtherNewsData, fetchNewsListFunc, fetchNewsFunc } from 'api/news';
import { TagData } from 'api/tag';
import { BicycleModel } from 'api/bicycle';

import { SlideProperty } from 'components/molecules/SliderBanner';

import { GetState, State as GlobalState } from './reducers';

export interface NewsItemProperty {
	id: number;
	title: string;
	tags: string[];
	date: string;
	imageUrl: string;
	summary: string;
	link: string;
	has_link: boolean;
}

export type NewsCardDataProperty = Partial<NewsItemProperty>;

type NewsListItemPayload = Pick<State['newsList'], 'data' | 'currentPage' | 'totalPage'>;

const getNewsList = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<NewsListItemPayload>,
	number | undefined
>('GET_NEWS_LIST', (page = 1) => async (_: Dispatch, getState: GetState) => {
	const {
		routing: { pathname, queries },
		locale: { isGlobal },
	} = getState();

	const pageType = pathname.split('/')[isGlobal ? 1 : 2] as NewsPageType;

	try {
		const { status, message, data } = await fetchNewsListFunc({
			search: queries.search,
			page,
			page_type: pageType || 'home',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const { last_page, data: newsData, current_page } = data;

		return {
			data: newsData.map(({ created_at, first_banner_image_url, tag, ...args }) => ({
				date: created_at,
				imageUrl: first_banner_image_url,
				tags: tag.map(({ name }) => name),
				...args,
			})),
			currentPage: current_page,
			totalPage: last_page,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

const getNewsInfo = createAction<Promise<{ data: NewsInfoProperty }>, number>(
	'GET_NEWS_INFO',
	async newsId => {
		try {
			const { status, message, data } = await fetchNewsFunc(newsId);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { other_news, recommended_models, tag, link, ...props } = data;

			return {
				data: {
					other_news: {
						data: other_news.reduce(
							(a, v) => ({
								...a,
								[v.id]: v,
							}),
							{},
						),
						list: other_news.map(d => d.id),
					},
					rentalBikes: {
						data: recommended_models.reduce(
							(a, v) => ({
								...a,
								[v.id]: v,
							}),
							{},
						),
						list: recommended_models.map(d => d.id),
					},
					tags: tag,
					link: link || '',
					...props,
				},
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

const clearNewsList = createAction('CLEAR_NEWS_LIST');

interface NewsInfoProperty {
	id: number;
	banners: SlideProperty[];
	title: string;
	created_at: string;
	tags: TagData[];
	content: string;
	other_news: { data: { [key: number]: OtherNewsData }; list: number[] };
	stores: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
	rentalBikes: { data: { [key: number]: BicycleModel }; list: number[] };
	type: boolean;
	has_link: boolean;
	link: string;
}

export interface State {
	newsList: {
		loading: boolean;
		error: string;
		totalPage: number;
		currentPage: number;
		data: NewsItemProperty[];
	};
	newsInfo: {
		loading: boolean;
		error: string;
		data: NewsInfoProperty;
	};
}

const initialState: State = {
	newsList: {
		loading: false,
		error: '',
		totalPage: 1,
		currentPage: 1,
		data: [],
	},
	newsInfo: {
		loading: false,
		error: '',
		data: {
			id: 0,
			banners: [],
			created_at: '',
			title: '',
			tags: [{ id: 0, name: '', code: '' }],
			content: '',
			other_news: { data: {}, list: [] },
			stores: [], // eslint-disable-line @typescript-eslint/no-explicit-any
			rentalBikes: { data: {}, list: [] },
			type: false,
			has_link: false,
			link: '',
		},
	},
};

type NewsInfoPayload = Pick<State['newsInfo'], 'data'>;

export const reducer = {
	news: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_NEWS_LIST_PENDING: state => ({
				...state,
				newsList: {
					...state.newsList,
					loading: true,
					error: '',
				},
			}),

			GET_NEWS_LIST_FULFILLED: (state, action: Action<NewsListItemPayload>) => ({
				...state,
				newsList: {
					...state.newsList,
					...action.payload,
					loading: false,
					data: [...state.newsList.data, ...action.payload.data],
				},
			}),

			GET_NEWS_LIST_REJECTED: (state, action) => ({
				...state,
				newsList: {
					...state.newsList,
					loading: false,
					error: action.payload.message,
				},
			}),

			CLEAR_NEWS_LIST: () => initialState,

			GET_NEWS_INFO_PENDING: state => ({
				...state,
				newsInfo: {
					...state.newsInfo,
					loading: true,
					error: '',
				},
			}),

			GET_NEWS_INFO_FULFILLED: (state, action: Action<NewsInfoPayload>) => ({
				...state,
				newsInfo: {
					...state.newsInfo,
					...action.payload,
					loading: false,
				},
			}),

			GET_NEWS_INFO_REJECTED: (state, action) => ({
				...state,
				newsInfo: {
					...state.newsInfo,
					loading: false,
					error: action.payload.message,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
++ useNewsList ++
++----------------------------------------------------------------------*/
const selectNewsList = (state: GlobalState) => state.news.newsList;
const newsListActionMap = { getNewsList, clearNewsList };

export const useNewsList = () => useRedux(selectNewsList, newsListActionMap);

/* +----------------------------------------------------------------------
++ useNewsInfo ++
++----------------------------------------------------------------------*/
const selectNewsInfo = (state: GlobalState) => state.news.newsInfo;
const newsInfoActionMap = { getNewsInfo, clearNewsList };

export const useNewsInfo = () => useRedux(selectNewsInfo, newsInfoActionMap);
