import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';

import { focusInChildren } from 'util/helper';
import { useBoolean } from 'util/hook';
import useOnClickOutside from 'util/hook/useOnClickOutside';

import styles from './index.css';

interface OnBlurProperty {
	relatedTarget: HTMLElement;
}

interface ValueComponentProperty {
	isOpen: boolean;
	openPanel: () => void;
	closePanel: () => void;
	onBlur: (props: OnBlurProperty) => void;
}

interface PanelComponentProperty {
	isOpen: boolean;
	openPanel: () => void;
	closePanel: () => void;
}

interface DropdownProperty {
	className?: string;
	valueClassName?: string;
	panelClassName?: string;
	keepOpen?: boolean;
	placement?: 'bottom' | 'top';
	alignment?: 'start' | 'end';
	offset?: [number, number];
	preventToogleOnClick?: boolean;
	preventFocusOnDropdown?: boolean;
	readOnly?: boolean;
	onBlur?: () => void;
	onBlurActivate?: boolean;
	onClickPanelOutside?: () => void;
	valueComponent: (props: ValueComponentProperty) => {};
	panelComponent: (props: PanelComponentProperty) => {};
}

const Dropdown: React.FC<DropdownProperty> = ({
	className,
	valueClassName,
	panelClassName,
	offset,
	keepOpen = false,
	placement = 'bottom',
	alignment = 'start',
	preventToogleOnClick = false,
	preventFocusOnDropdown = false,
	readOnly = false,
	onBlurActivate = true,
	valueComponent,
	panelComponent,
	onBlur = () => {},
	onClickPanelOutside = () => {},
}) => {
	const didMountRef = useRef(false);
	const refValue = useRef<HTMLDivElement | null>(null);
	const refPanel = useRef<HTMLDivElement | null>(null);
	const [isOpen, { toggle: togglePanel, setFalse: closePanel, setTrue: openPanel }] = useBoolean({
		defaultBoolean: keepOpen,
	});

	const onBlurHandler = ({ relatedTarget }: OnBlurProperty) => {
		if (
			onBlurActivate &&
			!keepOpen &&
			!focusInChildren(relatedTarget, refPanel.current as HTMLDivElement) &&
			!focusInChildren(relatedTarget, refValue.current as HTMLDivElement)
		) {
			closePanel();
		}
	};

	useEffect(() => {
		if (refPanel.current && isOpen && !preventFocusOnDropdown) {
			// Focus on panel after dropdown open
			(refPanel.current as HTMLDivElement).focus();
		}
	}, [isOpen, refPanel.current]);

	useEffect(() => {
		if (didMountRef.current) {
			if (!isOpen) {
				onBlur();
			}
		} else {
			didMountRef.current = true;
		}
	}, [isOpen]);

	useEffect(() => {
		if (keepOpen) {
			openPanel();
		} else {
			closePanel();
		}
	}, [keepOpen]);

	useOnClickOutside([refPanel, refValue], onClickPanelOutside, !isOpen);

	return (
		<div className={classnames(styles.dropdown, className)}>
			<div
				ref={refValue}
				className={classnames(styles.content, valueClassName, {
					[styles.defaultCursor]: preventToogleOnClick,
				})}
				onClick={() => {
					if (preventToogleOnClick || readOnly) {
						return;
					}
					togglePanel();
				}}
				onKeyPress={() => {}}
				role="button"
				tabIndex={0}
			>
				{valueComponent({ isOpen, openPanel, closePanel, onBlur: onBlurHandler })}
			</div>
			<div
				ref={refPanel}
				className={classnames(
					styles.menu,
					placement === 'bottom' && styles.placementBottom,
					placement === 'top' && styles.placementTop,
					alignment === 'start' && styles.alignmentStart,
					alignment === 'end' && styles.alignmentEnd,
					isOpen && styles.open,
					panelClassName,
				)}
				style={{
					...(offset && placement === 'bottom' && { top: `calc(100% + ${offset[1]}px)` }),
					...(offset && placement === 'top' && { bottom: `calc(100% + ${offset[1]}px)` }),
					...(offset && alignment === 'start' && { left: offset[0] }),
					...(offset && alignment === 'end' && { right: offset[0] }),
				}}
				onBlur={e => onBlurHandler({ relatedTarget: e.relatedTarget as HTMLElement })}
				role="button"
				tabIndex={0}
			>
				{isOpen && panelComponent({ isOpen, closePanel, openPanel })}
			</div>
		</div>
	);
};

export default Dropdown;
