/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import TooltipTrigger, { Trigger } from 'react-popper-tooltip';
import { Placement } from '@popperjs/core';
import 'react-popper-tooltip/dist/styles.css';

import HtmlContent from 'components/atoms/HtmlContent';

import styles from './index.css';

interface TooltipProperty {
	className?: string;
	tooltip: string;
	hideArrow?: boolean;
	placement?: Placement;
	offset?: [number, number];
	trigger?: Trigger;
	onVisibilityChange?: (tooltipShown: boolean) => void; // Called when the visibility of the tooltip changes
}

const Tooltip: React.FC<TooltipProperty> = ({
	className,
	children,
	tooltip,
	hideArrow,
	offset = [0, 10],
	onVisibilityChange,
	...props
}) => (
	<TooltipTrigger
		{...props}
		modifiers={[
			{
				name: 'offset',
				options: {
					offset,
				},
			},
		]}
		tooltipShown={tooltip === '' ? false : undefined}
		onVisibilityChange={tooltipShown => {
			if (typeof onVisibilityChange === 'function') {
				onVisibilityChange(tooltipShown);
			}
		}}
		tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
			<div
				{...getTooltipProps({
					ref: tooltipRef,
					className: classnames('tooltip-container', styles.tooltipContainer),
				})}
			>
				{!hideArrow && (
					<div
						{...getArrowProps({
							ref: arrowRef,
							className: 'tooltip-arrow',
							'data-placement': placement,
						})}
					/>
				)}
				<HtmlContent content={tooltip} />
			</div>
		)}
	>
		{({ getTriggerProps, triggerRef }) => (
			<div
				{...getTriggerProps({
					ref: triggerRef,
					className: classnames(styles.tooltipTrigger, className),
				})}
			>
				{children}
			</div>
		)}
	</TooltipTrigger>
);

export default Tooltip;
