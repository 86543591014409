import { useState, useEffect, useRef } from 'react';
import { fromEvent, Observable, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

export type Media = 'desktop' | 'mobile' | 'tablet';

export const useMedia = () => {
	const mobileMedia = window.matchMedia('(max-width: 799px)');
	const tabletMedia = window.matchMedia('(max-width: 1279px) and (min-width: 800px)');
	const desktopMedia = window.matchMedia('(min-width: 1280px)');

	let defaultMedia: Media = 'desktop';

	if (mobileMedia.matches) {
		defaultMedia = 'mobile';
	}

	if (tabletMedia.matches) {
		defaultMedia = 'tablet';
	}

	const [media, setMedia] = useState(defaultMedia);

	const handleMediaChange = (mediaName: Media) => (mediaHandler: MediaQueryListEventInit) => {
		if (mediaHandler.matches && mediaName !== media) {
			setMedia(mediaName);
		}
	};

	useEffect(() => {
		const mobileHandler = handleMediaChange('mobile');
		const tabletHandler = handleMediaChange('tablet');
		const desktopHandler = handleMediaChange('desktop');

		mobileMedia.addListener(mobileHandler);
		tabletMedia.addListener(tabletHandler);
		desktopMedia.addListener(desktopHandler);

		return () => {
			mobileMedia.removeListener(mobileHandler);
			tabletMedia.removeListener(tabletHandler);
			desktopMedia.removeListener(desktopHandler);
		};
	}, [media]);

	return media;
};

interface EventHandlers {
	[key: string]: EventListenerOrEventListenerObject;
}

export const useDom = (eventHandlers: EventHandlers) => {
	useEffect(() => {
		Object.keys(eventHandlers).forEach(event =>
			window.addEventListener(event, eventHandlers[event]),
		);

		return () => {
			Object.keys(eventHandlers).forEach(event =>
				window.removeEventListener(event, eventHandlers[event]),
			);
		};
	}, []);
};

export const useResize = () => {
	const [size, setSize] = useState({
		width: document.documentElement.clientWidth,
		height: document.documentElement.clientHeight,
	});

	useEffect(() => {
		let resizeTimer: NodeJS.Timeout;

		const handleResize = () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				setSize({
					width: document.documentElement.clientWidth,
					height: document.documentElement.clientHeight,
				});
			}, 0); // 如果頁面 re-render 太頻繁可以調整這邊
		};

		window.addEventListener('resize', handleResize);

		return () => {
			clearTimeout(resizeTimer);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return size;
};

export default useResize;

// useResize detect the window's scrollY
export const useScroll = () => {
	const [scrollY, setState] = useState(0);
	let scrollTimer: NodeJS.Timeout;

	const handleScroll = () => {
		clearTimeout(scrollTimer);
		scrollTimer = setTimeout(() => {
			setState(window.scrollY);
		}, 0);// 如果頁面 re-render 太頻繁可以調整這邊
	};

	useDom({ scroll: handleScroll });

	return { scrollY };
};

export const useElement = <T extends HTMLElement>(
	{
		defaultHeight = 0,
		defaultWidth = 0,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onUpdateOffsetTop = (_top: number) => {},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onUpdateHeight = (_height: number) => {},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onUpdateWidth = (_width: number) => {},
	} = {},
	triggerEffect = [],
) => {
	const [{ height, width, offsetTop }, setState] = useState({
		height: defaultHeight,
		width: defaultWidth,
		offsetTop: 0,
	});
	const refChild = useRef<T>(null);
	// Get real height, width, offsetTop to check should upldate state or not
	const lastUpdateHeight = useRef(height);
	const lastUpdateWidth = useRef(width);
	const lastUpdateOffsetTop = useRef(offsetTop);

	const handleOffsetTop = () => {
		const node = refChild.current;
		const bodyRect = document.body.getBoundingClientRect();

		if (node) {
			const elemRect = node.getBoundingClientRect();
			const newOffsetTop = elemRect.top - bodyRect.top;

			if (lastUpdateOffsetTop.current !== newOffsetTop) {
				setState(prevState => ({ ...prevState, offsetTop: newOffsetTop }));

				onUpdateOffsetTop(newOffsetTop);
				lastUpdateOffsetTop.current = newOffsetTop;
			}
		}
	};

	const handleHeight = () => {
		const node = refChild.current;

		if (node && lastUpdateHeight.current !== node.offsetHeight && node.offsetHeight > 0) {
			// Update the component height
			setState(prevState => ({ ...prevState, height: node.offsetHeight }));

			onUpdateHeight(node.offsetHeight);
			lastUpdateHeight.current = node.offsetHeight;
		}
	};

	const handleWidth = () => {
		const node = refChild.current;

		if (node && lastUpdateWidth.current !== node.offsetWidth && node.offsetWidth > 0) {
			// Update the component width
			setState(prevState => ({ ...prevState, width: node.offsetWidth }));

			onUpdateWidth(node.offsetWidth);
			lastUpdateWidth.current = node.offsetWidth;
		}
	};

	const handleDomEvent = () => {
		if (refChild.current !== null) {
			handleHeight();
			handleWidth();
			handleOffsetTop();
		}
	};

	const handleScroll = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	const handleResize = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	const handleMutation = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	useDom({ scroll: handleScroll, resize: handleResize, DOMNodeInserted: handleMutation });

	useEffect(() => {
		let didUnsubscribe = false;

		const updateDomHandle = () => {
			if (didUnsubscribe) {
				return;
			}
			handleDomEvent();
		};

		// Setup initial value
		updateDomHandle();

		return () => {
			didUnsubscribe = true;
		};
	}, [refChild, ...triggerEffect]);

	return { refChild, height, width, offsetTop };
};

export const useMove = (dom: HTMLElement): Observable<{ x: number; y: number }> => {
	const initalDirection = { x: 0, y: 0 };

	if (!dom) {
		return of(initalDirection);
	}

	const touchEventToCoordinate = (touchEvent: TouchEvent) => {
		// touchEvent.preventDefault();
		return {
			x: touchEvent.changedTouches[0].clientX,
			y: touchEvent.changedTouches[0].clientY,
		};
	};

	const start$ = fromEvent(dom, 'touchstart', { passive: false }).pipe(
		map(e => touchEventToCoordinate(e as TouchEvent)),
	);
	const move$ = fromEvent(dom, 'touchmove').pipe(map(e => touchEventToCoordinate(e as TouchEvent)));
	const end$ = fromEvent(dom, 'touchend').pipe(map(e => touchEventToCoordinate(e as TouchEvent)));

	return start$.pipe(
		switchMap(start => {
			return move$.pipe(
				first(),
				shareReplay(),
				filter(move => move.x !== start.x || move.y !== start.y),
				map(move => ({ x: move.x - start.x, y: move.y - start.y })),
				takeUntil(end$),
			);
		}),
	);
};

type SlideDirection = 'top' | 'bottom' | 'left' | 'right' | '';
let timeoutID: number | undefined;

export const useSlideDeriction = (dom: HTMLElement): Observable<SlideDirection> => {
	return useMove(dom).pipe(
		map(val => {
			if (val.y <= 7 && val.y >= -7) {
				if (val.x < -0.2) {
					document.body.style.overflow = 'hidden';
					return 'left';
				}
				if (val.x > 0.2) {
					document.body.style.overflow = 'hidden';
					return 'right';
				}
			} else {
				if (val.y < -0.2) {
					return 'top';
				}
				if (val.x > 0.2) {
					return 'bottom';
				}
			}
			return '';
		}),
		tap(val => {
			if ((val === 'left' || val === 'right') && document.body.style.overflow === 'hidden') {
				if (timeoutID) {
					window.clearTimeout(timeoutID);
				}
				timeoutID = window.setTimeout(() => {
					document.body.style.overflow = '';
				}, 200);
			}
		}),
	);
};
