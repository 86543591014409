/* eslint-disable indent */
import { handleActions, createAction } from 'redux-actions';

import { DynamicState } from 'types/DynamicState';
import { Difficulty } from 'types/DifficultyCode';
import { RoomInfo } from 'types/RoomInfo';

import { useRedux } from 'util/hook/redux';

import { fetchTravelTourListFunc, TravelTourListFetchParams } from 'api/travelTour';
import {
	TypeIdsApiData,
	fetchTypeIdsFunc,
	TypeIdsMultiApiData,
	fetchTypeIdsMultiFunc,
} from 'api/travelCustomTours';
import { fetchRidingLevelsFunc } from 'api/customTravelForm';
import { State as GlobalState } from './reducers';

export interface State {
	tourItems: DynamicState<ApiTravelTourData | null>;
	filterPanelTypeIds: DynamicState<TypeIdsMultiApiData[] | null>;
	filterPanelRidingLevels: DynamicState<Difficulty[] | null>;
}

const initialState: State = {
	tourItems: {
		data: null,
		loading: false,
		error: 'null',
	},
	filterPanelTypeIds: {
		data: null,
		loading: false,
		error: 'null',
	},
	filterPanelRidingLevels: {
		data: null,
		loading: false,
		error: 'null',
	},
};

/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/**
 * getTravelTour Action 區塊 用來拿取整個 travel-tours 頁面的 API 資料
 */

type TravelTypes = {
	id: number;
	name: string;
};

export interface ApiTravelTourItem {
	id: number; // 該梯次的獨特 id, 就算同活動也可能不同梯次
	travel_id: number; // 該活動的id 可能一個活動會有不同梯次
	name: string;
	duration_day: string;
	start_location: string;
	start_location_note: string;
	start_date: string;
	start_date_week: number;
	difficulty: Difficulty;
	travel_type: TravelTypes[];
	lowest_price: string;
	rooms: RoomInfo[];
	max_quota: number;
	min_quota: number;
	registered_count: number;
	wating_count: number; // 這邊拼字錯誤，有時間再請後端改吧
	deadline_date: string;
	active_status: string; // 需修改 目前旅遊狀態相關 enum 沒有範例 只有一個 'REGISTER'  先用 string;
	itinerary_status: 'NOT_ESTABLISHED' | 'FORMED' | 'UNFORMED' | 'FAILED'; // 行程狀態
}

export interface ApiTravelTourData {
	last_page: number;
	data: ApiTravelTourItem[];
}

type GetTravelTourPayload = Partial<DynamicState<ApiTravelTourData | null>>;

const getTravelTour = createAction<Promise<GetTravelTourPayload>, TravelTourListFetchParams>(
	'GET_TRAVEL_TOUR',
	async params => {
		try {
			const { status, message, data } = await fetchTravelTourListFunc(params);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong in GET_TRAVEL_TOUR' };
		}
	},
);

/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */

type GetTravelCustomToursTypeIdsPayload = Partial<DynamicState<TypeIdsMultiApiData[] | null>>;

/**
 * 取得旅遊客製包團行程類型列表篩選器 - 旅遊行程
 * 注意這邊取得的是兩階層 check box 旅遊行程的選項
 */
const getTravelCustomToursTypeIds = createAction<Promise<GetTravelCustomToursTypeIdsPayload>, void>(
	'GET_TRAVEL_CUSTOM_TOURS_TYPE_IDS',
	async () => {
		try {
			const { status, message, data } = await fetchTypeIdsMultiFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data };
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */

type GetRidingLevelsDataPayload = Partial<DynamicState<Difficulty[] | null>>;

/**
 *  從API獲取騎乘強度資料的action
 */
const getRidingLevelsData = createAction<Promise<GetRidingLevelsDataPayload>>(
	'GET_RIDING_LEVELS',
	async () => {
		try {
			const { status, message, data } = await fetchRidingLevelsFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data };
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/**
 * Reducers
 */
type ReducerTravelTourPayloads = GetTravelTourPayload &
	GetTravelCustomToursTypeIdsPayload &
	GetRidingLevelsDataPayload;

export const reducer = {
	travelTour: handleActions<State, ReducerTravelTourPayloads>(
		{
			GET_TRAVEL_TOUR_PENDING: state => ({
				...state,
				tourItems: {
					...state.tourItems,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_TOUR_FULFILLED: (state, action) => ({
				...state,
				tourItems: {
					...state.tourItems,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_CUSTOM_TOURS_TYPE_IDS_PENDING: state => ({
				...state,
				filterPanelTypeIds: {
					...state.filterPanelTypeIds,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CUSTOM_TOURS_TYPE_IDS_FULFILLED: (state, action) => ({
				...state,
				filterPanelTypeIds: {
					...state.filterPanelTypeIds,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_RIDING_LEVELS_PENDING: state => ({
				...state,
				filterPanelRidingLevels: {
					...state.filterPanelRidingLevels,
					loading: true,
					error: '',
				},
			}),

			GET_RIDING_LEVELS_FULFILLED: (state, action) => ({
				...state,
				filterPanelRidingLevels: {
					...state.filterPanelRidingLevels,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTravelTour ++
++----------------------------------------------------------------------*/
const selectTravelTourList = (state: GlobalState) => state.travelTour.tourItems.data;

export const useTravelTour = () => useRedux(selectTravelTourList, { getTravelTour });

/* +----------------------------------------------------------------------
++ useSelectTypeIds ++
++----------------------------------------------------------------------*/
const selectTypeIds = (state: GlobalState) => state.travelTour.filterPanelTypeIds.data;

export const useSelectTypeIds = () => useRedux(selectTypeIds, { getTravelCustomToursTypeIds });

/* +----------------------------------------------------------------------
++ useSelectRidingLevels ++
++----------------------------------------------------------------------*/
const selectRidingLevels = (state: GlobalState) => state.travelTour.filterPanelRidingLevels.data;

export const useSelectRidingLevels = () => useRedux(selectRidingLevels, { getRidingLevelsData });
