import { wrapRetryAdminFetch } from 'util/api';
import { AdminTravelOrderAddPurchaseData } from 'types/TravelOrderParticipantPaymentDetail';
import { FetchResponse } from 'types/FetchResponse';

export const fetchAdminTravelOrderAddPurchaseFunc = async (
	selector: string,
	lang: string,
): Promise<FetchResponse<AdminTravelOrderAddPurchaseData[]>> => {
	const response = await wrapRetryAdminFetch<AdminTravelOrderAddPurchaseData[]>(
		`travelOrder/${selector}/participants/products?lang=${lang}`,
		{
			method: 'GET',
		},
	);

	return response;
};

export const submitAdminTravelOrderAddPurchaseFunc = async (
	selector: string,
	lang: string,
	data: { updateItems: AdminTravelOrderAddPurchaseData[] },
): Promise<FetchResponse<null>> => {
	const response = await wrapRetryAdminFetch<null>(
		`travelOrder/${selector}/participants/products?lang=${lang}`,
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
	);

	return response;
};
