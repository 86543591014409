import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { localeParser } from 'util/helper';
import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/documentBackgroundScroll';

import { appPathKey } from 'util/routingConfig';
import { AppConfig, fetchConfigFunc } from 'api/config';
import { updateCurrentLocale } from 'models/locale';

import { GetState, State as GlobalState } from './reducers';

const setInitialed = createAction('SET_INITIALED');

const setInitial = createAction('SET_INITIAL');

const setBackgroundScrollY = createAction('SET_BACKGROUND_SCROLLY', () => {
	fixBackgroundScrollY();
});

const restoreBackgroundScrollY = createAction('RESTORE_BACKGROUND_SCROLLY', async () => {
	await unfixBackgroundScrollY();
});

const defaultAppConfig: AppConfig = {
	currency: '',
	default_lang: 'en',
	allowed_langs: ['en'],
	headers: [],
	subnavis: {},
};

export const fetchAppConfig = createAction('FETCH_APP_CONFIG', async () => {
	try {
		const { status, message, data } = await fetchConfigFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: data || defaultAppConfig,
		};
	} catch (error) {
		return { data: defaultAppConfig };
	}
});

/**
 * 初始化網站設定
 * 1. 判斷目前 URL 是 Global 還是 地區，並切換到對應的 Locale
 * 2. fetchAppConfig 拿網站基本設定
 */
const initialSetting = createAction(
	'INITIAL_SETTING',
	() => async (dispatch: Dispatch, getState: GetState) => {
		try {
			const {
				locale: {
					currentLocCode,
					locales: {
						data: { byCode: locByCode },
					},
				},
			} = getState();

			const { valid, locale } = localeParser(locByCode);

			if (valid && currentLocCode !== locale.code) {
				await dispatch(updateCurrentLocale(locale.code as string));
			}

			await dispatch(fetchAppConfig());
		} catch (error) {
			// dispatch(initialSetting());
			console.log(error);
		}
	},
);

export interface State {
	isInitialed: boolean;
	isBackgroundFixed: boolean;
	config: AppConfig;
}

const initialState: State = {
	isInitialed: false,
	isBackgroundFixed: false,
	config: defaultAppConfig,
};

export const reducer = {
	global: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_INITIALED: state => ({
				...state,

				isInitialed: true,
			}),

			SET_INITIAL: state => ({
				...state,

				isInitialed: false,
			}),

			SET_BACKGROUND_SCROLLY: state => ({
				...state,

				isBackgroundFixed: true,
			}),

			RESTORE_BACKGROUND_SCROLLY_FULFILLED: state => ({
				...state,

				isBackgroundFixed: false,
			}),

			FETCH_APP_CONFIG_FULFILLED: (state, action) => ({
				...state,
				config: action.payload.data,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => state.global;

const globalActionsMap = {
	setInitialed,
	setInitial,
	initialSetting,
	setBackgroundScrollY,
	restoreBackgroundScrollY,
};

type GlobalSelector = ReturnType<typeof mapHooksToState>;
type GlobalActionsMap = typeof globalActionsMap;

export const useGlobal = () =>
	useRedux<GlobalSelector, GlobalActionsMap>(mapHooksToState, globalActionsMap);

const selectConfig = (state: GlobalState) => ({
	...state.global.config,
	allAppPaths: state.global.config.headers.reduce(
		(a, c) => [...a, c, ...(state.global.config.subnavis[c] || [])],
		['account'] as appPathKey[],
	),
});

const configActionsMap = { fetchAppConfig };

type ConfigSelector = ReturnType<typeof selectConfig>;
type ConfigActionsMap = typeof configActionsMap;

export const useConfig = () =>
	useRedux<ConfigSelector, ConfigActionsMap>(selectConfig, configActionsMap);
