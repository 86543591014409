import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';
import { ApiTravelTourData } from 'models/travelTour';

/**
 * 取得此頁的資料 /travel/travel-tours
 * API: /api/v1/web/travel/itinerary
 */

export interface TravelTourListFetchParams {
	page?: number;
	per_page?: number;
	search?: string;
	start_date?: string;
	// end_date?: string; // 目前用不到但 API 有提供
	min_start_date?: number;
	max_start_date?: number;
	mix_duration_day?: number;
	max_duration_day?: number;
	type_ids?: number[];
	difficulty_ids?: number[];
	location_id?: number;
}

export type TravelTourListFetch = (
	params: TravelTourListFetchParams,
) => Promise<FetchResponse<ApiTravelTourData>>;

export const fetchTravelTourListFunc: TravelTourListFetch = async params => {
	const response = await wrapFetch(
		'travel/itinerary',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};
