/* eslint-disable indent */
import { handleActions, createAction } from 'redux-actions';

import { useRedux } from 'util/hook/redux';

import {
	TypeIdsApiData,
	fetchTravelCustomToursList,
	fetchTypeIdsFunc,
} from 'api/travelCustomTours';
import { DynamicState } from 'types/DynamicState';
import { TravelToursList, FetchToursParams } from 'types/travelClassicAndCustomTours';

import { State as GlobalState } from './reducers';

export interface State {
	travelCustomToursList: DynamicState<TravelToursList | null>;
	travelCustomToursTypeIds: DynamicState<TypeIdsApiData[] | null>;
}

const initialState: State = {
	travelCustomToursList: {
		data: null,
		loading: false,
		error: '',
	},
	travelCustomToursTypeIds: {
		data: null,
		loading: false,
		error: '',
	},
};

type GetTravelCustomToursListPayload = Partial<DynamicState<TravelToursList | null>>;

/**
 * 取得旅遊客製包團行程列表
 */
const getTravelCustomToursList = createAction<
	Promise<GetTravelCustomToursListPayload>,
	FetchToursParams
>('GET_TRAVEL_CUSTOM_TOURS_LIST', async params => {
	try {
		const { status, message, data } = await fetchTravelCustomToursList(params);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: {
				last_page: data.last_page,
				data: data.data.map(
					({
						id,
						name,
						duration_day,
						first_banner_image_url,
						is_liked,
						difficulty,
						lowest_price,
						rooms,
						start_location,
						min_quota,
						bicycle_type,
					}) => ({
						id,
						name,
						duration_day,
						first_banner_image_url,
						is_liked,
						difficulty,
						lowest_price,
						rooms,
						start_location,
						min_quota,
						bicycle_type,
					}),
				),
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

/** ____________________________________________________________________________________________________________________________________________________________________________________ */

type GetTravelCustomToursTypeIdsPayload = Partial<DynamicState<TypeIdsApiData[] | null>>;

/**
 * 取得旅遊客製包團行程類型列表篩選器 - 旅遊行程
 */
const getTravelCustomToursTypeIds = createAction<Promise<GetTravelCustomToursTypeIdsPayload>, void>(
	'GET_TRAVEL_CUSTOM_TOURS_TYPE_IDS',
	async () => {
		try {
			const { status, message, data } = await fetchTypeIdsFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data };
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

/*
 * reducer payload type
 */
type TravelCustomToursListReducerPayload = GetTravelCustomToursListPayload &
	GetTravelCustomToursTypeIdsPayload;

export const reducer = {
	travelCustomTours: handleActions<State, TravelCustomToursListReducerPayload>(
		{
			GET_TRAVEL_CUSTOM_TOURS_LIST_PENDING: state => ({
				...state,
				travelCustomToursList: {
					...state.travelCustomToursList,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CUSTOM_TOURS_LIST_FULFILLED: (state, action) => ({
				...state,
				travelCustomToursList: {
					...state.travelCustomToursList,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_CUSTOM_TOURS_TYPE_IDS_PENDING: state => ({
				...state,
				travelCustomToursTypeIds: {
					...state.travelCustomToursTypeIds,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CUSTOM_TOURS_TYPE_IDS_FULFILLED: (state, action) => ({
				...state,
				travelCustomToursTypeIds: {
					...state.travelCustomToursTypeIds,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTravelCustomTours ++
++----------------------------------------------------------------------*/

const selectTravelCustomTours = (state: GlobalState) =>
	state.travelCustomTours.travelCustomToursList;

export const useTravelCustomTours = () =>
	useRedux(selectTravelCustomTours, { getTravelCustomToursList });

/* +----------------------------------------------------------------------
	++ useTravelCustomToursTypeIds ++
++----------------------------------------------------------------------*/

const selectTravelCustomToursTypeIds = (state: GlobalState) =>
	state.travelCustomTours.travelCustomToursTypeIds;

export const useTravelCustomToursTypeIds = () =>
	useRedux(selectTravelCustomToursTypeIds, { getTravelCustomToursTypeIds });
