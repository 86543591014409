import { Action, createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import moment from 'moment';

import { FilterSelectionItem } from 'types/FilterSelectionItem';
import { BannerItem } from 'types/BannerItem';
import { Difficulty } from 'types/DifficultyCode';

import { StoreItemProperty } from 'models/stores';

import { fetchPathsInfoFunc, fetchPathsMenuFunc, fetchPathsSearchFunc } from 'api/paths';

import { State as GlobalState, GetState } from './reducers';

interface RouteInfoPhotoProperty {
	id: number;
	url: string;
}

export type RouteInfoTransportationProperty = 'BICYCLE' | 'PLANE' | 'BUS' | 'SHIP' | 'TRAIN';

export interface RouteInfoProperty {
	id: number;
	date?: string;
	time: string;
	distance?: string;
	title: string;
	route?: string;
	meal?: string;
	housing?: string;
	content?: string;
	contentTitle?: string;
	transportation?: RouteInfoTransportationProperty[];
	photos?: RouteInfoPhotoProperty[];
}

export interface RentalRouteDetailProperty {
	id: number;
	title: string;
	city: string;
	store: string;
	days: string | null;
	distance: number | null;
	difficulty: Difficulty | null;
	thumbnailUrl: string;
	intro: {
		content: string;
		photo: string;
	};
	banners: BannerItem[];
	schedule: RouteInfoProperty[];
	isSaved: boolean;
}

export type RouteCardDataProperty = Pick<
	RentalRouteDetailProperty,
	'id' | 'difficulty' | 'title' | 'days' | 'store' | 'thumbnailUrl' | 'isSaved'
>;

const defaultRentalDetail: RentalRouteDetailProperty = {
	id: -1,
	title: '',
	city: '',
	store: '',
	days: null,
	distance: null,
	difficulty: null,
	thumbnailUrl: 'https://fakeimg.pl/1280x800/0082ca/fff/?text=photo',
	intro: {
		content: '',
		photo: '',
	},
	banners: [],
	schedule: [],
	isSaved: false,
};

export interface State {
	filters: {
		locations: FilterSelectionItem[];
		level: FilterSelectionItem[];
		category: FilterSelectionItem[];
	};
	recommendRoutes: {
		currentPage: number;
		allPages: number;
		loading: boolean;
		data: RouteCardDataProperty[];
		error: string;
	};
	recommendStores: StoreItemProperty[];
	routeDetail: RentalRouteDetailProperty;
}

const initialState: State = {
	filters: {
		locations: [],
		level: [],
		category: [],
	},
	recommendRoutes: {
		currentPage: 1,
		allPages: 1,
		loading: false,
		data: [],
		error: '',
	},
	recommendStores: [],
	routeDetail: defaultRentalDetail,
};

const fetchRentalRouteDetail = createAction<
	Promise<{
		routeDetail: RentalRouteDetailProperty;
		recommendRoutes: RouteCardDataProperty[];
		recommendStores: StoreItemProperty[];
	}>,
	number
>('FETCH_RENTAL_ROUTE_DETAIL', async routeId => {
	try {
		const { status, message, data } = await fetchPathsInfoFunc(routeId);

		if ((status !== 200 && status !== 201) || data === undefined) {
			throw new Error(message);
		}

		return {
			routeDetail: {
				id: data.id,
				title: data.title,
				city: data.city_name,
				store: data.store_name,
				days: data.day,
				distance: data.distance,
				difficulty: data.difficulty,
				thumbnailUrl: data.img_url,
				intro: {
					content: data.description,
					photo: data.img_url,
				},
				banners: data.banners,
				schedule: data.path_contents.map(({ id, time, title, description }) => ({
					id,
					time,
					title,
					content: description,
					photos: [],
				})),
			},
			recommendRoutes: data.recommended_paths.map(pathData => ({
				id: pathData.id,
				title: pathData.title,
				store: pathData.store_name,
				days: pathData.day,
				difficulty: pathData.difficulty,
				thumbnailUrl: pathData.first_banner_image_url,
				isSaved: pathData.is_liked,
			})),
			recommendStores: data.recommended_stores.map(storeData => {
				const formattedOpenTime = moment(`2020-01-01 ${storeData.weekday_open_time}`).format(
					'HH:mm',
				);
				const formattedCloseTime = moment(`2020-01-01 ${storeData.weekday_close_time}`).format(
					'HH:mm',
				);

				return {
					id: storeData.id,
					name: storeData.name,
					time: `${formattedOpenTime} - ${formattedCloseTime}`,
					address: storeData.address,
					email: storeData.emails,
					phone: storeData.telephone,
					imageUrl: storeData.first_banner_image_url,
					links: [
						{
							name: 'facebook',
							href: storeData.facebook,
						},
						{
							name: 'instagram',
							href: storeData.instagram,
						},
					].filter(d => d.href),
				};
			}),
		};
	} catch (error) {
		return {
			routeDetail: defaultRentalDetail,
			recommendRoutes: [],
			recommendStores: [],
		};
	}
});

const toggleRecommendRoute = createAction(
	'TOGGLE_RECOMMEND_ROUTE',
	async (id: number) => (_: Dispatch, getState: GetState) => {
		// TODO: send isSaved data to backend with api

		const {
			rentalRoutes: {
				recommendRoutes: { data: recommendList },
			},
		} = getState();

		return recommendList.map(data => {
			if (data.id === id) {
				return {
					...data,
					isSaved: !data.isSaved,
				};
			}

			return data;
		});
	},
);

const fetchRentalRouteFilters = createAction(
	'FETCH_RENTAL_ROUTE_FILTERS',
	async (countryId: number) => {
		try {
			const { status, message, data } = await fetchPathsMenuFunc(countryId);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				locations: data.cities.map(({ id, name }) => ({ id, label: name })),
				level: data.difficulties
					.filter(({ id }) => id)
					.map(({ id, name }) => ({ id, label: name })),
				category: data.path_types.map(({ id, name }) => ({ id, label: name })),
			};
		} catch (error) {
			return {
				locations: [],
				level: [],
				category: [],
			};
		}
	},
);

type FetchRentalRouteListPayload = Partial<State['recommendRoutes']>;

const fetchRentalRouteList = createAction<
	Promise<FetchRentalRouteListPayload>,
	{
		locationIds?: number[];
		levels?: number[];
		categories?: number[];
		keywords?: string;
	}
>(
	'FETCH_RENTAL_ROUTE_LIST',
	async ({ locationIds = [], levels = [], categories = [], keywords = '' }) => {
		try {
			const { status, message, data } = await fetchPathsSearchFunc({
				location_ids: locationIds,
				difficulty_ids: levels,
				path_type_ids: categories,
				search: keywords,
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: data.map(pathData => ({
					id: pathData.id,
					title: pathData.title,
					store: pathData.store_name,
					days: `${pathData.day}`,
					difficulty: pathData.difficulty,
					thumbnailUrl: pathData.first_banner_image_url,
					isSaved: pathData.is_liked,
				})),
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				if (message === 'Path  not found') {
					return { data: [] };
				}
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

export const reducer = {
	rentalRoutes: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			FETCH_RENTAL_ROUTE_DETAIL_PENDING: state => ({
				...state,
				recommendRoutes: {
					...state.recommendRoutes,
					loading: true,
				},
			}),

			FETCH_RENTAL_ROUTE_DETAIL_FULFILLED: (state, action) => ({
				...state,
				routeDetail: action.payload.routeDetail,
				recommendRoutes: {
					...state.recommendRoutes,
					loading: false,
					data: action.payload.recommendRoutes,
				},
				recommendStores: action.payload.recommendStores,
			}),

			TOGGLE_RECOMMEND_ROUTE_FULFILLED: (state, action) => ({
				...state,
				recommendRoutes: {
					...state.recommendRoutes,
					data: action.payload,
				},
			}),

			FETCH_RENTAL_ROUTE_FILTERS_FULFILLED: (state, action) => ({
				...state,
				filters: action.payload,
			}),

			FETCH_RENTAL_ROUTE_LIST_FULFILLED: (state, action: Action<FetchRentalRouteListPayload>) => ({
				...state,
				recommendRoutes: {
					...state.recommendRoutes,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
				},
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => state.rentalRoutes;

export const useRentalRoutes = () =>
	useRedux(mapHooksToState, {
		fetchRentalRouteFilters,
		fetchRentalRouteList,
		toggleRentalRoute: toggleRecommendRoute,
	});

/* +----------------------------------------------------------------------
	++ useRecommendedRoutes ++
++----------------------------------------------------------------------*/
const selectRecommendedRoutes = (state: GlobalState) => state.rentalRoutes.recommendRoutes;

export const useRecommendedRoutes = () =>
	useRedux(selectRecommendedRoutes, { toggleRecommendRoute, fetchRentalRouteList });

/* +----------------------------------------------------------------------
	++ useRentalRoute ++
++----------------------------------------------------------------------*/
const selectRoute = (state: GlobalState) => ({
	routeDetail: state.rentalRoutes.routeDetail,
	recommendStores: state.rentalRoutes.recommendStores,
});

export const useRentalRoute = () => useRedux(selectRoute, { fetchRentalRouteDetail });
