import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';
import AuthGiantLayout from 'layouts/AuthGiantLayout';

import MemberOrderRoute from './Order';
import JourneysRoute from './Journeys';
import MemberEditRoute from './Edit';
import WishListRoute from './WishList';
import CertificateRoute from './Certificate';

const routes: CustomRoute = {
	path: appPath.account,
	components: () => [],
	render: (_, children) => <AuthGiantLayout>{children}</AuthGiantLayout>,
	children: [MemberOrderRoute, JourneysRoute, WishListRoute, CertificateRoute, MemberEditRoute],
};

export default routes;
