import { wrapRetryAdminFetch } from 'util/api';
import { AdminBicycleForm } from 'models/adminBicycleForms';

export interface StockBicycleInfo {
	id: number;
	gts_number: string;
	bicycle_number: string;
	position: string;
	rental_type: string;
	bicycle_spec: string;
	updated_at: string;
	forms: AdminBicycleForm[];
}

/**
 * StockBicycle - 取得車輛資訊與開放轉導表單列表
 * @param bicycleNumber
 * @returns
 */
export const fetchStockBicycleInfoFunc = async (bicycleNumber: string) => {
	const response = await wrapRetryAdminFetch<StockBicycleInfo | never>(
		`bicycle/${bicycleNumber}/info`,
		{
			method: 'GET',
		},
	);

	return response;
};

export interface StockBicycleDestination {
	id: number;
	to_position_type: string;
	to_position_id: number;
	logistics_date: string;
	name: string;
}

/**
 * StockBicycle - 取得車輛可配送終點列表
 * @param bicycleId
 * @returns
 */
export const fetchStockBicycleDestinationsFunc = async (bicycleId: number) => {
	const response = await wrapRetryAdminFetch<StockBicycleDestination[]>(
		`bicycle/${bicycleId}/destinations`,
		{ method: 'GET' },
	);

	return response;
};

export interface StockBicycleOrderForReturn {
	number: string;
	picked_up_at: string;
}

/**
 * StockBicycle - 取得車輛訂單
 * @param bicycleId
 * @returns
 */
export const fetchStockBicycleOrderFunc = async (bicycleId: number) => {
	const response = await wrapRetryAdminFetch<StockBicycleOrderForReturn>(
		`bicycle/${bicycleId}/order`,
		{
			method: 'GET',
		},
	);

	return response;
};

export interface StockBicycleOrderForPickup {
	id: number;
	number: string;
}

/**
 * StockBicycle - 取得可取車訂單編號列表
 * @param bicycleId
 * @returns
 */
export const fetchStockBicycleOrdersFunc = async (bicycleId: number) => {
	const response = await wrapRetryAdminFetch<StockBicycleOrderForPickup[]>(
		`bicycle/${bicycleId}/orders`,
		{
			method: 'GET',
		},
	);

	return response;
};

export interface BicycleInventoryPosition {
	position_type: '';
	position_id: number;
	name: string;
}

/**
 * BicycleInventoryPosition - 取得車輛庫存位置列表
 * @param null
 * @returns 包含 positions 物件的陣列
 */
export const fetchBicycleInventoryPositionFunc = async () => {
	const response = await wrapRetryAdminFetch<BicycleInventoryPosition[]>(
		`bicycle/inventory-positions`,
		{
			method: 'GET',
		},
	);

	return response;
};