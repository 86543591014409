/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import { GenderCodes } from 'types/Gender';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import {
	setupParticipantFormByCourseOrder,
	setEventsTypeId,
	setupEventParticipantTemplateProcess,
} from 'models/eventsParticipant';
import { BikeclassesFormDataType } from 'models/eventsParticipant/type';
import { openModal } from 'models/modal';
import { participantAddPurchaseCalculator } from 'models/rideOrder';
import { getDocument } from 'models/document';
import { getCourseReservationInfo } from 'models/course';
import { getCancelOrderInfo } from 'models/orderInfo';

import {
	cancelCourseOrderFunc,
	CourseOrder,
	CourseParticipant,
	fetchCourseOrderBySelectorFunc,
	updateCourseOrderBySelectorFunc,
} from 'api/courseOrder';
import { Product, submitAdminCourseOrderAddPurchaseFunc } from 'api/eventsOrderAdmin';

import { GetState, State as GlobalState } from './reducers';

export interface CourseOrderBySelector extends Omit<CourseOrder, 'participants'> {
	participants: {
		ids: number[];
		byIds: { [id: number]: CourseParticipant };
	};
}

export interface State {
	detail: FetchedData<string>;
	bySelector: Record<CourseOrder['selector'], CourseOrderBySelector>;
}

const initialState: State = {
	detail: {
		loading: false,
		error: '',
		data: '',
	},
	bySelector: {},
};

interface GetCourseOrderPayload {
	error?: string;
	data?: CourseOrderBySelector;
}

const getCourseOrderBySelector = createAction<Promise<GetCourseOrderPayload>, string>(
	'GET_COURSE_ORDER_BY_SELECTOR',
	async selector => {
		try {
			const { status, message, data } = await fetchCourseOrderBySelectorFunc(selector);

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			const { participants } = data;

			const normalizedParticipants = participants.reduce(
				(a, v) => ({ ids: [...a.ids, v.id], byIds: { ...a.byIds, [v.id]: v } }),
				{ ids: [] as number[], byIds: {} as { [id: number]: CourseParticipant } },
			);

			const newData = { ...data, participants: normalizedParticipants };

			return {
				data: newData,
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return {
					error: message,
				};
			}
			return {
				error: '',
			};
		}
	},
);

const setupParticipantByCourseOrderProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>(
	'SETUP_PARTICIPANT_BY_COURSE_ORDER_PROCESS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			courseOrder: {
				detail: { data: selector },
				bySelector,
			},
		} = getState();
		const data = bySelector[selector];

		// if still can edit, setup participant form
		data.participants.ids.forEach(id => {
			dispatch(setupParticipantFormByCourseOrder(id, 'bikeclasses'));
		});
	},
);

const getCourseOrderAndEventDetail = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>(
	'GET_COURSE_ORDER_AND_EVENT_DETAIL',
	selector => async (dispatch: Dispatch, getState: GetState) => {
		await dispatch(getCourseOrderBySelector(selector));

		const {
			courseOrder: { bySelector },
		} = getState();

		const { course_id: courseId, date_id: dateId } = bySelector[selector];

		await dispatch(getCourseReservationInfo({ courseId, dateId }));
	},
);

const initialCourseOrderDetailProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>(
	'INITIAL_COURSE_ORDER_DETAIL_PROCESS',
	selector => async (dispatch: Dispatch, getState: GetState) => {
		await dispatch(getCourseOrderAndEventDetail(selector));

		const {
			courseOrder: { bySelector },
			locale: {
				locales: {
					data: { byCode },
				},
			},
		} = getState();

		const orderData = bySelector[selector];
		const eventId = orderData.course_id;

		await Promise.all([
			dispatch(
				setEventsTypeId({
					eventType: 'bikeclasses',
					eventId,
				}),
			),
			dispatch(
				getDocument({
					type: 'RIDER',
					code: 'CODE_THEME_ACTIVITY_PARENT',
					country_id: byCode?.TW.id,
				}),
			),
			// 取得訂單取消說明文案
			dispatch(
				getCancelOrderInfo({
					type: 'COURSE',
					country_id: orderData.country_id,
				}),
			),
		]);

		await dispatch(setupEventParticipantTemplateProcess('bikeclasses'));
		await dispatch(setupParticipantByCourseOrderProcess());
	},
);

type UpdateCourseOrderParticipantDataPayload = {
	selector: string;
	participantId: number;
	data: Partial<CourseParticipant>;
};

const updateCourseOrderParticipantData = createAction<
	UpdateCourseOrderParticipantDataPayload,
	UpdateCourseOrderParticipantDataPayload
>('UPDATE_COURSE_ORDER_PARTICIPANT_DATA', ({ selector, participantId, data }) => ({
	selector,
	participantId,
	data,
}));

const updateCourseOrderBySelector = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>('UPDATE_RIDE_ORDER_BY_SELECTOR', selector => async (dispatch: Dispatch, getState: GetState) => {
	const {
		eventsParticipant: {
			form: {
				editing: { id: participantId, data: participantForm },
			},
		},
	} = getState();
	try {
		if (participantId === null || participantForm === null) {
			throw new Error('empty participant');
		}
		let newData: Partial<CourseParticipant> = { id: participantId };
		const form = participantForm as FormDataMapping<BikeclassesFormDataType>;
		newData = {
			...newData,
			first_name: form.firstName.value,
			last_name: form.lastName.value,
			en_first_name: form.firstNameEn.value,
			en_last_name: form.lastNameEn.value,
			identity_no: form.citizenId.value,
			gender: form.sex.value.value as GenderCodes,
			birthday: form.birthday.value ? form.birthday.value.format('YYYY-MM-DD') : '',
			height: Number(form.height.value),
			weight: Number(form.weight.value),
			phone: `${form.phone.value.intIdNum} ${form.phone.value.phoneNum}`,
			email: form.email.value,
			contact_name: form.emergencyName.value,
			contact_relationship: form.emergencyRelation.value.value || '',
			country: form.country.value,
			city: form.city.value,
			address: form.address.value,
			note: form.otherNote.value,
			contact_phone: `${form.emergencyPhone.value.intIdNum} ${form.emergencyPhone.value.phoneNum}`,
			extra_field_1: form.custom1.value,
			extra_field_2: form.custom2.value,
			extra_field_3: form.custom3.value,
			eating_habit: form.eatingHabit.value.value,
			eating_habit_note: form.eatingHabitNote.value,
			medical_record: form.specialDisease.value.value || false,
			medical_record_note: form.specialDiseaseNote.value,
			has_participated: form.hasParticipated.value.value || false,
			// parent_consent: null,
		};

		const { status, message } = await updateCourseOrderBySelectorFunc(selector, [newData]);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}
		dispatch(updateCourseOrderParticipantData({ selector, participantId, data: newData }));
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message,
						status: 'warning',
					},
				}),
			);
		}
	}
});

const submitAdminCourseOrderAddPurchase = createAction<
	(_: Dispatch, getState: GetState) => Promise<{ success: boolean }>,
	string
>(
	'SUBMIT_ADMIN_COURSE_ORDER_ADD_PURCHASE',
	selector => async (dispatch: Dispatch, getState: GetState) => {
		const {
			eventsParticipant: {
				form: { ids, byIds },
			},
			product: {
				dataById: { products: productsByIds, stocks: stocksByIds },
			},
		} = getState();

		let totalPrice = 0;
		const participants = [];

		const calculateProduct = participantAddPurchaseCalculator(productsByIds, stocksByIds);

		for (let i = 0; i < ids.length; i += 1) {
			const pId = ids[i];
			const { applyType, giveaways, productPurchase, servicePurchase } = byIds[pId];

			const [pApplyType] = calculateProduct(applyType.value);
			const pGiveaway = calculateProduct(giveaways.value);
			const pProducts = calculateProduct(productPurchase.value);
			const pServices = calculateProduct(servicePurchase.value);

			const resGiveaways = pGiveaway.map(({ id }) => id) as number[];

			const pProductSum = [...pProducts, ...pServices];
			const resProducts = pProductSum.map(({ id, amount }) => ({
				id,
				amount,
			})) as Product[];

			totalPrice +=
				(pApplyType ? pApplyType.price : 0) + pProductSum.reduce((a, n) => a + n.price, 0);

			const res = {
				id: Number(pId),
				...(pApplyType && { apply_type_id: pApplyType.id }),
				...(resGiveaways.length > 0 && { gift_id: resGiveaways }),
				...(resProducts.length > 0 && { products: resProducts }),
			};

			participants.push(res);
		}

		try {
			const { status, message } = await submitAdminCourseOrderAddPurchaseFunc(selector, {
				total_price: totalPrice,
				participants,
			});

			if (status === 200) {
				return Promise.resolve({ success: true });
			}

			if (message === 'VALIDATE_STOCK_ERROR') {
				dispatch(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message: JSON.stringify(message),
							status: 'warning',
						},
					}),
				);
			}

			throw new Error(message);
		} catch (error) {
			return Promise.reject(error);
		}
	},
);

const cancelCourseOrder = createAction<(_: Dispatch) => Promise<{ success: boolean }>, string>(
	'CANCEL_COURSE_ORDER',
	selector => async dispatch => {
		try {
			const { status, message } = await cancelCourseOrderFunc(selector);

			if (status === 200) {
				return Promise.resolve({ success: true });
			}
			throw new Error(message);
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: (error as Error).message,
						status: 'warning',
					},
				}),
			);
			return Promise.reject((error as Error).message);
		}
	},
);

export const reducer = {
	courseOrder: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_COURSE_ORDER_BY_SELECTOR_PENDING: state => ({
				...state,
				detail: {
					...state.detail,

					loading: true,
					error: '',
				},
			}),

			GET_COURSE_ORDER_BY_SELECTOR_FULFILLED: (state, action: Action<GetCourseOrderPayload>) => ({
				...state,
				detail: {
					...state.detail,
					...(action.payload.error && {
						error: action.payload.error,
					}),
					...(action.payload.data && {
						data: action.payload.data.selector,
					}),
					loading: false,
				},

				...(action.payload.data && {
					bySelector: {
						...state.bySelector,
						[action.payload.data.selector]: action.payload.data,
					},
				}),
			}),

			UPDATE_COURSE_ORDER_PARTICIPANT_DATA: (
				state,
				action: Action<UpdateCourseOrderParticipantDataPayload>,
			) => ({
				...state,

				bySelector: {
					...state.bySelector,
					[action.payload.selector]: {
						...state.bySelector[action.payload.selector],
						participants: {
							...state.bySelector[action.payload.selector].participants,
							byIds: {
								...state.bySelector[action.payload.selector].participants.byIds,
								[action.payload.participantId]: {
									...state.bySelector[action.payload.selector].participants.byIds[
										action.payload.participantId
									],
									...action.payload.data,
								},
							},
						},
					},
				},
			}),
		},
		initialState,
	),
};

const selectCourseOrder = (state: GlobalState) => state.courseOrder;

const courseOrderActionsMap = {
	getCourseOrderBySelector,
	updateCourseOrderBySelector,
	setupParticipantByCourseOrderProcess,
	submitAdminCourseOrderAddPurchase,
	initialCourseOrderDetailProcess,
	getCourseOrderAndEventDetail,
	cancelCourseOrder,
};

export const useCourseOrder = () =>
	useRedux<ReturnType<typeof selectCourseOrder>, typeof courseOrderActionsMap>(
		selectCourseOrder,
		courseOrderActionsMap,
	);
