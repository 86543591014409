import { FetchResponse } from 'types/FetchResponse';
import { PaginationData } from 'types/PaginationData';
import { BannerItem } from 'types/BannerItem';

import { wrapFetch } from 'util/api';

import { StoresSearchData } from './store';
import { TagData } from './tag';
import { BicycleModel } from './bicycle';

export type NewsPageType = 'home' | 'rental' | 'travel' | 'events';

interface NewsData {
	id: number;
	title: string;
	tag: TagData[];
	summary: string;
	first_banner_image_url: string;
	order: number;
	has_link: boolean;
	link: string;
	created_at: string;
}

type FetchNewsListFunc = (params: {
	page: number;
	search?: string;
	page_type: NewsPageType;
}) => Promise<FetchResponse<PaginationData<NewsData[]>>>;

export const fetchNewsListFunc: FetchNewsListFunc = async params => {
	const response = await wrapFetch(
		'news',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};

export interface OtherNewsData extends Omit<NewsData, 'tag'> {
	order: number;
	tag: string;
}

interface NewsInfo {
	id: number;
	title: string;
	content: string;
	tag: TagData[];
	other_news: OtherNewsData[];
	stores: StoresSearchData[];
	recommended_models: BicycleModel[];
	banners: BannerItem[];
	type: boolean;
	has_link: boolean;
	link: null | string;
	created_at: string;
}

type FetchNewsFunc = (newsId: number) => Promise<FetchResponse<NewsInfo>>;

export const fetchNewsFunc: FetchNewsFunc = async newsId => {
	const response = await wrapFetch(`news/${newsId}`, {
		method: 'GET',
	});

	return response;
};
