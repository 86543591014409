import i18n, { ReadCallback } from 'i18next';
import XHR, { BackendOptions } from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Store } from 'redux';

import { changeLanguage } from 'models/i18n';
import { getLanguageLocalStorage } from 'util/storage';
import { fetchFunc } from 'util/api';

import { lanList, LANGUAGE_NAME_MAP } from './config';

const { DEV, S3_ENDPOINT } = process.env;

const customAjax = async (url: string, options: BackendOptions, callback: ReadCallback) => {
	const newUrl = `${S3_ENDPOINT}${url}`;

	try {
		const data = await fetchFunc(
			newUrl,
			{
				method: 'GET',
				...options,
			},
			{},
			{ enpointType: 'external' },
		);

		callback(data, {
			status: '200',
		});
	} catch (error) {
		callback(null, {
			status: '404',
		});
	}
};

export const mapLanguageOption = (value: string) => ({
	value,
	label: LANGUAGE_NAME_MAP[value],
});

export const initi18next = (store: Store) => {
	// LanguageDetector 協助開發者偵測使用者瀏覽器 cookie、sessionStorage、localStorage 中儲存的語言設定。
	const lngDetector = new LanguageDetector();

	i18n
		.use(XHR)
		.use(lngDetector)
		.use(initReactI18next)
		.init(
			{
				fallbackLng: lanList[0],
				whitelist: lanList,
				load: 'currentOnly',

				// have a common namespace used around the full app
				ns: ['common'],
				defaultNS: 'common',

				// debug: process.env.NODE_ENV !== 'production',

				interpolation: {
					escapeValue: false, // not needed for react!!
				},

				// LanguageDetector 相關設定
				detection: {
					order: ['localStorage', 'cookie', 'navigator', 'htmlTag'], // LanguageDetector 偵測預設設定的順序
					lookupLocalStorage: getLanguageLocalStorage(), // 語言設定的前綴，通常是 i18nextLng 。
				},

				cache: ['localStorage'], // Cache 語言設定在 localStorage 中

				react: {
					useSuspense: false,
				},

				partialBundledLanguages: true,

				/* eslint-disable indent */
				backend: DEV
					? {
							loadPath: `/static/resources/{{lng}}/{{ns}}.json`,
					  }
					: {
							loadPath: `/resources/{{lng}}/{{ns}}.json`,
							crossDomain: true,
							parse: (data: any) => data, // eslint-disable-line @typescript-eslint/no-explicit-any
							ajax: customAjax,
					  },
				/* eslint-enable indent */
			},
			() => {
				if (store) {
					if (!lanList.includes(i18n.language)) {
						const [lan] = lanList;

						console.warn(`${i18n.language} is not in the translation resources`);

						i18n.changeLanguage(lan);
					}
				}
			},
		);

	i18n.on('languageChanged', () => {
		if (store) {
			const {
				i18n: { lan },
			} = store.getState();
			if (lan !== i18n.language) {
				/*
					因為使用 LanguageDetector ，第一次偵測瀏覽器語言後會觸發 languageChange，因此這段會執行。
					ref:
					1. https://www.i18next.com/overview/api#changelanguage
					2. https://github.com/i18next/i18next-browser-languageDetector
				*/
				store.dispatch(changeLanguage(i18n.language));
			}
		}
	});

	return i18n;
};

export const changeLng = (lng: string) => {
	let lan = lng;

	if (!lanList.includes(lng)) {
		[lan] = lanList;

		console.warn(`${lng} is not in the translation resources`);
	}

	i18n.changeLanguage(lan);
};

export const getLanguage = () => i18n.language;

export const t = (keys: string, options?: string) => i18n.t(keys, options);

export const withLang = (pathname: string) => {
	const regex = /^\/+/;
	if (regex.test(pathname)) {
		return `/${i18n.language}${pathname}`;
	}

	return `${i18n.language}/${pathname}`;
};
