import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

interface LoadingProperty {
	className?: string;
	fixed?: boolean;
}

const Loading: React.FC<LoadingProperty> = ({ className, fixed = false }) => (
	<div className={classnames(styles.loading, fixed && styles.fixed, className)}>
		<div className={classnames(styles.svgLoader)}>
			<svg className={classnames(styles.svgContainer)} height="40" width="40" viewBox="0 0 40 40">
				<circle className={classnames(styles.loaderSvg, styles.bg)} cx="20" cy="20" r="15" />
				<circle className={classnames(styles.loaderSvg, styles.animate)} cx="20" cy="20" r="15" />
			</svg>
		</div>
	</div>
);

export default Loading;
