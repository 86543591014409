/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';
import { filter, mergeMap } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { empty, of } from 'rxjs';
import { AnyAction, Dispatch } from 'redux';

import { DynamicState } from 'types/DynamicState';
import { GenderValues } from 'types/Gender';

import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';
import session from 'util/session';
import ChangeTypeOfKeys from 'util/types/changeTypeOfKeys';
import { appPath } from 'util/routingConfig';
import { refreshGiantToken } from 'util/api';
import userBasicInfoFormatter from 'util/functions/userInfoFormat';
import { initDynamicState } from 'util/helper';

import {
	UserBasicInfoData as UserBasicInfoRawData,
	UserBasicInfoRes,
	VIDRes,
	fetchFamilyMemberListFunc,
	fetchFamilyMemberInfoFunc,
	fetchVidFunc,
	revokeTokenFunc,
	fetchUserProfileFunc,
	UserEmergencyInfoData,
	UserOtherInfoData,
} from 'api/auth';
import { fecthMemberInfoFunc as fetchUserProfileOthersFunc } from 'api/member';

import { openModal } from 'models/modal';
import { pushRoute } from 'models/routing';

import { GetState, State as GlobalState } from './reducers';
import { fetchStoredCreditCard } from './payment';

const { SELF_HOST_ENDPOINT } = process.env;

type UserBasicInfoData = ChangeTypeOfKeys<UserBasicInfoRawData, 'gender', GenderValues>;

export interface UserInfoData extends UserBasicInfoData, UserBasicInfoRes {
	gid: UserBasicInfoRes['gid'];
}

export interface State {
	vid: DynamicState<string>;

	/**
	 * 目前登入者的 GID
	 *
	 * @type {DynamicState<string>}
	 * @memberof State
	 */
	loginUserGid: DynamicState<string>;

	/**
	 * 家庭成員的 GIDs
	 *
	 * @type {DynamicState<string[]>}
	 * @memberof State
	 */
	memberList: DynamicState<string[]>; //

	/**
	 * 目前使用者的 GID （可能是登入者的家庭成員）
	 *
	 * @type {string}
	 * @memberof State
	 */
	currentUserGid: string;
	userDataByGid: {
		[id: string]: UserInfoData;
	};
}

const initialState: State = {
	vid: initDynamicState(''),
	loginUserGid: initDynamicState(''),
	memberList: initDynamicState([]),
	currentUserGid: '',
	userDataByGid: {},
};

/** 移除所有授權的 Token 和 GID */
const removeAuth = createAction('REMOVE_AUTH', () => {
	storage.removeItem('refreshToken');
	session.removeItem('idToken');
	session.removeItem('accessToken');
	session.removeItem('gid');
});

const getVid = createAction<Promise<VIDRes>>('GET_GIANT_VID', async () => {
	const { message, vid } = await fetchVidFunc();

	if (message) {
		throw new Error(message);
	}

	return { vid };
});

const logout = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string | undefined
>('AUTH_LOGOUT', (redirectURL = SELF_HOST_ENDPOINT) => async (dispatch: Dispatch) => {
	const refreshToken = storage.getItem('refreshToken');
	const { status, message } = await revokeTokenFunc(refreshToken || '');

	if (status !== 200) {
		throw new Error(message);
	}

	dispatch(removeAuth()); // 登出成功就清除所有 Token 和 GID

	if (redirectURL) {
		window.location.href = redirectURL;
	} else {
		dispatch(pushRoute({ pathname: appPath.landing, withLoc: false }));
		dispatch(getVid()); // 登出後就變成訪客身份，拿新的 VID。
	}
});

const signinProcess = createAction(
	'AUTH_SIGNIN_PROCESS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			routing: { pathname },
			locale: { isGlobal, currentLocCode },
		} = getState();
		const pathnameLocale = isGlobal ? '' : currentLocCode;
		const saveUrl = pathname.includes(appPath.signin)
			? `${window.location.protocol}//${window.location.host}/${pathnameLocale}`
			: window.location.href;

		storage.setItem('authRedirectPath', saveUrl); // 將登入前的 URL 存進 local storage，以便跳轉 GID 登入後轉導。
		dispatch(pushRoute({ pathname: appPath.signin, withLoc: false }));
	},
);

interface GetUserProfileInfoPayload {
	error?: string;
	data?: { id: string; info: Pick<UserBasicInfoRes, 'id' & 'info'> };
}

/** 取得會員使用者的個人資料 */
export const getAuthUserProfile = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetUserProfileInfoPayload>
>('GET_GIANT_AUTH_USER_PROFILE', () => async (_, getState) => {
	try {
		const {
			auth: {
				loginUserGid: { data: loginUserGid },
			},
		} = getState();

		const [
			{ status: s1, message: m1, info: i1, general },
			{ status: s2, message: m2, data: i2 },
		] = await Promise.all([fetchUserProfileFunc(), fetchUserProfileOthersFunc()]);

		if (s1 !== 200) throw new Error(m1);
		if (s2 !== 200 && s2 !== 201) throw new Error(m2);

		// 針對打進來的英制單位作轉換
		const imperialIntoMetricForHeight = (height: string) => {
			if (i1.measureUnit === 'Imperial') {
				return (+height * 2.54).toFixed(0);
			}

			return height;
		};

		// 針對打進來的英制單位作轉換
		const imperialIntoMetricForWeight = (weight: string) => {
			if (i1.measureUnit === 'Imperial') {
				return (+weight * 0.45).toFixed(0);
			}

			return weight;
		};

		return {
			data: {
				id: loginUserGid,
				info: {
					...i1,
					...(i1.height && { height: imperialIntoMetricForHeight(i1.height) }),
					...(i1.weight && { weight: imperialIntoMetricForWeight(i1.weight) }),
					...(i2 && i2),
					gid: loginUserGid,
					general,
				},
			},
		};
	} catch (_error) {
		return { error: (_error as Error).message };
	}
});

interface GetUserGiantIdPayload {
	error?: string;
	data?: { id: string; info: UserBasicInfoData };
}

/** 取得會員使用者的 Giant ID 和基本資料 */
export const getAuthUserGiantId = createAction<Promise<GetUserGiantIdPayload>>(
	'GET_GIANT_AUTH_USER_ID',
	async () => {
		try {
			const { status, message, info, gid } = await fetchUserProfileFunc();

			if (status !== 200) {
				throw new Error(message);
			}

			return {
				data: {
					id: gid,
					info: { ...userBasicInfoFormatter(info) },
				},
			};
		} catch (_error) {
			return { error: (_error as Error).message };
		}
	},
);

interface GetUserFamilyMemberPayload {
	error?: string;
	data?: { id: string; info: UserInfoData };
}

const getAuthFamilyMemberInfo = createAction<Promise<GetUserFamilyMemberPayload>, string>(
	'GET_GIANT_AUTH_FAMILY_MEMBER_INFO',
	async id => {
		try {
			const { status, message, gid, info } = await fetchFamilyMemberInfoFunc(id);

			if (status !== 200) {
				throw new Error(message);
			}

			return {
				data: {
					id,
					info: { ...userBasicInfoFormatter(info), gid } as UserInfoData,
				},
			};
		} catch (error) {
			return { error: (error as Error).message };
		}
	},
);

interface GetMemberListPayload {
	error?: string;
	data?: State['memberList']['data'];
}

const getAuthUserFamilyMemberList = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<GetMemberListPayload>
>(
	'GET_GIANT_AUTH_USER_FAMILY_MEMBER_LIST',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: {
				loginUserGid: { data: loginUserGid },
				userDataByGid,
			},
		} = getState();

		try {
			const { status, message, members } = await fetchFamilyMemberListFunc();

			if (status !== 200 && status !== 400) {
				throw new Error(message);
			}

			const requests: Action<Promise<GetUserProfileInfoPayload>>[] = [];

			const gids = members.map(member => {
				// already get login user with getAuthUserProfileBasic
				if (member.gid !== loginUserGid) {
					requests.push(dispatch(getAuthFamilyMemberInfo(member.gid))); // 取得每個家庭成員的資料。
				}

				return member.gid;
			});

			await Promise.all(requests);

			return {
				data: gids,
			};
		} catch (_error) {
			const error = (_error as Error).message;
			if (
				error === `The user hasn't already joined the family` &&
				loginUserGid &&
				userDataByGid[loginUserGid]
			) {
				return {
					data: [loginUserGid], // 使用者還沒加入家庭成員，拿不到任何家庭成員資料，因此只回傳該使用者的 GID。
				};
			}
			return { error };
		}
	},
);

const setCurrentUser = createAction('SET_CURRENT_USER', (id: string) => {
	session.setItem('gid', id);
	return id;
});

/**
 * 登入後取得使用者資料，並設定使用者。
 */
export const getAuthProcess = createAction(
	'GET_AUTH_PROCESS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const refreshToken = storage.getItem('refreshToken');
		const seesionGid = session.getItem('gid');

		try {
			if (refreshToken) {
				await refreshGiantToken();

				await dispatch(getAuthUserGiantId()); // 取得登入會員的 GID 和基本資料
				// await dispatch(getAuthUserFamilyMemberList()); // 取得登入會員的家庭成員資料
				await dispatch(getAuthUserProfile()); // 取得登入會員的個人資料

				dispatch(fetchStoredCreditCard()); // 取得登入會員已經綁定的信用卡資料

				const {
					auth: {
						loginUserGid: { data: loginUserGid },
						memberList: { data: memberGids },
					},
				} = getState();

				/**
				 * 切換家庭成員時會重新整理頁面（reload page）， getAuthProcess 會重新執行。
				 * 因此這裡要確認 session 中的 GID 是否屬於登入者的家庭成員：
				 * 是，新使用者為原登入者的家庭成員。
				 * 否，新使用者維持為原登入者。
				 */
				if (seesionGid && memberGids.includes(seesionGid)) {
					dispatch(setCurrentUser(seesionGid));
				} else if (loginUserGid) {
					dispatch(setCurrentUser(loginUserGid));
				}
			} else {
				// await dispatch(removeAuth());
				storage.removeItem('refreshToken');
				session.removeItem('idToken');
				session.removeItem('accessToken');
				session.removeItem('gid');
			}
		} catch (error) {
			await dispatch(removeAuth());
		}
	},
);

/**
 * 處理包含 GIANT_AUTH 字串 action 的 FULFILLED 狀態但 payload 的 error 有值的情況（也就是沒有拿到正確資料的時候）
 * @param action$
 * @returns
 */
export const giantAuthErrorEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		filter(
			action => /([\S]+)?GIANT_AUTH([\S]+)?FULFILLED$/.test(action.type) && action.payload?.error,
		),
		mergeMap(action => {
			const TOKEN_ERRORS = ['Unauthorized', 'invalid_grant'];

			if (TOKEN_ERRORS.includes(action.payload.error)) {
				return of(removeAuth(), signinProcess()); // logout & redirect to signin
			}

			if (action.payload.message) {
				return of(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message: action.payload.message,
							status: 'warning',
						},
					}),
				);
			}

			return empty();
		}),
	);

export const reducer = {
	auth: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			REMOVE_AUTH: () => ({
				...initialState,
			}),

			SET_CURRENT_USER: (state, action: Action<string>) => ({
				...state,
				currentUserGid: action.payload,
			}),

			GET_GIANT_VID_PENDING: state => ({
				...state,
				vid: {
					...initialState.vid,
					loading: true,
				},
			}),

			GET_GIANT_VID_FULFILLED: (state, action: Action<VIDRes>) => ({
				...state,
				vid: {
					...state.vid,
					data: action.payload.vid,
					loading: false,
				},
			}),

			GET_GIANT_VID_REJECTED: (state, action) => ({
				...state,
				vid: {
					...state.vid,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_GIANT_AUTH_USER_ID_PENDING: state => ({
				...state,
				loginUserGid: {
					...initialState.loginUserGid,
					loading: true,
				},
			}),

			GET_GIANT_AUTH_USER_ID_FULFILLED: (state, action: Action<GetUserGiantIdPayload>) => ({
				...state,
				loginUserGid: {
					...state.loginUserGid,
					...(action.payload.data && {
						data: action.payload.data.id,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
					loading: false,
				},
				...(action.payload.data && {
					userDataByGid: {
						...state.userDataByGid,
						[action.payload.data.id]: {
							...state.userDataByGid[action.payload.data.id],
							...action.payload.data.info,
						},
					},
				}),
			}),

			// GET_GIANT_AUTH_USER_PROFILE_PENDING: state => ({
			// 	...state,
			// 	loginUserGid: {
			// 		...initialState.loginUserGid,
			// 	},
			// }),

			GET_GIANT_AUTH_USER_PROFILE_FULFILLED: (
				state,
				action: Action<GetUserProfileInfoPayload>,
			) => ({
				...state,
				...(action.payload.data && {
					userDataByGid: {
						...state.userDataByGid,
						[action.payload.data.id]: {
							...state.userDataByGid[action.payload.data.id],
							...action.payload.data.info,
						},
					},
				}),
			}),

			GET_GIANT_AUTH_USER_FAMILY_MEMBER_LIST_PENDING: state => ({
				...state,
				memberList: {
					...initialState.memberList,
					loading: true,
				},
			}),

			GET_GIANT_AUTH_USER_FAMILY_MEMBER_LIST_FULFILLED: (
				state,
				action: Action<GetMemberListPayload>,
			) => ({
				...state,
				memberList: {
					...state.memberList,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
					loading: false,
				},
			}),

			GET_GIANT_AUTH_FAMILY_MEMBER_INFO_FULFILLED: (
				state,
				action: Action<GetUserFamilyMemberPayload>,
			) => ({
				...state,
				...(action.payload.data && {
					userDataByGid: {
						...state.userDataByGid,
						[action.payload.data.id]: action.payload.data.info,
					},
				}),
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useGiantVid ++
++----------------------------------------------------------------------*/
const selectVid = (state: GlobalState) => state.auth.vid;
const vidActionsMap = {
	getVid,
};

export const useGiantVid = () =>
	useRedux<ReturnType<typeof selectVid>, typeof vidActionsMap>(selectVid, vidActionsMap);

/* +----------------------------------------------------------------------
	++ useAuth ++
++----------------------------------------------------------------------*/
const selectAuth = (state: GlobalState) => ({
	loginUserGid: state.auth.loginUserGid,
	memberList: state.auth.memberList,
	currentUserGid: state.auth.currentUserGid,
	userDataByGid: state.auth.userDataByGid,
	isLogin: state.auth.loginUserGid.data !== '',
	isLoginUser:
		state.auth.currentUserGid !== '' &&
		state.auth.loginUserGid.data !== '' &&
		state.auth.currentUserGid === state.auth.loginUserGid.data,
	hasFamily: state.auth.memberList.data.length > 1, // 家庭成員 > 1 即表示已經加入過家庭成員資料
});

const authActionsMap = {
	logout,
	signinProcess,
	getAuthProcess,
	setCurrentUser,
	getAuthUserProfile,
};

export const useAuth = () =>
	useRedux<ReturnType<typeof selectAuth>, typeof authActionsMap>(selectAuth, authActionsMap);
