import { useRouting } from 'models/routing';
import { match, MatchResult } from 'path-to-regexp';

export const pathMatcher = <P extends object = object>(matchPath: string, pathname: string): P => {
	const pathMatchFunc = match<P>(matchPath, {
		decode: decodeURIComponent,
	});

	const res = pathMatchFunc(pathname) as false | MatchResult<P>;

	const { params } = typeof res === 'boolean' ? { params: {} } : res;

	return params as P;
};

export const usePathMatch = <P extends object = object>(path: string): P => {
	const [{ pathname }] = useRouting();

	return pathMatcher(path, pathname);
};
