import { FetchResponse } from 'types/FetchResponse';
import { LocationData, LocationTree } from 'types/LocationData';

import { wrapFetch } from 'util/api';

export type LocationType = 'CONTINENT' | 'COUNTRY' | 'STATE' | 'CITY' | 'DISTRICT' | 'FULLNAME';

type FetchLocationsFunc = (params: {
	location_type: LocationType;
	ancestor_id?: number;
}) => Promise<FetchResponse<LocationData[]>>;

/**
 * 取得地區列表
 */
export const fetchLocationsFunc: FetchLocationsFunc = async params => {
	const response = await wrapFetch(
		'locations',
		{
			method: 'GET',
		},
		{
			location_type: params.location_type,
			...(params.ancestor_id && { ancestor_id: params.ancestor_id }),
		},
		{ withLan: true, withLocale: false },
	);

	return response;
};

type FetchLocationsTreeFunc = (params: {
	parent_type: LocationType;
	child_type: LocationType;
	ancestor_id?: number;
}) => Promise<FetchResponse<LocationTree[]>>;

/**
 * 取得樹狀地區列表(兩層)
 */
export const fetchLocationsTreeFunc: FetchLocationsTreeFunc = async params => {
	const response = await wrapFetch(
		'locations/tree',
		{
			method: 'GET',
		},
		{
			parent_type: params.parent_type,
			child_type: params.child_type,
			...(params.ancestor_id && { ancestor_id: params.ancestor_id }),
		},
		{ withLan: true, withLocale: false },
	);

	return response;
};
