import React, { CSSProperties, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';

import { /* accountNaviLinks */ appPath, appPathKey } from 'util/routingConfig';
import routingMatcher from 'util/routingMatcher';
import { useMedia } from 'util/event';
// import { pathSupportChecker } from 'util/helper';
import { useRouting } from 'models/routing';
import { globalLocal, useLocale } from 'models/locale';
import { useConfig } from 'models/global';
import { useTravelBriefList } from 'models/travel';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import ScrollingContainer from 'components/atoms/ScrollingContainer';
import DropdownArrow from 'components/atoms/DropdownArrow';
import NaviCollapse, { NavItem } from 'components/molecules/NaviCollapse';

import styles from './index.css';

interface ButtonNaviProperty {
	className?: string;
	actived?: boolean;
	to?: string;
	subNavis?: appPathKey[];
	onClick?: () => void;
}

const ButtonNavi: React.FC<ButtonNaviProperty> = ({
	className,
	to = '',
	actived = false,
	subNavis = [],
	onClick = () => {},
	children,
}) => {
	const { t } = useTranslation('nav');
	const [{ pathname }, { pushRoute }] = useRouting();
	const [
		{
			currentLocCode,
			locales: {
				data: { byCode: locByCode },
			},
		},
	] = useLocale();

	const onClickHandler = (nextPath: string, closePanel = () => {}) => () => {
		pushRoute({ pathname: nextPath });

		closePanel();
	};

	const [{ data: brief }, { getTravelBriefList }] = useTravelBriefList();
	useEffect(() => {
		getTravelBriefList();
	}, []);

	return (
		<DropdownArrow
			offset={[16, 16]}
			triggerClassName={classnames(styles.buttonNavi, className)}
			contentClassName={styles.buttonNaviContent}
			contentComponent={
				({ close }) =>
					subNavis.length > 0 ? (
						<div className={styles.buttonNaviSelection}>
							{subNavis.map(subnavi => {
								return subnavi !== 'travelBrief' || brief?.data.length !== 0 ? (
									<div
										key={subnavi}
										className={classnames(styles.option, {
											[styles.active]: routingMatcher(
												pathname,
												locByCode[currentLocCode],
												appPath[subnavi],
												{
													end: false,
												},
											),
										})}
										role="button"
										tabIndex={0}
										onKeyPress={() => {}}
										onClick={onClickHandler(appPath[subnavi], close)}
									>
										{t(subnavi)}
									</div>
								) : null;
							})}
						</div>
					) : null
				// eslint-disable-next-line react/jsx-curly-newline
			}
		>
			<Link
				to={to}
				className={classnames(styles.link, className, {
					[styles.actived]: actived,
				})}
				onClick={onClick}
			>
				{children}
			</Link>
		</DropdownArrow>
	);
};

interface DesktopComponentProperty {
	className?: string;
	style?: CSSProperties;
}

const DesktopComponent: React.FC<DesktopComponentProperty> = ({ className, style }) => {
	const { t } = useTranslation('nav');
	const [
		{
			currentLocCode,
			locales: {
				data: { byCode: locByCode },
			},
		},
	] = useLocale();
	const [{ pathname }] = useRouting();
	const [{ subnavis, headers }] = useConfig();

	return (
		<animated.nav
			className={classnames(styles.navigation, styles.desktop, className)}
			style={style}
		>
			{headers.map(navi => (
				<ButtonNavi
					key={navi}
					to={appPath[navi]}
					subNavis={subnavis[navi] || []}
					actived={routingMatcher(pathname, locByCode[currentLocCode], appPath[navi], {
						end: false,
					})}
				>
					{t(navi)}
				</ButtonNavi>
			))}
		</animated.nav>
	);
};

interface MobileComponentProperty {
	className?: string;
	closeMenu?: () => void;
}

const MobileComponent: React.FC<MobileComponentProperty> = ({
	className,
	closeMenu = () => {},
}) => {
	const { t } = useTranslation('nav');
	const media = useMedia();
	const [
		{
			currentLocCode,
			locales: { data: locales },
			isGlobal,
		},
	] = useLocale();
	const [{ pathname }, { pushRoute }] = useRouting();
	const [{ subnavis, headers, allAppPaths }] = useConfig();
	// const supportPath = pathSupportChecker(allAppPaths, {
	// 	account: ['account'],
	// });

	const [{ data: brief }, { getTravelBriefList }] = useTravelBriefList();
	useEffect(() => {
		getTravelBriefList();
	}, []);

	return (
		<nav className={classnames(styles.navigation, styles.mobile, className)}>
			<ScrollingContainer>
				{headers.map(navi => (
					<NaviCollapse key={navi} title={t(`nav:${navi}`)}>
						{(subnavis[navi] || []).map(subnavi => (
							<NavItem
								key={subnavi}
								to={appPath[subnavi]}
								actived={routingMatcher(
									pathname,
									locales.byCode[currentLocCode],
									appPath[subnavi],
									{
										end: false,
									},
								)}
								onClick={closeMenu}
								hasData={subnavi === 'travelBrief' && brief?.data.length === 0}
							>
								{t(`nav:${subnavi}`)}
							</NavItem>
						))}
					</NaviCollapse>
				))}

				{isGlobal ? (
					<>
						<NaviCollapse title={t('nav:locale')}>
							{locales.codes
								.filter(code => code !== globalLocal.code)
								.map(code => (
									<NavItem
										key={code}
										actived={code === currentLocCode}
										onClick={() => {
											pushRoute({ loc: code });
											closeMenu();
										}}
									>
										{locales.byCode[code].name}
									</NavItem>
								))}
						</NaviCollapse>
						<NaviCollapse title={t('nav:contact')}>
							<NavItem to="/" actived={pathname.includes('/')} onClick={closeMenu}>
								{t('nav:customService')}
							</NavItem>
							<NavItem to="/" actived={pathname.includes('/')} onClick={closeMenu}>
								{t('nav:cooperation')}
							</NavItem>
						</NaviCollapse>
						<NaviCollapse title={t('nav:relatedLinks')}>
							<NavItem to="/" actived={pathname.includes('/')} onClick={closeMenu}>
								{t('nav:shoppingCentre')}
							</NavItem>
							<NavItem to="/" actived={pathname.includes('/')} onClick={closeMenu}>
								{t('nav:officialWeb')}
							</NavItem>
						</NaviCollapse>
					</>
				) : (
					<>
						{/* {supportPath.account && (
							<NaviCollapse title={t('nav:account')}>
								{accountNaviLinks.map(d => (
									<NavItem
										key={d.key}
										to={d.path}
										actived={
											d.key === 'accountEdit'
												? false
												: routingMatcher(pathname, locales.byCode[currentLocCode], d.path, {
														end: false,
												  })
										}
										onClick={closeMenu}
									>
										{t(`nav:${d.key}`)}
									</NavItem>
								))}
							</NaviCollapse>
						)} */}
					</>
				)}

				{media !== 'desktop' && (
					<Button
						className={styles.itineraryRegister}
						outline
						onClick={() => {
							pushRoute({
								pathname: '/travel/travel-tours',
							});
						}}
					>
						{t('common:itineraryRegister')}
					</Button>
				)}
			</ScrollingContainer>
		</nav>
	);
};

interface NavigationProperty {
	className?: string;
	style?: CSSProperties;
	closeMenu?: () => void;
}

const Navigator: React.FC<NavigationProperty> = ({ className, style, closeMenu = () => {} }) => {
	const media = useMedia();

	if (media === 'desktop') {
		return <DesktopComponent className={className} style={style} />;
	}
	return <MobileComponent className={className} closeMenu={closeMenu} />;
};

export default Navigator;
