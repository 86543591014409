import { LeaderData } from 'types/class/Leader';
import { PhotoItemData } from 'types/Items';

export interface InstructorData extends LeaderData {
	expertise: string;
	experience: string;
	photos: PhotoItemData[];
}

/**
 * 捷安特講師
 *
 * @export
 * @class Instructor
 * @extends {Leader}
 */
export default class Instructor {
	/**
	 * 講師 ID
	 *
	 * @type {number}
	 * @memberof Instructor
	 */
	id: number;

	/**
	 * 講師職稱
	 *
	 * @type {string}
	 * @memberof Instructor
	 */
	job_title: string;

	/**
	 * 講師照片
	 *
	 * @type {string}
	 * @memberof Instructor
	 */
	img: string;

	/**
	 * 講師姓名
	 *
	 * @type {string}
	 * @memberof Instructor
	 */
	name: string;

	/**
	 * 講師資訊
	 *
	 * @type {string}
	 * @memberof Instructor
	 */
	info: string;

	/**
	 * 講師專長
	 *
	 * @type {string}
	 * @memberof Instructor
	 */
	expertise: string;

	/**
	 * 講師經驗
	 *
	 * @type {string}
	 * @memberof Instructor
	 */
	experiences: string;

	/**
	 * 講師照片資料
	 *
	 * @type {PhotoItemData[]}
	 * @memberof Instructor
	 */
	photos: PhotoItemData[];

	constructor(p?: InstructorData) {
		this.id = p?.id || 0;
		this.job_title = p?.job_title || '';
		this.img = p?.img || '';
		this.name = p?.name || '';
		this.info = p?.info || '';
		this.expertise = p?.expertise || '';
		this.experiences = p?.experience || '';
		this.photos = p?.photos || [];
	}

	/**
	 * 講師資訊頁連結
	 *
	 * @readonly
	 * @type {string}
	 * @memberof Instructor
	 */
	get linkTo(): string {
		return this.id.toString();
	}
}
