import { FetchResponse } from 'types/FetchResponse';
import { PaginationData } from 'types/PaginationData';

import { wrapFetch } from 'util/api';

import { TagData } from './tag';

export interface BikeCategoryProperty {
	id: number;
	title: string;
	image: string;
	description: string;
}

type FetchCategoriesFunc = () => Promise<FetchResponse<BikeCategoryProperty[]>>;

/**
 * 取得車種分類列表
 */
export const fetchBicycleCategoriesFunc: FetchCategoriesFunc = async () => {
	const response = await wrapFetch('bicycleCategories', {
		method: 'GET',
	});

	return response;
};

export interface BicycleRentalInfoOption {
	rental_type: string;
	code: string;
	title: string;
	price: number;
}

export interface BicycleSearchParams {
	search?: string;
	store_id?: number;
	category_id?: number;
}

export interface BicycleModel {
	id: number;
	title: string; // 車型
	image: string;
	description: string;
	rental_info: {
		title: string;
		options: BicycleRentalInfoOption[];
	};
	category_id: number;
	category: string; // 屬於哪一種車種
	model_family_id: number;
	model_family: string;
	rentable_sizes: string;
}

export interface RecommendedBicycle {
	id: number;
	title: string;
	image: string;
	description: string;
	bicycle_model_family_id: number;
	category_id: number;
	model_family: {
		title: string;
		description: string;
	};
	rental_info: {
		title: string;
		options: BicycleRentalInfoOption[];
	};
}

interface BicycleData {
	model_family_id: number;
	model_family: string; // 車型家族（底下有很多車型）
	description: string;
	tags: TagData[];
	models: BicycleModel[];
}

type FetchSearchFunc = (params?: BicycleSearchParams) => Promise<FetchResponse<BicycleData[]>>;

/**
 * 車種搜尋列表
 */
export const fetchBicycleSearchFunc: FetchSearchFunc = async params => {
	const response = await wrapFetch(
		'bicycle/search',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};

export interface BikeSizePropery {
	id: number;
	name: string;
	sizes: string[];
}

type FetchBicycleSizesFunc = () => Promise<FetchResponse<BikeSizePropery[]>>;

/**
 * 取得車輛商品尺寸
 */
export const fetchBicycleSizesFunc: FetchBicycleSizesFunc = async () => {
	const response = await wrapFetch('bicycle/sizes', {
		method: 'GET',
	});

	return response;
};

interface BicycleStock {
	id: number;
	title: string;
	image: string;
	price: number;
	bicycle_category: {
		id: number;
		title: string;
		image: string;
		description: string;
	};
	bicycle_specs: [
		{
			id: number;
			size: string;
			stock: number;
			height_info: string;
		},
	];
	standard_equipment_info: string;
}

type FetchBicycleStocksFunc = (
	params: {
		start_store_id: number;
		end_store_id: number;
		start_at: string;
		end_at: string;
		page: number;
		category_ids?: number[];
		category_with_sizes?: string[];
		search?: string;
	},
	options?: { signal?: AbortSignal },
) => Promise<FetchResponse<PaginationData<BicycleStock[]>>>;

/**
 * 取得車輛商品庫存
 */
export const fetchBicycleStocksFunc: FetchBicycleStocksFunc = async (params, options) => {
	const response = await wrapFetch(
		'bicycle/stocks',
		{
			method: 'GET',
			...(options?.signal && { signal: options.signal }),
		},
		params,
	);

	return response;
};

interface ValidateBicycleStock {
	id: number;
	size: string;
	stock: number;
	bicycle_category: {
		id: number;
		title: string;
		image: string;
		description: string;
	};
	bicycle_model: {
		title: string;
		price: number;
	};
}

type ValidateStockFunc = (params: {
	start_store_id: number;
	end_store_id: number;
	start_at: string;
	end_at: string;
	bicycle_specs: { id: number; amount: number }[];
}) => Promise<
	FetchResponse<{ bicycle_specs: ValidateBicycleStock[]; errors: { [id: number]: string } }>
>;

/**
 * 驗證車輛庫存
 */
export const validateBicycleStockFunc: ValidateStockFunc = async params => {
	const response = await wrapFetch('bicycle/validateStock', {
		method: 'POST',
		body: JSON.stringify(params),
	});

	return response;
};
