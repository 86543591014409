/**
 * 「活動課程」分為三個子類別：
 * 1. 主題活動 （Route: featuredrides, Data: eventsFeaturedRides, Model: Ride,   Code: THEME_ACTIVITY）
 * 2. 門市約騎 （Route: storerides,    Data: eventsStoreRides,    Model: Ride,   Code: STORE_RIDE）
 * 3. 課程講座 （Route: bikeclasses,   Data: eventsBikeClasses,   Model: Course, Code: COURSE）
 * 4. 旅遊行程  (Route: travel,        Data: travel,              Model: Travel, Code: TRAVEL)
 */

/**
 * 「活動課程」四個子類的資料類別（Data）
 */
export enum GiantEventData {
	eventsFeaturedRides = 'eventsFeaturedRides', // 主題活動
	eventsStoreRides = 'eventsStoreRides', // 門市約騎
	eventsBikeClasses = 'eventsBikeClasses', // 課程講座
	travel = 'travel', // 旅遊行程
}

export type GiantEventDataType = keyof typeof GiantEventData;

export const giantEventDataType = Object.keys(GiantEventData) as GiantEventDataType[];

/**
 * 「活動課程」四個子類的前端 Route
 */
export enum GiantEventRoute {
	featuredrides = 'featuredrides', // 主題活動
	storerides = 'storerides', // 門市約騎
	bikeclasses = 'bikeclasses', // 課程講座
	travel = 'travel', // 旅遊行程
}

export type GiantEventRouteType = keyof typeof GiantEventRoute;

/**
 * 「活動課程」四個子類的後端模組（Model）
 */
enum GiantEventModel {
	Ride = 'Ride', // 主題活動 和 門市約騎
	Course = 'Course', // 課程講座
	Travel = 'Travel', // 旅遊行程
}

export type GiantEventModelType = keyof typeof GiantEventModel;

/**
 * 「活動課程」四個子類的打後端 API 的代碼（Code）
 */
export enum GiantEventCode {
	THEME_ACTIVITY = 'THEME_ACTIVITY', // 主題活動
	STORE_RIDE = 'STORE_RIDE', // 門市約騎
	COURSE = 'COURSE', // 課程講座
	TRAVEL = 'TRAVEL', // 旅遊行程
}

export type GiantEventCodeType = keyof typeof GiantEventCode;

export const GIANT_EVENT_ROUTE_TO_DATA_MAP: { [key in GiantEventRouteType]: GiantEventDataType } = {
	featuredrides: GiantEventData.eventsFeaturedRides,
	storerides: GiantEventData.eventsStoreRides,
	bikeclasses: GiantEventData.eventsBikeClasses,
	travel: GiantEventData.travel,
} as const;

export const GIANT_EVENT_DATA_TO_ROUTE_MAP: { [key in GiantEventDataType]: GiantEventRouteType } = {
	eventsFeaturedRides: GiantEventRoute.featuredrides,
	eventsStoreRides: GiantEventRoute.storerides,
	eventsBikeClasses: GiantEventRoute.bikeclasses,
	travel: GiantEventRoute.travel,
} as const;

export const GIANT_EVENT_DATA_TO_CODE_MAP: { [key in GiantEventDataType]: GiantEventCodeType } = {
	eventsFeaturedRides: GiantEventCode.THEME_ACTIVITY,
	eventsStoreRides: GiantEventCode.STORE_RIDE,
	eventsBikeClasses: GiantEventCode.COURSE,
	travel: GiantEventCode.TRAVEL,
} as const;
