/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import {
	CancelOrderInfoType,
	CreateOrderInfoType,
	fetchCancelOrderInfoFunc,
	fetchCreateOrderInfoFunc,
	fetchStoreRentalCancelOrderInfoFunc,
} from 'api/orderInfo';

import { State as GlobalState } from './reducers';

export interface State {
	data: FetchedData<string | null>;
}

const initialState: State = {
	data: { loading: false, error: '', data: null },
};

type GetOrderInfoPayload = Partial<State['data']>;

const getStoreRentalCancelOrderInfo = createAction<Promise<GetOrderInfoPayload>, number>(
	'GET_ORDER_INFO',
	async store_id => {
		try {
			const { status, data, message } = await fetchStoreRentalCancelOrderInfoFunc({ store_id });

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data: data.rental_cancel_order_info };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return {
					error: message,
				};
			}
			return {
				error: '',
			};
		}
	},
);

export const getCancelOrderInfo = createAction<
	Promise<GetOrderInfoPayload>,
	{ type: CancelOrderInfoType; country_id: number }
>('GET_ORDER_INFO', async ({ type, country_id = undefined }) => {
	try {
		const { status, data, message } = await fetchCancelOrderInfoFunc({ type, country_id });

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data: data.cancel_order_info };
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				error: message,
			};
		}
		return {
			error: '',
		};
	}
});

const getCreateOrderInfo = createAction<Promise<GetOrderInfoPayload>, CreateOrderInfoType>(
	'GET_ORDER_INFO',
	async type => {
		try {
			const { status, data, message } = await fetchCreateOrderInfoFunc({ type });

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data: data.create_order_info };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return {
					error: message,
				};
			}
			return {
				error: '',
			};
		}
	},
);

export const reducer = {
	orderInfo: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ORDER_INFO_PENDING: state => ({
				...state,
				data: {
					...initialState.data,
					loading: true,
				},
			}),

			GET_ORDER_INFO_FULFILLED: (state, action: Action<GetOrderInfoPayload>) => ({
				...state,
				data: {
					...state.data,
					loading: false,
					...(action.payload.data && {
						data: action.payload.data,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		initialState,
	),
};

const selectOrderInfo = (state: GlobalState) => state.orderInfo.data;

const orderInfoActionsMap = {
	getStoreRentalCancelOrderInfo,
	getCancelOrderInfo,
	getCreateOrderInfo,
};

export const useOrderInfo = () =>
	useRedux<ReturnType<typeof selectOrderInfo>, typeof orderInfoActionsMap>(
		selectOrderInfo,
		orderInfoActionsMap,
	);
