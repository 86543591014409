import { FetchResponse } from 'types/FetchResponse';

import { wrapFetch } from 'util/api';
import { appPathKey, ConfigHeaders } from 'util/routingConfig';

export interface AppConfig {
	currency: string;
	default_lang: string;
	allowed_langs: string[];
	headers: ConfigHeaders[];
	subnavis: Partial<Record<ConfigHeaders, appPathKey[]>>;
}

type FetchConfigFunc = () => Promise<FetchResponse<AppConfig>>;

export const fetchConfigFunc: FetchConfigFunc = async () => {
	const response = await wrapFetch('config', {
		method: 'GET',
	});

	return response;
};
