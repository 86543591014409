/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';

import { FetchedData } from 'types/FetchedData';
import { ErrorMessage } from 'types/ErrorMessage';

import { useRedux } from 'util/hook/redux';

import {
	fetchStockBicycleDestinationsFunc,
	fetchStockBicycleInfoFunc,
	fetchStockBicycleOrderFunc,
	fetchStockBicycleOrdersFunc,
	StockBicycleInfo,
	StockBicycleDestination,
	StockBicycleOrderForReturn,
	StockBicycleOrderForPickup,
	fetchBicycleInventoryPositionFunc,
	BicycleInventoryPosition,
} from 'api/stockBicycle';

import { Dispatch } from 'react';
import { wrapRetryAdminFetch } from 'util/api';
import { GetState, State as GlobalState } from './reducers';

export interface State {
	info: FetchedData<StockBicycleInfo | null>;
	destinations: FetchedData<StockBicycleDestination[]>;
	order: FetchedData<StockBicycleOrderForReturn | null>;
	orders: FetchedData<StockBicycleOrderForPickup[]>;
	inventoryPosition: FetchedData<BicycleInventoryPosition[]>;
	inventoryQrCodeInfo: FetchedData<BicycleInventoryQrCodeInfo | {}>;
}

const initialState: State = {
	info: {
		loading: true,
		error: '',
		data: null,
	},
	destinations: {
		loading: true,
		error: '',
		data: [],
	},
	order: {
		loading: true,
		error: '',
		data: null,
	},
	orders: {
		loading: true,
		error: '',
		data: [],
	},

	// 盤點位置
	inventoryPosition: {
		loading: true,
		error: '',
		data: [],
	},

	// 盤點 QRCode 資訊（後端回傳後打進來）
	inventoryQrCodeInfo: {
		loading: true,
		error: '',
		data: {},
	},
};

const getStockBicycleInfo = createAction<Promise<State['info']>, string>(
	'GET_STOCK_BICYCLE_INFO',
	async bicycleNumber => {
		const { status, data, message = '' } = await fetchStockBicycleInfoFunc(bicycleNumber);

		if ((status !== 200 && status !== 201) || !data) {
			return { data: null, error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

const getStockBicycleDestinations = createAction<Promise<State['destinations']>, number>(
	'GET_STOCK_BICYCLE_DESTINATIONS',
	async bicycleId => {
		const { status, data, message = '' } = await fetchStockBicycleDestinationsFunc(bicycleId);

		if ((status !== 200 && status !== 201) || !data) {
			return { data: [], error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

const getStockBicycleOrder = createAction<Promise<State['order']>, number>(
	'GET_STOCK_BICYCLE_ORDER',
	async bicycleId => {
		const { status, data, message = '' } = await fetchStockBicycleOrderFunc(bicycleId);

		if ((status !== 200 && status !== 201) || !data) {
			return { data: null, error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

const getStockBicycleOrders = createAction<Promise<State['orders']>, number>(
	'GET_STOCK_BICYCLE_ORDERS',
	async bicycleId => {
		const { status, data, message = '' } = await fetchStockBicycleOrdersFunc(bicycleId);

		if ((status !== 200 && status !== 201) || !data) {
			return { data: [], error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

const getBicycleInventoryPosition = createAction<Promise<State['inventoryPosition']>>(
	'GET_BICYCLE_INVENTORY_POSITION',
	async () => {
		const { status, data, message = '' } = await fetchBicycleInventoryPositionFunc();

		if ((status !== 200 && status !== 201) || !data) {
			return { data: [], error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

/**
 * getBicycleInventoryQrCodeInfo 區域
 */
export interface InfoAPIItem {
	id: number;
	gts_number: string;
	bicycle_number: string;
	position: string;
	rental_type: string;
	bicycle_spec: string;
	updated_at: string;
	forms: string[];
}

interface GetBicycleInventoryQrCodeInfoActionPayload {
	data: InfoAPIItem;
	error: string;
	loading: boolean;
}

export interface BicycleInventoryQrCodeInfo {
	id: number;
	spec_title: string;
	number?: string; // api 車架號碼屬性
	bicycle_number?: string; // 前端車架號碼屬性
	position_name: string;
	status: string;
	inventory_result: boolean;
}

export type BicycleInventoryQrCodeInfoPayload = Omit<BicycleInventoryQrCodeInfo, 'number'>;

/**
 * 透過 bicycleId 取得車輛盤點資訊
 *
 * 1. 從 redux store 內的 adminBicycleForm > forms > inventory > inventoryPosition 的 current state
 * 		要用到 inventoryPosition.value 內的 position_id 與 position_type
 * 2. 使用 formData 傳送 position_id (inventoryPosition.value.value) 與 position_type (inventoryPosition.value.data) 給後端
 * 3. 從後端取得盤點資訊，並從 reducer 放進 inventoryQrCodeInfo
 */
const getBicycleInventoryQrCodeInfo = createAction(
	'GET_BICYCLE_INVENTORY_QRCODE_INFO',
	(scannedBikeIdentifier: string) => async (
		_: Dispatch<Action<BicycleInventoryQrCodeInfoPayload>>,
		getState: GetState,
	) => {
		const {
			adminBicycleForms: {
				forms: {
					inventory: { inventoryPosition },
				},
			},
		} = getState();

		const formData = new FormData();
		formData.append('position_id', `${inventoryPosition.value.value}`);
		formData.append('position_type', `${inventoryPosition.value.data}`);

		// 先從 /api/bicycle/{bicycle_number}/info 這隻 API 取得 bicycleId
		// 這裡要連續叫兩隻 API 邏輯比原本較為複雜 考慮兩邊用途差異 故不復用其他檔案的 call API func
		const InfoAPIResponse = await wrapRetryAdminFetch<InfoAPIItem>(
			`bicycle/${scannedBikeIdentifier}/info`,
			{
				method: 'GET',
			},
		);

		// 錯誤處理: 給空字串會被 CORS policy 擋住 所以這邊給個大數模擬 INVENTORY_BICYCLE_NOT_FOUND 的情況 若有 edge case 可視情況更改
		const bicycleId = InfoAPIResponse.data ? InfoAPIResponse.data.id : '99999999';

		// 再從 /api/bicycle/{bicycle_id}/inventory 這隻 API 取得盤點資訊
		const response = await wrapRetryAdminFetch<BicycleInventoryQrCodeInfo>(
			`bicycle/${bicycleId}/inventory`,
			{
				method: 'POST',
				body: formData,
			},
			{},
			{ isFormData: true },
		);

		/**
		 * 需對應 API 錯誤 status 顯示給使用者 請參考以下 issue：
		 * https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/1194#note_1185805
		 * 且 非 404 系列應附上對應車架號碼
		 */
		const handledStatusResponse = {
			...response,
			data: response.data
				? {
						...response.data,
						bicycle_number: response.data.number,
				  }
				: { bicycle_number: null, status: null },
		};

		switch (handledStatusResponse.message) {
			// 404 INVENTORY_NOT_FOUND
			case 'INVENTORY_NOT_FOUND':
				handledStatusResponse.data.status = ErrorMessage.INVENTORY_NOT_FOUND;
				break;

			// 404 INVENTORY_BICYCLE_NOT_FOUND
			case 'INVENTORY_BICYCLE_NOT_FOUND':
				handledStatusResponse.data.status = ErrorMessage.INVENTORY_BICYCLE_NOT_FOUND;
				break;

			// 404 INVENTORY_POSITION_NOT_FOUND
			case 'INVENTORY_POSITION_NOT_FOUND':
				handledStatusResponse.data.status = ErrorMessage.INVENTORY_POSITION_NOT_FOUND;
				break;

			// 400 INVENTORY_BICYCLE_NOT_ALLOWED
			case 'INVENTORY_BICYCLE_NOT_ALLOWED':
				handledStatusResponse.data.status = ErrorMessage.INVENTORY_BICYCLE_NOT_ALLOWED;
				handledStatusResponse.data.bicycle_number = scannedBikeIdentifier;
				break;

			// 500 INVENTORY_BICYCLE_MAINTAIN_POS_FAILED
			case 'INVENTORY_BICYCLE_MAINTAIN_POS_FAILED':
				handledStatusResponse.data.status = ErrorMessage.INVENTORY_BICYCLE_MAINTAIN_POS_FAILED;
				handledStatusResponse.data.bicycle_number = scannedBikeIdentifier;
				break;

			default:
				break;
		}

		return handledStatusResponse;
	},
);

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	adminStockBicycle: handleActions<State, any>(
		{
			GET_STOCK_BICYCLE_INFO_PENDING: state => ({
				...state,
				info: {
					...state.info,
					loading: true,
					error: '',
					data: null,
				},
			}),

			GET_STOCK_BICYCLE_INFO_FULFILLED: (state, action: Action<State['info']>) => ({
				...state,
				info: {
					...state.info,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),

			GET_STOCK_BICYCLE_DESTINATIONS_PENDING: state => ({
				...state,
				destinations: {
					...state.destinations,
					loading: true,
					error: '',
					data: [],
				},
			}),

			GET_STOCK_BICYCLE_DESTINATIONS_FULFILLED: (state, action: Action<State['destinations']>) => ({
				...state,
				destinations: {
					...state.destinations,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),

			GET_STOCK_BICYCLE_ORDER_PENDING: state => ({
				...state,
				order: {
					...state.order,
					loading: true,
					error: '',
					data: null,
				},
			}),

			GET_STOCK_BICYCLE_ORDER_FULFILLED: (state, action: Action<State['order']>) => ({
				...state,
				order: {
					...state.order,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),

			GET_STOCK_BICYCLE_ORDERS_PENDING: state => ({
				...state,
				orders: {
					...state.orders,
					loading: true,
					error: '',
					data: [],
				},
			}),

			GET_STOCK_BICYCLE_ORDERS_FULFILLED: (state, action: Action<State['orders']>) => ({
				...state,
				orders: {
					...state.orders,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),

			GET_BICYCLE_INVENTORY_POSITION_PENDING: state => ({
				...state,
				inventoryPosition: {
					...state.inventoryPosition,
					loading: true,
					error: '',
					data: [],
				},
			}),
			GET_BICYCLE_INVENTORY_POSITION_FULFILLED: (
				state,
				action: Action<State['inventoryPosition']>,
			) => ({
				...state,
				inventoryPosition: {
					...state.inventoryPosition,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),

			GET_BICYCLE_INVENTORY_QRCODE_INFO_PENDING: state => ({
				...state,
				inventoryQrCodeInfo: {
					...state.inventoryQrCodeInfo,
					loading: true,
					error: '',
					data: {},
				},
			}),

			GET_BICYCLE_INVENTORY_QRCODE_INFO_FULFILLED: (
				state,
				action: Action<GetBicycleInventoryQrCodeInfoActionPayload>,
			) => ({
				...state,
				inventoryQrCodeInfo: {
					...state.inventoryQrCodeInfo,
					loading: false,
					data: action.payload.data,
					error: '',
				},
			}),
		},

		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAdminStockBicycleInfo ++
++----------------------------------------------------------------------*/
const selectInfo = (state: GlobalState) => state.adminStockBicycle.info;
const infoActionsMap = {
	getStockBicycleInfo,
};

export const useAdminStockBicycleInfo = () =>
	useRedux<ReturnType<typeof selectInfo>, typeof infoActionsMap>(selectInfo, infoActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminStockBicycleDestinations ++
++----------------------------------------------------------------------*/
const selectDestinations = (state: GlobalState) => state.adminStockBicycle.destinations;
const destinationsActionsMap = {
	getStockBicycleDestinations,
};

export const useAdminStockBicycleDestinations = () =>
	useRedux<ReturnType<typeof selectDestinations>, typeof destinationsActionsMap>(
		selectDestinations,
		destinationsActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminStockBicycleOrder ++
++----------------------------------------------------------------------*/
const selectOrder = (state: GlobalState) => state.adminStockBicycle.order;
const orderActionsMap = {
	getStockBicycleOrder,
};

export const useAdminStockBicycleOrder = () =>
	useRedux<ReturnType<typeof selectOrder>, typeof orderActionsMap>(selectOrder, orderActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminStockBicycleOrders ++
++----------------------------------------------------------------------*/
const selectOrders = (state: GlobalState) => state.adminStockBicycle.orders;
const ordersActionsMap = {
	getStockBicycleOrders,
};

export const useAdminStockBicycleOrders = () =>
	useRedux<ReturnType<typeof selectOrders>, typeof ordersActionsMap>(
		selectOrders,
		ordersActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminStockBicycleInventoryPosition ++
++----------------------------------------------------------------------*/
const selectInventoryPosition = (state: GlobalState) => state.adminStockBicycle.inventoryPosition;
const inventoryPositionActionsMap = {
	getBicycleInventoryPosition,
};

export const useAdminBicycleInventoryPosition = () =>
	useRedux<ReturnType<typeof selectInventoryPosition>, typeof inventoryPositionActionsMap>(
		selectInventoryPosition,
		inventoryPositionActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminStockBicycleInventoryQrCodeInfo ++
++----------------------------------------------------------------------*/
const selectInventoryQrCodeInfo = (state: GlobalState) =>
	state.adminStockBicycle.inventoryQrCodeInfo;
const inventoryQrCodeInfoActionsMap = {
	getBicycleInventoryQrCodeInfo,
};

export const useAdminBicycleInventoryQrCodeInfo = () =>
	useRedux<ReturnType<typeof selectInventoryQrCodeInfo>, typeof inventoryQrCodeInfoActionsMap>(
		selectInventoryQrCodeInfo,
		inventoryQrCodeInfoActionsMap,
	);
