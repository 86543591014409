import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.adminBicycleDeliver.replace(appPath.admin, ''),
	components: () => [import(/* webpackChunkName: 'adminBicycleDeliver' */ './component')],
	render: ([AdminBicycleDeliver]) => <AdminBicycleDeliver />,
};

export default routes;
