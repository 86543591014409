enum Gender {
	M = 'M', // 男性
	F = 'F', // 女性
	// N = 3, // 暫不提供
}

enum GenderNotInitial {
	M = 'Male',
	F = 'Female',
}

export type GenderCodes = keyof typeof Gender;
export type GenderValues = '1' | '2'; /* | 3; */
export type GenderValuesNotInitial = 'Male' | 'Female';

export const genderI18nNs = 'gender';

export const genderCodes: GenderCodes[] = [Gender.M, Gender.F /* gender.N */];

// GIANT GID 2.0 改版，修改 gender 傳入值 M|F => 1|0 故作此更動
export const genderValueCodes: GenderValues[] = ['1', '2'];
export const genderNoInitialCodes: GenderValuesNotInitial[] = [
	GenderNotInitial.M,
	GenderNotInitial.F /* GenderNotInitial.N */,
];

export const genderValueToCodeMap: { [key in GenderValues]: GenderCodes } = {
	1: 'M',
	2: 'F',
	// 3: 'N',
} as const;

export const genderCodeToValueMap: { [key in GenderCodes]: GenderValues } = {
	M: '1',
	F: '2',
	// N: 3,
} as const;

export const genderValueToNoInitialCodesMap: { [key in GenderValues]: GenderValuesNotInitial } = {
	1: 'Male',
	2: 'Female',
	// N: 3,
} as const;
