/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { compile } from 'path-to-regexp';

import {
	GiantEventData,
	GiantEventDataType,
	GIANT_EVENT_DATA_TO_ROUTE_MAP,
} from 'types/GiantEvents';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';
import { appPath } from 'util/routingConfig';

import { fetchRideOrdersByIdentityFunc, RideOrderPreview } from 'api/rideOrder';

import { setRideOrderList, setRideOrderListData } from './rideOrder';
import { State as GlobalState, GetState } from './reducers';
import { pushRoute } from './routing';

interface EventsRegisterFormType {
	phone: { intIdNum: string; phoneNum: string };
	identityNo: string;
}

export type EventsRegisterFormField = keyof EventsRegisterFormType;

type EventsRegisterForm = FormDataMapping<EventsRegisterFormType>;

interface FormChangePayload {
	key: EventsRegisterFormField;
	data: Partial<InputFiled>;
}

export interface State {
	formStatus: FetchedData<boolean>;
	form: EventsRegisterForm;
}

const initialState: State = {
	formStatus: {
		data: false,
		loading: false,
		error: '',
	},
	form: {
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		identityNo: { value: '', valid: true, error: '', required: true },
	},
};

// createAction
const changeEventsRegisterForm = createAction<FormChangePayload, FormChangePayload>(
	'CHANGE_EVENTS_REGISTER_FORM',
	({ key, data }) => ({ key, data }),
);

type SendEventsRegisterFormPayload = Partial<
	FetchedData<{
		eventId: number;
		eventType: GiantEventDataType;
	}>
>;

// if success will trigger sendEventsRegisterFormSuccessEpic redirect to events order list page
const sendEventsRegisterForm = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SendEventsRegisterFormPayload>,
	number,
	GiantEventDataType
>(
	'SEND_EVENTS_REGISTER_FORM',
	(activityId, eventType) => async (dispatch: Dispatch, getState: GetState) => {
		try {
			const {
				form: { identityNo, phone },
			} = getState().eventsRegister;

			const phoneNumber = `${phone.value.intIdNum} ${phone.value.phoneNum}`;

			if (
				eventType === GiantEventData.eventsFeaturedRides ||
				eventType === GiantEventData.eventsStoreRides
			) {
				const { status, message, data } = await fetchRideOrdersByIdentityFunc({
					activity_id: activityId,
					identity_no: identityNo.value,
					cellphone: phoneNumber,
				});

				if (status !== 200 && status !== 201) {
					throw new Error(message);
				}

				const normalizedData = data.reduce(
					(a, v) => ({
						selectors: [...a.selectors, v.selector],
						bySelectors: {
							...a.bySelectors,
							[v.selector]: v,
						},
					}),
					{ selectors: [], bySelectors: {} } as {
						selectors: string[];
						bySelectors: Record<string, RideOrderPreview>;
					},
				);

				await dispatch(setRideOrderList({ type: eventType, data: normalizedData.selectors }));
				await dispatch(setRideOrderListData(normalizedData.bySelectors));

				// send the payload for sendEventsRegisterFormSuccessEpic
				return {
					data: {
						eventId: activityId,
						eventType,
					},
				};
			}

			if (eventType === GiantEventData.eventsBikeClasses) {
				// do something
			}

			return { error: '' };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: '' };
		}
	},
);

export const sendEventsRegisterFormSuccessEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		filter(
			action =>
				action.type === 'SEND_EVENTS_REGISTER_FORM_FULFILLED' && action.payload?.data?.eventType,
		),
		mergeMap(action => {
			return of(
				pushRoute({
					pathname: compile(appPath.eventsOrders, { encode: encodeURIComponent })({
						id: action.payload.data?.eventId,
						eventType:
							GIANT_EVENT_DATA_TO_ROUTE_MAP[action.payload.data.eventType as GiantEventDataType],
					}),
				}),
			);
		}),
	);

const resetEventsRegisterForm = createAction('RESET_EVENTS_REGISTER_FORM');

// handleAction
export const reducer = {
	eventsRegister: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			RESET_EVENTS_REGISTER_FORM: state => ({
				...state,
				form: {
					...initialState.form,
				},
			}),

			CHANGE_EVENTS_REGISTER_FORM: (state, action: Action<FormChangePayload>) => ({
				...state,
				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			SEND_EVENTS_REGISTER_FORM_PENDING: state => ({
				...state,
				formStatus: {
					...initialState.formStatus,
					loading: true,
				},
			}),

			SEND_EVENTS_REGISTER_FORM_FULFILLED: (state, action) => ({
				...state,
				formStatus: {
					...state.formStatus,
					loading: false,
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
			}),
		},
		initialState,
	),
};

const selectEventsRegisterForm = (state: GlobalState) => ({
	form: state.eventsRegister.form,
	formStatus: state.eventsRegister.formStatus,
});

const eventsRegisterFormActionsMap = {
	changeEventsRegisterForm,
	sendEventsRegisterForm,
	resetEventsRegisterForm,
};

export const useEventsRegisterForm = () =>
	useRedux<ReturnType<typeof selectEventsRegisterForm>, typeof eventsRegisterFormActionsMap>(
		selectEventsRegisterForm,
		eventsRegisterFormActionsMap,
	);
