import { AboutUsSchoolData } from 'api/aboutUs';

import Instructor, { InstructorData } from './Instructor';

interface Lecture {
	description: string;
	lecturers: Instructor[];
}

/**
 * 「捷安特單車學校」
 *
 * @export
 * @class GiantBikeSchool
 */
export default class GiantBikeSchool implements AboutUsSchoolData {
	/**
	 * 唯一 ID
	 *
	 * @type {number}
	 * @memberof GiantBikeSchool
	 */
	id: number;

	/**
	 * 「捷安特單車學校」簡介
	 *
	 * @type {string}
	 * @memberof GiantBikeSchool
	 */
	introduction: string;

	/**
	 * 「捷安特單車學校」課程介紹
	 *
	 * @type {string}
	 * @memberof GiantBikeSchool
	 */
	course_introduction: string;

	/**
	 * 「捷安特單車學校」成為講師
	 *
	 * @type {string}
	 * @memberof GiantBikeSchool
	 */
	become_instructor: string;

	/**
	 * 「捷安特單車學校」講師介紹
	 *
	 * @type {string}
	 * @memberof GiantBikeSchool
	 */
	instructor_introduction: string;

	/**
	 * 「捷安特單車學校」講師資料
	 *
	 * @type {InstructorData[]}
	 * @memberof GiantBikeSchool
	 */
	instructors: InstructorData[];

	constructor(p?: AboutUsSchoolData) {
		this.id = p?.id || 0;
		this.introduction = p?.introduction || '';
		this.course_introduction = p?.course_introduction || '';
		this.become_instructor = p?.become_instructor || '';
		this.instructor_introduction = p?.instructor_introduction || '';
		this.instructors = p?.instructors || [];
	}

	/**
	 * 「捷安特單車學校」講師介紹資料
	 *
	 * @readonly
	 * @type {Lecture}
	 * @memberof GiantBikeSchool
	 */
	get lecture(): Lecture {
		return {
			description: this.instructor_introduction,
			lecturers: this.instructors.map(i => new Instructor(i)) || [],
		};
	}
}
