import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import EventsHighlightInfoRoute from './List';

const routes: CustomRoute = {
	path: appPath.eventsHighlights.replace(appPath.events, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'eventsHighlights' */ './component')],
			render: ([EventsHighlights]) => <EventsHighlights />,
		},
		EventsHighlightInfoRoute,
	],
};

export default routes;
