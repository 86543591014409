import React from 'react';

import EventsRegisterSearchRoute from 'routes/Events/RegisterSearch';
import EventsOrdersRoute from 'routes/Events/Orders';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'eventsFeaturedRidesInfo' */ './component')],
			render: ([EventsFeaturedRidesInfo]) => <EventsFeaturedRidesInfo />,
		},
		{
			path: '/reservation',
			components: () => [
				import(/* webpackChunkName: 'eventsFeaturedRidesReservation' */ './reservation'),
			],
			render: ([EventsFeaturedRidesReservation]) => <EventsFeaturedRidesReservation />,
		},
		EventsRegisterSearchRoute,
		EventsOrdersRoute,
	],
};

export default routes;
