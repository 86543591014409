import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'rentalNewsInfo' */ './component')],
	render: ([RentalNewsInfo]) => <RentalNewsInfo />,
};

export default routes;
