import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.adminBicycleToNormal.replace(appPath.admin, ''),
	components: () => [import(/* webpackChunkName: 'adminBicycleToNormal' */ './component')],
	render: ([AdminBicycleToNormal]) => <AdminBicycleToNormal />,
};

export default routes;
