import { DeliveryPriceType } from 'api/ride';

const findDeliveryInterval = (
	participantsNum: number,
	deliveryPrices: DeliveryPriceType[],
): DeliveryPriceType | null => {
	let deliveryItem: DeliveryPriceType | null = null;

	for (let i = 0; i < deliveryPrices.length; i += 1) {
		const { max_people, min_people } = deliveryPrices[i];
		if (participantsNum >= min_people && participantsNum <= max_people) {
			deliveryItem = deliveryPrices[i];
			break;
		}
	}

	return deliveryItem;
};

export default findDeliveryInterval;
