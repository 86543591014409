/* eslint-disable indent */
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { Moment } from 'moment';

import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { UploadFileData } from 'types/UploadFileData';

import { useRedux } from 'util/hook/redux';

import { fetchPackageStoresFunc } from 'api/package';

import { GetState, State as GlobalState } from './reducers';
import { fetchLogisticsLocations } from './locations';

export type PackageFormType = 'packing' | 'assembly' | 'depart' | 'arrival';

interface PackingFormData {
	isPackingChecked: boolean;
	isAssemblyChecked: boolean;
	isDeliveryChecked: boolean;
	packingCountry: SelectValue<number | null>;
	packingCity: SelectValue<number | null>;
	packingStore: SelectValue<number | null>;
	packingDate: Moment | null;
	assemblyCountry: SelectValue<number | null>;
	assemblyCity: SelectValue<number | null>;
	assemblyStore: SelectValue<number | null>;
	assemblyDate: Moment | null;
	isDeliveryFromAtoBChecked: boolean;
	departCountry: SelectValue<number | null>;
	departCity: SelectValue<number | null>;
	departStore: SelectValue<number | null>;
	departDate: Moment | null;
	arrivalCountry: SelectValue<number | null>;
	arrivalCity: SelectValue<number | null>;
	arrivalStore: SelectValue<number | null>;
	arrivalDate: Moment | null;
	additionalPurchase: SelectValue<number | null>[];
}

export type PackingFormField = keyof PackingFormData;

interface ContactFormData {
	lastName: string;
	firstName: string;
	passportLastName: string;
	passportFirstName: string;
	mobile: { intIdNum: string; phoneNum: string };
	email: string;
	remarks: string;
}

export interface PackingBikeGroup {
	bikeType: InputFiled<SelectValue<number | null>>;
	uploadPhotos: {
		ids: number[]; // 上傳圖片的 timestamp
		byId: Record<number, UploadFileData | null>; // 如果這個 id 的 data 是 null, 代表被刪除了
		errorsById: Record<number, string>;
		required: boolean;
		error: string;
	};
}

export type ContactFormFiled = keyof ContactFormData;

export interface State {
	packing: {
		stores: SelectValue[];
	};
	assembly: {
		stores: SelectValue[];
	};
	depart: { stores: SelectValue[] };
	arrival: { stores: SelectValue[] };
	packingForm: FormDataMapping<PackingFormData>;
	packingBikes: {
		ids: number[];
		type: object[];
		byIds: Record<number, PackingBikeGroup | null>;
	};
	contactForm: FormDataMapping<ContactFormData>;
}

const packingBikeGroup: PackingBikeGroup = {
	bikeType: { value: { label: '', value: null }, valid: true, error: '', required: true },
	uploadPhotos: {
		ids: [],
		byId: {},
		errorsById: {},
		required: false,
		error: '',
	},
};

const initialState: State = {
	packing: {
		stores: [],
	},
	assembly: {
		stores: [],
	},
	depart: {
		stores: [],
	},
	arrival: {
		stores: [],
	},
	packingForm: {
		isPackingChecked: { value: true, valid: true, error: '', required: false },
		isAssemblyChecked: { value: true, valid: true, error: '', required: false },
		isDeliveryChecked: { value: true, valid: true, error: '', required: false },
		packingCountry: { value: { label: '', value: null }, valid: true, error: '', required: true },
		packingCity: { value: { label: '', value: null }, valid: true, error: '', required: true },
		packingStore: { value: { label: '', value: null }, valid: true, error: '', required: true },
		packingDate: { value: null, valid: true, error: '', required: true },
		assemblyCountry: { value: { label: '', value: null }, valid: true, error: '', required: true },
		assemblyCity: { value: { label: '', value: null }, valid: true, error: '', required: true },
		assemblyStore: { value: { label: '', value: null }, valid: true, error: '', required: true },
		assemblyDate: { value: null, valid: true, error: '', required: true },
		isDeliveryFromAtoBChecked: { value: false, valid: true, error: '', required: false },
		departCountry: { value: { label: '', value: null }, valid: true, error: '', required: true },
		departCity: { value: { label: '', value: null }, valid: true, error: '', required: true },
		departStore: { value: { label: '', value: null }, valid: true, error: '', required: true },
		departDate: { value: null, valid: true, error: '', required: true },
		arrivalCountry: { value: { label: '', value: null }, valid: true, error: '', required: true },
		arrivalCity: { value: { label: '', value: null }, valid: true, error: '', required: true },
		arrivalStore: { value: { label: '', value: null }, valid: true, error: '', required: true },
		arrivalDate: { value: null, valid: true, error: '', required: true },
		additionalPurchase: {
			value: [{ label: '', value: null }],
			valid: true,
			error: '',
			required: true,
		},
	},
	packingBikes: {
		ids: [0],
		type: [{ value: '' }],
		byIds: {
			0: packingBikeGroup,
		},
	},
	contactForm: {
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		passportLastName: { value: '', valid: true, error: '', required: true },
		passportFirstName: { value: '', valid: true, error: '', required: true },
		mobile: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		remarks: { value: '', valid: true, error: '', required: false },
	},
};

interface PackageLocationsPayload {
	type: PackageFormType;
	data: SelectValue[];
}

const getDeliveryPackingStores = createAction<
	Promise<PackageLocationsPayload>,
	PackageFormType,
	number
>('GET_DELIVERY_PACKING_STORES', async (type: PackageFormType, locationId: number) => {
	try {
		const { status, message, data } = await fetchPackageStoresFunc(type, locationId);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}
		return {
			type,
			data: data.stores.map(store => ({
				value: store.id,
				label: store.name,
				data: store,
			})),
		};
	} catch (error) {
		return {
			type,
			data: [],
		};
	}
});

interface PackingFormChangePayload<K extends PackingFormField = PackingFormField> {
	key: K;
	data: Partial<InputFiled<PackingFormData[K]>>;
}

const changeDeliveryPackingForm = createAction<PackingFormChangePayload, PackingFormChangePayload>(
	'CHANGE_DELIVERY_PACKING_FORM',
	({ key, data }) => ({
		key,
		data,
	}),
);

interface ContactFormChangePayload<K extends ContactFormFiled = ContactFormFiled> {
	key: K;
	data: Partial<InputFiled<ContactFormData[K]>>;
}

const changeDeliveryContactForm = createAction<ContactFormChangePayload, ContactFormChangePayload>(
	'CHANGE_DELIVERY_CONTACT_FORM',
	({ key, data }) => ({
		key,
		data,
	}),
);

/**
 * 清空全部表單
 */
const resetForms = createAction('RESET_DELIVERY_FORMS');

const addDeliveryPackingBike = createAction<number>('ADD_DELIVERY_PACKING_BIKE', () =>
	new Date().getTime(),
);

const resetDeliveryPackingBike = createAction('RESET_DELIVERY_PACKING_BIKE');

const removeDeliveryPackingBike = createAction<number, number>(
	'REMOVE_DELIVERY_PACKING_BIKE',
	id => id,
);

interface PackingBikeChangePayload<K extends keyof PackingBikeGroup = keyof PackingBikeGroup> {
	id: number;
	key: K;
	data: Partial<PackingBikeGroup[K]>;
}

const changePackingBike = createAction<PackingBikeChangePayload, PackingBikeChangePayload>(
	'CHANGE_DELIVERY_PACKING_BIKE_TYPE',
	({ id, key, data }) => ({
		id,
		key,
		data,
	}),
);

/**
 * 勾選「打包」服務時才會出現對應表單內容，
 * 「服務類型」沒有選擇「打包」，「代客運送」設值為 false。
 */
const checkServiceTypeToChangeRelativeForm = createAction<
	(_: Dispatch, getState: GetState) => void,
	'packing' | 'assembly'
>(
	'CHECK_SERVICE_TYPE_CHANGE_RELATIVE_FORM',
	serviceType => (dispatch: Dispatch, getState: GetState) => {
		const {
			deliveryPackingForm: {
				packingForm: {
					isPackingChecked: { value: isPacking },
					isAssemblyChecked: { value: isAssembly },
				},
			},
		} = getState();

		const { keys, required } = {
			packing: {
				keys: ['packingCountry', 'packingCity', 'packingStore', 'packingDate'],
				required: isPacking,
			},
			assembly: {
				keys: ['assemblyCountry', 'assemblyCity', 'assemblyStore', 'assemblyDate'],
				required: isAssembly,
			},
		}[serviceType] as { keys: PackingFormField[]; required: boolean };

		keys.forEach(key => {
			dispatch(
				changeDeliveryPackingForm({
					key,
					data: {
						required,
						valid: true,
						error: '',
					},
				}),
			);
		});

		// 沒有選擇「打包」時，代客運送設值為 false。
		if (!isPacking || !isAssembly) {
			dispatch(
				changeDeliveryPackingForm({
					key: 'isDeliveryChecked',
					data: {
						value: false,
						valid: true,
						error: '',
					},
				}),
			);
		}
	},
);

/**
 *「國家」欄位更變時，打 API 改變「縣市」和「門市」選項。
 */
const clearCityAndStoreByChangingCountry = createAction<
	(_: Dispatch, getState: GetState) => void,
	{ serviceType: PackageFormType; countryValue: number }
>(
	'CLEAR_CITY_AND_STORE_BY_CHANGING_COUNTRY',
	({ serviceType, countryValue }) => (dispatch: Dispatch) => {
		dispatch(fetchLogisticsLocations(serviceType, countryValue));
		dispatch(getDeliveryPackingStores(serviceType, countryValue));

		const keys = {
			packing: ['packingCity', 'packingStore'],
			assembly: ['assemblyCity', 'assemblyStore'],
			depart: ['departCity', 'departStore'],
			arrival: ['arrivalCity', 'arrivalStore'],
		}[serviceType] as PackingFormField[];

		keys.forEach(key => {
			dispatch(
				changeDeliveryPackingForm({
					key,
					data: {
						value: {
							label: '',
							value: null,
						},
						valid: true,
						error: '',
					},
				}),
			);
		});
	},
);

/**
 * 「縣市」改變時，清空「門市」。
 */
const clearStoreByChangingCity = createAction<
	(_: Dispatch, getState: GetState) => void,
	{ cityId: number; serviceType: PackageFormType }
>(
	'CLEAR_STORE_BY_CHANGING_CITY',
	({ cityId, serviceType }) => (dispatch: Dispatch, getState: GetState) => {
		const {
			deliveryPackingForm: {
				packingForm: { packingStore, assemblyStore, departStore, arrivalStore },
			},
		} = getState();

		const { key, condition } = {
			packing: {
				key: 'packingStore',
				condition: !packingStore.value.value || packingStore.value.value !== cityId,
			},
			assembly: {
				key: 'assemblyStore',
				condition: !assemblyStore.value.value || assemblyStore.value.value !== cityId,
			},
			depart: {
				key: 'departStore',
				condition: !departStore.value.value || departStore.value.value !== cityId,
			},
			arrival: {
				key: 'arrivalStore',
				condition: !arrivalStore.value.value || arrivalStore.value.value !== cityId,
			},
		}[serviceType] as { key: PackingFormField; condition: boolean };

		if (condition) {
			dispatch(
				changeDeliveryPackingForm({
					key,
					data: {
						value: {
							label: '',
							value: null,
						},
						valid: true,
						error: '',
					},
				}),
			);
		}
	},
);

export const reducer = {
	deliveryPackingForm: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_DELIVERY_PACKING_STORES_FULFILLED: (state, action: Action<PackageLocationsPayload>) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					stores: action.payload.data,
				},
			}),

			RESET_DELIVERY_FORMS: state => ({
				...state,

				packingForm: initialState.packingForm,
				contactForm: initialState.contactForm,
			}),

			RESET_DELIVERY_PACKING_BIKE: state => ({
				...state,

				packingBikes: initialState.packingBikes,
			}),

			CHANGE_DELIVERY_PACKING_FORM: (state, action: Action<PackingFormChangePayload>) => ({
				...state,

				packingForm: {
					...state.packingForm,
					[action.payload.key]: {
						...state.packingForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CHANGE_DELIVERY_CONTACT_FORM: (state, action: Action<ContactFormChangePayload>) => ({
				...state,

				contactForm: {
					...state.contactForm,
					[action.payload.key]: {
						...state.contactForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			ADD_DELIVERY_PACKING_BIKE: (state, action: Action<number>) => ({
				...state,

				packingBikes: {
					...state.packingBikes,
					ids: [...state.packingBikes.ids, action.payload],
					byIds: {
						...state.packingBikes.byIds,
						[action.payload]: { ...packingBikeGroup },
					},
				},
			}),

			REMOVE_DELIVERY_PACKING_BIKE: (state, action: Action<number>) => ({
				...state,

				packingBikes: {
					...state.packingBikes,
					ids: state.packingBikes.ids.filter(id => id !== action.payload),
					byIds: {
						...state.packingBikes.byIds,
						[action.payload]: null,
					},
				},
			}),

			CHANGE_DELIVERY_PACKING_BIKE_TYPE: (state, action: Action<PackingBikeChangePayload>) => ({
				...state,

				packingBikes: {
					...state.packingBikes,
					byIds: {
						...state.packingBikes.byIds,
						[action.payload.id]: state.packingBikes.byIds[action.payload.id]
							? {
									...(state.packingBikes.byIds[action.payload.id] as PackingBikeGroup),
									[action.payload.key]: {
										...(state.packingBikes.byIds[action.payload.id] as PackingBikeGroup)[
											action.payload.key
										],
										...action.payload.data,
									},
							  }
							: null,
					},
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useDeliveryPackingForm ++
++----------------------------------------------------------------------*/
const selectDeliveryLocations = (state: GlobalState) => ({
	packing: state.deliveryPackingForm.packing.stores,
	assembly: state.deliveryPackingForm.assembly.stores,
	depart: state.deliveryPackingForm.depart.stores,
	arrival: state.deliveryPackingForm.arrival.stores,
});

const deliveryLocationsActionsMap = { getDeliveryPackingStores };

export const useDeliveryLocations = () =>
	useRedux<ReturnType<typeof selectDeliveryLocations>, typeof deliveryLocationsActionsMap>(
		selectDeliveryLocations,
		deliveryLocationsActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useDeliveryPackingForm ++
++----------------------------------------------------------------------*/
const selectDeliveryPackingForm = (state: GlobalState) => ({
	packingForm: state.deliveryPackingForm.packingForm,
	packingBikes: state.deliveryPackingForm.packingBikes,
	contactForm: state.deliveryPackingForm.contactForm,
});

const deliveryPackingFormActionsMap = {
	resetForms,
	resetDeliveryPackingBike,
	changeDeliveryPackingForm,
	changeDeliveryContactForm,
	addDeliveryPackingBike,
	removeDeliveryPackingBike,
	changePackingBike,
	clearStoreByChangingCity,
	clearCityAndStoreByChangingCountry,
	checkServiceTypeToChangeRelativeForm,
};

export const useDeliveryPackingForm = () =>
	useRedux<ReturnType<typeof selectDeliveryPackingForm>, typeof deliveryPackingFormActionsMap>(
		selectDeliveryPackingForm,
		deliveryPackingFormActionsMap,
	);
