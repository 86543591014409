import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.travelGuide.replace(appPath.travel, ''),
	components: () => [import(/* webpackChunkName: 'travelGuide' */ './component')],
	render: ([TravelGuide]) => <TravelGuide />,
};

export default routes;
