/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, handleActions, Action } from 'redux-actions';

import { LocationData } from 'types/LocationData';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import { LocationType, fetchLocationsFunc, fetchLocationsTreeFunc } from 'api/location';

import { PackageFormType } from './deliveryPackingForm';
import { State as GlobalState } from './reducers';

export interface State {
	dataById: Record<number, LocationData>;
	locations: FetchedData<number[]>;
	locationsTree: FetchedData<{
		parentIds: number[];
		childrenIdsByParentId: Record<number, number[]>;
	}>;
	logistics: Record<PackageFormType, FetchedData<number[]>>;
}

const initialState: State = {
	dataById: {},
	locations: {
		loading: false,
		error: '',
		data: [],
	},
	locationsTree: {
		loading: false,
		error: '',
		data: { parentIds: [], childrenIdsByParentId: {} },
	},
	logistics: {
		packing: { loading: false, error: '', data: [] },
		assembly: { loading: false, error: '', data: [] },
		depart: { loading: false, error: '', data: [] },
		arrival: { loading: false, error: '', data: [] },
	},
};

const locationsNormalizedFunc = (data: LocationData[]) =>
	data.reduce(
		(a, v) => ({
			ids: [...a.ids, v.id],
			byId: {
				...a.byId,
				[v.id]: v,
			},
		}),
		{ ids: [], byId: {} } as {
			ids: number[];
			byId: { [key: number]: LocationData };
		},
	);

type GetLocationsPayload = Partial<
	FetchedData<{
		ids: number[];
		byId: Record<number, LocationData>;
	}>
>;

export const getLocations = createAction<
	Promise<GetLocationsPayload>,
	{ locationType: LocationType; ancestorId?: number }
>('GET_LOCATIONS', async ({ locationType, ancestorId }) => {
	try {
		const { status, message, data } = await fetchLocationsFunc({
			location_type: locationType,
			ancestor_id: ancestorId,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const normalizedData = locationsNormalizedFunc(data);

		return {
			data: normalizedData,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

const changeLogisticsLocationsStatus = createAction<PackageFormType, PackageFormType>(
	'CHANGE_LOGISTICS_LOCATIONS_STATUS',
	type => type,
);

interface LogisticsLocationsPayload
	extends Partial<
		FetchedData<{
			ids: number[];
			byId: Record<number, LocationData>;
		}>
	> {
	type: PackageFormType;
}

export const fetchLogisticsLocations = createAction<
	Promise<LogisticsLocationsPayload>,
	PackageFormType,
	number
>('FETCH_LOGISTICS_LOCATIONS', async (type, ancestorId) => {
	try {
		const { status, message, data } = await fetchLocationsFunc({
			location_type: 'CITY',
			ancestor_id: ancestorId,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const normalizedData = locationsNormalizedFunc(data);

		return {
			type,
			data: normalizedData,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { type, error: message };
		}
		return { type, error: '' };
	}
});

interface LocationsTreePayloadData {
	ids: number[];
	childrenIdsByParentId: Record<number, number[]>;
	byId: { [key: number]: LocationData };
}

type GetLocationsTreePayload = Partial<FetchedData<LocationsTreePayloadData>>;

const getLocationsTree = createAction<
	Promise<GetLocationsTreePayload>,
	{ parentType: LocationType; childType: LocationType; ancestorId?: number }
>('GET_LOCATIONS_TREE', async ({ parentType, childType, ancestorId }) => {
	try {
		const { status, message, data } = await fetchLocationsTreeFunc({
			parent_type: parentType,
			child_type: childType,
			ancestor_id: ancestorId,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const normalizedData = data.reduce(
			(a, v) => {
				const childNormalizedData = locationsNormalizedFunc(v.children);
				return {
					ids: [...a.ids, v.id],
					childrenIdsByParentId: {
						...a.childrenIdsByParentId,
						[v.id]: childNormalizedData.ids,
					},
					byId: {
						...a.byId,
						...childNormalizedData.byId,
						[v.id]: v,
					},
				};
			},
			{ ids: [], childrenIdsByParentId: {}, byId: {} } as LocationsTreePayloadData,
		);

		return {
			data: normalizedData,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: '' };
	}
});

export const reducer = {
	locations: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_LOCATIONS_PENDING: state => ({
				...state,
				locations: {
					...initialState.locations,

					loading: true,
				},
			}),

			GET_LOCATIONS_FULFILLED: (state, action: Action<GetLocationsPayload>) => ({
				...state,
				locations: {
					...state.locations,

					loading: false,
					...(action.payload.data && {
						data: action.payload.data.ids,
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
				...(action.payload.data && {
					dataById: { ...state.dataById, ...action.payload.data.byId },
				}),
			}),

			GET_LOCATIONS_TREE_PENDING: state => ({
				...state,
				locationsTree: {
					...initialState.locationsTree,

					loading: true,
				},
			}),

			GET_LOCATIONS_TREE_FULFILLED: (state, action: Action<GetLocationsTreePayload>) => ({
				...state,
				locationsTree: {
					...state.locationsTree,

					loading: false,
					...(action.payload.data && {
						data: {
							parentIds: action.payload.data.ids,
							childrenIdsByParentId: action.payload.data.childrenIdsByParentId,
						},
					}),
					...(action.payload.error && {
						error: action.payload.error,
					}),
				},
				...(action.payload.data && {
					dataById: { ...state.dataById, ...action.payload.data.byId },
				}),
			}),

			CHANGE_LOGISTICS_LOCATIONS_STATUS: (state, action: Action<PackageFormType>) => ({
				...state,
				logistics: {
					...state.logistics,
					[action.payload]: {
						loading: true,
						error: '',
						data: [],
					},
				},
			}),

			FETCH_LOGISTICS_LOCATIONS_FULFILLED: (state, action: Action<LogisticsLocationsPayload>) => ({
				...state,
				logistics: {
					...state.logistics,

					[action.payload.type]: {
						loading: false,
						...(action.payload.data && {
							data: action.payload.data.ids,
						}),
						...(action.payload.error && {
							error: action.payload.error,
						}),
					},
				},
				...(action.payload.data && {
					dataById: { ...state.dataById, ...action.payload.data.byId },
				}),
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useLocations ++
++----------------------------------------------------------------------*/
const selectLocations = (state: GlobalState) => ({
	locations: state.locations.locations,
	dataById: state.locations.dataById,
});

const locationsMap = {
	getLocations,
};

export const useLocations = () =>
	useRedux<ReturnType<typeof selectLocations>, typeof locationsMap>(selectLocations, locationsMap);

/* +----------------------------------------------------------------------
	++ useLocationsTree ++
++----------------------------------------------------------------------*/
const selectLocationsTree = (state: GlobalState) => ({
	locationsTree: state.locations.locationsTree,
	dataById: state.locations.dataById,
});

const locationsTreeMap = {
	getLocationsTree,
};

export const useLocationsTree = () =>
	useRedux<ReturnType<typeof selectLocationsTree>, typeof locationsTreeMap>(
		selectLocationsTree,
		locationsTreeMap,
	);

/* +----------------------------------------------------------------------
	++ useLogisticsLocations ++
++----------------------------------------------------------------------*/
const selectLogistics = (state: GlobalState) => ({
	locations: state.locations.logistics,
	dataById: state.locations.dataById,
});

const logisticsActionMap = {
	changeLogisticsLocationsStatus,
	fetchLogisticsLocations,
};

export const useLogisticsLocations = () =>
	useRedux<ReturnType<typeof selectLogistics>, typeof logisticsActionMap>(
		selectLogistics,
		logisticsActionMap,
	);
