/* eslint-disable no-mixed-operators */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import moment from 'moment';

import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';
import {
	ErrorMessage,
	ErrorMessageCodes,
	errorMessageCodes,
	ERROR_MESSAGE_I18N_NAMESPACE,
	OTHER_ERROR_MESSAGE_I18N_KEY,
} from 'types/ErrorMessage';

import { t } from 'util/i18n';
import { useRedux } from 'util/hook/redux';
import { openPaymentView, createPaymentWindow } from 'util/functions/openPaymentView';
import findDeliveryInterval from 'util/functions/findDeliveryInterval';

import { openModal } from 'models/modal';
import { updateParticipantPrimaryStatus } from 'models/travelParticipant';
import { getDeliveryWayMappingValue } from 'models/deliveryWay';
import { getInvoiceMappingValue } from 'models/invoice';
import { changePayMethodByParticipant, getPaymentMappingValue } from 'models/payment';

import { DeliveryPriceType } from 'api/ride'; // 這邊的 Type 可以拉出來

// import { getTravelLeftQuota } from 'models/travel';
import {
	fetchTravelAuthTokenByIdentityFunc,
	OriginalOrder,
	submitTravelOrderFunc,
	submitTravelOrderTransactionFunc,
	TravelOrderResponse,
	TravelOrderTransactionAccountInfoData,
	TravelOrderTransactionResponse,
} from 'api/travelOrder';
import { GiantTravelRouteType } from 'types/GiantTravel';
import { TravelFormDataType } from 'models/travelParticipant/type';
import session from 'util/session';
import storage from 'util/storage';
import { findIntNumByCountry, modifyPhoneByIntId, nullToUndefined } from 'util/helper';
import { paymentTypeForCUBMap } from 'util/payment';
import { eventReservationFormMockData } from 'models/eventsParticipant/__mocks__/data';
import { travelReservationFormMockData } from 'models/travelParticipant/__mocks__/data';
import { getTravelParticipantPaymentDetails } from 'models/travelOrder';
import { GenderValues, GenderValuesNotInitial, genderValueToNoInitialCodesMap } from 'types/Gender';
import { SelectValue } from 'types/SelectValue';
import { TravelParticipantInfoData } from 'types/TravelOrderParticipantPaymentDetail';
import {
	participantTravelDataMapping,
	travelReservationFormDataMapping,
	calcParticipantAdditionalPurchaseItems,
	TravelStepOrderPurchaseItem,
} from './utils';
import { TravelFormField, TravelFormFieldData, ShoppingItemData } from './type';

import { GetState, State as GlobalState } from '../reducers';

interface AccountInfo {
	account: string;
	deadline: string;
}

export interface ShoppingDetailList {
	roomInfo: TravelStepOrderPurchaseItem[];
	additionalPurchaseBike: TravelStepOrderPurchaseItem[];
	giveaway: TravelStepOrderPurchaseItem[];
	additionalPurchaseProducts: TravelStepOrderPurchaseItem[];
}

export interface State {
	formStatus: {
		isReady: boolean;

		/**
		 * 是否真正送出訂單（處理訂單中）
		 *
		 * @type {boolean}
		 */
		isSubmitting: boolean;

		/**
		 * 旅遊報名流程
		 *
		 * @type {(0 | 1 | 2 | 3 | 4 | 5)}
		 */
		step: 0 | 1 | 2 | 3 | 4 | 5;

		/**
		 * 「我要繳費」流程，追加款項個別項目的 id
		 *
		 * @type {number}
		 */
		addPaymentId: number;

		/**
		 * 旅遊訂單在後端 DB 的唯一 ID （用來獲得訂單資訊）
		 *
		 * @type {string}
		 */
		selector: string;
		view: string;
		accountInfo: AccountInfo;
	};
	form: FormDataMapping<TravelFormFieldData>;
	shoppingDetail: {
		totalPrice: number;
		list: ShoppingDetailList;
	};
}

const initialState: State = {
	formStatus: {
		isReady: false,
		isSubmitting: false,
		step: 0,
		addPaymentId: 0,
		selector: '',
		view: '',
		accountInfo: {
			account: '',
			deadline: '',
		},
	},
	form: {
		agreeContract: { value: false, valid: true, error: '', required: false },
		isAsMemberInfo: { value: false, valid: true, error: '', required: false },
		asMember: { value: { label: '', value: null }, valid: true, error: '', required: false },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		citizenId: { value: '', valid: true, error: '', required: true },
		birthday: { value: null, valid: true, error: '', required: true },
		gender: { value: { label: '', value: null }, valid: true, error: '', required: true },
	},
	shoppingDetail: {
		totalPrice: 0,
		list: {
			roomInfo: [],
			additionalPurchaseBike: [],
			giveaway: [],
			additionalPurchaseProducts: [],
		},
	},
};

/** ------------------------------------------------------------------------------------------------------------------------ */

const clearTravelReservation = createAction('CLEAR_TRAVEL_RESERVATION');

const initTravelReservationForm = createAction('INIT_TRAVEL_RESERVATION_FORM');

const setTravelReservationReady = createAction('SET_TRAVEL_RESERVATION_READY');

const setStep = createAction<State['formStatus']['step'], State['formStatus']['step']>(
	'SET_TRAVEL_RESERVATION_STEP',
	step => step,
);

const setAddPaymentId = createAction('SET_ADD_PAYMENT_ID', (id: number) => id);

export const goNextStep = createAction('SET_TRAVEL_RESERVATION_NEXT_STEP');

const goPrevStep = createAction('SET_TRAVEL_RESERVATION_PREV_STEP');

/** ------------------------------------------------------------------------------------------------------------------------ */
export interface TravelFormChangePayload {
	key: TravelFormField;
	data: Partial<InputFiled>;
}

export const changeForm = createAction(
	'CHANGE_TRAVEL_RESERVATION_FORM',
	({ key, data }: TravelFormChangePayload) => ({
		key,
		data,
	}),
);

/** ------------------------------------------------------------------------------------------------------------------------ */

interface ChangeItineraryRowDataParsedType {
	last_name: string;
	first_name: string;
	cellphone_country_code: string;
	cellphone: string;
	email: string;
	identity_no: string;
	birthday: string;
	gender: GenderValuesNotInitial;
}

/**
 * 轉團的 action ，把 travelItinerary 的資料轉換成 travelReservation 的資料並打進 reducer 內
 */
export const changeItineraryReservationDataByAdmin = createAction(
	'CHANGE_ITINERARY_RESERVATION_DATA_BY_ADMIN',
	(reservationData: ChangeItineraryRowDataParsedType) => async () => (dispatch: Dispatch) => {
		dispatch(changeForm({ key: 'lastName', data: { value: reservationData.last_name } }));
		dispatch(changeForm({ key: 'firstName', data: { value: reservationData.first_name } }));
		dispatch(
			changeForm({
				key: 'phone',
				data: {
					value: {
						intIdNum: reservationData.cellphone_country_code,
						phoneNum: reservationData.cellphone,
					},
				},
			}),
		);
		dispatch(changeForm({ key: 'email', data: { value: reservationData.email } }));
		dispatch(changeForm({ key: 'citizenId', data: { value: reservationData.identity_no } }));
		dispatch(changeForm({ key: 'birthday', data: { value: moment(reservationData.birthday) } }));
		dispatch(
			changeForm({
				key: 'gender',
				data: { value: { label: reservationData.gender, value: reservationData.gender } },
			}),
		);
		dispatch(
			changeForm({
				key: 'phone',
				data: {
					value: {
						intIdNum: reservationData.cellphone_country_code,
						phoneNum: reservationData.cellphone,
					},
				},
			}),
		);
	},
);

/** ------------------------------------------------------------------------------------------------------------------------ */

/** 同步會員資料到訂單資訊欄位 */
const syncFormWithMemberInfo = createAction<
	(dispatch: Dispatch, getState: GetState) => void,
	boolean
>(
	'SYNC_TRAVEL_RESERVATION_FORM_WITH_MEMBER_INFO',
	(check = false) => (dispatch: Dispatch, getState: GetState) => {
		const {
			travelReservation: { form },
			auth: { currentUserGid, userDataByGid },
			countryCodes: {
				data: { byCodes: countryCodesByCodes },
			},
		} = getState();

		const memberId = form.asMember.value.value;
		const userData =
			currentUserGid && check
				? userDataByGid[currentUserGid]
				: memberId
				? userDataByGid[memberId]
				: null;

		// todo: 未來可能會需要同步其他的欄位

		console.log('userData: ', userData);

		/**
		 * 客製化表單規則：
		 *
		 * 因應表單的值需要不同的處理
		 */
		const fieldsToSync = [
			{
				key: 'lastName',
				value: form.lastName.value || userData?.lastName || '',
			},
			{
				key: 'firstName',
				value: form.firstName.value || userData?.firstName || '',
			},
			{
				key: 'email',
				value: form.email.value || userData?.email || '',
			},
			{
				key: 'birthday',
				value:
					(typeof moment(form.birthday.value) === 'string' && moment(form.birthday.value)) ||
					moment(userData?.birthday),
			},
			{
				key: 'gender',
				value: {
					label:
						form.gender.value.value ||
						genderValueToNoInitialCodesMap[userData?.gender as GenderValues] ||
						'',
					value:
						form.gender.value.value ||
						genderValueToNoInitialCodesMap[userData?.gender as GenderValues] ||
						'',
				},
			},
			{
				key: 'phone',
				value: {
					intIdNum:
						form.phone.value.intIdNum ||
						findIntNumByCountry(countryCodesByCodes, userData?.general?.isoCountryCode as string) ||
						'',
					phoneNum:
						form.phone.value.phoneNum ||
						modifyPhoneByIntId(
							String(userData?.general?.phoneNumber),
							findIntNumByCountry(countryCodesByCodes, userData?.general?.isoCountryCode as string),
						) ||
						'',
				},
			},
		];

		console.log('fieldsToSync', fieldsToSync);

		fieldsToSync.forEach(field => {
			dispatch(
				changeForm({
					key: field.key as keyof TravelFormFieldData,
					data: {
						value: field.value,
					},
				}),
			);
		});
	},
);

/**
 * 計算旅遊訂單的購物明細和訂單總金額
 *
 * 訂單總金額 = 團費 + 各項加價購
 *
 * 1. 團費： 選擇的旅遊房間金額。
 * 2. 各項加價購： 參加者的各項加價購 price x {數量}。
 * 		加價購包含 4 種：商品加價購（products）、服務加價購（services）、贈品（gifts）、加價租用車款（rentalBike）。
 * 3. 旅遊訂單 submit 後才會進行計算，並且存進 redux 內，可以在 travelReservation 底下的 shoppingDetail 找到。
 */
export const calcTravelReservationShoppingDetail = createAction<
	(_: Dispatch, getState: GetState) => State['shoppingDetail']
>('CALCULATE_TRAVEL_RESERVATION_SHOPPING_DETAIL', () => (_: Dispatch, getState: GetState) => {
	const {
		product: { dataById: productDataById },
		travelParticipant: {
			form: { byIds: participantById, ids: participantIds },
		},
		travel: { reservationTravelInfo },
	} = getState();

	/** 計算參加者的加價購明細 */
	const { totalPrice, result: list } = calcParticipantAdditionalPurchaseItems(
		participantIds,
		participantById,
		productDataById,
		reservationTravelInfo.data,
	);

	return {
		totalPrice,
		list,
	};
});

/** ------------------------------------------------------------------------------------------------------------------------ */

/**
 * 取得 authToken 以便接下來取得旅遊訂單相關 API 操作
 */
export const travelAuthTokenFetchFunc = createAction(
	'TRAVEL_AUTH_TOKEN_FETCH_FUNC',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			auth: {
				loginUserGid: { data: loginUserGid },
			},
			travelReservation: { form: formData },
		} = getState();

		const { data } = await fetchTravelAuthTokenByIdentityFunc({
			identityNo: formData.citizenId.value,
			birthday: nullToUndefined(formData.birthday.value?.format('YYYY-MM-DD')),
		});

		// 把 travelAuthToken 打入 localStorage 內儲存
		if (data.token) {
			storage.setItem('travelAuthToken', data.token);
		}
	},
);

/** ------------------------------------------------------------------------------------------------------------------------ */

type SubmitTravelOrderPayload = Partial<FetchedData<TravelOrderResponse>>;

/** 送出旅遊訂單 */
const submitTravelOrder = createAction(
	'SUBMIT_TRAVEL_ORDER',
	(travelItineraryId: string) => async (dispatch: Dispatch, getState: GetState) => {
		storage.removeItem('travelAuthToken');

		dispatch(travelAuthTokenFetchFunc());

		/*  計算訂單總金額 */
		dispatch(calcTravelReservationShoppingDetail());

		const {
			payment: { payMethodByParticipant },
			travel: {
				reservationTravelInfo: { data: travelInfo },
			},
			travelReservation: {
				form: formData,
				shoppingDetail: { totalPrice },
			},
			travelParticipant: {
				form: { byIds: participantById, ids: participantIds },
			},
		} = getState();

		// const isPaymentRequired = totalPrice !== 0;

		// if (isPaymentRequired) {
		// 	/*  map 發票資訊 */
		// 	dispatch(getInvoiceMappingValue());
		// 	/*  map 付款方式 */
		// 	dispatch(getPaymentMappingValue());
		// }

		if (travelInfo === null) throw new Error('travel data not found');

		/** map 訂單資訊 */
		const formInfos = travelReservationFormDataMapping(formData, payMethodByParticipant);

		console.log('/** map 訂單資訊 */', formInfos);

		/** map 參加者資訊 */
		const participantsInfo = participantTravelDataMapping(
			participantIds,
			participantById as { [id: number]: FormDataMapping<TravelFormDataType> },
		);

		console.log('/** map 參加者資訊 */', participantsInfo);

		// 轉團的資料
		const transferredData = storage.getItem('transferredData', false);

		let originalOrder = {};

		// 如果轉團訂單有值則取得原始訂單資料，並帶入 submitTravelOrderFunc
		if (transferredData) {
			const originalParticipantIds = JSON.parse(
				transferredData,
			).participants.map((participant: TravelParticipantInfoData) => Number(participant.id));

			originalOrder = {
				orderSelector: JSON.parse(transferredData).order.selector,
				participants: originalParticipantIds,
			};
		}

		try {
			const { data, status, message } = await submitTravelOrderFunc({
				travel_id: travelInfo.id,
				travel_itinerary_id: parseInt(travelItineraryId, 10),
				totalPrice,
				...formInfos,
				...participantsInfo,
				originalOrder,
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			dispatch(getTravelParticipantPaymentDetails(data.selectors[0]));
			storage.setItem('travelOrderSelector', data.selectors[0]);

			// 如果該參加者後端判定為備取，則直接改其付款方式為 SEPARATE
			if (data.registeredType === 'waiting') {
				dispatch(
					changePayMethodByParticipant({
						key: 'paymentType',
						data: {
							value: {
								label: t('orderPaymentStatus:SEPARATE'),
								value: 'SEPARATE',
							} as SelectValue,
							valid: true,
							error: '',
						},
					}),
				);
			}

			return { data };
		} catch (_error) {
			if (_error instanceof Error) {
				const { message } = _error;
				/* 報名失敗：參加者正取名額 > 目前正取報名餘額；打 API 更新正取報名餘額，並重新計算每一個參加者的正（備）取狀態 */
				// if (message === 'NOT_ENOUGH_QUOTA') {
				// 	dispatch(updateParticipantPrimaryStatus());
				// 	dispatch(calcTravelReservationShoppingDetail());
				// 	dispatch(setStep(0)); // 回到 step 1
				// }

				/* 拋出錯誤 SUBMIT_TRAVEL_ORDER_REJECTED，觸發 catchTravelOrderErrorEpic 顯示重新計算正取名額的提示 Modal  */
				throw new Error(message);
			}

			return { error: '' };
		}
	},
);

/** ------------------------------------------------------------------------------------------------------------------------ */

/**
 * catch submit form error
 */
export const catchTravelOrderErrorEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType('SUBMIT_TRAVEL_ORDER_REJECTED'),
		mergeMap(action => {
			const { message } = action.payload;
			const isErrorMessage = errorMessageCodes.includes(message as ErrorMessageCodes);

			// if (isErrorMessage && message === ErrorMessage.NOT_ENOUGH_QUOTA) {
			// 	return of(
			// 		openModal({
			// 			category: 'modal',
			// 			type: 'warning',
			// 			i18n: 'travelReservationForm',
			// 			data: {
			// 				message: 'updateParticipantIsPrimaryStatus',
			// 			},
			// 		}),
			// 	);
			// }

			return of(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ERROR_MESSAGE_I18N_NAMESPACE,
					data: {
						message: isErrorMessage ? message : OTHER_ERROR_MESSAGE_I18N_KEY,
						status: 'warning',
					},
				}),
			);
		}),
	);

/** ------------------------------------------------------------------------------------------------------------------------ */

/**
 * 「旅遊訂單」第三步驟付款送出、「我要繳費」的付款送出（該 function 操作在兩個頁面）
 *
 * 該付款 function 會透過 transactionOrderInfo 是否有值來決定使用在哪個頁面
 *
 *「我要繳費」會在 paymentCase 帶入 PURCHASE ，「旅遊訂單」則是帶入 transactions.label
 */
const submitTravelOrderTransaction = createAction<
	(_: Dispatch, getState: GetState) => Promise<TravelOrderTransactionResponse>,
	string
>('SUBMIT_TRAVEL_ORDER_TRANSACTION', selector => async (dispatch: Dispatch, getState: GetState) => {
	const {
		travelReservation: {
			formStatus: { addPaymentId },
		},
		payment: {
			payMethodByTransaction: { value: transactions },
			payMethodForCUB: {
				value: { value: payMethodForCUB },
			},
		},
		travelRegister: {
			transactionOrderInfo: { data: transactionOrderInfo },
		},
	} = getState();

	try {
		// 該 transaction id 來自「我要繳費」追加款項付款 id
		const travelAddPaymentIds = addPaymentId;

		// 該 transaction id 來自「旅遊訂單」
		const travelCreateOrderIds =
			transactions.value !== null
				? transactions.value.reduce((resultArr, currentTransaction) => {
						resultArr.push(currentTransaction);
						return resultArr;
				  }, [] as number[])
				: [];

		let transactionIds;

		// 「我要繳費」流程，針對追加款項特別做處理
		if (
			transactionOrderInfo.length > 0 &&
			transactionOrderInfo.some(d => d.adjustment.code === 'ADD_PAYMENT')
		) {
			transactionIds = [travelAddPaymentIds];
		} else {
			transactionIds = travelCreateOrderIds;
		}

		const { status, message, data } = await submitTravelOrderTransactionFunc(selector, {
			paymentCase: transactionOrderInfo.some(d => d.adjustment.code === 'ADD_PAYMENT')
				? 'PURCHASE'
				: transactions.data || '',
			paymentMethod: payMethodForCUB !== null ? +payMethodForCUB : 0,
			transactionIds,
		});

		if (status === 200) {
			console.log('in the 200 submitTravelOrderTransaction');
			console.log('data in submitTravelOrderTransaction 200', data);

			// 這邊使用國泰的金流，操作上使用原本頁面直接跳轉，不使用彈窗
			if (data.view) {
				console.log('in the data.data.view');
				openPaymentView(window, data.view);
			}

			console.log('in the 200 submitTravelOrderTransaction after all conditions');
			return Promise.resolve(data);
		}

		throw new Error(message);
	} catch (error) {
		dispatch(
			openModal({
				category: 'toast',
				type: 'message',
				data: {
					message: (error as Error).message,
					status: 'warning',
				},
			}),
		);
		return Promise.reject((error as Error).message);
	}
});

/** ------------------------------------------------------------------------------------------------------------------------ */

export const reducer = {
	travelReservation: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_TRAVEL_RESERVATION: () => ({
				...initialState,
			}),

			SET_TRAVEL_RESERVATION_READY: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					isReady: true,
				},
			}),

			SET_TRAVEL_RESERVATION_STEP: (state, action: Action<State['formStatus']['step']>) => ({
				...state,
				formStatus: {
					...state.formStatus,
					step: action.payload,
				},
			}),

			SET_TRAVEL_RESERVATION_NEXT_STEP: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					step: (state.formStatus.step + 1) as State['formStatus']['step'],
				},
			}),

			SET_ADD_PAYMENT_ID: (state, action) => ({
				...state,
				formStatus: {
					...state.formStatus,
					addPaymentId: action.payload,
				},
			}),

			SET_TRAVEL_RESERVATION_PREV_STEP: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					step:
						state.formStatus.step >= 1
							? ((state.formStatus.step - 1) as State['formStatus']['step'])
							: state.formStatus.step,
				},
			}),

			CALCULATE_TRAVEL_RESERVATION_SHOPPING_DETAIL: (
				state,
				action: Action<State['shoppingDetail']>,
			) => ({
				...state,
				shoppingDetail: action.payload,
			}),

			SUBMIT_TRAVEL_ORDER_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_TRAVEL_ORDER_FULFILLED: (state, action: Action<SubmitTravelOrderPayload>) => ({
				...state,

				...(action.payload.data && {
					formStatus: {
						...state.formStatus,
						isSubmitting: false,
						selector: action.payload?.data.selectors[0] || '',
						// view: action.payload?.data.view || '',
					},
				}),
			}),

			SUBMIT_TRAVEL_ORDER_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),

			CHANGE_TRAVEL_RESERVATION_FORM: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
				action: Action<TravelFormChangePayload>,
			) => ({
				...state,

				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			INIT_TRAVEL_RESERVATION_FORM: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
			) => ({
				...state,

				form: {
					...initialState.form,
				},
			}),

			SUBMIT_TRAVEL_ORDER_TRANSACTION_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_TRAVEL_ORDER_TRANSACTION_FULFILLED: (
				state,
				action: Action<TravelOrderTransactionResponse>,
			) => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
					view: action.payload.view || '',
					accountInfo: action.payload.accountInfo || ({} as TravelOrderTransactionAccountInfoData),
				},
			}),

			SUBMIT_TRAVEL_ORDER_TRANSACTION_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTravelReservationFormStatus ++
++----------------------------------------------------------------------*/
const selectFormStatus = (state: GlobalState) => state.travelReservation.formStatus;

const formStatusActionsMap = {
	clearTravelReservation,
	initTravelReservationForm,
	setReady: setTravelReservationReady,
	setStep,
	goNextStep,
	goPrevStep,
	setAddPaymentId,
	submitTravelOrder,
	syncFormWithMemberInfo,
	submitTravelOrderTransaction,
};

export const useTravelReservationFormStatus = () =>
	useRedux<ReturnType<typeof selectFormStatus>, typeof formStatusActionsMap>(
		selectFormStatus,
		formStatusActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useTravelsReservationForm ++
++----------------------------------------------------------------------*/
const selectTravelReservationForm = (state: GlobalState) => state.travelReservation.form;

const travelReservationFormActionsMap = {
	changeForm,
	calcTravelReservationShoppingDetail,
};

export const useTravelReservationForm = () =>
	useRedux<ReturnType<typeof selectTravelReservationForm>, typeof travelReservationFormActionsMap>(
		selectTravelReservationForm,
		travelReservationFormActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useTravelReservationShoppingDetail ++
++----------------------------------------------------------------------*/
const selectTravelReservationShoppingDetail = (state: GlobalState) =>
	state.travelReservation.shoppingDetail;

const travelReservationShoppingDetailActionsMap = {};

export const useTravelReservationShoppingDetail = () =>
	useRedux<
		ReturnType<typeof selectTravelReservationShoppingDetail>,
		typeof travelReservationShoppingDetailActionsMap
	>(selectTravelReservationShoppingDetail, travelReservationShoppingDetailActionsMap);
