import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAuth, useGiantVid } from 'models/auth';

import Modal, { ModalProps } from 'components/atoms/Modal';

import AtomsCheckIcon from 'images/icon/atoms-icon-check-circle-big.inline.svg';

import styles from './index.css';

const { GIANT_SIGNIN_URL, GIANT_CLIENT_ID } = process.env;

interface ModalTransMemberProperty extends Pick<ModalProps, 'isOpen' | 'closeModal'> {
	className?: string;
}

const ModalTransMember: React.FC<ModalTransMemberProperty> = ({
	className,
	isOpen,
	closeModal = () => {},
}) => {
	const { t } = useTranslation(['auth']);
	const [
		{
			currentUserGid,
			memberList: { data: memberIds },
			userDataByGid,
		},
		{ setCurrentUser, logout },
	] = useAuth();

	// giant vid is fetching on Root component
	const [{ loading: isVidLoading, data: vid }] = useGiantVid();

	const handleClickMemberOption = useCallback(id => {
		setCurrentUser(id);
		closeModal();
		window.location.reload();
	}, []);

	const handleClickLoginOtherUser = useCallback(() => {
		logout(`${GIANT_SIGNIN_URL}?client_id=${GIANT_CLIENT_ID}&vid=${vid}`);
	}, [vid]);

	return (
		<Modal
			className={classnames(styles.modalTransMember, className)}
			title={t('auth:transMember')}
			isOpen={isOpen}
			closeModal={closeModal}
		>
			<div className={styles.container}>
				{memberIds.map(id => (
					<div
						key={id}
						className={classnames(styles.option, { [styles._active]: id === currentUserGid })}
						role="button"
						tabIndex={0}
						onClick={() => handleClickMemberOption(id)}
						onKeyPress={() => {}}
					>
						{`${userDataByGid[id]?.firstName} ${userDataByGid[id]?.lastName}`}
						{id === currentUserGid && <AtomsCheckIcon />}
					</div>
				))}
				{!isVidLoading && vid && (
					<div
						className={styles.login}
						role="button"
						tabIndex={0}
						onClick={handleClickLoginOtherUser}
						onKeyPress={() => {}}
					>
						{t('auth:loginOther')}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ModalTransMember;
