import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import LecturerRoute from './Lecturer';

const routes: CustomRoute = {
	path: appPath.storiesGiantBikeSchool.replace(appPath.stories, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'rentalRoutes' */ './component')],
			render: ([StoriesGiantBikeSchools]) => <StoriesGiantBikeSchools />,
		},
		LecturerRoute,
	],
};

export default routes;
