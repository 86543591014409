import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { MemberNationality, fetchMemberNationalitiesFunc } from 'api/member';

import { State as GlobalState } from './reducers';

export interface State {
	data: {
		codes: string[];
		byCode: Record<string, MemberNationality>;
	};
	loading: boolean;
	error: string;
}

const initialState: State = {
	data: { codes: [], byCode: {} },
	loading: false,
	error: '',
};

export const getNationalities = createAction<Promise<Partial<State>>>(
	'FETCH_NATIONALITIES',
	async () => {
		try {
			const { status, message, data } = await fetchMemberNationalitiesFunc();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			// 選項第一筆需要是臺灣
			const targetIndex = data.findIndex(nation => nation.code === 'TW');
			const [TWCode] = data.splice(targetIndex, 1);
			data.unshift(TWCode);

			const normalized = data.reduce<State['data']>(
				(a, v) => ({
					codes: [...a.codes, v.code],
					byCode: {
						...a.byCode,
						[v.code]: v,
					},
				}),
				{ codes: [], byCode: {} },
			);

			return { data: normalized };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

export const reducer = {
	nationalities: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			FETCH_NATIONALITIES_PENDING: () => ({
				...initialState,
				loading: true,
			}),

			FETCH_NATIONALITIES_FULFILLED: (state, action: Action<Partial<State>>) => ({
				...state,
				loading: false,
				...(action.payload.data && {
					data: action.payload.data,
				}),
				...(action.payload.error && {
					error: action.payload.error,
				}),
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useNationalities ++
++----------------------------------------------------------------------*/
const selectNationalities = (state: GlobalState) => state.nationalities;

const NationalitiesActionsMap = {
	getNationalities,
};

export const useNationalities = () =>
	useRedux<ReturnType<typeof selectNationalities>, typeof NationalitiesActionsMap>(
		selectNationalities,
		NationalitiesActionsMap,
	);
