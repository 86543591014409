import { wrapRetryAdminFetch } from 'util/api';

export type Product = {
	id: number;
	amount: number;
};

interface AdminEventsOrderAddPurchaseRequestBody {
	total_price: number;
	participants: {
		id: number;
		apply_type_id?: number;
		gift_id?: number[];
		products?: Product[];
	}[];
}

export type AdminEventsOrderAddPurchaseResponse = {
	product_stock_errors: {
		[id: number]: string;
	};
};

export const submitAdminRideOrderAddPurchaseFunc = async (
	selector: string,
	data: AdminEventsOrderAddPurchaseRequestBody,
) => {
	const response = await wrapRetryAdminFetch<never | AdminEventsOrderAddPurchaseResponse>(
		`rideOrder/${selector}/addPurchase`,
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
	);

	return response;
};

export const submitAdminCourseOrderAddPurchaseFunc = async (
	selector: string,
	data: AdminEventsOrderAddPurchaseRequestBody,
) => {
	const response = await wrapRetryAdminFetch<never | AdminEventsOrderAddPurchaseResponse>(
		`courseOrder/${selector}/addPurchase`,
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
	);

	return response;
};
