import { useRef } from 'react';
import { useTransition, useChain, config, SpringHandle, TransitionFn } from 'react-spring';

interface Option {
	isOpen?: boolean;
	coverOptions?: { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
	mainOptions?: { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type useModalSpringFunc = (
	x: Option,
) => {
	coverTransition: TransitionFn<
		boolean | undefined,
		{
			opacity: number;
		}
	>;
	mainTransition: TransitionFn<
		boolean | undefined,
		{
			transform: string;
		}
	>;
};

const useModalSpring: useModalSpringFunc = ({ isOpen, coverOptions = {}, mainOptions = {} }) => {
	const coverRef = useRef<SpringHandle>() as React.RefObject<SpringHandle>;
	const coverTransition = useTransition(isOpen, {
		ref: coverRef,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.stiff,
		...coverOptions,
	});

	const modalRef = useRef<SpringHandle>() as React.RefObject<SpringHandle>;
	const mainTransition = useTransition(isOpen, {
		ref: modalRef,
		from: { transform: 'translateY(100%)' },
		enter: { transform: 'translateY(0%)' },
		leave: { transform: 'translateY(100%)' },
		config: config.stiff,
		...mainOptions,
	});

	useChain(isOpen ? [coverRef, modalRef] : [modalRef, coverRef], [0, 0.2]);

	return {
		coverTransition,
		mainTransition,
	};
};

export default useModalSpring;
