import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.adminBicycleLost.replace(appPath.admin, ''),
	components: () => [import(/* webpackChunkName: 'adminBicycleLost' */ './component')],
	render: ([AdminBicycleLost]) => <AdminBicycleLost />,
};

export default routes;
