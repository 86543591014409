import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import { PhoneValue } from 'types/PhoneValue';
import { InputFiled } from 'types/InputFiled';

import { useRedux } from 'util/hook/redux';

import { openModal } from 'models/modal';
import { pushRoute } from 'models/routing';

import {
	fetchContactInfoFunc,
	sendContactFormFunc,
	ServiceType,
	ContactFormData,
} from 'api/contact';

import { State as GlobalState, GetState } from './reducers';

export type ContactUsFormField =
	| 'service'
	| 'lastName'
	| 'firstName'
	| 'telephone'
	| 'email'
	| 'subject'
	| 'need';

export type ContactForm = Record<Exclude<ContactUsFormField, 'telephone'>, InputFiled> &
	Record<Extract<ContactUsFormField, 'telephone'>, InputFiled<PhoneValue>>;

export interface FormChangePayload {
	key: ContactUsFormField;
	data: Partial<InputFiled>;
}

// createAction
const changeContactForm = createAction<FormChangePayload, FormChangePayload>(
	'CHANGE_CONTACT_FORM',
	({ key, data }) => ({ key, data }),
);

const fetchContactInfo = createAction('FETCH_CONTACT_INFO', async (type: ServiceType) => {
	try {
		const { status, message, data } = await fetchContactInfoFunc(type);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { contactInfo: data.contact_info };
	} catch {
		return { contactInfo: '' };
	}
});

const sendContactForm = createAction(
	'SEND_CONTACT_FORM',
	() => async (dispatch: Dispatch, getState: GetState) => {
		try {
			const {
				contact: { form },
			} = getState();

			const contactFormData: ContactFormData = {
				type: form.service.value.value,
				name: `${form.firstName.value} ${form.lastName.value}`,
				phone: `${form.telephone.value.intIdNum} ${form.telephone.value.phoneNum}`,
				email: form.email.value,
				title: form.subject.value,
				content: form.need.value,
			};

			const { status, message } = await sendContactFormFunc(contactFormData);

			if (status !== 200 && status !== 201) {
				dispatch(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message,
							status: 'warning',
						},
					}),
				);

				return { loading: false, error: message, success: false };
			}

			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message,
						status: 'pass',
					},
				}),
			);

			dispatch(pushRoute({ pathname: '/' }));

			return { loading: false, error: '', success: true };
		} catch (error) {
			return { loading: false, error, success: false };
		}
	},
);

const resetContactForm = createAction('RESET_CONTACT_FORM');

export interface State {
	form: ContactForm;
	contactInfo: string;
	formSendingStatus: {
		loading: boolean;
		error: string;
		success: boolean;
	};
}

const initialState: State = {
	form: {
		service: { value: '', valid: true, error: '', required: true },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		telephone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: false },
		subject: { value: '', valid: true, error: '', required: true },
		need: { value: '', valid: true, error: '', required: true },
	},
	contactInfo: '',
	formSendingStatus: { loading: false, error: '', success: false },
};

// handleAction
export const reducer = {
	contact: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CHANGE_CONTACT_FORM: (state, action: Action<FormChangePayload>) => ({
				...state,
				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key as ContactUsFormField],
						...action.payload.data,
					},
				},
			}),

			FETCH_CONTACT_INFO_FULFILLED: (state, action: Action<{ contactInfo: string }>) => ({
				...state,
				contactInfo: action.payload.contactInfo,
			}),

			SEND_CONTACT_FORM_PENDING: state => ({
				...state,
				formSendingStatus: {
					...state.formSendingStatus,
					loading: true,
				},
			}),

			SEND_CONTACT_FORM_FULFILLED: (
				state,
				action: Action<{ loading: boolean; error: string; success: boolean }>,
			) => ({
				...state,
				formSendingStatus: action.payload,
			}),

			RESET_CONTACT_FORM: () => initialState,
		},
		initialState,
	),
};

const selectContactForm = (state: GlobalState) => ({
	form: state.contact.form,
	contactInfo: state.contact.contactInfo,
});

export const useContactForm = () =>
	useRedux(selectContactForm, {
		changeContactForm,
		fetchContactInfo,
		sendContactForm,
		resetContactForm,
	});
