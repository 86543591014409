import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.accountOrders.replace(appPath.account, ''),
	components: () => [import(/* webpackChunkName: 'accountOrders' */ './component')],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'rental' */ './component')],
			render: ([AccountOrders]) => <AccountOrders />,
		},
	],
};

export default routes;
