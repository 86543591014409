/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import { GenderCodes } from 'types/Gender';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';

import { useRedux } from 'util/hook/redux';

import {
	setupParticipantFormByCourseOrder,
	setEventsTypeId,
	setupEventParticipantTemplateProcess,
} from 'models/eventsParticipant';
import {
	setupParticipantFormByChangeItinerary,
	setupParticipantFormByTravelOrderAddPurchase,
} from 'models/travelParticipant';
import { openModal } from 'models/modal';
import { participantAddPurchaseCalculator } from 'models/rideOrder';
import { getDocument } from 'models/document';
import { getCourseReservationInfo } from 'models/course';
import { getCancelOrderInfo } from 'models/orderInfo';

import { CourseParticipant, updateCourseOrderBySelectorFunc } from 'api/courseOrder';
import { Product, submitAdminCourseOrderAddPurchaseFunc } from 'api/eventsOrderAdmin';

import {
	cancelTravelOrderFunc,
	fetchTravelOrderParticipantInfoFunc,
	fetchTravelOrderPaymentInfoFunc,
	fetchTravelParticipantPaymentDetailFunc,
	TravelParticipant,
} from 'api/travelOrder';
import {
	fetchAdminTravelOrderAddPurchaseFunc,
	submitAdminTravelOrderAddPurchaseFunc,
} from 'api/travelOrderAdmin';
import {
	TravelOrderPaymentInfoData,
	TravelParticipantInfoData,
	TravelParticipantPaymentDetail,
	AdminTravelOrderAddPurchaseData,
} from 'types/TravelOrderParticipantPaymentDetail';
import storage from 'util/storage';
import { objectHasValue } from 'util/helper';
import { GetState, State as GlobalState } from './reducers';
import {
	setTravelTypeId,
	setupParticipantFormByTravelOrder,
	setupTravelParticipantTemplateProcess,
} from './travelParticipant';
import { TravelFormDataType } from './travelParticipant/type';
import {
	changeItineraryReservationDataByAdmin,
	travelAuthTokenFetchFunc,
} from './travelReservation';

export type TravelOrderBySelector = TravelOrderPaymentInfoData;

export type TravelOrderBySelectorKey = keyof TravelOrderBySelector; // 沒有使用作參考用途

export interface State {
	detail: FetchedData<string>;
	bySelector: FetchedData<TravelOrderBySelector>; // 沒有使用作參考用途
	travelParticipantPaymentDetails: FetchedData<TravelParticipantPaymentDetail>;
	adminTravelOrderAddPurchase: FetchedData<AdminTravelOrderAddPurchaseData[]>;
}

const initialState: State = {
	detail: {
		loading: false,
		error: '',
		data: '',
	},
	bySelector: {
		// 沒有使用作參考用途
		loading: false, // 沒有使用作參考用途
		error: '', // 沒有使用作參考用途
		data: {} as TravelOrderBySelector, // 沒有使用作參考用途
	},
	travelParticipantPaymentDetails: {
		loading: false,
		error: '',
		data: {} as TravelParticipantPaymentDetail,
	},
	adminTravelOrderAddPurchase: {
		loading: false,
		error: '',
		data: [] as AdminTravelOrderAddPurchaseData[],
	},
};

/** ---------------------------------------------------------------------------------------------------------------------------------------- */
interface GetTravelOrderPayload {
	error?: string;
	data?: TravelOrderBySelector;
}

/**
 * 待處理，該函式目前沒有使用，但可做為參考用途暫時保留
 * 取得旅遊訂單付款資訊、參加者資訊 - 使用在旅遊第四階段
 * @param {string} selector
 * @return {TravelOrderBySelector} 付款資訊、參加者資訊的一筆合併資料
 */
// const getTravelOrderBySelector = createAction<Promise<GetTravelOrderPayload>, string>(
// 	'GET_TRAVEL_ORDER_BY_SELECTOR',
// 	async selector => {
// 		try {
// 			const { status, message, data } = await fetchTravelOrderPaymentInfoFunc(selector);

// 			if (status !== 200 && status !== 201) {
// 				throw new Error(message);
// 			}

// 			const {
// 				status: participantStatus,
// 				message: participantMessage,
// 				data: participantData,
// 			} = await fetchTravelOrderParticipantInfoFunc(selector);

// 			if (participantStatus !== 200 && participantStatus !== 201) {
// 				throw new Error(participantMessage);
// 			}

// 			const newData = { ...data, ...participantData };

// 			console.log('fetchTravelOrderPaymentInfoFunc', data);
// 			console.log('participantRowData', participantData);
// 			console.log('newData', newData);

// 			return {
// 				data: newData,
// 			};
// 		} catch (error) {
// 			if (error instanceof Error) {
// 				const { message } = error;
// 				return {
// 					error: message,
// 				};
// 			}
// 			return {
// 				error: '',
// 			};
// 		}
// 	},
// );

/** ---------------------------------------------------------------------------------------------------------------------------------------- */
interface GetTravelParticipantPaymentDetailsPayload {
	error?: string;
	data?: TravelParticipantPaymentDetail;
}

/**
 * 取得旅遊訂單參加者交易明細 - 旅遊訂單第三階段
 * @param {string} selector
 * @returns {TravelParticipantPaymentDetail} 旅遊訂單參加者交易明細
 */
export const getTravelParticipantPaymentDetails = createAction<
	(dispatch: Dispatch) => Promise<GetTravelParticipantPaymentDetailsPayload>,
	string
>('GET_TRAVEL_PARTICIPANT_PAYMENT_DETAILS', selector => async (dispatch: Dispatch) => {
	try {
		const { status, message, data } = await fetchTravelParticipantPaymentDetailFunc(selector);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				error: message,
			};
		}
		return {
			error: '',
		};
	}
});

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

/**
 * 將 旅遊訂單參加者的 id 打進 setupParticipantFormByTravelOrder -使用在『我要繳費』第三步驟中間的參加者資訊
 */
const setupParticipantByTravelOrderProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>(
	'SETUP_PARTICIPANT_BY_TRAVEL_ORDER_PROCESS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			travelRegister: {
				travelOrderParticipantInfo: { data },
			},
		} = getState();

		// if still can edit, setup participant form
		data
			.filter(d => d?.permissions?.show)
			.forEach(({ id }) => {
				dispatch(setupParticipantFormByTravelOrder(id, 'travel'));
			});
	},
);

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

const changeItineraryByAdmin = createAction(
	'CHANGE_ITINERARY_BY_ADMIN',
	() => async (dispatch: Dispatch) => {
		const transferredData = storage.getItem('transferredData', false);

		if (!transferredData) {
			return;
		}

		const data: Partial<TravelParticipantInfoData[]> = JSON.parse(transferredData).participants;

		const reservationData = JSON.parse(transferredData).order;

		if (data && reservationData) {
			dispatch(changeItineraryReservationDataByAdmin(reservationData));

			data.forEach(d => {
				if (d) {
					dispatch(setupParticipantFormByChangeItinerary(d, 'travel'));
				}
			});
		}
	},
);

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

// 待處理
const getCourseOrderAndEventDetail = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>(
	'GET_COURSE_ORDER_AND_EVENT_DETAIL',
	selector => async (dispatch: Dispatch, getState: GetState) => {
		await dispatch(getCourseOrderBySelector(selector));

		const {
			courseOrder: { bySelector },
		} = getState();

		const { course_id: courseId, date_id: dateId } = bySelector[selector];

		await dispatch(getCourseReservationInfo({ courseId, dateId }));
	},
);

/** ---------------------------------------------------------------------------------------------------------------------------------------- */
/**
 * 初始化『我要繳費』第三步驟中間的參加者資訊表單
 *
 * @function setTravelTypeId 設置該筆訂單的編號進去 state 讓 form 內的屬性（ex. editing, template）得以成立
 * @function setupTravelParticipantTemplateProcess 拿回 travel data 後依資料更改必填需求，以及初始化參加者欄位。
 * @function setupParticipantByTravelOrderProcess 將 旅遊訂單參加者的 id 打進 setupParticipantFormByTravelOrder
 */
const initialTravelOrderDetailProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>('INITIAL_TRAVEL_ORDER_DETAIL_PROCESS', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		travelRegister: {
			registerOrderInfoData: { data },
		},
		locale: {
			locales: {
				data: { byCode },
			},
		},
	} = getState();

	// travelId 使用訂單編號
	const travelId = data.order_number;
	const numberId = travelId.match(/\d+/) || '';

	await Promise.all([
		dispatch(
			setTravelTypeId({
				eventType: 'travel',
				travelId: parseInt(numberId[0], 10),
			}),
		),
		dispatch(
			getDocument({
				type: 'TRAVEL',
				code: 'CODE_THEME_ACTIVITY_PARENT',
				country_id: byCode?.TW.id,
			}),
		),
		// 取得訂單取消說明文案
		dispatch(
			getCancelOrderInfo({
				type: 'TRAVEL',
				country_id: byCode?.TW.id,
			}),
		),
	]);

	await dispatch(setupTravelParticipantTemplateProcess('travel'));
	await dispatch(setupParticipantByTravelOrderProcess());
});

/**
 * 處理轉團的資料
 */
const initialTravelOrderDetailForChangeItinerary = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>('INITIAL_TRAVEL_ORDER_DETAIL_FOR_CHANGE_ITINERARY', () => async (dispatch: Dispatch) => {
	await dispatch(changeItineraryByAdmin());
});

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

type UpdateTravelOrderParticipantDataPayload = {
	selector: string;
	participantId: number;
	data: Partial<TravelParticipant>;
};

// 待處理
const updateTravelOrderParticipantData = createAction<
	UpdateTravelOrderParticipantDataPayload,
	UpdateTravelOrderParticipantDataPayload
>('UPDATE_TRAVEL_ORDER_PARTICIPANT_DATA', ({ selector, participantId, data }) => ({
	selector,
	participantId,
	data,
}));

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

// 待處理
const updateCourseOrderBySelector = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	string
>('UPDATE_RIDE_ORDER_BY_SELECTOR', selector => async (dispatch: Dispatch, getState: GetState) => {
	const {
		eventsParticipant: {
			form: {
				editing: { id: participantId, data: participantForm },
			},
		},
	} = getState();
	try {
		if (participantId === null || participantForm === null) {
			throw new Error('empty participant');
		}
		let newData: Partial<CourseParticipant> = { id: participantId };
		const form = participantForm as FormDataMapping<TravelFormDataType>;
		newData = {
			...newData,
			first_name: form.firstName.value,
			last_name: form.lastName.value,
			en_first_name: form.firstNameEn.value,
			en_last_name: form.lastNameEn.value,
			identity_no: form.citizenId.value,
			gender: form.sex.value.value as GenderCodes,
			birthday: form.birthday.value ? form.birthday.value.format('YYYY-MM-DD') : '',
			height: Number(form.height.value),
			weight: Number(form.weight.value),
			phone: `${form.phone.value.intIdNum} ${form.phone.value.phoneNum}`,
			email: form.email.value,
			contact_name: form.emergencyName.value,
			contact_relationship: form.emergencyRelation.value.value || '',
			country: form.country.value,
			city: form.city.value,
			address: form.address.value,
			note: form.demand.value,
			contact_phone: `${form.emergencyPhone.value.intIdNum} ${form.emergencyPhone.value.phoneNum}`,
			extra_field_1: form.custom1.value,
			extra_field_2: form.custom2.value,
			extra_field_3: form.custom3.value,
			eating_habit: form.eatingHabit.value.value,
			eating_habit_note: form.eatingHabitNote.value,
			medical_record: form.isMedicalRecord.value || false,
			medical_record_note: form.medicalRecordNote.value,
		};

		const { status, message } = await updateCourseOrderBySelectorFunc(selector, [newData]);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}
		dispatch(updateCourseOrderParticipantData({ selector, participantId, data: newData }));
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message,
						status: 'wraning',
					},
				}),
			);
		}
	}
});

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

/**
 * 使用情境：後台開啟管理員加價購頁面，取得加價購資料時使用
 * 透過旅遊行程 selector 取得後台加價購頁面所需資料
 * 資料格式參考 GET /api/travelOrder/{selector}/participants/products
 */
const getAdminTravelOrderAddPurchaseBySelector = createAction<
	(
		dispatch: Dispatch,
		getState: GetState,
	) => Promise<
		| {
				data: AdminTravelOrderAddPurchaseData[];
				error?: undefined;
		  }
		| {
				error: string;
				data?: undefined;
		  }
	>,
	string
>('GET_ADMIN_TRAVEL_ORDER_ADD_PURCHASE_BY_SELECTOR', selector => async (_, getState) => {
	try {
		const {
			i18n: { lan },
		} = getState();

		const { status, message, data } = await fetchAdminTravelOrderAddPurchaseFunc(selector, lan);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data,
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return {
				error: message,
			};
		}
		return {
			error: '',
		};
	}
});

/**
 * 使用情境：後台開啟管理員加價購頁面，把資料打回表單時使用
 * 將後台加價購頁面的資料，根據每一位參加者(participant)的加價購項目，切分成加價購表單所需的一組一組的資料
 */
const setupParticipantByTravelAddPurchaseProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>(
	'SETUP_PARTICIPANT_BY_TRAVEL_ADD_PURCHASE_PROCESS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			travelOrder: {
				adminTravelOrderAddPurchase: { data: participants },
			},
		} = getState();

		participants.forEach(({ id }) => {
			dispatch(setupParticipantFormByTravelOrderAddPurchase(id));
		});
	},
);

/**
 * 使用情境：後台開啟管理員加價購頁面，把表單資料送出時使用
 * 將後台加價購頁面已經編輯完的表單資料，整理成後端需要的格式，把資料送出給後端
 * 資料格式參考 POST /api/travelOrder/{selector}/participants/products
 */
const submitAdminTravelOrderAddPurchase = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<{ success: boolean }>,
	string
>('SUBMIT_ADMIN_COURSE_ORDER_ADD_PURCHASE', selector => async (dispatch, getState) => {
	const {
		travelOrder: {
			adminTravelOrderAddPurchase: { data: previousAddPurchaseData },
		},
		travelParticipant: { form },
		i18n: { lan },
	} = getState();

	// 將表單資料先整理成後端需要的格式（格式可餐考 swagger）
	const newAddPurchaseData = form?.ids?.map(
		id =>
			({
				id,
				items: [
					...((form?.byIds[id] as unknown) as AdminTravelOrderAddPurchaseData)?.gifts?.map(
						gift => ({
							product_id: gift.productId,
							stock_id: gift.stockId,
							amount: gift.amount,
						}),
					),
					...((form?.byIds[id] as unknown) as AdminTravelOrderAddPurchaseData)?.products?.map(
						product => ({
							product_id: product.productId,
							stock_id: product.stockId,
							amount: product.amount,
						}),
					),
					...((form?.byIds[id] as unknown) as AdminTravelOrderAddPurchaseData)?.services?.map(
						service => ({
							product_id: service.productId,
							stock_id: service.stockId,
							amount: service.amount,
						}),
					),
					...((form?.byIds[id] as unknown) as AdminTravelOrderAddPurchaseData)?.bicycle?.map(
						bike => ({
							product_id: bike.productId,
							stock_id: bike.stockId,
							amount: bike.amount,
						}),
					),
				],
			} as { id: number; items: { product_id: number; stock_id: number; amount: number }[] }),
	);

	/**
	 * 整理 submitData 資料內容
	 * 由於旅遊行程後台加價購，除了可以新增商品以外，還可以減少商品。所以需要驗證舊資料與新資料的差異，並且把差異的部分送給後端
	 */
	const submitData = newAddPurchaseData?.map(participant => ({
		id: participant.id,
		items: participant.items.reduce((acc, cur) => {
			const indexOfPreviousData = previousAddPurchaseData.findIndex(d => d.id === participant.id);
			const previousItems = [
				...previousAddPurchaseData[indexOfPreviousData]?.gifts,
				...previousAddPurchaseData[indexOfPreviousData]?.products,
				...previousAddPurchaseData[indexOfPreviousData]?.services,
				...previousAddPurchaseData[indexOfPreviousData]?.bicycles,
			];

			const indexOfPreviousItem = previousItems.findIndex(i => i.product_id === cur.product_id);

			// 如果舊資料有這個商品(product_id)，且同個商品在新資料的規格 (stock_id) 有調整，除了新的規格 (stock_id) 要帶給後端之外，舊的規格 amount 要帶 0 給後端，讓後端可以刪除舊的規格
			if (
				indexOfPreviousItem !== -1 &&
				previousItems[indexOfPreviousItem].stock_id !== cur.stock_id &&
				cur.amount !== 0
			) {
				return [
					...acc,
					cur,
					{
						product_id: previousItems[indexOfPreviousItem]?.product_id,
						stock_id: previousItems[indexOfPreviousItem]?.stock_id,
						amount: 0,
					},
				];
			}

			// 如果舊資料沒有這個商品(product_id)，且新資料的 amount 為 0，則不需要送給後端
			if (indexOfPreviousItem === -1 && cur.amount === 0) {
				return acc;
			}

			// 如果舊資料沒有這個商品(product_id)，且新資料的 amount 不為 0，則需要送給後端
			return [...acc, cur];
		}, [] as { product_id: number | undefined; stock_id: number | undefined; amount: number | undefined }[]),
	}));

	// debug 用：因為加價購這邊的資料規格比較複雜，建議這段 console 先保留起來，方便除錯
	console.log('previousAddPurchaseData', previousAddPurchaseData);
	console.log('newAddPurchaseData', newAddPurchaseData);
	console.log('submitData', submitData);

	try {
		const { status, message } = await submitAdminTravelOrderAddPurchaseFunc(selector, lan, {
			updateItems: (submitData as unknown) as AdminTravelOrderAddPurchaseData[],
		});

		if (status === 200) {
			return Promise.resolve({ success: true });
		}

		if (message === 'VALIDATE_STOCK_ERROR') {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: JSON.stringify(message),
						status: 'wraning',
					},
				}),
			);
		}

		throw new Error(message);
	} catch (error) {
		return Promise.reject(error);
	}
});

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

// 取消旅遊訂單
const cancelTravelOrder = createAction<(_: Dispatch) => Promise<{ success: boolean }>, string>(
	'CANCEL_TRAVEL_ORDER',
	selector => async dispatch => {
		try {
			const { status, message } = await cancelTravelOrderFunc(selector, 'ALL');

			if (status === 200) {
				return Promise.resolve({ success: true });
			}
			throw new Error(message);
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: (error as Error).message,
						status: 'wraning',
					},
				}),
			);
			return Promise.reject((error as Error).message);
		}
	},
);

/** ---------------------------------------------------------------------------------------------------------------------------------------- */

export const reducer = {
	travelOrder: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			// GET_TRAVEL_ORDER_BY_SELECTOR_PENDING: state => ({
			// 	...state,
			// 	bySelector: {
			// 		...state.bySelector,
			// 		loading: true,
			// 		error: '',
			// 	},
			// }),

			// GET_TRAVEL_ORDER_BY_SELECTOR_FULFILLED: (state, action: Action<GetTravelOrderPayload>) => ({
			// 	...state,
			// 	bySelector: {
			// 		...state.bySelector,

			// 		...(action.payload.error && {
			// 			error: action.payload.error,
			// 		}),

			// 		...(action.payload.data && {
			// 			data: action.payload.data,
			// 		}),

			// 		loading: false,
			// 	},
			// }),

			GET_TRAVEL_PARTICIPANT_PAYMENT_DETAILS_PENDING: state => ({
				...state,
				travelParticipantPaymentDetails: {
					...state.travelParticipantPaymentDetails,

					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_PARTICIPANT_PAYMENT_DETAILS_FULFILLED: (
				state,
				action: Action<GetTravelParticipantPaymentDetailsPayload>,
			) => ({
				...state,
				travelParticipantPaymentDetails: {
					...state.travelParticipantPaymentDetails,

					...(action.payload.error && {
						error: action.payload.error,
					}),

					...(action.payload.data && {
						data: action.payload.data,
					}),

					loading: false,
				},
			}),

			UPDATE_COURSE_ORDER_PARTICIPANT_DATA: (
				state,
				action: Action<UpdateTravelOrderParticipantDataPayload>,
			) => ({
				...state,

				bySelector: {
					...state.bySelector,
					[action.payload.selector]: {
						...state.bySelector[action.payload.selector],
						participants: {
							...state.bySelector[action.payload.selector].participants,
							byIds: {
								...state.bySelector[action.payload.selector].participants.byIds,
								[action.payload.participantId]: {
									...state.bySelector[action.payload.selector].participants.byIds[
										action.payload.participantId
									],
									...action.payload.data,
								},
							},
						},
					},
				},
			}),

			GET_ADMIN_TRAVEL_ORDER_ADD_PURCHASE_BY_SELECTOR_PENDING: state => ({
				...state,
				adminTravelOrderAddPurchase: {
					...state.adminTravelOrderAddPurchase,
					loading: true,
					error: '',
				},
			}),

			GET_ADMIN_TRAVEL_ORDER_ADD_PURCHASE_BY_SELECTOR_FULFILLED: (
				state,
				action: Action<{ data: AdminTravelOrderAddPurchaseData[] }>,
			) => ({
				...state,
				adminTravelOrderAddPurchase: {
					...state.adminTravelOrderAddPurchase,

					data: action.payload.data,

					loading: false,
				},
			}),
		},
		initialState,
	),
};
const selectTravelOrder = (state: GlobalState) => state.travelOrder;

const travelOrderActionsMap = {
	// getTravelOrderBySelector,
	getTravelParticipantPaymentDetails,
	updateCourseOrderBySelector,
	setupParticipantByTravelOrderProcess,
	// submitAdminCourseOrderAddPurchase,
	initialTravelOrderDetailProcess,
	initialTravelOrderDetailForChangeItinerary,
	getCourseOrderAndEventDetail,
	cancelTravelOrder,
	getAdminTravelOrderAddPurchaseBySelector,
	setupParticipantByTravelAddPurchaseProcess,
	submitAdminTravelOrderAddPurchase,
};

export const useTravelOrder = () =>
	useRedux<ReturnType<typeof selectTravelOrder>, typeof travelOrderActionsMap>(
		selectTravelOrder,
		travelOrderActionsMap,
	);
