import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import LeaderRoute from './Leader';

const routes: CustomRoute = {
	path: appPath.storiesGiantAdventure.replace(appPath.stories, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'StoriesGiantAdventure' */ './component')],
			render: ([StoriesGiantAdventure]) => <StoriesGiantAdventure />,
		},
		LeaderRoute,
	],
};

export default routes;
