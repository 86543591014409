import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import Icon from 'components/atoms/Icon';

import styles from './index.css';

export interface ButtonProperty {
	className?: string;
	disabled?: boolean;
	color?:
		| 'blue'
		| 'red'
		| 'white'
		| 'gradient'
		| 'lightGray'
		| 'shallowGray'
		| 'transparent'
		| 'primary';
	outline?: boolean;
	small?: boolean;
	shape?: 'rectangle' | 'round';
	Icon?: string;
	onClick?: (e: MouseEvent) => void;
}

const Button: React.FC<ButtonProperty> = ({
	className,
	disabled = false,
	outline = false,
	small = false,
	color = 'blue',
	shape = 'rectangle',
	children,
	Icon: PropsIcon,
	onClick = () => {},
	...props
}) => (
	<button
		className={classnames(
			styles.button,
			className,
			{
				[styles.disabled]: disabled,
				[styles.filled]: !outline,
				[styles.outline]: outline,
				[styles.small]: small,
			},
			styles[color],
			styles[shape],
		)}
		type="button"
		onClick={e => {
			if (!disabled) {
				onClick(e);
			}
		}}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		{PropsIcon && (
			<Icon className={styles.icon} src={PropsIcon} size={small ? 'small' : 'normal'} />
		)}
		<span>{children}</span>
	</button>
);

export default Button;
