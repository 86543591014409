import { wrapRetryAdminFetch } from 'util/api';

interface ApproveSTSTF {
	id: number;
	from_position_name: string;
	to_position_name: string;
	bicycle_spec_name: string;
	note: string;
	amount: number;
}

export const fetchApproveSTSTFFunc = async (formId: number) => {
	const response = await wrapRetryAdminFetch<ApproveSTSTF>(`approve/STSTF/${formId}`, {
		method: 'GET',
	});

	return response;
};

interface ApproveLTF {
	id: number;
	reason: string;
	from_position_name: string;
	to_position_name: string;
	bicycle_spec_name: string;
	note: string;
	amount: number;
}

export const fetchApproveLTFFunc = async (storeId: number, logisticsDate: string) => {
	const response = await wrapRetryAdminFetch<ApproveLTF[]>(
		`approve/LTF/${storeId}/${logisticsDate}`,
		{
			method: 'GET',
		},
	);

	return response;
};

interface ApproveBTF {
	id: number;
	from_position_name: string;
	to_position_name: string;
	bicycle_spec_name: string;
	bicycle_number: string;
	broken_time: string;
	note: string;
}

export const fetchApproveBTFFunc = async (storeId: number, logisticsDate: string) => {
	const response = await wrapRetryAdminFetch<ApproveBTF[]>(
		`approve/BTF/${storeId}/${logisticsDate}`,
		{
			method: 'GET',
		},
	);

	return response;
};
