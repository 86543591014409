import React from 'react';

import { useRouting } from 'models/routing';
import { appPath } from 'util/routingConfig';

import SubNavigation from 'components/molecules/SubNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import Toast from 'components/molecules/Toast';
import ModalWarning from 'components/molecules/ModalWarning';
import AdminLayout from 'layouts/AdminLayout';
import CookieConsent from 'components/organisms/CookieConsent';

const App: React.FC = ({ children }) => {
	const [{ pathname }] = useRouting();

	if (pathname.includes(appPath.admin)) {
		return <AdminLayout>{children}</AdminLayout>;
	}

	return (
		<>
			<Header />
			<SubNavigation />
			{children}
			<Footer />
			<Toast />
			<ModalWarning />
			<CookieConsent />
		</>
	);
};

export default App;
