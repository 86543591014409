import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';
import TransactionRoute from './Transaction';

const routes: CustomRoute = {
	path: appPath.orderDetail,
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'orderDetail' */ './component')],
			render: ([OrderDetail]) => <OrderDetail />,
		},
		TransactionRoute,
	],
};

export default routes;
