import { TourCardDataProperty } from 'models/tours';
import { FetchFunction } from 'types/FetchFunction';

import { wrapFetch } from 'util/api';

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TravelBriefListData {
	id: number;
	name: string;
	location: string;
	location_url: string;
	list_img: string;
	date: string;
}

export interface TravelBriefContent extends TravelBriefListData {
	content: string;
}

export type TravelBriefList = {
	data: TravelBriefListData[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	next_page_url: string;
	path: string;
	per_page: number;
	prev_page_url: string;
	to: number;
	total: number;
	current_page: number;
};

type TravelFetchBriefListRes = TravelBriefList;
type TravelBriefListFetch = FetchFunction<TravelFetchBriefListRes>;

/**
 * 取得旅遊說明會列表的資料
 */
export const fetchTravelBriefList: TravelBriefListFetch = async () => {
	const response = await wrapFetch(
		`travelOrientation`,
		{
			method: 'GET',
		},
		{
			page: 1,
		},
	);

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

export interface TravelGuideData {
	title: string;
	content: string;
}

export interface TravelGuideInfo {
	id: number;
	block: TravelGuideData[];
}

type TravelFetchGuideRes = TravelGuideInfo;
type TravelGuideFetch = FetchFunction<TravelFetchGuideRes>;

/**
 * 取得旅遊服務內容
 */
export const fetchTravelGuide: TravelGuideFetch = async locationId => {
	const response = await wrapFetch(`serviceContent/${locationId}`, {
		method: 'GET',
	});

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

type TravelFetchBriefContentRes = TravelBriefContent;
type TravelBriefContentFetch = FetchFunction<TravelFetchBriefContentRes>;

/**
 * 取得旅遊說明會內容的資料
 */
export const fetchTravelBriefContent: TravelBriefContentFetch = async id => {
	const response = await wrapFetch(`travelOrientation/${id}`, {
		method: 'GET',
	});

	return response;
};

/** ------------------------------------------------------------------------------------------------------------------------------------------- */

type TraveFetchTourCardInfoRes = TourCardDataProperty[];
type TravelCardInfoFetch = FetchFunction<TraveFetchTourCardInfoRes>;

export const fetchTourCardInfo: TravelCardInfoFetch = async () => {
	const response = await wrapFetch('home/travels', {
		method: 'GET',
	});

	return response;
};
