import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'travelClassicTourInfo' */ './component')],
			render: ([TravelClassicTourInfo]) => <TravelClassicTourInfo />,
		},
		{
			path: '/reservation',
			components: () => [
				import(/* webpackChunkName: 'travelClassicTourInfoReservation' */ './reservation'),
			],
			render: ([TravelClassicTourInfoReservation]) => <TravelClassicTourInfoReservation />,
		},
		// EventsRegisterSearchRoute,
		// EventsOrdersRoute,
	],
};

export default routes;
