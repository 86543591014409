import { GenderValues, genderValueToCodeMap } from 'types/Gender';

import ChangeTypeOfKeys from 'util/types/changeTypeOfKeys';

import { UserBasicInfoData } from 'api/auth';

const userBasicInfoFormatter = (
	data: UserBasicInfoData,
): ChangeTypeOfKeys<UserBasicInfoData, 'gender', GenderValues> => {
	const { gender, ...otherInfo } = data;

	return {
		...(gender !== undefined && { gender: genderValueToCodeMap[gender] as GenderValues }),
		...otherInfo,
	};
};

export default userBasicInfoFormatter;
