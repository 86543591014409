import { EventsParticipantForms } from './type';

export const formTemplates: EventsParticipantForms = {
	featuredrides: {
		sameAs: { value: null, valid: true, error: '', required: false }, // null | 'contactInfo' | 'memberInfo'
		asMember: { value: { label: '', value: null }, valid: true, error: '', required: true },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		citizenship: { value: { label: '', value: null }, valid: true, error: '', required: true },
		citizenId: { value: '', valid: true, error: '', required: true },
		sex: { value: { label: '', value: null }, valid: true, error: '', required: true },
		birthday: { value: null, valid: true, error: '', required: true },
		height: { value: '', valid: true, error: '', required: false },
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		emergencyName: { value: '', valid: true, error: '', required: true },
		emergencyRelation: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		emergencyPhone: {
			value: { intIdNum: '', phoneNum: '' },
			valid: true,
			error: '',
			required: true,
		},
		eatingHabit: { value: { label: '', value: null }, valid: true, error: '', required: true },
		eatingHabitNote: { value: '', valid: true, error: '', required: false },
		specialDisease: { value: { label: '', value: null }, valid: true, error: '', required: true },
		specialDiseaseNote: { value: '', valid: true, error: '', required: true },
		parentConsent: { value: null, valid: true, error: '', required: false },
		elderliesConsent: { value: null, valid: true, error: '', required: false },
		stayRoom: { value: null, valid: true, error: '', required: true },
		roommate: { value: '', valid: true, error: '', required: false },
		custom1: { value: '', valid: true, error: '', required: true },
		custom2: { value: '', valid: true, error: '', required: true },
		custom3: { value: '', valid: true, error: '', required: true },
		applyType: { value: {}, valid: true, error: '', required: true },
		productPurchase: { value: {}, valid: true, error: '', required: false },
		servicePurchase: { value: {}, valid: true, error: '', required: false },
		giveaways: { value: {}, valid: true, error: '', required: false },
	},
	storerides: {
		sameAs: { value: null, valid: true, error: '', required: false }, // null | 'contactInfo' | 'memberInfo'
		asMember: { value: { label: '', value: null }, valid: true, error: '', required: true },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		citizenship: { value: { label: '', value: null }, valid: true, error: '', required: true },
		citizenId: { value: '', valid: true, error: '', required: true },
		sex: { value: { label: '', value: null }, valid: true, error: '', required: true },
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		emergencyName: { value: '', valid: true, error: '', required: true },
		emergencyRelation: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		emergencyPhone: {
			value: { intIdNum: '', phoneNum: '' },
			valid: true,
			error: '',
			required: true,
		},
		stayRoom: { value: null, valid: true, error: '', required: true },
		roommate: { value: '', valid: true, error: '', required: false },
		custom1: { value: '', valid: true, error: '', required: true },
		custom2: { value: '', valid: true, error: '', required: true },
		custom3: { value: '', valid: true, error: '', required: true },
		otherNote: { value: '', valid: true, error: '', required: false },
		applyType: { value: {}, valid: true, error: '', required: true },
		productPurchase: { value: {}, valid: true, error: '', required: false },
		servicePurchase: { value: {}, valid: true, error: '', required: false },
		giveaways: { value: {}, valid: true, error: '', required: false },
	},
	bikeclasses: {
		sameAs: { value: null, valid: true, error: '', required: false },
		asMember: { value: { label: '', value: null }, valid: true, error: '', required: true },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		lastNameEn: { value: '', valid: true, error: '', required: true },
		firstNameEn: { value: '', valid: true, error: '', required: true },
		citizenship: { value: { label: '', value: null }, valid: true, error: '', required: true },
		citizenId: { value: '', valid: true, error: '', required: true },
		sex: { value: { label: '', value: null }, valid: true, error: '', required: true },
		birthday: { value: null, valid: true, error: '', required: true },
		height: { value: '', valid: true, error: '', required: true },
		weight: { value: '', valid: true, error: '', required: false },
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		country: { value: '', valid: true, error: '', required: false },
		city: { value: '', valid: true, error: '', required: false },
		address: { value: '', valid: true, error: '', required: false },
		email: { value: '', valid: true, error: '', required: true },
		emergencyName: { value: '', valid: true, error: '', required: true },
		emergencyRelation: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		emergencyPhone: {
			value: { intIdNum: '', phoneNum: '' },
			valid: true,
			error: '',
			required: true,
		},
		eatingHabit: { value: { label: '', value: null }, valid: true, error: '', required: true },
		eatingHabitNote: { value: '', valid: true, error: '', required: false },
		specialDisease: { value: { label: '', value: null }, valid: true, error: '', required: true },
		specialDiseaseNote: { value: '', valid: true, error: '', required: true },
		hasParticipated: { value: { label: '', value: null }, valid: true, error: '', required: true },
		otherNote: { value: '', valid: true, error: '', required: false },
		parentConsent: { value: null, valid: true, error: '', required: false },
		elderliesConsent: { value: null, valid: true, error: '', required: false },
		stayRoom: { value: null, valid: true, error: '', required: true },
		roommate: { value: '', valid: true, error: '', required: false },
		custom1: { value: '', valid: true, error: '', required: true },
		custom2: { value: '', valid: true, error: '', required: true },
		custom3: { value: '', valid: true, error: '', required: true },
		applyType: { value: {}, valid: true, error: '', required: true },
		productPurchase: { value: {}, valid: true, error: '', required: false },
		servicePurchase: { value: {}, valid: true, error: '', required: false },
		giveaways: { value: {}, valid: true, error: '', required: false },
		isPrimary: { value: false, valid: true, error: '', required: false },
	},
};
