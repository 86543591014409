import { createAction, handleActions } from 'redux-actions';

import { Difficulty } from 'types/DifficultyCode';
import { DynamicState } from 'types/DynamicState';

import { useRedux } from 'util/hook/redux';

import { fetchTourCardInfo } from 'api/travel';

import { State as GlobalState } from './reducers';

export interface TourItemProperty {
	id: number;
	title: string;
	price: number;
	difficulty: Difficulty;
	day: string;
	imageUrl: string;
	tags: string[];
	isSaved?: boolean;
	overdue?: boolean;
}

export interface TourCardDataProperty {
	name: string;
	travels: TourItemProperty[];
}

export interface State {
	tourCardInfo: DynamicState<TourCardDataProperty[] | null>;
}

const initialState: State = {
	tourCardInfo: {
		data: null,
		loading: false,
		error: '',
	},
};

type GetTourCardInfoPayload = Partial<DynamicState<TourCardDataProperty[] | null>>;

const getTourCardInfo = createAction<Promise<GetTourCardInfoPayload>>(
	'GET_TOUR_CARD_INFO',
	async () => {
		try {
			const { status, message, data } = await fetchTourCardInfo();

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data,
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

type ReducerTourPayloads = GetTourCardInfoPayload;

export const reducer = {
	tours: handleActions<State, ReducerTourPayloads>(
		{
			GET_TRAVEL_BRIEF_LIST_PENDING: state => ({
				...state,
				tourCardInfo: {
					...state.tourCardInfo,
					loading: true,
					error: '',
				},
			}),
			GET_TOUR_CARD_INFO_FULFILLED: (state, action) => ({
				...state,
				tourCardInfo: {
					...state.tourCardInfo,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

/* +----------------------------------------------------------------------
	++ useTourCardInfo(取得旅遊卡片資訊) ++
++----------------------------------------------------------------------*/

const selectTourCardInfo = (state: GlobalState) => state.tours.tourCardInfo;

export const useTourCardInfo = () =>
	useRedux(selectTourCardInfo, {
		getTourCardInfo,
	});
