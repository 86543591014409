/* eslint-disable indent */
import { handleActions, createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import { DynamicState } from 'types/DynamicState';
import { FormDataMapping } from 'types/FormDataMapping';
import { InputFiled } from 'types/InputFiled';
import { UploadFileData } from 'types/UploadFileData';
import { FileType } from 'types/FileType';
import { FetchToursParams, TravelToursList } from 'types/travelClassicAndCustomTours';

import { useRedux } from 'util/hook/redux';

import { PackageImageUploadResponseData } from 'api/package';
import {
	TravelClassicToursRidingLevelsData,
	TravelClassicToursInfo,
	fetchTravelClassicToursRidingLevelsFunc,
	fetchTravelClassicToursList,
	fetchTravelClassicToursInfoFunc,
	TravelClassicToursInfoRating,
	fetchTravelClassicToursRatingFunc,
	TravelClassicToursRatingReviewer,
	sendCustomerFeedbackImageFunc,
	getTravelClassicTourPDFFunc,
} from 'api/travelClassicTours';

import { GetState, State as GlobalState } from './reducers';

export type CustomerFeedbackFormFiled = keyof Partial<TravelClassicToursRatingReviewer>;

interface CustomerFeedbackImageGroup {
	uploadPhotos: {
		ids: number[]; // 上傳圖片的 timestamp
		byId: Record<number, UploadFileData | null>; // 如果這個 id 的 data 是 null, 代表被刪除了
		errorsById: Record<number, string>;
		required: boolean;
		error: string;
	};
}

const customerFeedbackImageGroup: CustomerFeedbackImageGroup = {
	uploadPhotos: {
		ids: [],
		byId: {},
		errorsById: {},
		required: false,
		error: '',
	},
};

export interface State {
	travelClassicToursList: DynamicState<TravelToursList | null>;
	travelClassicTourPDF: DynamicState<Blob | null>;
	travelClassicToursRidingLevels: DynamicState<TravelClassicToursRidingLevelsData[] | null>;
	travelClassicToursInfo: DynamicState<TravelClassicToursInfo | null>;
	travelClassicToursInfoRating: DynamicState<TravelClassicToursInfoRating | null>;
	travelClassicToursCustomerFeedbackForm: FormDataMapping<
		Partial<TravelClassicToursRatingReviewer>
	>;
	customerFeedbackImage: {
		ids: number[];
		type: object[];
		byIds: Record<number, CustomerFeedbackImageGroup | null>;
	};
}

const initialState: State = {
	travelClassicToursList: {
		data: null,
		loading: false,
		error: '',
	},
	travelClassicToursInfo: {
		data: null,
		loading: false,
		error: '',
	},
	travelClassicTourPDF: {
		data: null,
		loading: false,
		error: '',
	},
	travelClassicToursInfoRating: {
		data: null,
		loading: false,
		error: '',
	},
	travelClassicToursRidingLevels: {
		data: null,
		loading: false,
		error: '',
	},
	travelClassicToursCustomerFeedbackForm: {
		name: { value: '', valid: true, error: '', required: true },
		rating: { value: 0, valid: true, error: '', required: true },
		comment: { value: '', valid: true, error: '', required: false },
		image: { value: [], valid: true, error: '', required: false },
	},
	customerFeedbackImage: {
		ids: [0],
		type: [{ value: '' }],
		byIds: {
			0: customerFeedbackImageGroup,
		},
	},
};

/**
 * 清空旅客評價表單
 */
const resetCustomerFeedbackForms = createAction(
	'RESET_TRAVEL_CLASSIC_TOURS_CUSTOMER_FEEDBACK_FORMS',
);

/** ________________________________________________________________________________________ */

interface CustomerFeedbackFormChangePayload<
	K extends CustomerFeedbackFormFiled = CustomerFeedbackFormFiled
> {
	key: K;
	data: Partial<InputFiled<Partial<TravelClassicToursRatingReviewer>[K]>>;
}

/**
 * 更改旅客評價表單
 */
const changeTravelClassicToursCustomerFeedbackForm = createAction<
	CustomerFeedbackFormChangePayload,
	CustomerFeedbackFormChangePayload
>('CHANGE_TRAVEL_CLASSIC_TOURS_CUSTOMER_FEEDBACK_FORM', ({ key, data }) => ({
	key,
	data,
}));

/** ________________________________________________________________________________________ */

/**
 * 清空旅客評價照片
 */
const resetCustomerFeedbackImage = createAction('RESET_CUSTOMER_FEEDBACK_IMAGE');

/** ________________________________________________________________________________________ */

/**
 * 移除旅客評價照片
 */
const removeCustomerFeedbackImage = createAction<number, number>(
	'REMOVE_CUSTOMER_FEEDBACK_IMAGE',
	id => id,
);

/** ________________________________________________________________________________________ */

interface CustomerFeedbackImageChangePayload {
	id: number;
	key: keyof CustomerFeedbackImageGroup;
	data: CustomerFeedbackImageGroup;
}

/**
 * 更改旅客評價照片
 */
const changeCustomerFeedbackImage = createAction<
	CustomerFeedbackImageChangePayload,
	CustomerFeedbackImageChangePayload
>('CHANGE_CUSTOMER_FEEDBACK_IMAGE', ({ id, key, data }) => ({
	id,
	key,
	data,
}));

/** ________________________________________________________________________________________ */

/**
 * 上傳旅客評價照片
 */
const uploadTravelClassicToursCustomerFeedbackFormImage = createAction<
	(_: Dispatch, getState: GetState) => void,
	FileType[]
>(
	'UPLOAD_TRAVEL_CLASSIC_TOURS_CUSTOMER_FEEDBACK_FORM_IMAGE',
	files => async (dispatch: Dispatch, getState: GetState) => {
		const {
			travelClassicTours: { customerFeedbackImage },
		} = getState();

		const imagesFormData = new FormData();
		imagesFormData.append('files[]', files[0].file);
		imagesFormData.append('type', 'packageOrder');

		try {
			const { data } = await sendCustomerFeedbackImageFunc(imagesFormData);

			const uploadTimestamp = new Date().getTime();
			const uploadData = data as PackageImageUploadResponseData[];
			const uploadPhoto = uploadData[0];
			const originalPhotosData = customerFeedbackImage.byIds[0]?.uploadPhotos;

			if (originalPhotosData === undefined) {
				return;
			}

			dispatch(
				changeCustomerFeedbackImage({
					id: 0,
					key: 'uploadPhotos',
					data: {
						ids: [...originalPhotosData.ids, uploadTimestamp],
						byId: {
							...originalPhotosData.byId,
							[uploadTimestamp]: uploadPhoto,
						},
						errorsById: {
							...originalPhotosData.errorsById,
							[uploadTimestamp]: '',
						},
						error: '',
					},
				}),
			);
		} catch (error) {
			console.log('something wrong here');
		}
	},
);

/** ________________________________________________________________________________________ */

type GetTravelClassicToursInfoPayload = Partial<DynamicState<TravelClassicToursInfo | null>>;

/**
 * 取得旅遊經典行程內頁資訊
 */
const getTravelClassicToursInfo = createAction<Promise<GetTravelClassicToursInfoPayload>, number>(
	'GET_TRAVEL_CLASSIC_TOURS_INFO',
	async travelId => {
		try {
			const { status, message, data } = await fetchTravelClassicToursInfoFunc(travelId);
			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return {
				data: {
					id: data.id,
					name: data.name,
					banner: data.banner,
					start_location_note: data.start_location_note,
					end_location_note: data.end_location_note,
					duration_day: data.duration_day,
					total_mileage: data.total_mileage,
					lowest_price: data.lowest_price,
					description: data.description,
					note: data.note,
					difficulty: data.difficulty,
					is_open_for_customized: data.is_open_for_customized,
					additional_products: data.additional_products,
					trip_type: data.trip_type,
					route: data.route,
					rooms: data.rooms,
					youtube_url: data.youtube_url,
					products: data.products,
				},
			};
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

/** ________________________________________________________________________________________ */

export type GetTravelClassicTourPDFPayload = Partial<DynamicState<{ data: Blob }>>;
/**
 * 取得經典行程 PDF
 */
const getTravelClassicTourPDF = createAction<Promise<GetTravelClassicTourPDFPayload>, number>(
	'GET_TRAVEL_CLASSIC_TOUR_PDF',
	async travelId => {
		try {
			const { status, message, data } = await getTravelClassicTourPDFFunc(travelId);
			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			return { data: { data } };
		} catch (error) {
			if (error instanceof Error) {
				const { message } = error;
				return { error: message };
			}
			return { error: 'something went wrong' };
		}
	},
);

/** ________________________________________________________________________________________ */

type GetTravelClassicToursInfoRatingPayload = Partial<DynamicState<TravelClassicToursInfoRating>>;

/**
 * 取得旅遊經典行程內頁評價資訊
 */
const getTravelClassicToursInfoRating = createAction<
	Promise<GetTravelClassicToursInfoRatingPayload>,
	number
>('GET_TRAVEL_CLASSIC_TOURS_INFO_RATING', async tripId => {
	try {
		const { status, message, data } = await fetchTravelClassicToursRatingFunc(tripId);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: {
				total_rating: data.total_rating,
				reviewer: data.reviewer,
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

/** ________________________________________________________________________________________ */

type GetTravelClassicToursListPayload = Partial<DynamicState<TravelToursList | null>>;

/**
 * 取得旅遊經典行程列表
 */
const getTravelClassicToursList = createAction<
	Promise<GetTravelClassicToursListPayload>,
	FetchToursParams
>('GET_TRAVEL_CLASSIC_TOURS_LIST', async params => {
	try {
		// Ensure the params include is_curated: true ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/1761#note_1491900
		const updatedParams = { ...params, is_curated: true };

		const { status, message, data } = await fetchTravelClassicToursList(updatedParams);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return {
			data: {
				last_page: data.last_page,
				current_page: data.current_page,
				data: data.data.map(
					({
						id,
						name,
						duration_day,
						first_banner_image_url,
						is_liked,
						difficulty,
						lowest_price,
						rooms,
						start_location,
						min_quota,
						travel_types, // 此屬性針對旅遊首頁使用
						bicycle_type,
					}) => ({
						id,
						name,
						duration_day,
						first_banner_image_url,
						is_liked,
						difficulty,
						lowest_price,
						rooms,
						start_location,
						min_quota,
						travel_types, // 此屬性針對旅遊首頁使用
						bicycle_type,
					}),
				),
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			return { error: message };
		}
		return { error: 'something went wrong' };
	}
});

/** ________________________________________________________________________________________ */

type GetTravelClassicToursRidingLevelPayload = Partial<
	DynamicState<TravelClassicToursRidingLevelsData[] | null>
>;

/**
 *  取得旅遊經典行程騎乘強度 tag 資料
 */
const getTravelClassicToursRidingLevels = createAction<
	Promise<GetTravelClassicToursRidingLevelPayload>
>('GET_TRAVEL_CLASSIC_TOURS_RIDING_LEVELS', async () => {
	try {
		const { status, message, data } = await fetchTravelClassicToursRidingLevelsFunc();

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { data };
	} catch (error) {
		throw new Error((error as Error).message);
	}
});

/** ________________________________________________________________________________________ */

type ReducerTravelPayloads = GetTravelClassicToursListPayload &
	GetTravelClassicToursRidingLevelPayload &
	GetTravelClassicToursInfoPayload &
	GetTravelClassicToursInfoRatingPayload &
	(CustomerFeedbackImageChangePayload | CustomerFeedbackFormChangePayload) &
	GetTravelClassicTourPDFPayload;

export const reducer = {
	travelClassicTours: handleActions<State, ReducerTravelPayloads>(
		{
			RESET_CUSTOMER_FEEDBACK_IMAGE: state => ({
				...state,
				customerFeedbackImage: initialState.customerFeedbackImage,
			}),

			RESET_TRAVEL_CLASSIC_TOURS_CUSTOMER_FEEDBACK_FORMS: state => ({
				...state,
				travelClassicToursCustomerFeedbackForm: initialState.travelClassicToursCustomerFeedbackForm,
			}),

			REMOVE_CUSTOMER_FEEDBACK_IMAGE: (state, action) => ({
				...state,
				customerFeedbackImage: {
					...state.customerFeedbackImage,
					ids: state.customerFeedbackImage.ids.filter(id => id !== action.payload),
					byIds: {
						...state.customerFeedbackImage.byIds,
						[action.payload]: null,
					},
				},
			}),

			CHANGE_TRAVEL_CLASSIC_TOURS_CUSTOMER_FEEDBACK_FORM: (state, action) => ({
				...state,
				travelClassicToursCustomerFeedbackForm: {
					...state.travelClassicToursCustomerFeedbackForm,
					[action.payload.key]: {
						...state.travelClassicToursCustomerFeedbackForm[
							action.payload.key as keyof TravelClassicToursRatingReviewer
						],
						...action.payload.data,
					},
				},
			}),

			CHANGE_CUSTOMER_FEEDBACK_IMAGE: (state, action) => ({
				...state,

				customerFeedbackImage: {
					...state.customerFeedbackImage,
					byIds: {
						...state.customerFeedbackImage.byIds,
						0: state.customerFeedbackImage.byIds[0]
							? {
									...state.customerFeedbackImage.byIds[0],
									[action.payload.key as keyof CustomerFeedbackImageGroup]: {
										...state.customerFeedbackImage.byIds[0][
											action.payload.key as keyof CustomerFeedbackImageGroup
										],
										...action.payload.data,
									},
							  }
							: null,
					},
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_INFO_PENDING: state => ({
				...state,
				travelClassicToursInfo: {
					...state.travelClassicToursInfo,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_INFO_FULFILLED: (state, action) => ({
				...state,
				travelClassicToursInfo: {
					...state.travelClassicToursInfo,
					...(action.payload.data && {
						data: { ...state.travelClassicToursInfo.data, ...action.payload.data },
					}),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_CLASSIC_TOUR_PDF_PENDING: state => ({
				...state,
				travelClassicTourPDF: {
					...state.travelClassicTourPDF,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CLASSIC_TOUR_PDF_FULFILLED: (state, action) => ({
				...state,
				travelClassicTourPDF: {
					...state.travelClassicTourPDF,
					...(action.payload.data.data && {
						data: { ...state.travelClassicTourPDF, ...((action.payload.data as unknown) as Blob) },
					}),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_INFO_RATING_PENDING: state => ({
				...state,
				travelClassicToursInfoRating: {
					...state.travelClassicToursInfoRating,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_INFO_RATING_FULFILLED: (state, action) => ({
				...state,
				travelClassicToursInfoRating: {
					...state.travelClassicToursInfoRating,
					...(action.payload.data && {
						data: { ...state.travelClassicToursInfoRating.data, ...action.payload.data },
					}),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_LIST_PENDING: state => ({
				...state,
				travelClassicToursList: {
					...state.travelClassicToursList,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_LIST_FULFILLED: (state, action) => ({
				...state,
				travelClassicToursList: {
					...state.travelClassicToursList,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_RIDING_LEVELS_PENDING: state => ({
				...state,
				travelClassicToursRidingLevels: {
					...state.travelClassicToursRidingLevels,
					loading: true,
					error: '',
				},
			}),

			GET_TRAVEL_CLASSIC_TOURS_RIDING_LEVELS_FULFILLED: (state, action) => ({
				...state,
				travelClassicToursRidingLevels: {
					...state.travelClassicToursRidingLevels,
					...(action.payload.data && { data: action.payload.data }),
					...(action.payload.error && { error: action.payload.error }),
					loading: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useTravelClassicToursLis(經典旅遊行程列表) ++
++----------------------------------------------------------------------*/
const selectTravelClassicToursList = (state: GlobalState) =>
	state.travelClassicTours.travelClassicToursList;

export const useTravelClassicToursList = () =>
	useRedux(selectTravelClassicToursList, { getTravelClassicToursList });

/* +----------------------------------------------------------------------
	++ useTravelClassicToursRidingLevels(經典旅遊行程列表篩選 - 騎乘強度) ++
++----------------------------------------------------------------------*/
const selectTravelClassicToursRidingLevels = (state: GlobalState) =>
	state.travelClassicTours.travelClassicToursRidingLevels;

export const useTravelClassicToursRidingLevels = () =>
	useRedux(selectTravelClassicToursRidingLevels, { getTravelClassicToursRidingLevels });

/* +----------------------------------------------------------------------
	++ useTravelClassicToursInfo(經典旅遊行程內頁) ++
++----------------------------------------------------------------------*/
const selectTravelClassicToursInfo = (state: GlobalState) =>
	state.travelClassicTours.travelClassicToursInfo;

export const useTravelClassicToursInfo = () =>
	useRedux(selectTravelClassicToursInfo, { getTravelClassicToursInfo });

/* +----------------------------------------------------------------------
	++ useTravelClassicToursInfoRating(經典旅遊行程內頁 - 旅客評價) ++
++----------------------------------------------------------------------*/
const selectTravelClassicToursInfoRating = (state: GlobalState) =>
	state.travelClassicTours.travelClassicToursInfoRating;

export const useTravelClassicToursInfoRating = () =>
	useRedux(selectTravelClassicToursInfoRating, { getTravelClassicToursInfoRating });

/* +----------------------------------------------------------------------
	++ useTravelClassicToursCustomerFeedbackForm(經典旅遊行程內頁旅客評價表單) ++
++----------------------------------------------------------------------*/
const selectTravelClassicToursCustomerFeedbackForm = (state: GlobalState) => ({
	travelClassicToursCustomerFeedbackForm:
		state.travelClassicTours.travelClassicToursCustomerFeedbackForm,
	customerFeedbackImage: state.travelClassicTours.customerFeedbackImage,
});

export const useTravelClassicToursCustomerFeedbackForm = () =>
	useRedux(selectTravelClassicToursCustomerFeedbackForm, {
		resetCustomerFeedbackForms,
		resetCustomerFeedbackImage,
		removeCustomerFeedbackImage,
		changeTravelClassicToursCustomerFeedbackForm,
		changeCustomerFeedbackImage,
		uploadTravelClassicToursCustomerFeedbackFormImage,
	});

/* +----------------------------------------------------------------------
	++ useTravelClassicTourPDF(經典旅遊行程內頁 - PDF) ++
++----------------------------------------------------------------------*/
const selectTravelClassicTourPDF = (state: GlobalState) =>
	state.travelClassicTours.travelClassicTourPDF;

export const useTravelClassicTourPDF = () =>
	useRedux(selectTravelClassicTourPDF, { getTravelClassicTourPDF });
