/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Rename some keys of an object according to a key-mapper object.
 *
 * @param object An object that some of its keys defined in the key-mapper object are going to be renamed.
 * @param mapper An object defines the key-value name pairs of the old key and new key.
 * @returns An object that renames the keys according to the key-mapper object.
 */

export const objectDataKeyMapper = <
	T extends object,
	M extends { [Key in keyof M]: Key extends keyof T ? PropertyKey : 'Error: key not in T' },
	PrevKeys extends keyof M = keyof M
>(
	object: T,
	mapper: M,
): Omit<T, PrevKeys> &
	{ [Key in typeof mapper[PrevKeys]]: T[PrevKeys extends keyof T ? PrevKeys : never] } => {
	const res: any = { ...object };

	(Object.keys(mapper) as (keyof T & keyof M)[]).forEach(key => {
		if (res[key] !== undefined) {
			delete res[key];
			res[mapper[key]] = object[key];
		}
	});
	return res;
};
