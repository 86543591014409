import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.travelCustomTours.replace(appPath.travel, ''),
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'travelCustomToures' */ './component')],
			render: ([TravelCustomToures]) => <TravelCustomToures />,
		},
	],
};

export default routes;
