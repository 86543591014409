export interface LeaderData {
	id: number;
	name: string;
	job_title: string;
	info: string;
	img: string;
}

/**
 * 捷安特領隊
 *
 * @export
 * @class Leader
 */
export default class Leader implements LeaderData {
	/**
	 * 領隊 ID
	 *
	 * @type {number}
	 * @memberof Leader
	 */
	id: number;

	/**
	 * 領隊姓名
	 *
	 * @type {string}
	 * @memberof Leader
	 */
	name: string;

	/**
	 * 領隊職稱
	 *
	 * @type {string}
	 * @memberof Leader
	 */
	job_title: string;

	/**
	 * 領隊資訊
	 *
	 * @type {string}
	 * @memberof Leader
	 */
	info: string;

	/**
	 * 領隊照片 URL
	 *
	 * @type {string}
	 * @memberof Leader
	 */
	img: string;

	constructor(props?: LeaderData) {
		this.id = props?.id || 0;
		this.name = props?.name || '';
		this.job_title = props?.job_title || '';
		this.img = props?.img || '';
		this.info = props?.info || '';
	}

	/**
	 * 領隊資訊頁連結
	 *
	 * @readonly
	 * @type {string}
	 * @memberof Leader
	 */
	get linkTo(): string {
		return this.id.toString();
	}
}
