/* eslint-disable indent */
import { createAction, handleActions } from 'redux-actions';
import { createContext, useContext } from 'react';
import { Dispatch } from 'redux';
import { Location, History, LocationDescriptorObject } from 'history';
import qs from 'qs';

import { useRedux } from 'util/hook/redux';
import history from 'store/history';

import { globalLocal } from './locale';
import { GetState, State as GlobalState } from './reducers';

type Queries = {
	[key: string]: string | number | undefined;
};

export const routeChange = createAction<Location, Location>(
	'ROUTE_CHANGE',
	(location: Location) => ({
		...location,
		queries: qs.parse(location.search, { ignoreQueryPrefix: true }),
	}),
);

interface StateProperty {
	prevPathname: string;
	prevSearch: string;
}

const initialStateValue: StateProperty = {
	prevPathname: '',
	prevSearch: '',
};

export interface PushRouteProperty extends LocationDescriptorObject<StateProperty> {
	loc?: string;
	withLoc?: boolean;
	queries?: Queries;
}

export const pushRoute = createAction(
	'PUSH_ROUTE',
	({
		pathname,
		queries = {},
		loc,
		withLoc = true,
		state = initialStateValue,
		search,
		...props
	}: PushRouteProperty) => (_: Dispatch, getState: GetState) => {
		const {
			routing: { pathname: prevPathname, search: prevSearch },
			locale: { currentLocCode },
		} = getState();

		const newLocCode = loc || currentLocCode;
		const newPathnameLocale = newLocCode === globalLocal.code ? '' : `/${newLocCode}`;
		const currentPathnameLocale = currentLocCode === globalLocal.code ? '' : `/${currentLocCode}`;
		const regex = new RegExp(currentPathnameLocale, 'g');
		const prevPathnameWitoutLoc = prevPathname.replace(regex, '');

		history.push({
			...props,
			...(pathname && {
				pathname: withLoc ? `${currentPathnameLocale}${pathname}` : pathname,
			}),
			...(!pathname &&
				loc && {
					pathname: `${newPathnameLocale}${prevPathnameWitoutLoc}`,
				}),
			search: search || qs.stringify(queries, { addQueryPrefix: true }),
			state: {
				...state,
				prevPathname,
				prevSearch,
			},
		});
	},
);

export const replaceRoute = createAction(
	'REPLACE_ROUTE',
	({ pathname, queries = {}, loc, withLoc = true, search, ...props }: PushRouteProperty) => (
		_: Dispatch,
		getState: GetState,
	) => {
		const {
			routing: { pathname: prevPathname, state: prevState },
			locale: { currentLocCode },
		} = getState();

		const newLocCode = loc || currentLocCode;
		const newPathnameLocale = newLocCode === globalLocal.code ? '' : `/${newLocCode}`;
		const currentPathnameLocale = currentLocCode === globalLocal.code ? '' : `/${currentLocCode}`;
		const regex = new RegExp(currentPathnameLocale, 'g');
		const prevPathnameWitoutLoc = prevPathname.replace(regex, '');

		console.log('prevPathname', prevPathname);
		console.log('withLoc', withLoc);
		console.log('pathname', pathname);
		console.log('prevPathname', prevPathname);
		console.log('------------------');

		history.replace({
			...props,
			...(pathname && {
				pathname: withLoc ? `${currentPathnameLocale}${pathname}` : pathname,
			}),
			...(!pathname &&
				loc && {
					pathname: `${newPathnameLocale}${prevPathnameWitoutLoc}`,
				}),
			search: search || qs.stringify(queries, { addQueryPrefix: true }),
			state: { ...prevState },
		});

		console.log('pathname', pathname);
		console.log('currentPathname', history.location.pathname);
	},
);

export interface State extends Location<StateProperty> {
	queries: {
		[key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	};
}

export const reducer = {
	routing: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			ROUTE_CHANGE: (state, action) => ({
				...state,
				...action.payload,
			}),
		},
		{
			...history.location,
			queries: qs.parse(history.location.search, {
				ignoreQueryPrefix: true,
			}),
			state: initialStateValue,
		},
	),
};

export const HistoryContext = createContext<History>(history);

export const useHistory = () => useContext(HistoryContext);

const mapHooksToState = (state: GlobalState) => state.routing;

export const useRouting = () => useRedux(mapHooksToState, { pushRoute, replaceRoute });
