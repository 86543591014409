/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';

import { GenderCodes, GenderValues } from 'types/Gender';
import { RelationshipCodes } from 'types/Relationship';
import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { ERROR_MESSAGE_I18N_NAMESPACE } from 'types/ErrorMessage';

import { objectDataKeyMapper } from 'util/functions/objectKeyMapper';
import { useRedux } from 'util/hook/redux';
import { t } from 'util/i18n';
import { openPaymentView, createPaymentWindow } from 'util/functions/openPaymentView';
import { createErrorMsgKey, nullToUndefined } from 'util/helper';

import { UserInfoData } from 'models/auth';
import { RentalReservationStore } from 'models/stores';
import { updateStocks as updateBicycleStocks } from 'models/bicycle';
import { updateStocks as updateProductStocks } from 'models/product';
import { openModal } from 'models/modal';
import { clearPayment, getPaymentMappingValue } from 'models/payment';
import { clearInvoice, getInvoiceMappingValue } from 'models/invoice';

import { validateBicycleStockFunc } from 'api/bicycle';
import { validateProductStockFunc } from 'api/product';
import {
	submitRentalOrderFunc,
	RentalOrderResponse,
	submitRentalOrderTransactionFunc,
	RentalOrderTransactionResponse,
} from 'api/rentalOrder';
import { submitAdminRentalOrderFunc } from 'api/rentalOrderAdmin';

import { GetState, State as GlobalState } from './reducers';

interface RentalReservationInfoForm {
	isAsMemberInfo: boolean;
	lastName: string;
	firstName: string;
	nationality: SelectValue<string | null>;
	gender: SelectValue<GenderValues | null>;
	id: string;
	height: string;
	mobile: { intIdNum: string; phoneNum: string };
	email: string;
	emergencyName: string;
	emergencyRelation: SelectValue<RelationshipCodes | null>;
	emergencyMobile: { intIdNum: string; phoneNum: string };
	remarks: string;
	agreeContract: boolean;
}

export type RentalReservationFormFiled = keyof RentalReservationInfoForm;

const keyMapperFromUserInfoToRentalForm = {
	nationalityCode: 'nationality',
	phoneNumber: 'mobile',
} as const;

type KeyMapperFromUserInfoToRentalForm = typeof keyMapperFromUserInfoToRentalForm;

export interface State {
	formStatus: {
		isReady: boolean;
		isSubmitting: boolean;
		step: 0 | 1 | 2 | 3;
		selector: string;
		view: string;
	};
	infoForm: FormDataMapping<RentalReservationInfoForm>;
	storesInfo: {
		startStore: RentalReservationStore | null;
		destinationStore: RentalReservationStore | null;
		startDate: string | null;
		startTime: string | null;
		returnDate: string | null;
		returnTime: string | null;
	};
	cart: {
		isValidating: boolean;

		/**
		 * 根據 key = stock ID 計算購物車的商品數量（前端用 stock ID 存購物車中的商品資料，並帶給後端）
		 */
		data: { bicycles: { [key: number]: number }; productAdditionals: { [key: number]: number } };

		/**
		 * 根據 key = product ID 計算購物車的商品數量（前端用 product ID 列出購物車的商品清單：cartToShoppingList.ts）
		 */
		calcByProduct: {
			bicycles: { [key: number]: number };
			productAdditionals: { [key: number]: number };
		};
	};
}

const initialState: State = {
	formStatus: {
		isReady: false,
		isSubmitting: false,
		step: 0,
		selector: '',
		view: '',
	},
	infoForm: {
		isAsMemberInfo: { value: false, valid: true, error: '', required: false },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		nationality: { value: { label: '', value: null }, valid: true, error: '', required: true },
		gender: { value: { label: '', value: null }, valid: true, error: '', required: true },
		id: { value: '', valid: true, error: '', required: true },
		height: { value: '', valid: true, error: '', required: true },
		mobile: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		emergencyName: { value: '', valid: true, error: '', required: true },
		emergencyRelation: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		emergencyMobile: {
			value: { intIdNum: '', phoneNum: '' },
			valid: true,
			error: '',
			required: true,
		},
		remarks: { value: '', valid: true, error: '', required: false },
		agreeContract: { value: false, valid: true, error: '', required: true },
	},
	storesInfo: {
		startStore: null,
		destinationStore: null,
		startDate: null,
		startTime: null,
		returnDate: null,
		returnTime: null,
	},
	cart: {
		isValidating: false,
		data: { bicycles: {}, productAdditionals: {} },
		calcByProduct: { bicycles: {}, productAdditionals: {} },
	},
};

const clearRentalReservation = createAction('CLEAR_RENTAL_RESERVATION');

export const clearRentalReservationEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType('CLEAR_RENTAL_RESERVATION'),
		switchMap(() => {
			return of(clearPayment(), clearInvoice());
		}),
	);

const setRentalReservationReady = createAction('SET_RENTAL_RESERVATION_READY');

const setStep = createAction<State['formStatus']['step'], State['formStatus']['step']>(
	'SET_RENTAL_RESERVATION_STEP',
	step => step,
);

const goNextStep = createAction('SET_RENTAL_RESERVATION_NEXT_STEP');

const goPrevStep = createAction('SET_RENTAL_RESERVATION_PREV_STEP');

export interface FormChangePayload {
	key: RentalReservationFormFiled;
	data: Partial<InputFiled>;
}

const changeRentalReservationForm = createAction<FormChangePayload, FormChangePayload>(
	'CHANGE_RENTAL_RESERVATION_FORM',
	({ key, data }) => ({
		key,
		data,
	}),
);

/**
 * 將會員的個人資料 map 到表單
 */
const updateRentalReservationFormByAuthUserProfile = createAction<
	(_: Dispatch, getState: GetState) => void,
	boolean
>(
	'UPDATE_RENTAL_RESERVATION_FORM_BY_AUTH_USER_PROFILE',
	(check = true) => (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: { currentUserGid, userDataByGid },
			nationalities: {
				data: { byCode: nationalitiesByCodes },
			},
		} = getState();

		const currentUserData = userDataByGid[currentUserGid];

		const data = objectDataKeyMapper<UserInfoData, KeyMapperFromUserInfoToRentalForm>(
			currentUserData,
			keyMapperFromUserInfoToRentalForm,
		);

		(Object.keys(data) as (keyof typeof data)[]).forEach(key => {
			const DataValue = check ? data[key] : '';
			let formDataValue: unknown = DataValue;

			if (key === 'gender') formDataValue = { label: '', value: DataValue };
			if (key === 'mobile') formDataValue = { intIdNum: '', phoneNum: DataValue };
			if (key === 'nationality') {
				formDataValue = {
					label: nationalitiesByCodes[DataValue as string]?.name || '',
					value: nationalitiesByCodes[DataValue as string]?.code || '',
				};
			}

			if (key !== undefined) {
				dispatch(
					changeRentalReservationForm({
						key: key as keyof RentalReservationInfoForm,
						data: { value: formDataValue, valid: true, error: '' },
					}),
				);
			}
		});
	},
);

const changeStoresInfo = createAction<Partial<State['storesInfo']>, Partial<State['storesInfo']>>(
	'CHANGE_RENTAL_RESERVATION_STORES_INFO',
	data => data,
);

interface UpdateCartPayload {
	type: keyof State['cart']['data'];
	data: State['cart']['data'][keyof State['cart']['data']];
}

interface UpdateCartParams {
	type: keyof State['cart']['data'];
	stockId: number;
	amount: number;
}

const resetCart = createAction('RESET_RENTAL_RESERVATION_CART');

/**
 * 將商品加入購物車
 */
const addCartItem = createAction<
	(_: Dispatch, getState: GetState) => UpdateCartPayload,
	UpdateCartParams
>(
	'UPDATE_RENTAL_RESERVATION_CART_ITEM',
	({ type, stockId, amount }) => (dispatch: Dispatch, getState: GetState) => {
		const {
			rentalReservation: {
				cart: { data },
			},
		} = getState();

		const ifAlreadyInCart = data[type][stockId] !== undefined;

		const cartData = {
			...data[type],
			[stockId]: ifAlreadyInCart ? data[type][stockId] + amount : amount,
		};

		dispatch(
			openModal({
				category: 'toast',
				type: 'message',
				data: {
					message: t('cart:addedToCart'),
					status: 'pass',
				},
			}),
		);

		return {
			type,
			data: cartData,
		};
	},
);

/**
 * 調整購物車中的商品數量
 */
const adjustCartItemAmount = createAction<
	(_: Dispatch, getState: GetState) => UpdateCartPayload,
	UpdateCartParams
>(
	'UPDATE_RENTAL_RESERVATION_CART_ITEM',
	({ type, stockId, amount }) => (_: Dispatch, getState: GetState) => {
		const {
			rentalReservation: {
				cart: { data },
			},
		} = getState();
		let cartData = { ...data[type] };

		if (amount === 0 && data[type][stockId]) {
			delete cartData[stockId];
		} else {
			cartData = {
				...cartData,
				[stockId]: amount,
			};
		}

		return {
			type,
			data: cartData,
		};
	},
);

/**
 * 驗證購物車中的商品數量是否符合目前的庫存
 * Success：商品數量 <= 庫存
 * Failure：商品數量 > 庫存
 */
const validateCartStocks = createAction(
	'VALIDATE_RENTAL_CART_STOCKS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			product: {
				dataById: { stocks: productStocks },
			},
			bicycle: {
				stocks: {
					normalized: { stocks: bicycleStocks },
				},
			},
			rentalReservation: {
				cart: { data: cart },
				storesInfo: { startStore, destinationStore, startDate, startTime, returnDate, returnTime },
			},
		} = getState();

		try {
			const params = {
				start_store_id: startStore?.id as number,
				end_store_id: destinationStore?.id as number,
				start_at: moment(`${startDate} ${startTime}`, 'YYYY/MM/DD HH:mm:ss').format(
					'YYYY-MM-DD HH:mm:ss',
				),
				end_at: moment(`${returnDate} ${returnTime}`, 'YYYY/MM/DD HH:mm:ss').format(
					'YYYY-MM-DD HH:mm:ss',
				),
			};

			/** 驗證車輛庫存 */
			const bicycleResponse = await validateBicycleStockFunc({
				...params,
				bicycle_specs: Object.entries(cart.bicycles).map(([stockId, amount]) => ({
					id: parseInt(stockId, 10),
					amount,
				})),
			});

			/** 驗證加價購商品庫存 */
			const productAdditionalResponse = await validateProductStockFunc({
				start_at: params.start_at,
				end_at: params.end_at,
				products: Object.entries(cart.productAdditionals).map(([stockId, amount]) => ({
					id: parseInt(stockId, 10),
					amount,
				})),
			});

			const newBicycleStocks = {
				...bicycleStocks,
			};
			const newProductStocks = {
				...productStocks,
			};

			/** 更新車輛庫存的 Hash Table */
			bicycleResponse.data.bicycle_specs.forEach(d => {
				newBicycleStocks[d.id] = {
					...newBicycleStocks[d.id],
					stock: d.stock,
				};
			});

			/** 更新加價購商品庫存的 Hash Table */
			productAdditionalResponse.data.product_stocks.forEach(d => {
				newProductStocks[d.id] = {
					...newProductStocks[d.id],
					stock: d.stock,
				};
			});

			await dispatch(updateBicycleStocks(newBicycleStocks));
			await dispatch(updateProductStocks(newProductStocks));

			if (bicycleResponse.status === 200 && productAdditionalResponse.status === 200) {
				return Promise.resolve({
					success: true,
				});
			}

			/* 商品數量超過庫存，顯示 Error Modal  */
			if (
				(bicycleResponse.data.errors &&
					Object.values(bicycleResponse.data.errors).includes('OUT_OF_STOCK')) ||
				(productAdditionalResponse.data.errors &&
					Object.values(productAdditionalResponse.data.errors).includes('OUT_OF_STOCK'))
			) {
				dispatch(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message: t('cart:cartItemsOutOfStock'),
							status: 'warning',
						},
					}),
				);
			}

			if (bicycleResponse.data.errors || productAdditionalResponse.data.errors) {
				if (
					Object.values(bicycleResponse.data.errors).includes('OUT_OF_STOCK') ||
					Object.values(productAdditionalResponse.data.errors).includes('OUT_OF_STOCK')
				) {
					dispatch(
						openModal({
							category: 'toast',
							type: 'message',
							data: {
								message: t('cart:cartItemsOutOfStock'),
								status: 'warning',
							},
						}),
					);
				} else if (
					Object.values(bicycleResponse.data.errors).includes('EXCEED_LIMIT') ||
					Object.values(productAdditionalResponse.data.errors).includes('EXCEED_LIMIT')
				) {
					dispatch(
						openModal({
							category: 'toast',
							type: 'message',
							data: {
								message: t('cart:cartItemsExceedLimit'),
								status: 'warning',
							},
						}),
					);
				} else if (
					Object.values(bicycleResponse.data.errors).includes('NOT_FOUND') ||
					Object.values(productAdditionalResponse.data.errors).includes('NOT_FOUND')
				) {
					dispatch(
						openModal({
							category: 'toast',
							type: 'message',
							data: {
								message: t('cart:cartItemsNotFound'),
								status: 'warning',
							},
						}),
					);
				}
			}

			throw new Error(`${bicycleResponse.message}, ${productAdditionalResponse.message}`);
		} catch (error) {
			return Promise.reject(error);
		}
	},
);

/**
 * 計算購物車中各商品的數量（用商品的 stock ID 轉成以 product ID 為 key 的 Hash Table）
 */
const calcCartProductAmount = createAction<
	(_: Dispatch, getState: GetState) => State['cart']['calcByProduct']
>(
	'CALCULATE_RENTAL_RESERVATION_CART_AMOUNT_BY_PRODUCT',
	() => (_: Dispatch, getState: GetState) => {
		const {
			bicycle: {
				stocks: {
					normalized: { stocks: bicycleStockData }, // 車輛庫存資料（以 stock ID 為 key）
				},
			},
			product: {
				dataById: { stocks: productStockData }, // 加價購商品庫存資料（以 stock ID 為 key）
			},
			rentalReservation: {
				cart: {
					data: { bicycles, productAdditionals }, // 消費者購物車裡的車輛和加價購商品
				},
			},
		} = getState();

		const bicycleResult = Object.keys(bicycles).reduce<{ [id: number]: number }>((r, a) => {
			const stockId = parseInt(a, 10);
			const { productId } = bicycleStockData[stockId];
			// eslint-disable-next-line no-param-reassign
			r[productId] = (r[productId] || 0) + bicycles[stockId];
			return r;
		}, {});

		const productResult = Object.keys(productAdditionals).reduce<{ [id: number]: number }>(
			(r, a) => {
				const stockId = parseInt(a, 10);
				const { productId } = productStockData[stockId];
				// eslint-disable-next-line no-param-reassign
				r[productId] = (r[productId] || 0) + productAdditionals[stockId];
				return r;
			},
			{},
		);

		return {
			bicycles: bicycleResult,
			productAdditionals: productResult,
		};
	},
);

/**
 * 每當「更新購物車中租賃車輛的數量」及「驗證購物車的商品數量是否符合庫存」完成之後，計算商品總數量。
 * @param {ActionsObservable<AnyAction>} action$
 */
export const calcCartAmountByProductEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType('UPDATE_RENTAL_RESERVATION_CART_ITEM', 'VALIDATE_RENTAL_CART_STOCKS_FULFILLED'),
		map(() => calcCartProductAmount()),
	);

/**
 * 從租車流程前台送出訂單
 */
const submitRentalOrder = createAction<
	(_: Dispatch, getState: GetState) => Promise<RentalOrderResponse>
>('SUBMIT_RENTAL_ORDER', () => async (dispatch: Dispatch, getState: GetState) => {
	/** map 發票資訊 */
	dispatch(getInvoiceMappingValue());
	/** map 付款方式 */
	dispatch(getPaymentMappingValue());

	const {
		bicycle: {
			stocks: {
				normalized: { stocks: bStocks, products: bProducts },
			},
		},
		product: {
			dataById: { stocks: pStocks, products: pProducts },
		},
		rentalReservation: {
			cart: { data: cart },
			storesInfo: { startStore, destinationStore, startDate, startTime, returnDate, returnTime },
			infoForm: {
				firstName,
				lastName,
				gender,
				nationality,
				id: identityNo,
				height,
				mobile,
				email,
				emergencyName,
				emergencyRelation,
				emergencyMobile,
				remarks,
			},
		},
		payment: {
			mappingValue: paymentData,
			payMethod: {
				value: { value: payMethod },
			},
		},
		invoice: { mappingValue: invoiceData },
	} = getState();

	/** 計算訂單總金額 */
	const sum =
		Object.entries(cart.bicycles)
			.map(([id, amount]) => {
				const stockId = parseInt(id, 10);
				const { productId } = bStocks[stockId];
				return bProducts[productId].price * amount;
			})
			.reduce((a, c) => a + c, 0) +
		Object.entries(cart.productAdditionals)
			.map(([id, amount]) => {
				const stockId = parseInt(id, 10);
				const { productId } = pStocks[stockId];
				return pProducts[productId].price * amount;
			})
			.reduce((a, c) => a + c, 0);

	try {
		const { data, status, message } = await submitRentalOrderFunc({
			start_store_id: startStore?.id as number,
			end_store_id: destinationStore?.id as number,
			start_at: moment(`${startDate} ${startTime}`, 'YYYY/MM/DD HH:mm:ss').format(
				'YYYY-MM-DD HH:mm:ss',
			),
			end_at: moment(`${returnDate} ${returnTime}`, 'YYYY/MM/DD HH:mm:ss').format(
				'YYYY-MM-DD HH:mm:ss',
			),
			total_price: sum,
			bicycle_specs: Object.entries(cart.bicycles).map(([id, amount]) => ({
				id: parseInt(id, 10),
				amount,
			})),
			products: Object.entries(cart.productAdditionals).map(([id, amount]) => ({
				id: parseInt(id, 10),
				amount,
			})),
			first_name: firstName.value,
			last_name: lastName.value,
			nationality: nullToUndefined(nationality.value.value),

			// GIANT GID 2.0 改版，修改 gender 傳入值 M|F => 1|0 故作此更動
			gender: gender.value.value === '1' ? 'M' : 'F',
			identity_no: identityNo.value,
			height: parseInt(height.value, 10),
			phone: `${mobile.value.intIdNum} ${mobile.value.phoneNum}`,
			email: email.value,
			contact_name: emergencyName.value,
			contact_relationship: emergencyRelation.value.value as RelationshipCodes,
			contact_phone: `${emergencyMobile.value.intIdNum} ${emergencyMobile.value.phoneNum}`,
			customer_note: remarks.value,

			...paymentData,
			...invoiceData,
		});

		// let safariWindow: Window | null = null;

		// if (status === 200) {
		// 	if (payMethod === 'atm') safariWindow = createPaymentWindow();
		// 	if (data.view) openPaymentView(safariWindow, data.view); // 跳出付款視窗

		// 	return Promise.resolve(data);
		// }

		// 此處都改為原地跳轉 issue ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/1791
		if (status === 200) {
			if (data.view) {
				openPaymentView(window, data.view);
			}

			return Promise.resolve({ data });
		}

		if (message === 'VALIDATE_STOCK_ERROR') {
			/** 重新驗證庫存 */
			await dispatch(validateCartStocks());
		} else if (
			[
				'The given data was invalid.',
				'store not found',
				'invalid start_at / end_at value',
				'START_AT_STORE_CLOSE_DATES_ERROR',
				'END_AT_STORE_CLOSE_DATES_ERROR',
				'START_AT_STORE_REGULAR_OFF_DAY_ERROR',
				'END_AT_STORE_REGULAR_OFF_DAY_ERROR',
				'START_AT_EARLIER_THAN_OPEN_TIME_ERROR',
				'END_AT_EARLIER_THAN_OPEN_TIME_ERROR',
				'START_AT_LATER_THAN_CLOSE_TIME_ERROR',
				'END_AT_LATER_THAN_CLOSE_TIME_ERROR',
				'START_AT_LATER_THAN_LATEST_RENT_TIME_ERROR',
				'PRICE_ERROR',
			].includes(message)
		) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: createErrorMsgKey(message),
						status: 'warning',
					},
					i18n: ERROR_MESSAGE_I18N_NAMESPACE,
				}),
			);
		} else {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: JSON.stringify(message),
						status: 'warning',
					},
				}),
			);
		}

		throw new Error(message);
	} catch (error) {
		return Promise.reject(error);
	}
});

/**
 * Backstage 建立金額調整並審核通過後，消費者可由 Email 內的連結進入前台畫面並點擊前往付款。
 * route： "/order/:orderType/:selector/transaction/:id"
 */
const submitRentalOrderTrnsaction = createAction<
	(_: Dispatch, getState: GetState) => Promise<RentalOrderTransactionResponse>,
	string,
	number
>(
	'SUBMIT_RENTAL_ORDER_TRANSACTION',
	(selector, id) => async (dispatch: Dispatch, getState: GetState) => {
		/** map 發票資訊 */
		dispatch(getInvoiceMappingValue());
		/** map 付款方式 */
		dispatch(getPaymentMappingValue());

		const {
			payment: {
				mappingValue: paymentData,
				payMethod: {
					value: { value: payMethod },
				},
			},
			invoice: { mappingValue: invoiceData },
		} = getState();

		try {
			const { status, message, data } = await submitRentalOrderTransactionFunc(selector, id, {
				...paymentData,
				...invoiceData,
			});

			let safariWindow: Window | null = null;

			if (status === 200) {
				if (payMethod === 'atm') safariWindow = createPaymentWindow();
				if (data.view) openPaymentView(safariWindow, data.view);

				return Promise.resolve(data);
			}
			throw new Error(message);
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: (error as Error).message,
						status: 'warning',
					},
				}),
			);
			return Promise.reject((error as Error).message);
		}
	},
);

/**
 * 消費者如於初次下單後，欲新增租賃車輛或者加價購，可自行通知相關管理人員於 Backstage 協助新增。
 * route： "/admin/order/rental/:selector/add-purchase"
 */
const submitAdminRentalOrder = createAction<
	(_: Dispatch, getState: GetState) => Promise<{ success: boolean }>,
	string
>('SUBMIT_ADMIN_RENTAL_ORDER', selector => async (dispatch: Dispatch, getState: GetState) => {
	const {
		bicycle: {
			stocks: {
				normalized: { stocks: bStocks, products: bProducts },
			},
		},
		product: {
			dataById: { stocks: pStocks, products: pProducts },
		},
		rentalReservation: {
			cart: { data: cart },
		},
	} = getState();

	const sum =
		Object.entries(cart.bicycles)
			.map(([id, amount]) => {
				const stockId = parseInt(id, 10);
				const { productId } = bStocks[stockId];
				return bProducts[productId].price * amount;
			})
			.reduce((a, c) => a + c, 0) +
		Object.entries(cart.productAdditionals)
			.map(([id, amount]) => {
				const stockId = parseInt(id, 10);
				const { productId } = pStocks[stockId];
				return pProducts[productId].price * amount;
			})
			.reduce((a, c) => a + c, 0);

	try {
		const { status, message } = await submitAdminRentalOrderFunc(selector, {
			total_price: sum,
			bicycle_specs: Object.entries(cart.bicycles).map(([id, amount]) => ({
				id: parseInt(id, 10),
				amount,
			})),
			products: Object.entries(cart.productAdditionals).map(([id, amount]) => ({
				id: parseInt(id, 10),
				amount,
			})),
		});

		if (status === 200) {
			return Promise.resolve({ success: true });
		}

		// fetch cart stocks to update info & show errors
		if (message === 'VALIDATE_STOCK_ERROR') {
			await dispatch(validateCartStocks());
		} else if (message === 'PRICE_ERROR') {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message,
						status: 'warning',
					},
					i18n: ERROR_MESSAGE_I18N_NAMESPACE,
				}),
			);
		} else {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: JSON.stringify(message),
						status: 'warning',
					},
				}),
			);
		}

		throw new Error(message);
	} catch (error) {
		return Promise.reject(error);
	}
});

export const reducer = {
	rentalReservation: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_RENTAL_RESERVATION: () => ({
				...initialState,
			}),

			SET_RENTAL_RESERVATION_READY: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					isReady: true,
				},
			}),

			SET_RENTAL_RESERVATION_STEP: (state, action: Action<State['formStatus']['step']>) => ({
				...state,
				formStatus: {
					...state.formStatus,
					step: action.payload,
				},
			}),

			SET_RENTAL_RESERVATION_NEXT_STEP: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					step:
						state.formStatus.step <= 2
							? ((state.formStatus.step + 1) as State['formStatus']['step'])
							: state.formStatus.step,
				},
			}),

			SET_RENTAL_RESERVATION_PREV_STEP: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					step:
						state.formStatus.step >= 1
							? ((state.formStatus.step - 1) as State['formStatus']['step'])
							: state.formStatus.step,
				},
			}),

			CHANGE_RENTAL_RESERVATION_FORM: (state, action: Action<FormChangePayload>) => ({
				...state,
				infoForm: {
					...state.infoForm,
					[action.payload.key]: {
						...state.infoForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CHANGE_RENTAL_RESERVATION_STORES_INFO: (
				state,
				action: Action<Partial<State['storesInfo']>>,
			) => ({
				...state,

				storesInfo: {
					...state.storesInfo,
					...action.payload,
				},
			}),

			RESET_RENTAL_RESERVATION_CART: state => ({
				...state,
				cart: initialState.cart,
			}),

			UPDATE_RENTAL_RESERVATION_CART_ITEM: (state, action: Action<UpdateCartPayload>) => ({
				...state,
				cart: {
					...state.cart,
					data: {
						...state.cart.data,
						[action.payload.type]: action.payload.data,
					},
				},
			}),

			VALIDATE_RENTAL_CART_STOCKS_PENDING: state => ({
				...state,

				cart: {
					...state.cart,
					isValidating: true,
				},
			}),

			VALIDATE_RENTAL_CART_STOCKS_FULFILLED: state => ({
				...state,

				cart: {
					...state.cart,
					isValidating: false,
				},
			}),

			VALIDATE_RENTAL_CART_STOCKS_REJECTED: state => ({
				...state,

				cart: {
					...state.cart,
					isValidating: false,
				},
			}),

			CALCULATE_RENTAL_RESERVATION_CART_AMOUNT_BY_PRODUCT: (
				state,
				action: Action<State['cart']['calcByProduct']>,
			) => ({
				...state,
				cart: {
					...state.cart,
					calcByProduct: action.payload,
				},
			}),

			SUBMIT_RENTAL_ORDER_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_RENTAL_ORDER_FULFILLED: (state, action: Action<RentalOrderResponse>) => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
					selector: action.payload.selector || '',
					view: action.payload.view || '',
				},
			}),

			SUBMIT_RENTAL_ORDER_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),

			SUBMIT_RENTAL_ORDER_TRANSACTION_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_RENTAL_ORDER_TRANSACTION_FULFILLED: (
				state,
				action: Action<RentalOrderTransactionResponse>,
			) => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
					view: action.payload.view || '',
				},
			}),

			SUBMIT_RENTAL_ORDER_TRANSACTION_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),

			SUBMIT_ADMIN_RENTAL_ORDER_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_ADMIN_RENTAL_ORDER_FULFILLED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),

			SUBMIT_ADMIN_RENTAL_ORDER_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useRentalReservationFormStatus ++
++----------------------------------------------------------------------*/
const selectFormStatus = (state: GlobalState) => state.rentalReservation.formStatus;

const formStatusActionsMap = {
	clearRentalReservation,
	setReady: setRentalReservationReady,
	setStep,
	goNextStep,
	goPrevStep,
	submitRentalOrder,
	submitRentalOrderTrnsaction,
	submitAdminRentalOrder,
};

export const useRentalReservationFormStatus = () =>
	useRedux<ReturnType<typeof selectFormStatus>, typeof formStatusActionsMap>(
		selectFormStatus,
		formStatusActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useRentalReservationForm ++
++----------------------------------------------------------------------*/
const selectInfoForm = (state: GlobalState) => state.rentalReservation.infoForm;

const infoFormActionsMap = {
	changeRentalReservationForm,
	updateRentalReservationFormByAuthUserProfile,
};

export const useRentalReservationForm = () =>
	useRedux<ReturnType<typeof selectInfoForm>, typeof infoFormActionsMap>(
		selectInfoForm,
		infoFormActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useRentalReservationStoresInfo ++
++----------------------------------------------------------------------*/
const selectStoresInfo = (state: GlobalState) => state.rentalReservation.storesInfo;
const storesInfoActionsMap = { changeStoresInfo };

export const useRentalReservationStoresInfo = () =>
	useRedux<ReturnType<typeof selectStoresInfo>, typeof storesInfoActionsMap>(
		selectStoresInfo,
		storesInfoActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useRentalReservationCart ++
++----------------------------------------------------------------------*/
const selectCart = (state: GlobalState) => ({
	isCartValidating: state.rentalReservation.cart.isValidating,
	cart: state.rentalReservation.cart.data,
	productSelectedAmount: state.rentalReservation.cart.calcByProduct,
});

const cartActionsMap = {
	resetCart,
	addCartItem,
	adjustCartItemAmount,
	validateCartStocks,
};

export const useRentalReservationCart = () =>
	useRedux<ReturnType<typeof selectCart>, typeof cartActionsMap>(selectCart, cartActionsMap);
