import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.adminBicycleRepair.replace(appPath.admin, ''),
	components: () => [import(/* webpackChunkName: 'adminBicycleRepair' */ './component')],
	render: ([AdminBicycleRepair]) => <AdminBicycleRepair />,
};

export default routes;
