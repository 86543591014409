import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

type PageWrapperProps = {
	className?: string;
	withHeader?: boolean;
	withSubnav?: boolean;
	withMinViewHeight?: boolean;
	children: React.ReactNode;
};

const PageWrapper = ({
	className,
	withHeader = true,
	withSubnav = true,
	withMinViewHeight = true,
	children,
}: PageWrapperProps) => (
	<div
		className={classnames(
			styles.pageWrapper,
			withHeader && styles.withHeader,
			withSubnav && styles.withSubnav,
			withMinViewHeight && styles.minViewHeight,
			className,
		)}
	>
		{children}
	</div>
);

export default PageWrapper;

type SectionWrapperProps = {
	className?: string;
	wrapperClassName?: string;
	withOverflow?: boolean;
	withoutMargin?: boolean;
	children: React.ReactNode;
};

export const SectionWrapper = ({
	className,
	wrapperClassName,
	withOverflow = false,
	withoutMargin = false,
	children,
}: SectionWrapperProps) => (
	<section
		className={classnames(
			styles.sectionWrapper,
			withOverflow && styles.overflow,
			withoutMargin && styles.withoutMargin,
			className,
		)}
	>
		<div className={classnames(styles.wrapper, wrapperClassName)}>{children}</div>
	</section>
);
