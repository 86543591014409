import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { compile } from 'path-to-regexp';

import { FileType } from 'types/FileType';
import { SelectValue } from 'types/SelectValue';
import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';

import { useRedux } from 'util/hook/redux';
import { appPath } from 'util/routingConfig';
import { wrapRetryAdminFetch } from 'util/api';
import { ADMIN_BICYCLE_ERROR_I18N } from 'util/const';

import { pushRoute } from 'models/routing';
import { openModal } from 'models/modal';

import { BicycleInventoryPosition, StockBicycleDestination } from 'api/stockBicycle';

import { State as GlobalState, GetState } from './reducers';

const Field = {
	store: 'store', // 門市
	orderNumber: 'orderNumber', // 訂單編號
	carId: 'carId', // 車架號碼
	carType: 'carType', // item (車輛序號 + 尺寸)
	cars: 'cars', // 其他車輛
	pickupDate: 'pickupDate', // 取車日期
	returnDate: 'returnDate', // 取車日期
	uploadPhotos: 'uploadPhotos', // 照片上傳
	otherSituation: 'otherSituation', // 其他狀況
	remarks: 'remarks', // 備註
	locationType: 'locationType', // 位置類型
	location: 'location', // 位置
	repairTime: 'repairTime', // 修復日期
	repairer: 'repairer', // 修復人
	receiveTime: 'receiveTime', // 收件日期
	receiver: 'receiver', // 收件人
	maintainTime: 'maintainTime', // 保養日期
	maintainer: 'maintainer', // 保養人
	replyTime: 'replyTime', // 回復時間
	replier: 'replier', // 回復人
	deliverStartFrom: 'deliverStartFrom', // 起點
	deliverDestination: 'deliverDestination', // 終點
	deliverDate: 'deliverDate', // 配送日期
	filler: 'filler', // 填寫者
	inventoryPosition: 'inventoryPosition', // 盤點位置
} as const;

export type AdminBicycleFormField = {
	// 取車檢查表
	pickUp:
		| typeof Field.orderNumber
		| typeof Field.cars
		| typeof Field.uploadPhotos
		| typeof Field.remarks;
	// 車輛盤點
	inventory: 
		| typeof Field.inventoryPosition
	// 還車檢查表
	return:
		| typeof Field.cars
		| typeof Field.uploadPhotos
		| typeof Field.otherSituation
		| typeof Field.remarks;
	// 遺失表
	lost: typeof Field.remarks;
	// 異動單
	broken: typeof Field.remarks;
	// 門市/物流修復單
	repair: typeof Field.uploadPhotos | typeof Field.remarks;
	// 門市/物流收車單
	receive: typeof Field.uploadPhotos | typeof Field.cars | typeof Field.remarks;
	// 門市/物流保養單
	maintain: typeof Field.uploadPhotos | typeof Field.cars | typeof Field.remarks;
	// 遺失回復表
	found:
		| typeof Field.locationType
		| typeof Field.location
		| typeof Field.uploadPhotos
		| typeof Field.remarks;
	// 門市/物流配送單
	deliver: typeof Field.deliverDestination | typeof Field.cars | typeof Field.remarks;
	// 車輛保留單
	reserve: typeof Field.cars | typeof Field.remarks;
	//	保留回復單
	toNormal: typeof Field.cars | typeof Field.remarks;
};

/**
 * 掃描（其他）車輛的 ID 介面
 * 因為表單顯示車輛 ID 為 bicycle_number ；打 `bicycle/${bicycleId}/pickUp` API 則帶 id 。因此先將兩筆資料都要存。
 * issue ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/477#note_902564
 */
interface CarsIds {
	id: string;
	bicycle_number: string;
}

export interface FormDataType {
	orderNumber: SelectValue<number | null>; // 訂單編號
	cars: SelectValue<CarsIds>[]; // 其他車輛
	uploadPhotos: FileType[]; // 照片上傳
	otherSituation: SelectValue<'' | 'lost' | 'broken' | null>; // 其他狀況
	remarks: string; // 備註
	locationType: SelectValue<string | null>; // 位置類型
	location: SelectValue<number | null>; // 位置
	deliverDestination: { label: string; value: null; data: StockBicycleDestination | null }; // 終點
	inventoryPosition: { label: string; value: number; data: BicycleInventoryPosition | null }; // 盤點位置
}

type PickUpFormType = FormDataMapping<
	Pick<FormDataType, 'orderNumber' | 'cars' | 'uploadPhotos' | 'remarks'>
>;
type InventoryFormType = FormDataMapping<Pick<FormDataType, 'inventoryPosition'>>;
type ReturnFormType = FormDataMapping<
	Pick<FormDataType, 'cars' | 'uploadPhotos' | 'otherSituation' | 'remarks'>
>;
type LostFormType = FormDataMapping<Pick<FormDataType, 'remarks'>>;
type BrokenFormType = FormDataMapping<Pick<FormDataType, 'remarks'>>;
type RepairFormType = FormDataMapping<Pick<FormDataType, 'uploadPhotos' | 'remarks'>>;
type ReceiveFormType = FormDataMapping<Pick<FormDataType, 'cars' | 'uploadPhotos' | 'remarks'>>;
type MaintainFormType = FormDataMapping<Pick<FormDataType, 'cars' | 'uploadPhotos' | 'remarks'>>;
type FoundFormType = FormDataMapping<
	Pick<FormDataType, 'locationType' | 'location' | 'uploadPhotos' | 'remarks'>
>;
type DeliverFormType = FormDataMapping<
	Pick<FormDataType, 'deliverDestination' | 'cars' | 'remarks'>
>;
type ReserveFormType = FormDataMapping<Pick<FormDataType, 'cars' | 'remarks'>>;
type ToNormalFormType = FormDataMapping<Pick<FormDataType, 'cars' | 'remarks'>>;

export type State = {
	loading: boolean;
	forms: {
		pickUp: PickUpFormType;
		inventory: InventoryFormType;
		return: ReturnFormType;
		lost: LostFormType;
		broken: BrokenFormType;
		repair: RepairFormType;
		receive: ReceiveFormType;
		maintain: MaintainFormType;
		found: FoundFormType;
		deliver: DeliverFormType;
		reserve: ReserveFormType;
		toNormal: ToNormalFormType;
	};
};

export type AdminBicycleForm = keyof State['forms'];

const initialState: State = {
	loading: false,
	forms: {
		pickUp: {
			orderNumber: { value: { label: '', value: null }, valid: true, error: '', required: true },
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			uploadPhotos: { value: [], valid: true, error: '', required: false },
			remarks: { value: '', valid: true, error: '', required: false },
		},
		inventory: {
			inventoryPosition: {
				value: { label: '', value: 0, data: null },
				valid: true,
				error: '',
				required: true,
			},
		},
		return: {
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			uploadPhotos: { value: [], valid: true, error: '', required: false },
			otherSituation: {
				value: { label: '', value: null },
				valid: true,
				error: '',
				required: false,
			},
			remarks: { value: '', valid: true, error: '', required: false },
		},
		lost: {
			remarks: { value: '', valid: true, error: '', required: false },
		},
		broken: {
			remarks: { value: '', valid: true, error: '', required: false },
		},
		repair: {
			uploadPhotos: { value: [], valid: true, error: '', required: false },
			remarks: { value: '', valid: true, error: '', required: false },
		},
		receive: {
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			uploadPhotos: { value: [], valid: true, error: '', required: false },
			remarks: { value: '', valid: true, error: '', required: false },
		},
		maintain: {
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			uploadPhotos: { value: [], valid: true, error: '', required: false },
			remarks: { value: '', valid: true, error: '', required: false },
		},
		found: {
			locationType: { value: { label: '', value: null }, valid: true, error: '', required: true },
			location: { value: { label: '', value: null }, valid: true, error: '', required: true },
			uploadPhotos: { value: [], valid: true, error: '', required: false },
			remarks: { value: '', valid: true, error: '', required: false },
		},
		deliver: {
			deliverDestination: {
				value: { label: '', value: null, data: null },
				valid: true,
				error: '',
				required: true,
			},
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			remarks: { value: '', valid: true, error: '', required: false },
		},
		reserve: {
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			remarks: { value: '', valid: true, error: '', required: false },
		},
		toNormal: {
			cars: {
				value: [],
				valid: true,
				error: '',
				required: false,
			},
			remarks: { value: '', valid: true, error: '', required: false },
		},
	},
};

type FormChangePayload<T extends AdminBicycleForm> = {
	type: T;
	key: AdminBicycleFormField[T];
	data: Partial<InputFiled>;
};

const changeForm = createAction(
	'CHANGE_ADMIN_BICYCLE_FORM',
	<T extends AdminBicycleForm>({ type, key, data }: FormChangePayload<T>) => ({
		type,
		key,
		data,
	}),
);

const resetForm = createAction<AdminBicycleForm, AdminBicycleForm>(
	'RESET_ADMIN_BICYCLE_FORM',
	type => type,
);

/** 取車檢查 */
const submitAdminBicyclePickup = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number, screenshot: Blob) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					pickUp: { orderNumber, uploadPhotos, cars, remarks },
				},
			},
		} = getState();

		const formdata = new FormData();
		formdata.append('order_id', `${orderNumber.value.value}`);
		uploadPhotos.value.forEach(({ file }: FileType) => {
			formdata.append('images[]', file);
		});
		formdata.append('note', remarks.value);
		cars.value.forEach(d => {
			formdata.append('other_bicycle_ids[]', `${d.value.id}`);
		});
		formdata.append('screen_shot', screenshot);

		const { status, error_code = '' } = await wrapRetryAdminFetch(
			`bicycle/${bicycleId}/pickUp`,
			{
				method: 'POST',
				body: formdata,
			},
			{},
			{ isFormData: true },
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 還車檢查 */
const submitAdminBicycleReturn = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number, screenshot: Blob) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					return: { uploadPhotos, cars, remarks, otherSituation },
				},
			},
		} = getState();

		const formdata = new FormData();
		formdata.append('condition', `${otherSituation.value.value}`);
		uploadPhotos.value.forEach(({ file }: FileType) => {
			formdata.append('images[]', file);
		});
		formdata.append('note', remarks.value);
		cars.value.forEach(d => {
			formdata.append('other_bicycle_ids[]', `${d.value.id}`);
		});
		formdata.append('screen_shot', screenshot);

		const { status, error_code = '' } = await wrapRetryAdminFetch(
			`bicycle/${bicycleId}/return`,
			{
				method: 'POST',
				body: formdata,
			},
			{},
			{ isFormData: true },
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出遺失單 */
const submitAdminBicycleLost = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					lost: { remarks },
				},
			},
		} = getState();

		const { status, error_code = '' } = await wrapRetryAdminFetch(`bicycle/${bicycleId}/lost`, {
			method: 'POST',
			body: JSON.stringify({
				note: remarks.value,
			}),
		});

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出異動單 */
const submitAdminBicycleBroken = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					broken: { remarks },
				},
			},
		} = getState();

		const { status, error_code = '' } = await wrapRetryAdminFetch(`bicycle/${bicycleId}/broken`, {
			method: 'POST',
			body: JSON.stringify({
				note: remarks.value,
			}),
		});

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出門市 / 物流修復單 */
const submitAdminBicycleRepair = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					repair: { remarks, uploadPhotos },
				},
			},
		} = getState();

		const formdata = new FormData();
		uploadPhotos.value.forEach(({ file }: FileType) => {
			formdata.append('images[]', file);
		});
		formdata.append('note', remarks.value);

		const { status, error_code = '' } = await wrapRetryAdminFetch(
			`bicycle/${bicycleId}/repair`,
			{
				method: 'POST',
				body: formdata,
			},
			{},
			{ isFormData: true },
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出門市 / 物流收車單 */
const submitAdminBicycleReceive = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					receive: { cars, remarks, uploadPhotos },
				},
			},
		} = getState();

		const formdata = new FormData();
		uploadPhotos.value.forEach(({ file }: FileType) => {
			formdata.append('images[]', file);
		});
		formdata.append('note', remarks.value);
		cars.value.forEach(d => {
			formdata.append('other_bicycle_ids[]', `${d.value.id}`);
		});

		const { status, error_code = '' } = await wrapRetryAdminFetch(
			`bicycle/${bicycleId}/receive`,
			{
				method: 'POST',
				body: formdata,
			},
			{},
			{ isFormData: true },
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出門市 / 物流保養單 */
const submitAdminBicycleMaintain = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					maintain: { cars, remarks, uploadPhotos },
				},
			},
		} = getState();

		const formdata = new FormData();
		uploadPhotos.value.forEach(({ file }: FileType) => {
			formdata.append('images[]', file);
		});
		formdata.append('note', remarks.value);
		cars.value.forEach(d => {
			formdata.append('other_bicycle_ids[]', `${d.value.id}`);
		});

		const { status, error_code = '' } = await wrapRetryAdminFetch(
			`bicycle/${bicycleId}/maintain`,
			{
				method: 'POST',
				body: formdata,
			},
			{},
			{ isFormData: true },
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出遺失回復單 */
const submitAdminBicycleFound = createAction(
	'SUBMIT_ADMIN_BICYCLE_FOUND',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					found: { locationType, location, remarks, uploadPhotos },
				},
			},
		} = getState();

		const formdata = new FormData();
		uploadPhotos.value.forEach(({ file }: FileType) => {
			formdata.append('images[]', file);
		});
		formdata.append('note', remarks.value);
		formdata.append('position_id', `${location.value.value}`);
		formdata.append('position_type', `${locationType.value.value}`);

		const { status, error_code = '' } = await wrapRetryAdminFetch(
			`bicycle/${bicycleId}/found`,
			{
				method: 'POST',
				body: formdata,
			},
			{},
			{ isFormData: true },
		);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出門市 / 物流配送單 */
const submitAdminBicycleDeliver = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					deliver: { cars, deliverDestination, remarks },
				},
			},
		} = getState();

		const { status, error_code = '' } = await wrapRetryAdminFetch(`bicycle/${bicycleId}/deliver`, {
			method: 'POST',
			body: JSON.stringify({
				other_bicycle_ids: cars.value.map(d => d.value.id),
				note: remarks.value,
				to_position_type: deliverDestination.value.data?.to_position_type,
				to_position_id: deliverDestination.value.data?.to_position_id,
				logistics_date: deliverDestination.value.data?.logistics_date,
			}),
		});

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出車輛保留單 */
const submitAdminBicycleReserve = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					reserve: { cars, remarks },
				},
			},
		} = getState();

		const { status, error_code = '' } = await wrapRetryAdminFetch(`bicycle/${bicycleId}/reserve`, {
			method: 'POST',
			body: JSON.stringify({
				other_bicycle_ids: cars.value.map(d => d.value.id),
				note: remarks.value,
			}),
		});

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

/** 送出車輛保留回復單 */
const submitAdminBicycleToNormal = createAction(
	'SUBMIT_ADMIN_BICYCLE_FORM',
	(bicycleId: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			adminStockBicycle: {
				info: { data: bicycleData },
			},
			adminBicycleForms: {
				forms: {
					toNormal: { cars, remarks },
				},
			},
		} = getState();

		const { status, error_code = '' } = await wrapRetryAdminFetch(`bicycle/${bicycleId}/toNormal`, {
			method: 'POST',
			body: JSON.stringify({
				other_bicycle_ids: cars.value.map(d => d.value.id),
				note: remarks.value,
			}),
		});

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ADMIN_BICYCLE_ERROR_I18N,
					data: { message: error_code, status: 'warning' },
				}),
			);
			throw new Error(error_code);
		}

		dispatch(
			pushRoute({
				pathname: compile(appPath.adminBicycleRedirect, { encode: encodeURIComponent })({
					id: bicycleData?.bicycle_number,
				}),
			}),
		);
	},
);

export const reducer = {
	adminBicycleForms: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			RESET_ADMIN_BICYCLE_FORM: (state, action: Action<AdminBicycleForm>) => ({
				...state,
				loading: false,
				forms: { ...state.forms, [action.payload]: initialState.forms[action.payload] },
			}),

			CHANGE_ADMIN_BICYCLE_FORM: <T extends AdminBicycleForm>(
				state: {
					loading: boolean;
					forms: {
						[K in T]: {
							[R in AdminBicycleFormField[K]]: InputFiled;
						};
					};
				},
				action: Action<FormChangePayload<T>>,
			) => ({
				...state,

				forms: {
					...state.forms,
					[action.payload.type]: {
						...state.forms[action.payload.type],
						[action.payload.key]: {
							...state.forms[action.payload.type][action.payload.key],
							...action.payload.data,
						},
					},
				},
			}),

			SUBMIT_ADMIN_BICYCLE_FORM_PENDING: state => ({
				...state,
				loading: true,
			}),

			SUBMIT_ADMIN_BICYCLE_FORM_FULFILLED: state => ({
				...state,
				loading: false,
			}),

			SUBMIT_ADMIN_BICYCLE_FORM_REJECTED: state => ({
				...state,
				loading: false,
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAdminBicyclePickup ++
++----------------------------------------------------------------------*/
const selectPickup = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.pickUp,
});

const pickupActionsMap = {
	resetForm: () => resetForm('pickUp'),
	changeForm: (options: Omit<FormChangePayload<'pickUp'>, 'type'>) =>
		changeForm<'pickUp'>({ ...options, type: 'pickUp' }),
	submitForm: submitAdminBicyclePickup,
};

export const useAdminBicyclePickup = () =>
	useRedux<ReturnType<typeof selectPickup>, typeof pickupActionsMap>(
		selectPickup,
		pickupActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleReturn ++
++----------------------------------------------------------------------*/
const selectReturn = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.return,
});

const returnActionsMap = {
	resetForm: () => resetForm('return'),
	changeForm: (options: Omit<FormChangePayload<'return'>, 'type'>) =>
		changeForm<'return'>({ ...options, type: 'return' }),
	submitForm: submitAdminBicycleReturn,
};

export const useAdminBicycleReturn = () =>
	useRedux<ReturnType<typeof selectReturn>, typeof returnActionsMap>(
		selectReturn,
		returnActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleLost ++
++----------------------------------------------------------------------*/
const selectLost = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.lost,
});

const lostActionsMap = {
	resetForm: () => resetForm('lost'),
	changeForm: (options: Omit<FormChangePayload<'lost'>, 'type'>) =>
		changeForm<'lost'>({ ...options, type: 'lost' }),
	submitForm: submitAdminBicycleLost,
};

export const useAdminBicycleLost = () =>
	useRedux<ReturnType<typeof selectLost>, typeof lostActionsMap>(selectLost, lostActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminBicycleBroken ++
++----------------------------------------------------------------------*/
const selectBroken = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.broken,
});

const brokenActionsMap = {
	resetForm: () => resetForm('broken'),
	changeForm: (options: Omit<FormChangePayload<'broken'>, 'type'>) =>
		changeForm<'broken'>({ ...options, type: 'broken' }),
	submitForm: submitAdminBicycleBroken,
};

export const useAdminBicycleBroken = () =>
	useRedux<ReturnType<typeof selectBroken>, typeof brokenActionsMap>(
		selectBroken,
		brokenActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleRepair ++
++----------------------------------------------------------------------*/
const selectRepair = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.repair,
});

const repairActionsMap = {
	resetForm: () => resetForm('repair'),
	changeForm: (options: Omit<FormChangePayload<'repair'>, 'type'>) =>
		changeForm<'repair'>({ ...options, type: 'repair' }),
	submitForm: submitAdminBicycleRepair,
};

export const useAdminBicycleRepair = () =>
	useRedux<ReturnType<typeof selectRepair>, typeof repairActionsMap>(
		selectRepair,
		repairActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleReceive ++
++----------------------------------------------------------------------*/
const selectReceive = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.receive,
});

const receiveActionsMap = {
	resetForm: () => resetForm('receive'),
	changeForm: (options: Omit<FormChangePayload<'receive'>, 'type'>) =>
		changeForm<'receive'>({ ...options, type: 'receive' }),
	submitForm: submitAdminBicycleReceive,
};

export const useAdminBicycleReceive = () =>
	useRedux<ReturnType<typeof selectReceive>, typeof receiveActionsMap>(
		selectReceive,
		receiveActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleMaintain ++
++----------------------------------------------------------------------*/
const selectMaintain = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.maintain,
});

const maintainActionsMap = {
	resetForm: () => resetForm('maintain'),
	changeForm: (options: Omit<FormChangePayload<'maintain'>, 'type'>) =>
		changeForm<'maintain'>({ ...options, type: 'maintain' }),
	submitForm: submitAdminBicycleMaintain,
};

export const useAdminBicycleMaintain = () =>
	useRedux<ReturnType<typeof selectMaintain>, typeof maintainActionsMap>(
		selectMaintain,
		maintainActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleFound ++
++----------------------------------------------------------------------*/
const selectFound = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.found,
});

const foundActionsMap = {
	resetForm: () => resetForm('found'),
	changeForm: (options: Omit<FormChangePayload<'found'>, 'type'>) =>
		changeForm<'found'>({ ...options, type: 'found' }),
	submitForm: submitAdminBicycleFound,
};

export const useAdminBicycleFound = () =>
	useRedux<ReturnType<typeof selectFound>, typeof foundActionsMap>(selectFound, foundActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminBicycleDeliver ++
++----------------------------------------------------------------------*/
const selectDeliver = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.deliver,
});

const deliverActionsMap = {
	resetForm: () => resetForm('deliver'),
	changeForm: (options: Omit<FormChangePayload<'deliver'>, 'type'>) =>
		changeForm<'deliver'>({ ...options, type: 'deliver' }),
	submitForm: submitAdminBicycleDeliver,
};

export const useAdminBicycleDeliver = () =>
	useRedux<ReturnType<typeof selectDeliver>, typeof deliverActionsMap>(
		selectDeliver,
		deliverActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleReserve ++
++----------------------------------------------------------------------*/
const selectReserve = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.reserve,
});

const reserveActionsMap = {
	resetForm: () => resetForm('reserve'),
	changeForm: (options: Omit<FormChangePayload<'reserve'>, 'type'>) =>
		changeForm<'reserve'>({ ...options, type: 'reserve' }),
	submitForm: submitAdminBicycleReserve,
};

export const useAdminBicycleReserve = () =>
	useRedux<ReturnType<typeof selectReserve>, typeof reserveActionsMap>(
		selectReserve,
		reserveActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleToNormal ++
++----------------------------------------------------------------------*/
const selectToNormal = (state: GlobalState) => ({
	loading: state.adminBicycleForms.loading,
	form: state.adminBicycleForms.forms.toNormal,
});

const toNormalActionsMap = {
	resetForm: () => resetForm('toNormal'),
	changeForm: (options: Omit<FormChangePayload<'toNormal'>, 'type'>) =>
		changeForm<'toNormal'>({ ...options, type: 'toNormal' }),
	submitForm: submitAdminBicycleToNormal,
};

export const useAdminBicycleToNormal = () =>
	useRedux<ReturnType<typeof selectToNormal>, typeof toNormalActionsMap>(
		selectToNormal,
		toNormalActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useAdminBicycleInventory ++	
++----------------------------------------------------------------------*/

const selectInventory = (state: GlobalState) => ({form: state.adminBicycleForms.forms.inventory,});

const inventoryActionsMap = {
	resetForm: () => resetForm('inventory'),
	changeForm: (options: Omit<FormChangePayload<'inventory'>, 'type'>) =>
		changeForm<'inventory'>({ ...options, type: 'inventory' }),
};

export const useAdminBicycleInventory = () =>
	useRedux<ReturnType<typeof selectInventory>, typeof inventoryActionsMap>(
		selectInventory,
		inventoryActionsMap,
	);
