/* eslint-disable indent */
import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

interface DivisionProperty {
	className?: string;
	vertical?: boolean;
	size?: number;
	color?: 'default' | 'white' | 'blue';
}

const Division: React.FC<DivisionProperty> = ({
	className,
	vertical = false,
	color = 'default',
	size,
}) => (
	<div
		className={classnames(
			styles.division,
			className,
			{ [styles.vertical]: vertical },
			styles[color],
		)}
		style={{
			...(!vertical &&
				size && {
					width: `${size}px`,
				}),
			...(vertical &&
				size && {
					height: `${size}px`,
				}),
		}}
	/>
);

export default Division;
