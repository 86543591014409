import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';
import { pathInTwGlobalWhiteList, pathInTwWhiteList } from 'util/const';

import { replaceRoute } from 'models/routing';

import TravelRoute from './Travel';
import RentalRoute from './Rental';
import ActivityRoute from './Events';
import DeliveryRoute from './Delivery';
import CalendarRoute from './Calendar';
import AccountRoute from './Account';
import OrderRoute from './Order';
import StoriesRoute from './Stories';
import SigninRoute from './Signin';
import QuestionsRoute from './Questions';
import DownloadRoute from './Download';
import AdminRoute from './Admin';
import AboutRoute from './About';
import PrivacyTermsRoute from './PrivacyTerms';
import RelatedTermsRoute from './RelatedTerms';
import AuthCallbackRoute from './AuthCallback';

/* +----------------------------------------------------------------------
全站 route path 結構：分為「全球（/）」和「區域（/:locale）」兩大類。

共同的 routes：
- 我要租車 /rental
- 活動課程 /events
- 我要旅遊 /travel
- 打包運送 /logistics
- 我們的故事 /stories
- 後台管理 /admin
- 關於我們 /about
- 行事曆 /calender
- 隱私權 /privacy-terms
- 相關條款 /related-terms

全球獨有的 routes :
- 訂單內頁 /order
- 會員登入 /signin
- 會員中心 /account
- 轉導頁 /auth-callback

區域獨有的 routes :
- 常見問題 /questions
- 下載專區 /download

全站 route path 設定 ref: src\util\routingConfig.ts
正式機 route path 啟用狀況 ref: https://docs.google.com/spreadsheets/d/1hmpEIO6PEiwRC5oZLh9al3zeoB-jvcMjgorWCKbDNMw/edit#gid=0
++----------------------------------------------------------------------*/

// 全球（/）和地區（/:locale）都有的 routes
const commonRoutes = [
	ActivityRoute,
	TravelRoute,
	DeliveryRoute,
	StoriesRoute,
	AboutRoute,
	AdminRoute,
	CalendarRoute,
	PrivacyTermsRoute,
	RelatedTermsRoute,
];

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		const children = await next();
		console.log('on Enter Root');

		/*
			2022-03-30 首次上 prod，以 `${domain}/TW/rental` 作為首次上線的首頁。
			進到 root '/' 時，除了：
			1. 台灣底下的 routes 白名單（'/TW/rental', 'TW/events', 'TW/logistics','TW/about', 'TW/admin', '/TW/calendar', '/TW/privacy-terms', '/TW/related-terms', '/TW/questions', '/TW/download'）
			2. 全球底下的 routes 白名單（'/auth-callback', '/signin', '/order', /'admin', '/account'）
			其他頁面都必須跳轉到首頁 `${domain}/TW/rental`。
		*/
		if (process.env.PRODUCTION) {
			const {
				routing: { pathname },
			} = store.getState();

			const twRegex = new RegExp('/TW');
			const isPathInTw = twRegex.test(pathname);
			const isPathInTwWhiteList = pathInTwWhiteList.includes(pathname.split('/')[2]);
			const isPathInTwGlobalWhiteList = pathInTwGlobalWhiteList.includes(pathname.split('/')[1]);

			if ((isPathInTw && isPathInTwWhiteList) || (!isPathInTw && isPathInTwGlobalWhiteList)) {
				return children;
			}

			await store.dispatch(
				replaceRoute({
					pathname: appPath.rental,
					withLoc: true,
				}),
			);
		}

		console.log('on Enter Root / end');
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'landingPage' */ '../layouts/LandingPage')],
			render: ([Home]) => <Home />,
			onEnter: async ({ next }) => {
				console.log('on Enter Home');
				const children = await next();
				console.log('on Enter Home / end');
				return children;
			},
		},
		{
			path: appPath.rental,
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [
						import(/* webpackChunkName: 'rentalGlobsl' */ '../layouts/RentalGlobal'),
					],
					render: ([RentalGlobal]) => <RentalGlobal />,
				},
				{
					path: '/:any',
					components: () => [],
					render: (_, children) => children,
					onEnter: async ({ next, store, params }) => {
						const { any } = params;

						// 全球的「我要租車」頁只有（src\layouts\RentalGlobal\index.tsx）此頁，其下的 route 沒有其他頁面，因此只要進到底下的 route，都轉導回此頁。
						await store.dispatch(
							replaceRoute({
								pathname: appPath.rental,
								withLoc: false,
								queries: {
									path: typeof any !== 'string' ? undefined : any,
								},
							}),
						);

						const children = await next();

						return children;
					},
				},
			],
		},
		{
			path: appPath.travelQuestions,
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [
						import(/* webpackChunkName: 'rentalGlobal' */ '../layouts/RentalGlobal'),
					],
					render: ([RentalGlobal]) => <RentalGlobal />,
				},
				{
					path: '/:any',
					components: () => [],
					render: (_, children) => children,
					onEnter: async ({ next, store, params }) => {
						const { any } = params;

						// 旅遊「常見問題」頁因為沒有全球內容故須導致 RentalGlobal 頁來選擇地區。
						await store.dispatch(
							replaceRoute({
								pathname: appPath.travelQuestions,
								withLoc: false,
								queries: {
									path: typeof any !== 'string' ? undefined : any,
								},
							}),
						);

						const children = await next();

						return children;
					},
				},
			],
		},
		{
			path: appPath.travelGuide,
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [
						import(/* webpackChunkName: 'rentalGlobal' */ '../layouts/RentalGlobal'),
					],
					render: ([RentalGlobal]) => <RentalGlobal />,
				},
				{
					path: '/:any',
					components: () => [],
					render: (_, children) => children,
					onEnter: async ({ next, store, params }) => {
						const { any } = params;

						// 旅遊「服務內容」頁因為沒有全球內容故須導致 RentalGlobal 頁來選擇地區。
						await store.dispatch(
							replaceRoute({
								pathname: appPath.travelGuide,
								withLoc: false,
								queries: {
									path: typeof any !== 'string' ? undefined : any,
								},
							}),
						);

						const children = await next();

						return children;
					},
				},
			],
		},
		...commonRoutes,
		OrderRoute,
		SigninRoute,
		AccountRoute,
		AuthCallbackRoute,
		{
			path: '/:locale',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import(/* webpackChunkName: 'home' */ '../layouts/LandingPage')],
					render: ([Home]) => <Home />,
				},
				RentalRoute,
				...commonRoutes,
				QuestionsRoute,
				DownloadRoute,
			],
		},
	],
};

export default routes;
