import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

import TravelNewsRoute from './News';
import TravelCustomToursRoute from './CustomTours';
import TravelCustomReservationRoute from './CustomReservation';
import TravelQuestionsRoute from './Questions';
import TravelBriefRoute from './Brief';
import TravelClassicToursRoute from './ClassicTours';
import TravelToursRoute from './TravelTours';
import TravelGuide from './Guide';
import TravelPayment from './payment';
import TravelNotice from './TravelNotice';

const routes: CustomRoute = {
	path: appPath.travel,
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'travel' */ './component')],
			render: ([Travel]) => <Travel />,
		},
		TravelNewsRoute,
		TravelCustomToursRoute,
		TravelCustomReservationRoute,
		TravelQuestionsRoute,
		TravelBriefRoute,
		TravelClassicToursRoute,
		TravelToursRoute,
		TravelGuide,
		TravelPayment,
		TravelNotice,
	],
};

export default routes;
