import React, { MouseEvent } from 'react';

import { useRouting, PushRouteProperty } from 'models/routing';
import { useLocale } from 'models/locale';

const onClickHandler = (
	callback: (e: MouseEvent<HTMLAnchorElement>) => void,
	pushRoute: (props: PushRouteProperty) => void,
) => {
	return (e: MouseEvent<HTMLAnchorElement>): void => {
		const { pathname, search, hash } = e.currentTarget;

		e.preventDefault();
		pushRoute({
			pathname,
			search,
			hash,
		});
		callback(e);
	};
};

interface LinkProperty {
	className?: string;
	type?: 'internal' | 'external';
	to?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const Link: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	to,
	onClick = () => {},
	children,
}) => {
	const [{ currentLocCode, isGlobal }] = useLocale();
	const [, { pushRoute }] = useRouting();

	const loc = isGlobal ? '' : `/${currentLocCode}`;

	if (type === 'external') {
		return (
			<a
				className={className}
				// 2022-03-30 首次上 prod， isGlobal 永遠是 false ，因為「會員中心」掛在全球底下，這裡要另外判斷 href 含有 /account 的情況導到正確的 route。
				href={to?.includes('http') || to?.includes('/account') ? to : `${loc}${to}`}
				target="_blank"
				rel="noopener noreferrer"
				onClick={onClick}
			>
				{children}
			</a>
		);
	}

	return (
		<a
			className={className}
			href={to}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, pushRoute)}
			onKeyPress={() => {}}
		>
			{children}
		</a>
	);
};

export default Link;
