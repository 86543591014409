import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

// A modern alternative to CSS resets
import 'normalize.css';
// Global css setting
import './global.css';

import Router from 'layouts/Router';
import Root from 'layouts/Root';

import { initi18next } from 'util/i18n';
import storage from 'util/storage';
import { globalLocal } from 'models/locale';

import configureStore from './store';
import history from './store/history';
import routes from './routes';

const store = configureStore({});
const i18n = initi18next(store);

// 2022-03-30 首次上 prod 隱藏 global locale，並以 'TW' 為預設 locale。
const { API } = process.env;
storage.setItem('locale', API === 'stage' || API === 'production' ? `${2}` : `${globalLocal.id}`);

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<Root>
				<Router history={history} routes={routes} store={store} />
			</Root>
		</Provider>
	</I18nextProvider>,
	document.getElementById('content'),
);
