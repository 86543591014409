/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { fetchFunc, wrapRetryAdminFetch } from 'util/api';
import storage from 'util/storage';

import { State as GlobalState } from './reducers';

export const PositionMapper = {
	logistics_centers: 'LOGISTICS_CENTER',
	stores: 'STORE',
} as const;

export type AdminUserPisitionType = keyof typeof PositionMapper;
type AdminUserPisitionData = Record<AdminUserPisitionType, { id: number; name: string }[]>;

// export const adminUserPositionTypes = Object.keys(PositionMapper) as AdminUserPisitionType[];
export const adminUserPositionOptionValues = Object.values(PositionMapper);

export interface State {
	info: {
		loading: boolean;
		error: string;
		data: {
			id: number;
			account: string;
		} | null;
	};
	positions: {
		loading: boolean;
		error: string;
		data: AdminUserPisitionData;
	};
}

const initialState: State = {
	info: {
		loading: true,
		error: '',
		data: null,
	},
	positions: {
		loading: true,
		error: '',
		data: {
			logistics_centers: [],
			stores: [],
		},
	},
};

export const refreshAdminToken = createAction('REFRESH_ADMIN_TOKEN', async () => {
	try {
		const { status: refreshStatus, token } = await fetchFunc(
			`refreshToken`,
			{
				method: 'POST',
			},
			{},
			{ enpointType: 'baskstage', withAuth: true },
		);
		if (refreshStatus) {
			storage.setItem('token', token, false);
		}
		return true;
	} catch (err) {
		return false;
	}
});

const fetchAdminUserInfo = createAction<Promise<State['info']>>(
	'FETCH_ADMIN_USER_INFO',
	async () => {
		const { status, message, data } = await wrapRetryAdminFetch<
			Exclude<State['info']['data'], null>
		>('adminUser/info', { method: 'GET' });

		if ((status !== 200 && status !== 201) || !data) {
			return { data: null, error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

const fetchAdminUserPositions = createAction<Promise<State['positions']>, number>(
	'FETCH_ADMIN_USER_POSITIONS',
	async (bicycle_id: number) => {
		const { status, data, message } = await wrapRetryAdminFetch<State['positions']['data']>(
			'adminUser/positions',
			{ method: 'GET' },
			{ bicycle_id },
		);

		if ((status !== 200 && status !== 201) || !data) {
			return { data: initialState.positions.data, error: message, loading: false };
		}

		return { data, error: '', loading: false };
	},
);

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	adminUser: handleActions<State, any>(
		{
			FETCH_ADMIN_USER_INFO_PENDING: state => ({
				...state,
				info: initialState.info,
			}),

			FETCH_ADMIN_USER_INFO_FULFILLED: (state, action: Action<State['info']>) => ({
				...state,
				info: {
					...state.info,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),

			FETCH_ADMIN_USER_POSITIONS_PENDING: state => ({
				...state,
				positions: initialState.positions,
			}),

			FETCH_ADMIN_USER_POSITIONS_FULFILLED: (state, action: Action<State['positions']>) => ({
				...state,
				positions: {
					...state.positions,
					loading: action.payload.loading,
					data: action.payload.data,
					error: action.payload.error,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useAdminUserInfo ++
++----------------------------------------------------------------------*/
const selectInfo = (state: GlobalState) => state.adminUser.info;
const infoActionsMap = {
	fetchAdminUserInfo,
};

export const useAdminUserInfo = () =>
	useRedux<ReturnType<typeof selectInfo>, typeof infoActionsMap>(selectInfo, infoActionsMap);

/* +----------------------------------------------------------------------
	++ useAdminUserPositions ++
++----------------------------------------------------------------------*/
const selectPositions = (state: GlobalState) => state.adminUser.positions;
const positionsActionsMap = {
	fetchAdminUserPositions,
};

export const useAdminUserPositions = () =>
	useRedux<ReturnType<typeof selectPositions>, typeof positionsActionsMap>(
		selectPositions,
		positionsActionsMap,
	);
