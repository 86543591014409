import { wrapRetryAdminFetch } from 'util/api';

type Product = {
	id: number;
	amount: number;
};

interface AdminRentalOrderData {
	total_price: number;
	bicycle_specs: Product[];
	products: Product[];
}

export type AdminRentalOrderResponse = {
	bicycle_spec_errors: {
		[id: number]: string;
	};
	product_stock_errors: {
		[id: number]: string;
	};
};

export const submitAdminRentalOrderFunc = async (selector: string, data: AdminRentalOrderData) => {
	const response = await wrapRetryAdminFetch<never | AdminRentalOrderResponse>(
		`rentalOrder/${selector}/addPurchase`,
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
	);

	return response;
};
