import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { appPath } from 'util/routingConfig';

const routes: CustomRoute = {
	path: appPath.rentalReservation.replace(appPath.rental, ''),
	components: () => [import(/* webpackChunkName: 'rentalReservation' */ './component')],
	render: ([Reservation]) => <Reservation />,
};

export default routes;
